import { 
  FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE
} from "@/Hooks/Supply/UnitMeasurementHooks";
import { IUseCallAPI } from "./interfaces";
import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { UnitMeasurementAPI } from "@/Data/API/Supply/UnitMeasurementApi";

export const useDosageInputsCallAPI = ({
  paginationUnitMeasurement
}: IUseCallAPI) => {
  const { data: unitMeasurementList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementDropDown,
    initialPagination: paginationUnitMeasurement,
    autoIncrement: true
  });

  return {
    unitMeasurementList
  };
};
import { FC, useEffect } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dropdown, TextInput, Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import { useInputDescriptionSubGroupFunctions } from './Hooks/useInputDescriptionSubGroupFunctions';
import { useInputDescriptionSubGroupCallAPI } from './Hooks/useInputDescriptionSubGroupCallAPI';
import { useInputDescriptionSubGroupMemorizations } from './Hooks/useInputDescriptionSubGroupMemorizations';
import { IInputDescriptionSubGroup } from './interfaces';

export const InputDescriptionSubGroup: FC<IInputDescriptionSubGroup> = ({
  disabled,
  onDisabledButton,
  setIsErrorValidadeDescription,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { onGroupValidationError, onSuccessValidationError } =
    useInputDescriptionSubGroupFunctions({
      setIsErrorValidadeDescription,
    });

  const { mutateAsync, data, dataGroupDropDown, fetchNewGroupPage } =
    useInputDescriptionSubGroupCallAPI({
      onGroupValidationError,
      onSuccessValidationError,
    });

  const { fetchProductSubGroupValidationName, items } =
    useInputDescriptionSubGroupMemorizations({
      mutateAsync,
      dataGroupDropDown,
    });

  useEffect(() => {
    onDisabledButton &&
      onDisabledButton(
        form?.values?.descricao && form?.values?.grupoPaiExternalId
      );
  }, [
    onDisabledButton,
    form?.values?.grupoPaiExternalId,
    form?.values?.descricao,
  ]);

  return (
    <>
      <Col span={7}>
        <Tooltip
          title={`${t('products.toolTips.subGroups.nameOfSubGroup')} ${
            data?.subGrupoDescricao
          }`}
          showMe={
            data?.subGrupoDescricao !== form?.values?.descricao && !disabled
          }
        >
          <TextInput
            name="descricao"
            disabled={disabled}
            withoutMarginBottom={disabled}
            withStatusIcon
            required={!disabled}
            label={t('products.subGroupList.edit.name')}
            onChange={(x) => {
              fetchProductSubGroupValidationName(
                x,
                form?.values?.grupoPaiExternalId
              );
            }}
            dataTestId="subgroup-description"
          />
        </Tooltip>
      </Col>
      <Col span={7}>
        <Tooltip
          title={`${t('products.toolTips.subGroups.nameOfGroup')} ${
            data?.grupoDescricao
          }`}
          showMe={
            data?.grupoExternalId !== form?.values?.grupoPaiExternalId &&
            !disabled
          }
        >
          <Dropdown
            name="grupoPaiExternalId"
            selectedItemFromForm="grupoLabelInitialValue"
            disabled={disabled}
            withoutMarginBottom={disabled}
            withStatusIcon
            required={!disabled}
            label={t('products.subGroupList.edit.group')}
            items={items}
            onChange={(x) => {
              fetchProductSubGroupValidationName(form?.values?.descricao, x);
            }}
            onScrollEnd={fetchNewGroupPage}
            dataTestId="primary-group"
          />
        </Tooltip>
      </Col>
    </>
  );
};

import { Row } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card
} from '@/Components';
import { useFormikContext } from 'formik';
import { IInfoCardBody } from './interfaces';
import { useInfoCardStates } from './Hooks/useInfoCardStates';
import { useInfoCardCallAPI } from './Hooks/useInfoCardCallAPI';
import { Product } from './Components/Product';
import { Class } from './Components/Class';
import { BatchCode } from './Components/BatchCode';
import { ManufacturingDate } from './Components/ManufactoringDate';
import { ValidityDate } from './Components/ValidityDate';
import { Provider } from './Components/Provider';
import { CorporateName } from './Components/CorporateName';
import { Cnpj } from './Components/Cnpj';
import { NumberInvoice } from './Components/NumberInvoice';
import { SeriesInvoice } from './Components/SeriesInvoice';

export const InfoCard: FC<IInfoCardBody> = ({
  disabledForm,
  onClassTypeSelected,
  createError
}) => {
  const { t } = useTranslation();
  const form = useFormikContext();

  const {
    providerId,
    setProviderId
  } = useInfoCardStates();

  const {
    provider
  } = useInfoCardCallAPI({ providerId });

  useEffect(() => {
    const error = form.errors as any;
    if (error.numero !== ' ' && createError === true) {
      form.setFieldError('numero', ' ');
    }
    if (error.numeroNf !== ' ' && createError === true) {
      form.setFieldError('numeroNf', ' ');
    }
  }, [createError, form]);

  useEffect(() => {
    if (provider) {
      form.setFieldValue('Cnpj', provider.cnpj);
    }
  }, [provider]);

  return (
    <Card title={t('supply.lot.create.titleInformation')} withoutCollapse>
      <Row gutter={[16, 0]} justify="space-between">
        <Product
          disabledForm={disabledForm}
          onClassTypeSelected={onClassTypeSelected}
        />
        <Class />
        <BatchCode />
        <ManufacturingDate />
        <ValidityDate />
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Provider
          disabledForm={disabledForm}
          setProviderId={setProviderId}
        />
        <CorporateName />
        <Cnpj />
      </Row>
      <Row gutter={[16, 0]}>
        <NumberInvoice />
        <SeriesInvoice />
      </Row>
    </Card>
  );
};

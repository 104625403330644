import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../../Components/Table';
import { inventoryCollumn } from './Inventory.column';
import { ContainerPage } from '../../../../../../Layouts/ContainerPage';
import { PageLoading } from '../../../../../../Layouts/PageLoading';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';

export const StorageLocationInventory: React.FC = () => {
  const { t } = useTranslation();

  const {
    inventoryBySupplyPlaceList,
    isLoadingInventoryBySupplyPlaceList,
    refetchInventorySupplyPlaceList,
  } = useListCallAPI();

  const { headerOptions, rowOptions } = useListMemorizations();

  return (
    <PageLoading loading={isLoadingInventoryBySupplyPlaceList}>
      <ContainerPage>
        <Table
          columns={inventoryCollumn(t)}
          headerOptions={headerOptions}
          rowsOptions={rowOptions}
          pagination={inventoryBySupplyPlaceList}
          onRefetch={refetchInventorySupplyPlaceList}
          loading={isLoadingInventoryBySupplyPlaceList}
          hasSelection={false}
        ></Table>
      </ContainerPage>
    </PageLoading>
  );
};

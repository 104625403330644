import {
  GET_USER_LIST_FROM_USER_GROUP_TYPE,
  REMOVE_USER_IN_USER_GROUP,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import { useQueryClient } from '@tanstack/react-query';

export const useUserFormCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: userListData,
    isLoading: userListLoading,
    refetch: userListRefetch,
  } = useControllerQueryListApiHook({
    uniqId: GET_USER_LIST_FROM_USER_GROUP_TYPE,
    entityApi: UserGroupAPI.getUserListFromUserGroup,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'nome', direction: 'ASC' },
    },
  });

  const removeUserInUserGroup = useControllerMutateApiHook({
    uniqId: REMOVE_USER_IN_USER_GROUP,
    entityApi: UserGroupAPI.removeUserInGroup,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([GET_USER_LIST_FROM_USER_GROUP_TYPE]),
    },
  });

  return {
    userListData,
    userListLoading,
    userListRefetch,
    removeUserInUserGroup,
  };
};

import React, { FC } from 'react';
import { CompanyAdminList } from '../../../../Company/List';

interface ICompanyAdminListTab {
  UrlExternalId: string;
}

export const CompanyAdminListTab: FC<ICompanyAdminListTab> = ({
  UrlExternalId,
}) => {
  return (
    <CompanyAdminList
      filter={[
        {
          filterName: 'conglomeradoExternalId',
          value: UrlExternalId,
        },
      ]}
    />
  );
};

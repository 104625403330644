import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useBatchControlFunctions = ({
  stockBalance,
  setLotControlModal
}: IUseFunctions) => {
  const onClick = useCallback(() => {
    stockBalance &&
      stockBalance?.saldo > 0 &&
      setLotControlModal(true);
  }, [stockBalance]);

  return { onClick };
};
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInput
} from '@/Components';
import { IFormDescriptionBody } from './interfaces';
import { useFormDescriptionBodyCallAPI } from './Hooks/useFormDescriptionBodyCallAPI';
import { useFormDescriptionBodyFunctions } from './Hooks/useFormDescriptionBodyFunctions';

export const FormDescriptionBody: FC<IFormDescriptionBody> = ({
  disabledForm,
  form
}) => {
  const { t } = useTranslation();
  
  const {
    mutateAsync
  } = useFormDescriptionBodyCallAPI({
    form
  });

  const {
    fetchSpecialtiesValidationName
  } = useFormDescriptionBodyFunctions({
    mutateAsync
  });

  return (
    <TextInput
      name="descricao"
      label={t('specialties.edit.descriptionLabel')}
      placeHolder={t('specialties.edit.descriptionPlaceholder')}
      withoutMarginBottom
      withStatusIcon
      disabled={disabledForm}
      onChange={fetchSpecialtiesValidationName}
    />
  );
};

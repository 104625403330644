import {
  FETCH_GET_LOT,
  FETCH_LIST_STOCKLOCATIONS_OF_LOTS,
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { IGetLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { useParams } from 'react-router';
import { useEffect } from 'react';

export const useStockLocationCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: lot } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalId: externalId,
  });

  const {
    data: stockLocationList,
    isLoading: isLoadingList,
    refetch: refetchStockLocationList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STOCKLOCATIONS_OF_LOTS,
    entityApi: LotsAPI.stockLocationsOfLotsList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'localEstoqueDescricao', direction: 'ASC' },
      filter: [
        { filterName: 'produtoExternalId', value: lot?.produtoExternalId },
        { filterName: 'loteExternalId', value: lot?.externalId },
      ],
    },
    autoIncrement: true,
  });

  useEffect(() => {
    refetchStockLocationList({
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'localEstoqueDescricao', direction: 'ASC' },
      filter: [
        { filterName: 'produtoExternalId', value: lot?.produtoExternalId },
        { filterName: 'loteExternalId', value: lot?.externalId },
      ],
    });
  }, [refetchStockLocationList, lot?.produtoExternalId, lot?.externalId]);

  return {
    stockLocationList,
    isLoadingList,
    refetchStockLocationList,
  };
};

import { useParams } from 'react-router';
import { FETCH_LIST_COUNTRY } from '@/ControllerApiHook/UniqIds/Supply/CountryKeys';
import { FETCH_GET_LOT } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { FETCH_LIST_ALTERNATIVE_UNITS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { useControllerQueryApiHook, useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { CountryAPI } from '@/Data/API/Supply/CountryApi';
import { IGetLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';

export const useTechnicalInfoCardCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: lots } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalId: externalId
  });
  
  const { 
    data: paisOrigemList 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_COUNTRY,
    entityApi: CountryAPI.listCountry,
    initialPagination: {
      pageIndex: 0,
      pageSize: 500
    },
    autoIncrement: true
  });

  const { 
    data: unidadeAlternativaList 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_ALTERNATIVE_UNITS,
    entityApi: UnitMeasurementAPI.listAlternativeUnitMeasurement,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10
    },
    autoIncrement: true
  });

  return {
    lots,
    paisOrigemList,
    unidadeAlternativaList
  };
};
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import {
  LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
  LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
  THERE_IS_BIND_LABORATORY_TO_COMPANY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';

export const useCreateCallAPI = ({
  form,
  t,
  paginationCompany,
  paginationSupplyPlace,
}: IUseCallAPI) => {
  const {
    data: companyList,
    isLoading: isLoadingCompany,
    refetch: refetchSearchCompanyPage,
    fetchNewPage: fetchInputCompany,
  } = useControllerQueryListApiHook({
    uniqId: LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listCompanyForDropdownToLaboratory,
    initialPagination: paginationCompany,
  });

  const {
    data: supplyPlaceList,
    isLoading: isLoadingSupplyPlace,
    refetch: refetchSupplyPlace,
  } = useControllerQueryListApiHook({
    uniqId: LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listSupplyPlaceForDropdownToLaboratory,
    initialPagination: paginationSupplyPlace,
  });

  const { mutateAsync: fetchDescriptionLaboratory }: any =
    useControllerMutateApiHook({
      uniqId: THERE_IS_BIND_LABORATORY_TO_COMPANY,
      entityApi: LaboratoryAPI.laboratoryBindCompany,
      options: {
        onSuccess: () => {
          form?.setErrors({
            ...form?.errors,
            nomeLaboratorio: undefined,
          });
        },
        onError: () => {
          form?.setErrors({
            ...form?.errors,
            nomeLaboratorio: t('production.laboratory.create.validation'),
          });
        },
      },
    });

  return {
    companyList,
    isLoadingCompany,
    supplyPlaceList,
    isLoadingSupplyPlace,
    fetchDescriptionLaboratory,
    refetchSearchCompanyPage,
    refetchSupplyPlace,
    fetchInputCompany,
  };
};

import React, { ForwardRefExoticComponent, forwardRef } from 'react';
import { useField } from 'formik';
import { Text } from '../..';
import CurrencyFormat from 'react-currency-format';
import StorageUtils from '../../../Utils/StorageUtils';
import { isNumeric } from '@/Utils/IsNumericUtils';
import { INumberInput } from './types';
import { InnerNumberInput } from './VisualInput';

import styles from './NumberInput.module.scss';
import './NumberInput.override.scss';

export type { INumberInput } from './types';

export const NumberInput: ForwardRefExoticComponent<
  INumberInput & React.RefAttributes<unknown>
> = forwardRef(
  (
    {
      name,
      prefix,
      decimalScale,
      withCurrencyPrefix,
      withThousandSeparator,
      withDecimalSeparator,
      fixedDecimalScale,
      suffix,
      onChange,
      onBlur,
      subText,
      subTextTestId,
      acceptOnlyNumbers,
      ...props
    },
    ref
  ) => {
    const field = useField(name);
    const preference = StorageUtils.getPreference();

    let thousandSeparator;
    let decimalSeparator;

    if (withThousandSeparator) {
      thousandSeparator = preference?.idioma === 'pt-BR' ? '.' : ',';
    }

    if (withDecimalSeparator) {
      decimalSeparator = preference?.idioma === 'pt-BR' ? ',' : '.';
    }

    return (
      <>
        <CurrencyFormat
          name={name}
          decimalScale={decimalScale}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          fixedDecimalScale={fixedDecimalScale}
          customInput={InnerNumberInput}
          prefix={prefix}
          suffix={suffix}
          onBlur={onBlur}
          onValueChange={(x) => {
            onChange && onChange(isNumeric(x.floatValue) ? x.floatValue : 0);
            return field[2].setValue(
              isNaN(x.floatValue) ? undefined : x.floatValue
            );
          }}
          {...({
            ...props,
            name,
            outsideRef: ref,
            separators: {
              decimalScale,
              decimalSeparator,
              thousandSeparator,
            },
            acceptOnlyNumbers,
          } as any)}
        />
        {subText && (
          <Text
            id={subTextTestId}
            type="ui-tiny-content"
            color="text-300"
            children={subText}
            className={styles['sub-text']}
          />
        )}
      </>
    );
  }
);

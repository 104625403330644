import { FC } from "react";
import { Col } from 'antd';
import { InputWithSearch } from '@/Components';
import { useTranslation } from "react-i18next";
import { IRegisterType } from "./interfaces";
import { useRegisterTypeMemorizations } from "./Hooks/useRegisterTypeMemorizations";
import { useRegisterTypeFunctions } from "./Hooks/useRegisterTypeFunctions";

export const RegisterType: FC<IRegisterType> = ({
  recordTypeList,
  fetchNewRecordPage,
  recordTypeListLoading,
  refetchStateList
}) => {
  const { t } = useTranslation();

  const { items } = useRegisterTypeMemorizations({ recordTypeList });

  const { onSearch } = useRegisterTypeFunctions({ refetchStateList });

  return (
    <Col flex="24%">
      <InputWithSearch
        name="tipoRegistroId"
        placeHolder={t(
          'prescribers.createPage.info.registerPlaceholder'
        )}
        label={t('prescribers.createPage.info.register')}
        required
        items={items}
        onScrollEnd={fetchNewRecordPage}
        isLoading={recordTypeListLoading}
        onSearch={onSearch}
        withoutSearchIcon
      />
    </Col>
  );
};
import { useQueryClient } from '@tanstack/react-query';
import {
  CHANGE_PRESCRIBER_STATUS,
  DELETE_PRESCRIBER,
  FETCH_LIST_PRESCRIBERS,
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';
import { useEffect } from 'react';
import { IUseCallAPI } from './interfaces';
import { isNil } from 'lodash';

export const usePrescribersCallAPI = ({ externalId }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const changeStatus: any = useControllerMutateApiHook({
    uniqId: CHANGE_PRESCRIBER_STATUS,
    entityApi: PrescribersAPI.changePrescriberStatus,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(i18n.t('prescribers.messages.changeStatus')),
    },
  });

  const {
    data: prescribersDataList,
    isLoading: prescribersLoadingList,
    refetch: refetchPrescribersList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribers,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'nomeCompleto', direction: 'ASC' },
    },
    enabled: !isNil(externalId),
  });

  useEffect(() => {
    refetchPrescribersList({
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'especialidadeExternalId', value: externalId }],
    });
  }, [externalId, refetchPrescribersList]);

  const { mutateAsync: deletePrescriber }: any = useControllerMutateApiHook({
    uniqId: DELETE_PRESCRIBER,
    entityApi: PrescribersAPI.deletePrescribers,
    options: {
      onError: (x: any) => x && !x.errorCode && messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('prescribers.listPage.deleteModal.success'));

        queryClient.invalidateQueries([FETCH_LIST_PRESCRIBERS]);
      },
    },
  });

  return {
    changeStatus,
    prescribersDataList,
    prescribersLoadingList,
    refetchPrescribersList,
    deletePrescriber,
  };
};

import { FC } from 'react';
import { Modal } from '../../../../../../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';
import { Steps } from '@/Components/Steps';
import { useHistoricMemorizations } from './Hooks/useHistoricMemorizations';
import { useHistoricCallAPI } from './Hooks/useHistoricCallAPI';

interface IHistoricModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrderExternalId?: string;
}

export const HistoricModal: FC<IHistoricModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrderExternalId,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('saleOrder.editOrder.topButtons.historic')}
      body={<HistoricModalBody saleOrderExternalId={saleOrderExternalId} />}
      visible={visibleModal}
      onCancelClick={() => changeVisibleModal(false)}
      cancelButtonName={t('common.close')}
      onClose={() => changeVisibleModal(false)}
      withoutOkButton
      maxWidth={400}
      htmlType="submit"
    />
  );
};

interface IHistoricBody {
  saleOrderExternalId?: string;
}

export const HistoricModalBody: FC<IHistoricBody> = ({
  saleOrderExternalId,
}) => {
  const { t } = useTranslation();

  const { historicSaleOrder } = useHistoricCallAPI(saleOrderExternalId);

  const { historicItems } = useHistoricMemorizations({
    historicSaleOrder,
    t,
  });

  return (
    <>
      <div style={{ overflow: 'auto', maxHeight: '65vh', padding: 25 }}>
        {historicSaleOrder.isLoading ? (
          <>
            <Skeleton active avatar style={{ width: 340 }} />
            <Skeleton active avatar style={{ width: 340 }} />
          </>
        ) : (
          <div className="purchase-oder-time-line">
            <Steps type="historical" items={historicItems ?? []} />
          </div>
        )}
      </div>
    </>
  );
};

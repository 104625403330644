import { useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import { IUseFunctions } from "./interfaces";
import _ from 'lodash';

export const useModalBodyFunctions = ({
  mutateAsync,
  specialtiesName
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const fetchSpecialtiesValidationName = useMemo(
    () =>
      _.debounce(async (description: string) => {
        return await mutateAsync({
          descricao: description,
        });
      }, 500),
    [mutateAsync, form.values.descricao]
  );

  const onChange = useCallback((x: string) => {
    fetchSpecialtiesValidationName(x);
    specialtiesName(x);
  }, [
    fetchSpecialtiesValidationName,
    specialtiesName
  ]);

  return {
    onChange
  };
};
import { ModalFuncProps } from 'antd';
import {
  ICommonError,
  IGlobalModal,
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Content } from './Components/Content';
import { Title } from './Components/Title';
import { Footer } from './Components/Footer';

export const CannotDeleteModal: (
  error: ICommonError,
  params: { loteExternalId: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
});

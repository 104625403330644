import React from 'react';
import { Row, Col } from 'antd';
import { Dropdown } from '../../../../../../../../../Components';
import { InputWithSearch } from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { IListFreightTypeData } from '../../../../../../../../../Data/Interfaces/response/Purchase/IPurchaseResponse';
import { IListProviderData } from '../../../../../../../../../Data/Interfaces/response/Provider/IProviderResponse';
import { listProviderConfig } from '../../../../utils/functions';

interface IFourthRow {
  freightTypeList: { data: IListFreightTypeData[] };
  listShippingCompany: IListProviderData[];
  isLoading: boolean;
  onScrollEnd: () => void;
  onSearch: (search?: string) => void;
}

const FourthRow: React.FC<IFourthRow> = ({
  freightTypeList,
  listShippingCompany,
  onScrollEnd,
  isLoading,
  onSearch,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <InputWithSearch
          name="transportadoraExternalId"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.searchForCarrier'
          )}
          label={t('purchasing.invoice.create.carrierCode')}
          items={listProviderConfig(listShippingCompany)}
          onScrollEnd={onScrollEnd}
          isLoading={isLoading}
          onSearch={onSearch}
        />
      </Col>
      <Col span={6}>
        <Dropdown
          name="tipoFreteId"
          items={
            freightTypeList.data
              ? freightTypeList.data.map((e, i) => ({
                  id: e.id,
                  label: `${i} - ${e.descricao}`,
                }))
              : []
          }
          label={t('purchasing.invoice.create.frightType')}
          required
        />
      </Col>
    </Row>
  );
};

export default FourthRow;

import { Checkbox, TextInput } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import styles from '../../../CapsuleColors.module.scss';

export const DescriptionRow: FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={8}>
        <TextInput
          name="corCapsula"
          label={t('production.capsuleColors.create.capsuleColor')}
          placeHolder={t('production.capsuleColors.create.namePlaceholder')}
          required
        />
      </Col>

      <Col span={6} className={styles['checkbox']}>
        <Checkbox
          name="transparente"
          label={t('production.capsuleColors.create.transparentCapsule')}
        />
      </Col>
    </Row>
  );
};

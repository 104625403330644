import { 
  FETCH_LIST_UNITS_BY_CLASS 
} from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { 
  CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE 
} from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook
} from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IUseCallAPI } from './interfaces';

export const useUnitaryValueCallAPI = ({
  unitMeasurementPagination
}: IUseCallAPI) => {
  const { 
    data: unitsMeasurements 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClassAny,
    initialPagination: unitMeasurementPagination,
  });

  const { 
    mutateAsync: convertUnitOfMeasureMonetaryValue 
  }: any = useControllerMutateApiHook({
    uniqId: CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE,
    entityApi: UnitMeasurementAPI.convertUnitOfMeasureMonetaryValue,
  });

  return {
    unitsMeasurements,
    convertUnitOfMeasureMonetaryValue
  };
};
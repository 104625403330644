import { IUseFunctions } from "./interfaces";

export const useProviderFunctions = ({
  setExternalIdProvider,
  refetchSearchProviderPage
}: IUseFunctions) => {
  const onChange = (value: string) => {
    setExternalIdProvider(value);
  };

  const onSearch = (search: string | undefined) => {
    refetchSearchProviderPage({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'nome', direction: 'DESC' },
    });
  };

  return {
    onChange,
    onSearch
  };
};
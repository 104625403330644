import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { FETCH_DROPDOWN_LIST_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { FETCH_LIST_SERVICE_CHANNEL } from '@/ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { GET_USER_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';

export const useBodyCallAPI = () => {
  const clientList = useControllerQueryListApiHook({
    uniqId: FETCH_DROPDOWN_LIST_CLIENT,
    entityApi: ClientAPI.listDropdownClient,
    autoIncrement: true,
  });
  const serviceChannelList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SERVICE_CHANNEL,
    entityApi: ServiceAPI.listServiceChannel,
    autoIncrement: true,
  });

  const { data: myUser } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_USER_TYPE,
    entityApi: UserAPI.getUser,
  });

  return {
    clientList,
    serviceChannelList,
    myUser,
  };
};

import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const CreateTransferSchema = yup.object().shape({
  origin: yup.string().required(i18n.t('common.error.requiredField')),

  destiny: yup.string().required(i18n.t('common.error.requiredField')),

  transfers: yup
    .array()
    .of(
      yup.object().shape({
        originFunds: yup.number(),
        destinyFunds: yup.number(),
        product: yup.string().required(i18n.t('common.error.requiredField')),
        lot: yup.string().required(i18n.t('common.error.requiredField')),
        qntTransfers: yup
          .string()
          .required(i18n.t('common.error.requiredField')),
      })
    )
    .required(i18n.t('common.error.requiredField')),
});

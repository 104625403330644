import {
  EDIT_INFO_PRESCRIBERS,
  GET_PRESCRIBER
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_LIST_RECORD_TYPE } from '@/ControllerApiHook/UniqIds/People/RecordKeys';
import { FETCH_LIST_SPECIALTIES_FOR_DROPDOWN } from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { GET_LIST_STATE } from '@/ControllerApiHook/UniqIds/Locality/StateKeys';
import { IUseCallAPI } from './interfaces';
import { 
  useControllerMutateApiHook, 
  useControllerQueryListApiHook 
} from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import { RecordAPI } from '@/Data/API/People/RecordApi';
import { StateAPI } from '@/Data/API/Locality/StateApi';

export const useInfoModalCallAPI = ({
  changeVisibleModal
}: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    data: recordTypeList,
    refetch: refetchRecordTypeList,
    fetchNewPage: fetchNewRecordPage,
    isLoading: recordTypeListLoading,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_RECORD_TYPE,
    entityApi: RecordAPI.listRecordType,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  const {
    data: listState,
    refetch: refetchStateList,
    fetchNewPage: fetchNewStatePage,
    isLoading: listStateLoading,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    initialPagination: {
      pageIndex: 0,
      pageSize: 50,
      sorter: { column: 'descricao', direction: 'ASC' }
    }
  });

  const { 
    data: specialtiesData, 
    refetch: refetchSearchSpecialties, 
    fetchNewPage: fetchNewPageSpecialties 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SPECIALTIES_FOR_DROPDOWN,
    entityApi: SpecialtiesAPI.listSpecialtiesForDropdown,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  const editInfo: any = useControllerMutateApiHook({
    uniqId: EDIT_INFO_PRESCRIBERS,
    entityApi: PrescribersAPI.editInfoPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('prescribers.editPage.success.contact'));
        
        queryClient.invalidateQueries([GET_PRESCRIBER]);

        changeVisibleModal(true);
      }
    }
  });

  return {
    recordTypeList,
    refetchRecordTypeList,
    fetchNewRecordPage,
    recordTypeListLoading,
    listState,
    refetchStateList,
    fetchNewStatePage,
    listStateLoading,
    specialtiesData,
    refetchSearchSpecialties,
    fetchNewPageSpecialties,
    editInfo
  };
};
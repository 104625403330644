import { IUseFunctions } from './interfaces';
import { useEffect } from 'react';
import { uniqBy } from 'lodash';

export const useCreateFunctions = ({
  storageLocationList,
  setStorageLocations,
}: IUseFunctions) => {
  useEffect(() => {
    if (!storageLocationList?.data) return;

    setStorageLocations((prevState) =>
      uniqBy([...prevState, ...storageLocationList?.data], 'externalId')
    );
  }, [storageLocationList]);

  return {};
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IDeleteClientModal } from './interfaces';
import { useModalCallAPI } from './Hooks/useModalCallAPI';
import { useModalMemorizations } from './Hooks/useModalMemorizatrions';
import { useModalFunctions } from './Hooks/useModalFunctions';

export const DeleteModal: FC<IDeleteClientModal> = ({
  clientName,
  clientExternalId,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const { deleteClient } = useModalCallAPI({ changeVisibleModal });

  const { deleteModalBody } = useModalMemorizations({ clientName });

  const { onOkClickDeleteModal } = useModalFunctions({
    clientExternalId,
    deleteClient,
  });

  return (
    <Modal
      title={`${t('common.delete')} ${t('client.client')}?`}
      body={deleteModalBody}
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onOkClickDeleteModal}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      loading={deleteClient.isLoading}
    />
  );
};

import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { FETCH_CREATE_LOSSE } from '@/ControllerApiHook/UniqIds/Supply/LosseKeys';
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { LossesAPI } from '@/Data/API/Supply/LossesApi';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE } from '@/Hooks/Supply/SupplyHooks';
import StorageUtils from '@/Utils/StorageUtils';

export const useCreateCallAPI = () => {
  const {
    data: productLossList,
    isLoading: isLoadingProduct,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: storageLocationDropDownList,
    isLoading: isLoadingStorageLocation,
    fetchNewPage: fetchNewPageStorageLocation,
  } = useControllerQueryListApiHook({
    uniqId: FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE,
    entityApi: StorageLocationApi.getListStorageLocationDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  return {
    productLossList,
    isLoadingProduct,
    fetchNewProductPage,
    refetchSearchProductPage,
    storageLocationDropDownList,
    isLoadingStorageLocation,
    fetchNewPageStorageLocation,
  };
};

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { HorizontalTabs } from '../../../../../Components';
import { useParams } from 'react-router';
import { FETCH_GET_LABORATORY } from '../../../../../ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import LaboratoryDetail from '../Details';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetLaboratory } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { DeleteLaboratoryModal } from '../Details/components/Modal/deleteModal';

const FormLaboratory: React.FC = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const { externalId, tab } = useParams<{ externalId: string; tab: string }>();

  const { data: laboratory } = useControllerQueryApiHook<IGetLaboratory>({
    uniqId: FETCH_GET_LABORATORY,
    entityApi: LaboratoryAPI.getCurrentLaboratory,
    externalId: externalId,
  });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('common.details'),
            key: 'details',
            children: (
              <LaboratoryDetail
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
        ]}
        dropdownItens={[
          {
            label: t('common.delete'),
            icon: 'trash',
            permission: {
              permission: PermissionEnum.Producao_Laboratorio_Excluir,
              tooltip: 'noPermission.production.laboratory.delete',
            },
            onClick: () => setVisibleModal(true),
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled &&
            tab === 'details' &&
            laboratory && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
              codePermissionEdit:
                PermissionEnum.Producao_Laboratorio_EditarDetalhes,
              messageTooltipPermission:
                'noPermission.production.laboratory.edit',
            }),
        }}
      />
      {laboratory && (
        <DeleteLaboratoryModal
          laboratory={laboratory}
          visibleModal={visibleModal}
          changeVisibleModal={(value) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};

export default FormLaboratory;

import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import {
  Button,
  Icon,
  InputWithSearch,
} from '../../../../../../../../../Components';
import React from 'react';
import { useCreateCallAPI } from '../Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateStates } from './Hooks/useCreateStates';

interface IPackingClassificationRow {
  index: number;
  onDelete: (index: number) => void;
  totalRows: number;
  setProductRowAmount: React.Dispatch<React.SetStateAction<string[]>>;
  uuidV4: () => string;
}

const PackingClassificationRow: React.FC<IPackingClassificationRow> = ({
  index,
  onDelete,
  totalRows,
  setProductRowAmount,
  uuidV4,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<any> = useFormikContext();

  const {
    packingClassificationList,
    isLoadingPackingClassification,
    refetchPackingClassificationList,
  } = useCreateCallAPI();

  const { setCurrentPackingClassification, setIsDeleteButtonDisabled } =
    useCreateStates();

  const { deleteRow, setPackingClassification } = useCreateFunctions({
    form,
    packingClassificationList,
    setProductRowAmount,
    index,
    totalRows,
    onDelete,
    uuidV4,
    setCurrentPackingClassification,
    setIsDeleteButtonDisabled,
  });

  return (
    <>
      <Row wrap={false} gutter={[16, 0]}>
        <Col span={10}>
          <InputWithSearch
            label={
              index === 0
                ? t(
                    'production.pharmaceuticalForm.create.packingClassification'
                  )
                : ''
            }
            name={`embalagemClassificacaoExternalId.${index}.externalId`}
            placeHolder={t(
              'production.pharmaceuticalForm.create.packingClassificationPlaceholder'
            )}
            items={packingClassificationList?.data?.map((x: any) => ({
              id: x?.externalId,
              label: x?.descricao,
            }))}
            onChange={setPackingClassification}
            onSearch={(search?: string) => {
              refetchPackingClassificationList({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              });
            }}
            isLoading={isLoadingPackingClassification}
          />
        </Col>
        <Col>
          <Button
            type="secondary"
            onClick={() => deleteRow(index)}
            disabled={index === totalRows - 1}
            style={{ marginTop: `${index === 0 ? '20px' : 0}` }}
          >
            <Icon
              icon="trash"
              size="L"
              style={{
                cursor: index === totalRows - 1 ? 'not-allowed' : 'default',
                color:
                  index === totalRows - 1
                    ? 'var(--color-status-disabled-base)'
                    : '',
              }}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PackingClassificationRow;

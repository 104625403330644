import {
  i18n
} from '@/Components';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { 
  IDilutedProductResponse, 
  IProductResponse 
} from '@/Data/Interfaces/response/Product/IProductRespose';
import {
  FETCH_GET_DILUTED_PRODUCT,
  FETCH_GET_PRODUCT,
  PUT_DILUTED_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { IUseCallAPI } from './interfaces';

export const useDilutedProductDetailsCallAPI = ({
  productExternalId,
  associationProductId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: productExternalId
  });

  const editDilutedProduct = useControllerMutateApiHook({
    uniqId: PUT_DILUTED_PRODUCT,
    entityApi: ProductAPI.editDilutedProduct,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => messageSucessToast(i18n.t('products.diluted.editSuccess'))
    }
  });

  const { 
    data: dilutedProductData 
  } = useControllerQueryApiHook<IDilutedProductResponse>({
    uniqId: FETCH_GET_DILUTED_PRODUCT,
    entityApi: ProductAPI.getDilutedProduct,
    externalId: associationProductId
  });

  return {
    currentProduct,
    editDilutedProduct,
    dilutedProductData
  };
};
import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { FETCH_LIST_ALTERNATIVE_UNITS } from "@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys";
import { UnitMeasurementAPI } from "@/Data/API/Supply/UnitMeasurementApi";

export const useInfoForFormCalcCallAPI = () => {
  const { data: unidadeAlternativaList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_ALTERNATIVE_UNITS,
    entityApi: UnitMeasurementAPI.listAlternativeUnitMeasurement,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    autoIncrement: true,
  });

  return {
    unidadeAlternativaList
  };
};
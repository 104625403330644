import { FETCH_LIST_FOR_DROPDOWN_TYPE } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { IUseCallAPI } from "./interfaces";

export const useAssociatedProductCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const feedStockClassId = 1;
  const packingClassId = 2;
  const capsuleClassId = 3;

  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [
        {
          filterName: 'classesProdutoIds',
          value: [feedStockClassId, packingClassId, capsuleClassId],
        },
        {
          filterName: 'orderBy',
          value: 'descricao',
        },
        {
          filterName: 'produtoExternalId',
          value: productExternalId,
        },
      ]
    },
    autoIncrement: true
  });

  return {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  };
};
import { IListUnitMeasurementByClassData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { useFormikContext } from "formik";
import { IUseMemorizations } from "./interfaces";

export const useCostValueMemorizations = ({
  product,
  listunitsMeasurements
}: IUseMemorizations) => {
  const form: any = useFormikContext();

  const supportText= form.values.unidadeEstoqueId
  ? `/${
      listunitsMeasurements?.data?.find(
        (x: IListUnitMeasurementByClassData) => x.id === form.values.unidadeEstoqueId
      )?.unidadeAbreviacao
    }`
  : product && `/${product.unidadeMedida.abreviacao}`;

  return {
    supportText
  };
};
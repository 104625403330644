import {
  DELETE_PRODUCT_LINKED_MESSAGE
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
   FETCH_LIST_PRODUCT_LINKED_MESSAGE 
  } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryListApiHook 
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';

export const useProductMessageCallAPI = ({
  externalId
}: IUseCallAPI) => {
  const { 
    data: productMessageLinked, 
    isLoading,
    refetch: refetchProductMessageLinkedList 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCT_LINKED_MESSAGE,
    entityApi: ProductAPI.getProductLinkedMessageList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'mensagemDescricao', direction: 'ASC' },
      filter: [{ filterName: 'produtoExternalId', value: externalId }]
    }
  });

  const removeProductLinkedMsg: any = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT_LINKED_MESSAGE,
    entityApi: ProductAPI.deleteProductLinkedMessage
  });

  return {
    productMessageLinked,
    isLoading,
    refetchProductMessageLinkedList,
    removeProductLinkedMsg
  };
};
import { Col, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { CurrencyInput } from '../../../../../../../../../Components/Inputs/CurrencyInput';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Tooltip } from '../../../../../../../../../Components';
import { currencyFormater } from '../../../../../../../../../Utils/Currency';

interface IFirstRowProps {
  totalProducts: number;
  initialValues: any;
}

export const FirstRow: React.FC<IFirstRowProps> = ({
  totalProducts,
  initialValues,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const [showTotalProductsTooltip, setShowTotalProductsTooltip] =
    React.useState(false);

  const checkInitialValues = useCallback(
    (key: string) => {
      if (form.values) {
        return form.values[key] !== initialValues[key];
      }
      return false;
    },
    [form.values, totalProducts]
  );

  useEffect(() => {
    form.setFieldValue('valorProdutos', totalProducts);

    if (
      form.values.valorProdutos !== initialValues.valorProdutos &&
      initialValues.valorProdutos !== undefined &&
      form.values.valorProdutos !== undefined
    ) {
      setShowTotalProductsTooltip(true);
    } else {
      setShowTotalProductsTooltip(false);
    }
  }, [totalProducts]);

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorBaseIcms')}
          title={`${t(
            'purchasing.invoice.edit.originalIcmsBase'
          )}${currencyFormater(initialValues.valorBaseIcms)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSBase')}
            name="valorBaseIcms"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorIcms')}
          title={`${t(
            'purchasing.invoice.edit.originalIcmsValue'
          )}${currencyFormater(initialValues.valorIcms)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSValue')}
            name="valorIcms"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorBaseIcmsSubstituicao')}
          title={`${t(
            'purchasing.invoice.edit.originalIcmsBaseSubstitution'
          )}${currencyFormater(initialValues.valorBaseIcmsSubstituicao)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSBaseSubstitution')}
            name="valorBaseIcmsSubstituicao"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorIcmsSubstituicao')}
          title={`${t(
            'purchasing.invoice.edit.originalIcmsValueSubstitution'
          )}${currencyFormater(initialValues.valorIcmsSubstituicao)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSValueSubstitution')}
            name="valorIcmsSubstituicao"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={showTotalProductsTooltip}
          title={`${t(
            'purchasing.invoice.edit.originalTotalProducts'
          )}${currencyFormater(initialValues.valorProdutos)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.totalProducts')}
            name="valorProdutos"
            value={`${totalProducts}`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
            disabled
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

import { GET_USER_LIST_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';

export const useModalCallAPI = () => {
  const { data: users } = useControllerQueryListApiHook({
    uniqId: GET_USER_LIST_TYPE,
    entityApi: UserAPI.listUser,
    autoIncrement: true,
  });

  return {
    users,
  };
};

import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';

import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { IUserPreferencesResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { GET_PREFERENCES_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import {
  CREATE_PHARMACEUTICAL_FORM,
  FETCH_GET_PHARMACEUTICAL_FORM,
  FETCH_LIST_FOR_PHARMACEUTICAL_FORM,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createPharmaceuticalForm,
    isLoading: isLoadingCreatePharmaceuticalForm,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.createPharmaceuticalForm,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]);
        queryClient.invalidateQueries([FETCH_GET_PHARMACEUTICAL_FORM]);
        history.push('/production/dosage-form');
      },
    },
  });

  const { data: datePattern } =
    useControllerQueryApiHook<IUserPreferencesResponse>({
      uniqId: GET_PREFERENCES_TYPE,
      entityApi: UserAPI.getPreferences,
    });

  return {
    createPharmaceuticalForm,
    isLoadingCreatePharmaceuticalForm,
    datePattern,
  };
};

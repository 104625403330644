import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';
import { useWaterConcentrationFactorFunctions } from './Hooks/useWaterConcentrationFactorFunctions';

export const WaterConcentrationFactor: FC = () => {
  const { t } = useTranslation();

  const { onChange } = useWaterConcentrationFactorFunctions();

  return (
    <Col span={6}>
      <TextInput
        name="loteInformacaoTecnica.fatorConcentracaoAgua"
        label={[
          {
            children: t('supply.lot.create.waterConcentrationFactor'),
          },
        ]}
        placeHolder={t('supply.lot.create.waterConcentrationFactor')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('supply.lot.create.toolTips.waterConcentrationFactor'),
        }}
        type="number"
        onChange={onChange}
        required
      />
    </Col>
  );
};

import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import { Card, Label, Text } from '../../../../../../../../../Components';
import { Avatar } from '../../../../../../../../../Components/Avatar';
import { ButtonCard } from '../../../../../../../../../Components/EntityInformation/ButtonCard';
import { InfoModal } from '../../../../Modal/infoModal';
import { IGetClientResponse } from '../../../../../../../../../Data/Interfaces/response/Client/IClientResponse';
import DateUtils from '../../../../../../../../../Utils/DateUtils';
import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import StorageUtils from '@/Utils/StorageUtils';

export enum PersonType {
  legalEntity = 0,
  juridicalPerson = 1,
}

interface IInfoCard {
  client: IGetClientResponse;
}

export const InfoCard: FC<IInfoCard> = ({ client }) => {
  const [editCardDisabled, setEditCardDisabled] = useState(true);

  const preferences = StorageUtils.getPreference();
  const { t } = useTranslation();

  return (
    <Card
      title={
        <Avatar
          fisrtName={client.nome}
          size="M"
          type="ui-tiny-bold"
          description={{
            description: client.nome,
            type: 'heading4',
            color: 'text-50',
          }}
          color="white"
        />
      }
      withoutCollapse
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.personType')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={
              client.tipoPessoa === PersonType.legalEntity
                ? t('client.editPage.legalEntity')
                : t('client.editPage.juridicalPerson')
            }
          />
        </Col>
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={
              client.tipoPessoa === PersonType.legalEntity ? 'CPF' : 'CNPJ'
            }
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={
              client.tipoPessoa === PersonType.legalEntity
                ? MaskForCPF(client.cpf)
                : MaskForCNPJ(client.cnpj)
            }
          />
        </Col>
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.birthDate')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={DateUtils.convertDate(
              client.dataNascimento,
              preferences?.padraoData
            )}
          />
        </Col>
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.observation')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={client.observacao}
          />
        </Col>
        <Col span={12}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.descProduct')}
            withBottomMargin
          />
          {client.descontoProdutosAcabados !== undefined && (
            <Text
              type="ui-tiny-content"
              color="text-50"
              children={`${client.descontoProdutosAcabados?.toLocaleString(
                preferences?.idioma,
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                }
              )}%`}
            />
          )}
        </Col>
        <Col span={12}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.descFormula')}
            withBottomMargin
          />
          {client.descontoFormulas !== undefined && (
            <Text
              type="ui-tiny-content"
              color="text-50"
              children={`${client.descontoFormulas?.toLocaleString(
                preferences?.idioma,
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                }
              )}%`}
            />
          )}
        </Col>
        <ButtonCard
          disabled={editCardDisabled}
          onDisabledChange={setEditCardDisabled}
          permission={{
            permission: PermissionEnum.Vendas_Clientes_EditarDetalhes,
            tooltip: 'noPermission.sale.client.edit',
          }}
        />
      </Row>
      <InfoModal
        visible={!editCardDisabled}
        client={client}
        changeVisibleModal={(x) => setEditCardDisabled(x)}
        preferences={preferences}
      />
    </Card>
  );
};

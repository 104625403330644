import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_CAPSULE_COLORS,
  FETCH_LIST_CAPSULE_COLORS,
} from '@/ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: capsuleColorsList,
    isLoading: isLoadingCapsuleColorsList,
    refetch: refetchCapsuleColorsList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.getCapsuleColorsList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'corCapsula', direction: 'ASC' },
    },
  });

  const deleteCapsuleColors = useControllerMutateApiHook({
    uniqId: DELETE_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.deleteCapsuleColors,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_CAPSULE_COLORS]),
    },
  });

  return {
    deleteCapsuleColors,
    capsuleColorsList,
    isLoadingCapsuleColorsList,
    refetchCapsuleColorsList,
  };
};

import { currencyFormater } from '@/Utils/Currency';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useProductValuesMemorizations = ({
  totalProductValue,
  orderPurchase
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const withStatusIconInLabel = 
    totalProductValue !==
    form?.values?.totalizadores?.totalProdutosBruto;

  const withTooltip = {
    showMe:
      totalProductValue !==
      form?.values?.totalizadores?.totalProdutosBruto,
    title: `${t('common.originalValue')} ${currencyFormater(
      orderPurchase?.totalizadores?.totalProdutosBruto
    )}`,
    targetOffset: [75, -2],
  };

  return {
    withStatusIconInLabel,
    withTooltip
  };
};
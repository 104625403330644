import {
  Button,
  Card,
  Form
} from '@/Components';
import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Product } from './Components/Product';
import { AssociatedProduct } from './Components/AssociatedProduct';
import { PharmaceuticalForm } from './Components/PharmaceuticalForm';
import { DosageInputs } from './Components/DosageInputs';
import { Relationship } from './Components/Relationship';
import { useCreateAssociatedProductStates } from './Hooks/useCreateAssociatedProductStates';
import { Accumulate } from './Components/Accumulate';
import { useCreateAssociatedProductCallAPI } from './Hooks/useCreateAssociatedProductCallAPI';
import { useCreateAssociatedProductFunctions } from './Hooks/useCreateAssociatedProductFunctions';

export const CreateAssociatedProduct: FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();

  const {
    accumulate,
    setAccumulate,
    associatedProductExternalId,
    setAssociatedProductExternalId
  } = useCreateAssociatedProductStates({
    productExternalId
  });
    
  const {
    currentProduct,
    createAssociatedProduct
  } = useCreateAssociatedProductCallAPI({
    productExternalId
  });

  const {
    handleSubmit,
    onClickCancelButton
  } = useCreateAssociatedProductFunctions({
    createAssociatedProduct,
    productExternalId,
    accumulate
  });

  return (
    <Form onSubmit={handleSubmit}>
      {(props) => (
        <>
          <Card
            title={`${t('products.associated.associatedProduct')} | ${
              currentProduct?.descricao
            }`}
            withoutCollapse
          >
            <Row gutter={[16, 0]}>
              <Product currentProduct={currentProduct} />
            </Row>
            <Row gutter={[16, 0]}>
              <AssociatedProduct
                productExternalId={productExternalId}
                setAssociatedProductExternalId={setAssociatedProductExternalId}
              />
              <PharmaceuticalForm 
                productExternalId={productExternalId}
                associatedProductExternalId={associatedProductExternalId}
              />
              <DosageInputs />
            </Row>
            <Row gutter={[16, 0]}>
              <Relationship />
              <Accumulate setAccumulate={setAccumulate} />
            </Row>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <Button
                type="secondary"
                style={{ marginRight: 8 }}
                children={t('common.cancel')}
                onClick={onClickCancelButton}
              />
              <Button
                type="primary"
                children={
                  createAssociatedProduct.isLoading
                    ? t('common.creating')
                    : t('common.create')
                }
                htmlType="submit"
                loading={createAssociatedProduct.isLoading}
                disabled={
                  props.values.produtoAssociadoExternalId <
                    props.values.dosagemMinima ||
                  !props.values.produtoAssociadoExternalId ||
                  !props.values.tipoRelacaoQuantidade ||
                  !props.values.acumula
                }
              />
            </div>
          </Card>
        </>
      )}
    </Form>
  );
};

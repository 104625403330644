import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../../../../../../../Components';
import _ from 'lodash';
import styles from '../../../../../createPharmaceuticalForm.module.scss';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { THERE_IS_PHARMACEUTICAL_FORM_TYPE } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';

interface IDescriptionPharmaceuticalForm {
  enableButton: () => void;
}

const DescriptionPharmaceuticalForm: React.FC<
  IDescriptionPharmaceuticalForm
> = ({ enableButton }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const fetchPharmaceuticalFormDescription = useControllerMutateApiHook({
    entityApi: PharmaceuticalFormAPI.pharmaceuticalFormDescription,
    uniqId: THERE_IS_PHARMACEUTICAL_FORM_TYPE,
    options: {
      onError: () =>
        form?.setErrors({
          ...form?.errors,
          description: t(
            'production.pharmaceuticalForm.create.errors.errorPharmaceuticalFormDescriptionExist'
          ),
        }),
      onSuccess: () =>
        form?.setErrors({
          ...form?.errors,
          description: undefined,
        }),
    },
  });

  const fetchInputPharmaceuticalFormDescription = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await fetchPharmaceuticalFormDescription.mutateAsync(value);
        } catch {
          return undefined;
        }
      }, 500),
    [fetchPharmaceuticalFormDescription.mutateAsync]
  );

  return (
    <TextInput
      className={styles['text-input']}
      name="description"
      placeHolder={t(
        'production.pharmaceuticalForm.create.descriptionPlaceholder'
      )}
      required
      label={t('production.pharmaceuticalForm.create.description')}
      onChange={(value) => {
        enableButton();
        fetchInputPharmaceuticalFormDescription(value);
      }}
    />
  );
};

export default DescriptionPharmaceuticalForm;

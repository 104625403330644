import { useParams } from 'react-router';
import {
  FETCH_GET_LOT,
  FETCH_LIST_LOTS_DROPDOWN
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { useControllerQueryApiHook, useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { IGetLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

export const useDilutionsCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: lots } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalId: externalId
  });

  const {
    data: lotList,
    isLoading: isLotLoading,
    fetchNewPage: fetchNewLotPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOTS_DROPDOWN,
    entityApi: LotsAPI.getLotListDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [
        { filterName: 'produtoExternalId', value: lots?.produtoExternalId },
      ]
    }
  });

  return {
    lots,
    lotList,
    isLotLoading,
    fetchNewLotPage
  };
};
import { Avatar, Highlight, Text, Tooltip } from '@/Components';
import { IItems } from '@/Components/Inputs/InputWithSearch/Interface';
import { FC } from 'react';

import styles from './Avatar.module.scss';

interface IAvatarOption {
  item: IItems;
  currentSearch: string;

  dataTestId?: string;
}

export const AvatarOption: FC<IAvatarOption> = ({ item, currentSearch, dataTestId }) => (
  <div className={styles['content-avatar']} data-testid={dataTestId}>
    <Avatar
      fisrtName={item.avatar?.name}
      lastName={item.avatar?.lastName}
      size="S"
      type="small-text-bold"
      color="white"
    />

    <div className={styles['div-avatar']}>
      <Tooltip
        title={item.titleTooltipItem}
        showMe={item.disabledItem}
        placement="topLeft"
      >
        <Text
          className={styles['text']}
          type="ui-tiny-content"
          color={item.disabledItem ? 'text-400' : 'text-50'}
          style={{ height: 16 }}
        >
          <Highlight className={styles['text']} highlight={currentSearch}>
            {item.avatar?.name ||
              item.avatar?.lastName ||
              item.avatar?.email ||
              item.subLabel ||
              ''}
          </Highlight>
        </Text>
      </Tooltip>
      {item.avatar?.email && (
        <Text
          type="small-text"
          color="text-400"
          children={item.avatar?.email}
          style={{ height: 12 }}
        />
      )}
      {item.subLabel && (
        <Text
          className={styles['text']}
          type="small-text"
          color="text-300"
          children={item.subLabel}
        />
      )}
    </div>
  </div>
);

import { Text, Icon } from '../';
import { Button as AntdButton } from 'antd';
import { ReactNode } from 'react';
import { statusTypes } from '../../Utils/StatusUtils';
import Spinner from '../Spinner';
import { IconName } from '../Icon/IconClasses';
import { Color } from '../../Utils/ColorUtils';

import styles from './Status.module.scss';
import './Status.override.scss';

export interface IStatus {
  type: statusTypes;

  children: string | ReactNode | object;
  rightIcon?: boolean;
  leftIcon?: IconName;
  disabled?: boolean;
  iconColor?: Color;
  onClick?: (status: any) => void;

  loading?: boolean;

  circular?: boolean;

  dataTestId?: string;
}

export const Status: React.FC<IStatus> = ({
  type,
  children,
  disabled,
  rightIcon,
  onClick,
  loading,
  leftIcon,
  iconColor,
  circular = false,
  dataTestId,
}) => {
  return (
    <AntdButton
      className={`${type}-status ${styles['status']} ${
        rightIcon ? styles['status-with-rightIcon'] : ''
      } ${circular ? styles['circular'] : ''}`}
      disabled={disabled}
      onClick={onClick}
      style={disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
      data-testid={dataTestId}
    >
      {leftIcon && (
        <Icon
          color={iconColor}
          style={{ marginRight: '10px' }}
          icon={leftIcon}
          size="L"
        />
      )}
      <Text
        type="ui-tiny-bold"
        children={children as string}
        style={{ whiteSpace: 'nowrap' }}
      />
      {!loading ? (
        rightIcon && (
          <Icon
            icon="chevron-down"
            size="L"
            color={
              type === 'success'
                ? 'status-success-base'
                : type === 'danger'
                ? 'status-danger-base'
                : type === 'attention'
                ? 'status-warning-base'
                : type === 'primary'
                ? 'status-primary-base'
                : type === 'default'
                ? 'status-default-base'
                : 'status-disabled-base'
            }
          />
        )
      ) : (
        <Spinner className={styles['button-spinner']} />
      )}
    </AntdButton>
  );
};

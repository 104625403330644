import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { Modal } from '../../../../../../Components/Modal';
import { history } from '../../../../../../Store';
import { useTranslation } from 'react-i18next';
import { FETCH_LIST_PURCHASE_ORDER } from '../../../../../../ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { IInvoiceGetResponse } from '../../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import { DELETE_INVOICE } from '../../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';

interface IDeleteDraftModal {
  invoice?: IInvoiceGetResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteDraftModal: FC<IDeleteDraftModal> = ({
  invoice,
  visibleModal,
  changeVisibleModal,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const deleteDraftInvoice: any = useControllerMutateApiHook({
    uniqId: DELETE_INVOICE,
    entityApi: InvoiceAPI.deleteInvoice
  });

  return (
    <Modal
      title={t('common.modalDraftDelete.title')}
      body={
        <div style={{ padding: '20px', width: 400 }}>
          {t('common.modalDraftDelete.subTitle')}
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={async () => {
        const res = await deleteDraftInvoice.mutateAsync({
          notaFiscalEntradaExternalId: [invoice?.externalId] as any,
        });
        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
          history.push(`/order/invoice`);
        }
      }}
      okButtonName={
        deleteDraftInvoice.isLoading ? t('common.deleting') : t('common.delete')
      }
      loading={deleteDraftInvoice.isLoading}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};

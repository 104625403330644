import { FormikContextType } from 'formik';
import { ICreateLotRequest } from '../../../../../../../Data/Interfaces/request/Lot/ILotResquest';

export const setInitialValuesFormWaterConcentrationFactor = (
  form: FormikContextType<ICreateLotRequest>
) => {
  const currentFormWaterConcentration =
    form?.values?.loteInformacaoTecnica?.concentracaoAgua;
  const currentFormWaterConcentrationFactor =
    100 / (100 - currentFormWaterConcentration);
  const hasChanges =
    currentFormWaterConcentrationFactor !== currentFormWaterConcentration;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.fatorConcentracaoAgua',
      currentFormWaterConcentrationFactor ||
        form?.values?.loteInformacaoTecnica?.fatorConcentracaoAgua
    );
  }
};

export const setInitialValuesFormWaterConcentration = (
  form: FormikContextType<ICreateLotRequest>
) => {
  const currentFormWaterConcentrationFactor =
    form?.values?.loteInformacaoTecnica?.fatorConcentracaoAgua;
  const currentFormWaterConcentration =
    //prettier-ignore
    100 - (100 / currentFormWaterConcentrationFactor);
  const hasChanges =
    currentFormWaterConcentrationFactor !== currentFormWaterConcentration;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.concentracaoAgua',
      currentFormWaterConcentration ||
        form?.values?.loteInformacaoTecnica?.concentracaoAgua
    );
  }
};

export const setInitialValuesFormInternalDiluitionFactor = (
  form: FormikContextType<ICreateLotRequest>
) => {
  const currentFormInternalDiluition =
    form?.values?.loteInformacaoTecnica?.diluicaoInterna;
  const currentFormInternalDiluitionFactor = 100 / currentFormInternalDiluition;
  const hasChanges =
    currentFormInternalDiluitionFactor !== currentFormInternalDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.fatorDiluicaoInterna',
      currentFormInternalDiluitionFactor ||
        form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna
    );
  }
};

export const setInitialValuesFormInternalDiluition = (
  form: FormikContextType<ICreateLotRequest>
) => {
  const currentFormInternalDiluitionFactor =
    form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna;
  const currentFormInternalDiluition = 100 / currentFormInternalDiluitionFactor;
  const hasChanges =
    currentFormInternalDiluitionFactor !== currentFormInternalDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.diluicaoInterna',
      currentFormInternalDiluition ||
        form?.values?.loteInformacaoTecnica?.diluicaoInterna
    );
  }
};

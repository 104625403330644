import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { useFormikContext } from 'formik';
import { IUnitaryValue } from './interfaces';
import { useUnitaryValueCallAPI } from './Hooks/useUnitaryValueCallAPI';
import { useUnitaryValueFunctions } from './Hooks/useUnitaryValueFunctions';
import { useUnitaryValueMemorizations } from './Hooks/useUnitaryValueMemorizations';

export const UnitaryValue: FC<IUnitaryValue> = ({
  index,
  unitMeasurementPagination
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const {
    unitsMeasurements,
    convertUnitOfMeasureMonetaryValue
  } = useUnitaryValueCallAPI({
    unitMeasurementPagination
  });

  const {
    onUnitOfMeasureChange
  } = useUnitaryValueFunctions({
    index,
    convertUnitOfMeasureMonetaryValue
  });

  const {
    label,
    dropdownRight
  } = useUnitaryValueMemorizations({
    index,
    unitsMeasurements,
    onUnitOfMeasureChange
  });

  return (
    <>
      <CurrencyInput
        name={`pedidoCompraItens.${index}.precoUnitario`}
        label={label}
        required
        placeHolder={t(
          'purchasing.purchaseOrder.create.productsCardPlaceHolders.unitaryValue'
        )}
        withoutMarginBottom
        allowExtraDecimal
        dropdownRight={dropdownRight}
        disabled={
          !form?.values?.pedidoCompraItens?.[index]?.produtoExternalId
        }
      />
    </>
  );
};
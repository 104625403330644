import {
  FETCH_GET_PRODUCT,
  VALIDATION_NAME_GROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useParams } from 'react-router';
import { IUseCallAPI } from './interfaces';
import { useTranslation } from 'react-i18next';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useInputDescriptionGroupCallAPI = ({ form }: IUseCallAPI) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: VALIDATION_NAME_GROUP,
    entityApi: ProductAPI.productGroupNameValidation,
    options: {
      onError: () => {
        form?.setFieldError(
          'descricao',
          t('products.groupList.modal.errorGroupNameExist')
        );
      },
      onSuccess: () => {
        form?.setFieldError('descricao', undefined);
      }
    }
  });

  const { data } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: externalId
  });

  return {
    mutateAsync,
    data,
  };
};

import { Icon, InputWithSearch } from '@/Components';
import { IPagination } from '@/Components/Table';
import { IGetStorageLocationDropDownResponse } from '@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse';
import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import _ from 'lodash';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface IStorageLocationRow {
  pagination: IPagination;
  setPagination: Dispatch<SetStateAction<IPagination>>;
  isLoadingStorageLocationList: boolean;
  storageLocations: IGetStorageLocationDropDownResponse[];
  origin: string | undefined;
  destiny: string | undefined;
  setChangeStorageLocationModal: Dispatch<SetStateAction<boolean>>;
  onDestinyChange: (value: string) => void;
  onOriginChange: (value: string) => void;
  setDestiny: Dispatch<SetStateAction<string | undefined>>;
  setOrigin: Dispatch<SetStateAction<string | undefined>>;
}

export const StorageLocationRow: FC<IStorageLocationRow> = ({
  pagination,
  setPagination,
  isLoadingStorageLocationList,
  storageLocations,
  destiny,
  origin,
  setChangeStorageLocationModal,
  onOriginChange,
  setDestiny,
  setOrigin,
  onDestinyChange,
}) => {
  const form: FormikContextType<any> = useFormikContext();
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} justify="space-between">
      <Col span={11}>
        <InputWithSearch
          name="origin"
          label={t('supply.transfers.fields.supplyPlaceLocationFrom')}
          placeHolder={t(
            'supply.transfers.placeHolders.supplyPlaceLocationFrom'
          )}
          required={true}
          withoutMarginBottom={true}
          onScrollEnd={() =>
            setPagination({
              ...pagination,
              pageIndex: pagination.pageIndex + 1,
            })
          }
          isLoading={isLoadingStorageLocationList}
          items={storageLocations
            .filter((x: any) => x.externalId !== destiny)
            .map((x: any) => ({
              id: x.externalId,
              label: x.descricao,
              code: x.nomeEmpresa,
            }))}
          onChange={(value) => {
            onOriginChange(value);
            if (!!origin && form.values.transfers.length === 1) {
              setChangeStorageLocationModal(true);
            } else {
              setOrigin(value);
            }
          }}
        />
      </Col>
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          icon="arrow-down"
          size="XXL"
          style={{ transform: 'rotate(-90deg)', marginTop: '18px' }}
          color="text-400"
        />
      </Col>
      <Col span={11}>
        <InputWithSearch
          name="destiny"
          label={t('supply.transfers.fields.supplyPlaceLocationTo')}
          required={true}
          onScrollEnd={() =>
            setPagination({
              ...pagination,
              pageIndex: pagination.pageIndex + 1,
            })
          }
          withoutMarginBottom={true}
          placeHolder={t('supply.transfers.placeHolders.supplyPlaceLocationTo')}
          items={storageLocations
            .filter((x: any) => x.externalId !== origin)
            .map((x: any) => ({
              id: x.externalId,
              label: x.descricao,
              code: x.nomeEmpresa,
            }))}
          onChange={(value) => {
            setDestiny(value);
            onDestinyChange(value);
          }}
          isLoading={isLoadingStorageLocationList}
        />
      </Col>
    </Row>
  );
};

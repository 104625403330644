import { useQueryParams } from '@/Utils/Http';
import StorageUtils from '@/Utils/StorageUtils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useCreateMemorizations = () => {
  const queryParams = useQueryParams();
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({
      nome: queryParams.get('nome'),
      cpf: queryParams.get('cpf'),
      cnpj: queryParams.get('cnpj'),
      razaoSocial: queryParams.get('razaoSocial'),
      pessoa: 0,
    }),
    [queryParams]
  );

  const body = useMemo(
    () => (
      <div style={{ padding: 20, maxWidth: 400 }}>
        {t('supply.balanceAdjustment.backModal.body')}
      </div>
    ),
    [t]
  );

  const preferences = useMemo(() => StorageUtils.getPreference(), []);

  return {
    initialValues,
    body,
    preferences,
  };
};

import { FC } from "react";
import {
  InputWithSearch,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ICas } from "./interfaces";
import { useCasFunctions } from "./Hooks/useCasFunctions";
import { useCasCallAPI } from "./Hooks/useCasCallAPI";
import { useCasMemorizations } from "./Hooks/useCasMemorizations";

export const Cas: FC<ICas> = ({
  product,
  disabledForm
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    casList,
    isLoadingCas,
    fetchNewCasPage,
    refetchSearchCasPage
  } = useCasCallAPI();
  
  const {
    onSearch
  } = useCasFunctions({ refetchSearchCasPage });

  const {
    items
  } = useCasMemorizations({ casList });

  return (
    <Col span={7}>
      <Tooltip
        title={`${t('products.toolTips.products.originalCas')} ${
          product && product.materiaPrima
            ? product.materiaPrima.casDescricao
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima.casId !== form?.values?.casId
        }
      >
        <InputWithSearch
          withStatusIcon={!!product}
          name="casId"
          label={t('products.create.cas')}
          placeHolder={t('products.create.casPlaceholder')}
          isLoading={isLoadingCas}
          onScrollEnd={fetchNewCasPage}
          onSearch={onSearch}
          items={items}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { 
  THERE_IS_PACKING_CLASSIFICATION_TYPE 
} from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';

export const useCreateCallAPI = ({ form, t }: IUseCallAPI) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_PACKING_CLASSIFICATION_TYPE,
    entityApi:
      PackingClassificationAPI.isPackingClassificationNameAlreadyRegistred,
    options: {
      onSuccess: () => {
        form?.setErrors({
          ...form?.errors,
          descricao: undefined,
        });
      },
      onError: () => {
        form?.setErrors({
          ...form?.errors,
          descricao: t(
            'production.packingClassification.create.erros.errorPackingClassificationNameExist'
          ),
        });
      },
    },
  });

  return { mutateAsync };
};

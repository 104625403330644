import { i18n, Icon, Text } from '../Components';
import {
  FooterThisActionInitialStockBalanceBatch,
  FooterThisActionIsPermanent,
} from '../Components/Table/components/Modals/DeleteModal';
import { v4 as uuidV4 } from 'uuid';
import { Divflex } from '../Layouts/DivWhithFlex';

export const DeleteModalOnTable = (
  items: any,
  title: string,
  itemsName: string,
  plusItemsDescription: string,
  itemsName2?: string,
  amountPlusVisible?: number
) => (
  <div style={{ padding: '20px' }}>
    <Text color="text-50" type="ui-tiny-content">
      {title}
    </Text>
    <ul style={{ padding: '15px 3px', margin: '0' }}>
      {itemsName2
        ? items?.slice(0, amountPlusVisible || 5).map((x: any) => (
            <li key={x.ExternalId} style={{ height: '18px' }}>
              <Text color="text-50" type="ui-tiny-bold">
                {`${x[itemsName]} (${x[itemsName2!]})`}
              </Text>
            </li>
          ))
        : items?.slice(0, amountPlusVisible || 5).map((x: any) => (
            <li key={x.ExternalId} style={{ height: '18px' }}>
              <Text color="text-50" type="ui-tiny-bold">
                {x[itemsName]}
              </Text>
            </li>
          ))}
      {items.length > (amountPlusVisible || 5) && (
        <li key={uuidV4()} style={{ height: '18px' }}>
          <Text
            color="text-300"
            type="ui-tiny-content"
            children={`+ ${
              items.length - (amountPlusVisible || 5)
            } ${plusItemsDescription}`}
          />
        </li>
      )}
    </ul>
    <FooterThisActionIsPermanent />
  </div>
);

export const DeleteModalOnTableNotFooter = (
  items: any,
  title: string,
  itemsName: string,
  plusItemsDescription: string
) => (
  <div>
    <Text color="text-50" type="ui-tiny-content">
      {title}
    </Text>
    <ul style={{ padding: '15px 3px 5px', marginLeft: '20px' }}>
      {items?.slice(0, 4).map((x: any) => (
        <li key={x.ExternalId} style={{ height: '18px' }}>
          <Text color="text-50" type="ui-tiny-bold">
            {x[itemsName]}
          </Text>
        </li>
      ))}
      {items.length > 4 && (
        <li key={uuidV4()} style={{ height: '18px' }}>
          <Text
            color="text-300"
            type="ui-tiny-content"
            children={`+ ${items.length - 4} ${plusItemsDescription}`}
          />
        </li>
      )}
    </ul>
  </div>
);

export const DeleteModalOnTableForLot = (
  items: any,
  title: string,
  itemsName: string,
  plusItemsDescription: string
) => (
  <div style={{ padding: '20px' }}>
    <Text color="text-50" type="ui-tiny-content">
      {title}
    </Text>
    <ul style={{ padding: '15px 3px', margin: '0' }}>
      {items?.slice(0, 4).map((x: any) => (
        <li key={x.ExternalId} style={{ height: '18px' }}>
          <Text color="text-50" type="ui-tiny-bold">
            {x[itemsName]}
          </Text>
        </li>
      ))}
      {items.length > 4 && (
        <li key={uuidV4()} style={{ height: '18px' }}>
          <Text
            color="text-300"
            type="ui-tiny-content"
            children={`+ ${items.length - 4} ${plusItemsDescription}`}
          />
        </li>
      )}
    </ul>
    <FooterThisActionInitialStockBalanceBatch />
    <br />
    <FooterThisActionIsPermanent />
  </div>
);

export const DeleteModalOnTableLotWarning = (
  items: any,
  title: string,
  itemsName: string
) => (
  <>
    <div style={{ padding: 20, width: 400 }}>
      <Text color="text-50" type="ui-sub-content">
        {title}
      </Text>
      <ul>
        {items?.map((x: any) => (
          <li key={x.ExternalId}>
            <Text color="text-50" type="ui-tiny-bold">
              {x[itemsName]}
            </Text>
          </li>
        ))}
      </ul>
      <Text
        color="text-50"
        type="ui-sub-content"
        children={`${i18n.t('modal.deleteModal.single.avatar.footer3')}`}
      />
      <Text
        color="text-50"
        type="ui-sub-content"
        children={`${i18n.t('modal.deleteModal.many.footer')}`}
      />
    </div>
  </>
);

export const DeleteModalOnTableErrorCode = (
  items: any,
  title: string,
  itemsName: string,
  plusItemsDescription: string,
  description: string,
  warning: string,
  itemsNameAdditionalInfo?: string
) => (
  <div>
    <Text color="text-50" type="ui-tiny-content" style={{ marginTop: -10 }}>
      {title}
    </Text>
    <ul style={{ padding: '15px 20px', margin: '0' }}>
      {items?.slice(0, 4).map((x: any) => (
        <li key={x.ExternalId} style={{ height: '18px' }}>
          <Text color="text-50" type="ui-tiny-bold">
            {x[itemsName]}
            {itemsNameAdditionalInfo && ` (${x[itemsNameAdditionalInfo]})`}
          </Text>
        </li>
      ))}
      {items.length > 4 && (
        <li key={uuidV4()} style={{ height: '18px' }}>
          <Text
            color="text-300"
            type="ui-tiny-content"
            children={`+ ${items.length - 4} ${plusItemsDescription}`}
          />
        </li>
      )}
    </ul>
    <Text color="text-50" type="ui-tiny-content">
      {description}
    </Text>

    <Divflex style={{ marginTop: 25 }}>
      <Icon
        icon={'exclamation-upside-down'}
        size={'SM'}
        color={'text-400'}
        style={{ padding: '18px 18px 0 8px' }}
      />
      <Text type="paragraph2" color={'text-400'}>
        {warning}
      </Text>
    </Divflex>
  </div>
);

import React, { FC } from 'react';
import { Form } from '../../../../../Components';
import { CreateSchema } from './CreateForm.schema';
import { PersonalDataCard } from './Components/Cards/PersonalDataCard';
import { AccessCard } from './Components/Cards/AccessCatd';
import { useCreateUserCallAPI } from './Hooks/useCreateUserCallAPI';
import { useCreateUserFunctions } from './Hooks/useCreateUserFunctions';
import { FooterButton } from './Components/FooterButton';

export const UserForm: FC = () => {
  const { createUser } = useCreateUserCallAPI();
  const { onSubmit } = useCreateUserFunctions({ createUser });

  return (
    <Form onSubmit={onSubmit} schema={CreateSchema}>
      <PersonalDataCard />
      <AccessCard />
      <FooterButton createUser={createUser} />
    </Form>
  );
};

import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_DROPDOWN_REASON_LOSS } from '@/ControllerApiHook/UniqIds/Supply/LosseKeys';
import { LossesAPI } from '@/Data/API/Supply/LossesApi';

export const useCreataCallAPI = () => {
  const { data: reasonLossList, isLoading: loadingReasonLoss } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_DROPDOWN_REASON_LOSS,
      entityApi: LossesAPI.getReasonLossListDropdown,
      initialPagination: {
        pageIndex: 0,
        pageSize: 10,
        sorter: { column: 'id', direction: 'ASC' },
      },
      autoIncrement: true,
    });
  return { reasonLossList, loadingReasonLoss };
};

import { FieldArray } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Card, Form } from '../../../../../../../../Components';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { TransfersRows } from './TransfersRows';
import { useEditMemorizations } from '../../../Hooks/useEditMemorizations';

export const TransferForm: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { transfers } = useEditCallAPI({ externalId });

  const { initialValuesTransfersCard } = useEditMemorizations({ transfers });

  return (
    <Form initialValues={initialValuesTransfersCard}>
      {(props) => (
        <Card
          title={t('supply.transfers.detailsForm.titleTransfer')}
          withoutCollapse={true}
        >
          <FieldArray
            name="transferenciaLoteItens"
            render={() => (
              <div>
                {props?.values?.transferenciaLoteItens &&
                  props?.values?.transferenciaLoteItens.map(
                    (_: any, index: any) => <TransfersRows index={index} />
                  )}
              </div>
            )}
          />
        </Card>
      )}
    </Form>
  );
};

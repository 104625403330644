import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { Col, Row } from 'antd';
import { CurrencyInput } from '../../../../../../../../Components/Inputs/CurrencyInput';
import StorageUtils from '../../../../../../../../Utils/StorageUtils';

interface IPharmaceuticalFormFinancial {
  enableButton: () => void;
}

export const FinancialCard: React.FC<IPharmaceuticalFormFinancial> = ({
  enableButton,
}) => {
  const { t } = useTranslation();
  const preference = StorageUtils.getPreference();
  const currencyTypeReal = 0;
  return (
    <Card title={t('common.financial')} withoutCollapse={true}>
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <CurrencyInput
            name="operationalCost"
            label={[
              {
                children: t(
                  'production.pharmaceuticalForm.create.operationalCost'
                ),
              },
            ]}
            placeHolder={t('products.create.costPricePlaceholder')}
            withoutMarginBottom={true}
            required
            onChange={() => enableButton()}
            prefix={preference?.tipoMoedaId === currencyTypeReal ? 'R$ ' : '$ '}
          />
        </Col>
      </Row>
    </Card>
  );
};

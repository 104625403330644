import { useCallback } from 'react';
import { IEditFunctions } from './interfaces';

export const useEditFunctions = ({ editLoss, externalId }: IEditFunctions) => {
  const onSubmit = useCallback(async (values: any) => {
    return await editLoss.mutateAsync({
      perdaExternalId: externalId,
      dataPerda: values.lossDate,
      motivoPerdaExternalId: values.lossReason,
      observacao: values.comments,
    });
  }, []);

  return { onSubmit };
};

import { TFunction } from 'i18next';
import { Text } from '@/Components';

export const productSynonymColumns = (t: TFunction) => {
  const formatQuantity = (quantity: number) => {
    return quantity.toFixed(3).replace('.', ',');
  };

  return [
    {
      title: t('products.diluted.pharmaceuticalForm'),
      dataIndex: 'formaFarmaceuticaDescricao',
      key: 'formaFarmaceuticaDescricao',
      width: '60%',
      render: (x: string, row: any) => {
        return (
          <Text type="ui-tiny-bold" color="text-50">
            {row.formaFarmaceuticaDescricao}
          </Text>
        );
      },
    },
    {
      title: t('products.diluted.minimalDosage'),
      dataIndex: 'dosagemMinima',
      key: 'dosagemMinima',
      width: '20%',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-content" color="text-50">
          {formatQuantity(row.dosagemMinima)}
          {row.unidadeMedidaAbreviacao}
        </Text>
      ),
    },
    {
      title: t('products.diluted.maximalDosage'),
      dataIndex: 'dosagemMaxima',
      key: 'dosagemMaxima',
      width: '20%',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-content" color="text-50">
          {formatQuantity(row.dosagemMaxima)}
          {row.unidadeMedidaAbreviacao}
        </Text>
      ),
    },
  ];
};

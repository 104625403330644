import {
  DELETE_PRODUCT,
  EDIT_STATUS_PRODUCT,
  FETCH_LIST_PRODUCTS_GROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useParams } from 'react-router';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { useEffect } from 'react';

export const useProductFormCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const editProductStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PRODUCT,
    entityApi: ProductAPI.productStatus,
  });

  const {
    data: productList,
    isLoading,
    refetch: refetchProductList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCTS_GROUP,
    entityApi: ProductAPI.productsOfGroupsList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [{ filterName: 'grupoExternalId', value: externalId }],
    },
    autoIncrement: true,
  });

  useEffect(() => {
    refetchProductList({
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [{ filterName: 'grupoExternalId', value: externalId }],
    });
  }, [refetchProductList, externalId]);

  const deleteProduct: any = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT,
    entityApi: ProductAPI.deleteProduct,
  });

  return {
    editProductStatus,
    productList,
    isLoading,
    deleteProduct,
    refetchProductList,
  };
};

import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Avatar } from '../../../../../../Components/Avatar';
import { Status, Tag, Text } from '../../../../../../Components';
import { statusTypes } from '../../../../../../Utils/StatusUtils';
import { statusServiceTypeProp } from '../../../Order/List/order.columns';
import { formatPhoneNumber } from 'react-phone-number-input';
import DateUtils from '../../../../../../Utils/DateUtils';
import { TFunction } from 'react-i18next';
import { IGetServiceResponse } from '../../../../../../Data/Interfaces/response/Sales/Service/IServiceResponse';
import { IPreferences } from '../../../../../../Data/Interfaces/response/User/IGetUserReponse';
import { isNumeric } from '../../../../../../Utils/IsNumericUtils';
import { Color } from '@/Utils/ColorUtils';

import styles from './Header.module.scss';

const channelBackground = {
  counter: 'text-100' as Color,
  facebook: 'messenger-gradient' as Color,
  phone: 'status-success-base' as Color,
  whatsapp: 'status-success-base' as Color,
  instagram: 'instagram-gradient' as Color,
  telegran: 'status-information-base' as Color,
  email: 'status-information-base' as Color,
  'shopping-bag-ecommerce': 'status-success-base' as Color,
  'shopping-bag-chat': 'status-warning-base' as Color,
  mobile: 'status-information-base' as Color,
  'mobile-chat': 'status-warning-base' as Color,
};

interface IHeaderSaleOrder {
  service?: IGetServiceResponse;
  preferences?: IPreferences;
  t: TFunction<'translation', undefined>;
}

export const HeaderSaleOrder: FC<IHeaderSaleOrder> = ({
  service,
  preferences,
  t,
}) => (
  <div className={styles['header']} id="headerSaleOrder">
    <Avatar
      size="LM"
      type="ui-sub-bold"
      description={{
        description: service?.nomeCliente,
        type: 'ui-sub-semibold',
        color: 'text-50',
      }}
      email={{
        email: <Tag type="secondary" children={`#${service?.codigo}`}></Tag>,
        type: 'ui-tiny-content',
      }}
      fisrtName={service?.nomeCliente}
      color="white"
      channelAvatar={
        service && {
          icon: service.canalIcon,
          backgroundColor: channelBackground[service.canalIcon],
        }
      }
    />
    <Row style={{ display: 'flex' }} gutter={[65, 0]}>
      <Col>
        <Text
          type="ui-tiny-content"
          children={t('saleOrder.editOrder.header.lastUpdate')}
          color="text-200"
        />
        <Text
          type="ui-tiny-content"
          children={`${DateUtils.convertDate(
            service?.dataAtualizacao,
            preferences?.padraoData
          )} ${t('common.complementDateHour')} ${DateUtils.convertDate(
            service?.dataAtualizacao,
            preferences?.padraoHora
          )}`}
          color="text-50"
        />
      </Col>
      {service?.contato && (
        <Col>
          <Text
            type="ui-tiny-content"
            children={t('saleOrder.editOrder.header.contact')}
            color="text-200"
          />

          <Text
            type="ui-tiny-content"
            children={
              isNumeric(service.contato)
                ? formatPhoneNumber(service.contato)
                : service.contato
            }
            color="text-50"
          />
        </Col>
      )}
      <Col>
        <Text
          type="ui-tiny-content"
          children={t('saleOrder.editOrder.header.attendant')}
          color="text-200"
        />
        <Avatar
          className={styles['attendant-avatar']}
          size="SS"
          type="smaller-text-bold"
          description={{
            description: service?.nomeAtendente,
            type: 'ui-tiny-content',
            color: 'text-50',
          }}
          fisrtName={service?.nomeAtendente}
          color="white"
        />
      </Col>
      <Col>
        {service && (
          <Status
            type={
              statusServiceTypeProp[service.statusAtendimentoId]
                ?.status as statusTypes
            }
            children={
              statusServiceTypeProp[service.statusAtendimentoId]?.description ||
              ''
            }
          />
        )}
      </Col>
    </Row>
  </div>
);

import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '@/Components';
import { ISpecialty } from './interfaces';
import { useSpecialtyMemorizations } from './Hooks/useSpecialtyMemorizations';
import { useSpecialtyFunctions } from './Hooks/useSpecialtyFunctions';

export const Specialty: FC<ISpecialty> = ({
  specialtiesData,
  fetchNewSpecialtiesPage,
  refetchSearchSpecialties,
}) => {
  const { t } = useTranslation();

  const { items } = useSpecialtyMemorizations({ specialtiesData });

  const { onSearch } = useSpecialtyFunctions({ refetchSearchSpecialties });

  return (
    <Col span={24}>
      <InputWithSearch
        name="especialidadesIds"
        label={t('prescribers.createPage.info.specialties')}
        placeHolder={t('prescribers.createPage.info.specialtiesPlaceholder')}
        items={items}
        multipleSelection
        typeTag="tertiary"
        onScrollEnd={fetchNewSpecialtiesPage}
        onSearch={onSearch}
        withoutSearchIcon
      />
    </Col>
  );
};

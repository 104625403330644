import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IPreferences } from '../Data/Interfaces/response/User/IGetUserReponse';
import { messageErrorToast } from './MessageErrorToast';
import { i18n } from '@/Components';

class StorageUtils {
  REMEMBER_ME_KEY = 'REMEMBER_ME';
  PREFERENCE_KEY = 'USER_PREFERENCE';

  public SetItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public GetItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public RemoveItem(key: string) {
    localStorage.removeItem(key);
  }

  public SetRememberMe(rememberMe: boolean) {
    localStorage.setItem(this.REMEMBER_ME_KEY, rememberMe.toString());
  }

  public SetPreference(preference: string) {
    localStorage.setItem(this.PREFERENCE_KEY, preference);
  }
  public getPreference(): IPreferences {
    const preferences = localStorage.getItem(this.PREFERENCE_KEY);

    if (!preferences) GetPreferenceFromAPI();

    const result =
      preferences !== 'undefined' && preferences && preferences !== undefined
        ? JSON.parse(preferences)
        : undefined;
    return result;
  }
}

const instance = new StorageUtils();

const GetPreferenceFromAPI = async () => {
  try {
    const result = await UserAPI.getPreferences();
    instance.SetPreference(JSON.stringify(result.preferencias));
  } catch {
    messageErrorToast(i18n.t('toast.errors.getPreferences'));
  }
};

export default instance;

import {
  FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE,
  GET_LOT_DETAIL_BY_STOCKLOCATION,
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { IUseCallAPI } from './interfaces';

export const useCreateCallAPI = ({ form }: IUseCallAPI) => {
  const {
    data: lotList,
    isLoading: isLotLoading,
    refetch: refetchLot,
    fetchNewPage: refetchNewPageLot,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE,
    entityApi: LotsAPI.getLotByProductStockLocation,
    autoIncrement: true,
  });

  const {} = useControllerQueryApiHook({
    uniqId: GET_LOT_DETAIL_BY_STOCKLOCATION,
    entityApi: LotsAPI.getLotDetailstByStockLocation,
    onSuccess: (response) => {
      form?.setFieldValue('fornecedor', response?.fornecedor);
      form?.setFieldValue('numeroNf', response?.numeroNf);
      form?.setFieldValue('serieNf', response?.serieNf);
      form?.setFieldValue('prevQnt', response?.saldoLote);
      form?.setFieldValue('newQnt', response?.saldoLote);
    },
    externalId: [
      form.values.loteExternalId,
      form.values.localEstoqueExternalId,
    ],
  });

  return { lotList, isLotLoading, refetchLot, refetchNewPageLot };
};

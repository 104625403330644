import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../Components';
import { FirstRow } from './rows/FirstRow';
import { SecondRow } from './rows/SecondRow';
import { ThirdRow } from './rows/ThirdRow';
import { useFormikContext } from 'formik';

interface IValueSectionProps {
  totalProducts: number;
  initialValues: any;
}

export const ValueSection: React.FC<IValueSectionProps> = ({
  totalProducts,
  initialValues,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const [showTotalTooltip, setShowTotalTooltip] =
    React.useState<boolean>(false);

  useEffect(() => {
    const freight = form.values.valorFrete || 0;
    const insurance = form.values.valorSeguro || 0;
    const discount = form.values.valorDesconto || 0;
    const others = form.values.valorOutrasDespesas || 0;
    const products = totalProducts || 0;

    form.values.valorTotalNotaFiscal =
      freight + insurance + products - discount + others;

    if (
      form.values.valorTotalNotaFiscal !== initialValues.valorTotalNotaFiscal
    ) {
      setShowTotalTooltip(true);
    } else {
      setShowTotalTooltip(false);
    }
  }, [form.values, totalProducts]);

  return (
    <Card title={t('purchasing.invoice.create.value')} withoutCollapse>
      <FirstRow initialValues={initialValues} totalProducts={totalProducts} />
      <SecondRow initialValues={initialValues} />
      <ThirdRow
        initialValues={initialValues}
        showTotalTooltip={showTotalTooltip}
      />
    </Card>
  );
};

import React from 'react';
import { Table } from '../../../../../Components/Table';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { packinkgClassificationColumns } from './PackingClassification.column';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';

export const PackingClassificationList: React.FC = () => {
  const { t } = useTranslation();

  const { setSelectedRowsOnTable, selectedRowsOnTable } = useListStates();

  const {
    packingClassificationFormList,
    isLoadingPackingListFormList,
    editPackingClassificationFormStatus,
    deletePackingClassificationForm,
    refetchPackingList,
  } = useListCallAPI();

  const { headerOptions, rowOptions, deleteModal } = useListMemorizations({
    packingClassificationFormList,
    selectedRowsOnTable,
  });

  const { onSelectedRowsOnTable, onEdit, onDelete } = useListFunctions({
    editPackingClassificationFormStatus,
    setSelectedRowsOnTable,
    deletePackingClassificationForm,
  });

  return (
    <PageLoading loading={isLoadingPackingListFormList}>
      <Table
        columns={packinkgClassificationColumns(t)}
        loading={isLoadingPackingListFormList}
        headerOptions={headerOptions}
        fieldStatus="ativo"
        editOptions={editTableStatus()}
        editModalLoading={editPackingClassificationFormStatus.isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        selectedRowsOnTable={onSelectedRowsOnTable}
        hasSelection
        rowsOptions={rowOptions}
        pagination={packingClassificationFormList}
        onRefetch={refetchPackingList}
        deleteModal={deleteModal}
      ></Table>
    </PageLoading>
  );
};

export default PackingClassificationList;

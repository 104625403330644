import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { InputWithSearch, TextInput } from '../../../../../../../../../Components';
import { TextInputWithMask } from '../../../../../../../../../Components/Inputs/TextInputWithMask';
import { useTranslation } from 'react-i18next';
import { INatureOfOperationListData } from '../../../../../../../../../Data/Interfaces/response/Purchase/INatureOfOperationResponse';
import { configNatureOfOperationList } from '../../../../utils/functions';
import { NumberInput } from '../../../../../../../../../Components/Inputs/NumberInput';

interface IFirstRowProps {
  natureOfOperationList: INatureOfOperationListData[] | undefined;
  isLoadingNatureOfOperation: boolean;
  fetchNewNatureOfOperationPage: () => void;
  refetchSearchNatureOfOperationPage: (search?: string) => void;
}

const FirstRow: React.FC<IFirstRowProps> = ({
  natureOfOperationList,
  isLoadingNatureOfOperation,
  fetchNewNatureOfOperationPage,
  refetchSearchNatureOfOperationPage,
}) => {
  const { t } = useTranslation();
  const [seriesMaskVisible, setSeriesMaskVisible] = useState(false);

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="notaFiscal"
          label={t('purchasing.invoice.create.invoiceModel')}
          disabled
          value={t('purchasing.invoice.create.placeHolder.invoiceModelValue')}
        />
      </Col>
      <Col span={4}>
        <NumberInput
          name="numero"
          required
          label={t('purchasing.invoice.create.invoiceNumber')}
          placeHolder={`${t('common.exampleAbbreviation')}: 0025`}
          withThousandSeparator
          withDecimalSeparator
          maxLength={11}
        />
      </Col>
      <Col span={2}>
        <TextInputWithMask
          mask={seriesMaskVisible ? 'invoiceSeries' : undefined}
          name="serie"
          required
          label={t('purchasing.invoice.create.invoiceSeries')}
          placeHolder={`${t('common.exampleAbbreviation')}: 5`}
          onChange={(value) => {
            if (value.length === 10) {
              setSeriesMaskVisible(true);
            } else {
              setSeriesMaskVisible(false);
            }
          }}
        />
      </Col>
      <Col span={12}>
        <InputWithSearch
          label={t('purchasing.invoice.create.natureOfOperation')}
          name="naturezaOperacaoId"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.natureOfOperation'
          )}
          required
          items={configNatureOfOperationList(natureOfOperationList ?? [])}
          isLoading={isLoadingNatureOfOperation}
          onScrollEnd={fetchNewNatureOfOperationPage}
          onSearch={refetchSearchNatureOfOperationPage}
        />
      </Col>
    </Row>
  );
};

export default FirstRow;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../Components';
import FirstRow from './rows/FirstRow';
import SecondRow from './rows/SecondRow';
import ThirdRow from './rows/ThirdRow';

interface IValueSectionProps {
  importedProducts: any[];
}

const ValueSection: React.FC<IValueSectionProps> = ({ importedProducts }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('purchasing.invoice.create.value')} withoutCollapse>
      <FirstRow importedProducts={importedProducts} />
      <SecondRow />
      <ThirdRow importedProducts={importedProducts} />
    </Card>
  );
};

export default ValueSection;

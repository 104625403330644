import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IStockLabel } from "./interfaces";
import { Checkbox, Tooltip } from "@/Components";
import { useFormikContext } from "formik";

import styles from "./StockLabel.module.scss";

export const StockLabel: FC<IStockLabel> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={6} className={styles['checkbox']}>
      <Tooltip
        title={`${t('products.toolTips.products.originalDcb')} ${
          product?.etiqueta === true
            ? t('products.toolTips.products.enabled')
            : t('products.toolTips.products.disabled')
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.etiqueta !== form?.values?.etiqueta
        }
      >
        <Checkbox
          name="etiqueta"
          label={t('products.create.tag')}
          disabled={disabledForm}
          withStatusIcon={!!product}
          withTooltip={{
            icon: 'question-mark',
            title: t('products.create.tagTooltip'),
          }}
        />
      </Tooltip>
    </Col>
  );
};
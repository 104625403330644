import { Button } from '@/Components';
import { history } from '@/Store';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFChildren } from '@/Components/Form';
import styles from './DetailsForm.module.scss';

interface IFooterButtons {
  props: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({ props }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          history.push('/production/packing-classification');
        }}
      />
      <Button
        type="primary"
        children={t('common.save')}
        htmlType="submit"
        disabled={!!props.errors.descricao || !props.values.descricao}
      />
    </div>
  );
};

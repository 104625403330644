import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { IUseCallAPI } from './interfaces';

export const useCreateCallAPI = ({ pagination }: IUseCallAPI) => {
  const { data: storageLocationList, isLoading: isLoadingStorageLocationList } =
    useControllerQueryListApiHook({
      uniqId: FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE,
      entityApi: StorageLocationApi.getListStorageLocationDropDown,
      initialPagination: pagination,
    });

  return {
    storageLocationList,
    isLoadingStorageLocationList,
  };
};

import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CREATE_PROVIDER_TYPE,
  FETCH_PROVIDER_TYPE,
} from '@/ControllerApiHook/UniqIds/People/PersonKeys';
import { PersonAPI } from '@/Data/API/People/PersonApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { FETCH_LIST_SUPPLIER } from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { useTranslation } from 'react-i18next';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutateAsync: createProvider, isLoading: isLoadingCreateProvider } =
    useControllerMutateApiHook({
      uniqId: CREATE_PROVIDER_TYPE,
      entityApi: PersonAPI.createProvider,
      options: {
        onError: (x) => messageErrorToast(x),
        onSuccess: () => {
          messageSucessToast(
            t('purchasing.provider.details.successMessage.addProvider')
          );
          queryClient.invalidateQueries([FETCH_LIST_SUPPLIER]);
          history.push('/order/supplier');
        },
      },
    });

  const { data: providerTypes } = useControllerQueryListApiHook({
    uniqId: FETCH_PROVIDER_TYPE,
    entityApi: PersonAPI.listProviderTypes,
  });

  return {
    createProvider,
    isLoadingCreateProvider,
    providerTypes,
  };
};

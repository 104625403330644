import { Dropdown } from '@/Components';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import DescriptionPharmaceuticalForm from './pharmaceuticalFormDescription';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { calculationTypeDropdown } from '../../../../Utils/utils';

interface IRowDescriptionPosology {
  enableButton: () => void;
}

export const RowDescription: FC<IRowDescriptionPosology> = ({
  enableButton,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={2}>
        <TextInputNumber
          name="order"
          placeHolder={t(
            'production.pharmaceuticalForm.create.orderPlaceholder'
          )}
          formaterNumberZeroMask={3}
          required
          onChange={() => enableButton()}
          label={t('production.pharmaceuticalForm.create.order')}
          withoutArrows={true}
        />
      </Col>
      <Col span={11}>
        <DescriptionPharmaceuticalForm enableButton={enableButton} />
      </Col>
      <Col span={6}>
        <Dropdown
          name="calculationType"
          items={calculationTypeDropdown?.map((values) => ({
            id: values.id,
            label: values.name,
          }))}
          placeHolder={t(
            'production.pharmaceuticalForm.create.calculationTypePlaceholder'
          )}
          onChange={() => enableButton()}
          required
          label={t('production.pharmaceuticalForm.create.calculationType')}
        />
      </Col>
      <Col span={5}>
        <PercentageInput
          name="excipientMinimum"
          label={t('production.pharmaceuticalForm.create.excipientMinimum')}
          placeHolder={t(
            'production.pharmaceuticalForm.create.excipientMinimumPlaceholder'
          )}
          withoutMarginBottom={true}
          required
          onChange={() => enableButton()}
        />
      </Col>
    </Row>
  );
};

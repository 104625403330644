import { IQueryListParams } from '@/Utils/Http';
import { useState } from 'react';

export const useCreateStates = () => {
  const [paginationLot, setPaginationLot] = useState<IQueryListParams>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [unitMeasurementPagination, setUnitMeasurementPagination] =
    useState<IQueryListParams>({
      pageIndex: 0,
      pageSize: 50,
    });
  return {
    paginationLot,
    setPaginationLot,
    unitMeasurementPagination,
    setUnitMeasurementPagination,
  };
};

import React, { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { BodyModal } from './Body';
import { useAddUserGroupCallAPI } from './Hooks/useAddUserGroupCallAPI';
import { useAddUserGroupStates } from './Hooks/useAddUserGroupStates';
import { useAddUserGroupMemorizations } from './Hooks/useAddUserGroupMemorizations';
import { useAddUserGroupsFunctions } from './Hooks/useAddUserGroupsFunctions';
import { IAddUserGroup } from './interfaces';

export const AddUserModal: FC<IAddUserGroup> = ({
  visible,
  externalId,
  setVisible,
}) => {
  const { usersSelected, setUsersSelected } = useAddUserGroupStates();

  const { addUserInUserGroup } = useAddUserGroupCallAPI({
    setUsersSelected,
    setVisible,
  });

  const { titleModal } = useAddUserGroupMemorizations({
    usersSelected,
  });

  const { onSubmit } = useAddUserGroupsFunctions({
    externalId,
    addUserInUserGroup,
  });

  return (
    <Modal
      visible={visible}
      okButtonName="userGroups.editGroups.modal.addBtn"
      onOkClickForm={(x) => onSubmit(x.users)}
      title={titleModal}
      buttonOkWidth={140}
      disabledButtonFormNames={['users']}
      body={
        <BodyModal
          usersSelected={usersSelected}
          setUsersSelected={setUsersSelected}
          groupExternalId={externalId}
        />
      }
      withForm
      onCancelClick={() => setVisible(false)}
      onClose={() => setVisible(false)}
      htmlType="submit"
    />
  );
};

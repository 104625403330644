import { FC } from 'react';
import { Col } from 'antd';
import { InputWithSearch } from '@/Components';
import { useTranslation } from 'react-i18next';
import { ISpecialties } from './interfaces';
import { useSpecialtiesFunctions } from './Hooks/useSpecialtiesFunctions';

export const Specialties: FC<ISpecialties> = ({
  specialtiesData,
  refetchSearchSpecialties,
  fetchNewPage,
}) => {
  const { t } = useTranslation();
  const { onSearch } = useSpecialtiesFunctions({ refetchSearchSpecialties });

  return (
    <Col span={24}>
      <InputWithSearch
        name="especialidadesExternalId"
        selectedItemFromForm="specialtiesLabel"
        label={t('prescribers.createPage.info.specialties')}
        placeHolder={t('prescribers.createPage.info.specialtiesPlaceholder')}
        items={specialtiesData?.map((x) => ({
          id: x.externalId,
          label: x.descricao,
        }))}
        typeTag="tertiary"
        multipleSelection
        onSearch={onSearch}
        onScrollEnd={fetchNewPage}
        withoutSearchIcon
      />
    </Col>
  );
};

/* eslint-disable react/jsx-key */
import React, { FC } from 'react';
import WithSideBar from '../../Layouts/WithSideBar';
import { SectorsRoutes } from './Sectors';
import { DashboardRoutes } from './Dashboard';
import { SystemConfigurationsRoutes } from './SystemConfigurations';
import { UserSettingRoutes } from './UserSetting';

const AuthenticatedApp: FC = () => {
  return (
    <WithSideBar>
      <DashboardRoutes />

      <UserSettingRoutes />

      <SystemConfigurationsRoutes />

      <SectorsRoutes />
    </WithSideBar>
  );
};

export default AuthenticatedApp;

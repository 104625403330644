import React from 'react';
import { useParams } from 'react-router-dom';
import { PageLoading } from '@/Layouts/PageLoading';
import { CompanyDataCardWrapper } from '@/Layouts/CompanyFormLayout/CompanyDataCard';
import { CompanyAdressCard } from '@/Layouts/CompanyFormLayout/CompanyAdressCard';
import { CompanyResponsibleUserCardWrapper } from '@/Layouts/CompanyFormLayout/CompanyResponsibleUserCard/Edit';
import { useCompanyFormStates } from './Hooks/useCompanyFormStates';
import { useCompanyFormMemorizations } from './Hooks/useCompanyFormMemorizations';
import { useCompanyFormCallAPI } from './Hooks/useCompanyFormCallAPI';

export const Create: React.FC = () => {
  const { radioUser, setRadioUser } = useCompanyFormStates();

  const { externalId } = useParams<{ externalId: string }>();

  const { isEditForm } = useCompanyFormMemorizations({ externalId });

  const { 
    company,
    isLoading
  } = useCompanyFormCallAPI({ externalId });

  return (
    <PageLoading loading={isLoading}>
      {company && <CompanyDataCardWrapper company={company} />}
      {company && <CompanyAdressCard editForm={isEditForm} company={company} />}
      {company && (
        <CompanyResponsibleUserCardWrapper
          company={company}
          radioUser={radioUser}
          onChangeRadioUser={setRadioUser}
        />
      )}
    </PageLoading>
  );
};

import { IconName } from "@/Components/Icon/IconClasses";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useStatusCardMemorizations = ({
  statusTranslationFunction
}: IUseMemorizations) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const statusIcon = [
    'time',
    'disapproved',
    'like',
    'cancel',
    'shipping-truck',
    'partialcube',
    'simple-cube',
    'pencil',
  ] as IconName[];

  const statusType = [
    'Waiting for Approval',
    'Disapproved',
    'Released',
    'Canceled',
    'Confirmed with Provider',
    'Partial Received',
    'Total Received',
    'Drafit',
  ];

  const statusColor = [
    'waitingApproval',
    'disapproved',
    'relesed',
    'canceled',
    'confirmedProvider',
    'partialReceived',
    'totalReceived',
    'draft',
  ];

  const statusPurchaseOrder: number = form?.values?.status;

  const translationPath = statusTranslationFunction?.(statusPurchaseOrder);

  const translatedStatus = t(translationPath ?? '');

  return {
    statusIcon,
    statusType,
    statusColor,
    translatedStatus,
    statusPurchaseOrder
  };
};
import { IItems } from '../../Interface';
import { Options } from './OptionsLabel';

interface IOptionsInputWithSearch {
  items?: IItems[];
  currentSearch: string;
  name?: string
  inputDataTestId?: string;

  isLoading?: boolean;
}

export const OptionsInputWithSearch = ({
  items,
  currentSearch,
  name,
  inputDataTestId,

  isLoading,
}: IOptionsInputWithSearch) => {

  if (isLoading && items && items?.length < 1) return;

  return items?.map((item: IItems) => ({
    value: item.id,
    label: <Options item={item} currentSearch={currentSearch} dataTestId={`${inputDataTestId || name}-item-${item.id}`} />,
    content: item.content ? item.content : item.label,
    disabled: item.disabledItem,
    titleTooltipItem: item.titleTooltipItem,
  }));
};

import {
  DELETE_STANDARD_FORMULA,
  FETCH_LIST_STANDARD_FORMULA,
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';

export const useListCallAPI = () => {
  const {
    data: standardFormulaList,
    isLoading: isLoadingStandardList,
    refetch: refetchStandardFormulaList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.getStandardFormulaList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'produto', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const deleteStandardFormula: any = useControllerMutateApiHook({
    uniqId: DELETE_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.deleteStandardFormula,
  });

  return {
    standardFormulaList,
    isLoadingStandardList,
    refetchStandardFormulaList,
    deleteStandardFormula
  };
};
import { FC } from 'react';
import { Flag, Icon, Text } from '@/Components';
import { AvatarDropdown } from '../AvatarDropdown';
import { IItem } from '../..';

import styles from './OptionDropdown.module.scss';

interface IOptionDropdown {
  item: IItem;
}

export const OptionDropdown: FC<IOptionDropdown> = ({ item }) => (
  <div
    className={`${styles[item.code ? 'div-content-with-code' : 'div-content']}`}
  >
    {item.leftIcon && <Icon size="M" icon={item.leftIcon} color="text-50" />}
    {item.avatar && <AvatarDropdown avatar={item.avatar} />}
    {item.img && <Flag image={item.img} />}
    {item.label && !item.avatar && (
      <div className={styles['div-content-text']}>
        <Text
          className={styles['text-dropdown']}
          type="ui-sub-content"
          color="text-50"
          children={item.label}
        />
        {item.subLabel && (
          <Text
            className={`${styles['text-dropdown']} input-with-search-subLabel`}
            type="small-text"
            color="text-300"
            children={item.subLabel}
          />
        )}
      </div>
    )}
    {item.code && (
      <Text
        className={styles['code-dropdown']}
        type="small-text"
        color="text-300"
        children={item.code}
      />
    )}
  </div>
);

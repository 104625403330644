import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { IUseMemorizations } from './interfaces';

export const useGroupMemorizations = ({ listGroups }: IUseMemorizations) => {
  const listArray = listGroups ? listGroups.data : [];
  const items = listArray?.map((x: IProductGroupListData) => ({
    id: x.externalId,
    label: x.descricao,
  }));

  return { items };
};

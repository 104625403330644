import { useMemo } from "react";
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import { currencyFormater } from '@/Utils/Currency';
import { IUseMemorizations } from "./interfaces";
import { useTranslation } from "react-i18next";

export const useUnitaryDiscountMemorizations = ({
  formValues,
  index,
  setTotal,
  getTotalValue
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const discountTooltipValue = useMemo(() => {
    return formValues.discountType === DiscountTypes.porcentage
      ? (formValues.unitValue * formValues.discountValue) / 100
      : (formValues.discountValue * 100) / formValues.unitValue;
  }, [formValues]);

  const title = formValues.unitValue < formValues.discountValue
  ? t(
      'purchasing.purchaseOrder.create.errors.discountCannotBeGreater'
    )
  : formValues.discountType === DiscountTypes.porcentage
  ? currencyFormater(discountTooltipValue)
  : `${discountTooltipValue.toFixed(2)}%`;

  const label = index === 0
  ? t(
      'purchasing.purchaseOrder.create.productsCardField.unitaryDiscount'
    )
  : undefined;

  const dropdownLeft = {
    name: `pedidoCompraItens.${index}.tipoDesconto`,
    onChange: (x: string) =>
      setTotal(getTotalValue({ newDiscountType: parseInt(x) })),
  };

  return {
    discountTooltipValue,
    title,
    label,
    dropdownLeft
  };
};
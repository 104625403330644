import { IGetLotDropDownResponseData } from "@/Data/Interfaces/response/Lot/IListLot";
import { IUseMemorizations } from "./interfaces";
import { useFormikContext } from "formik";

export const useBatchSearchMemorizations = ({
  lotList,
  disabledForm
}: IUseMemorizations) => {
  const form: any = useFormikContext();

  const items = lotList?.map((x: IGetLotDropDownResponseData) => ({
    id: x.loteExternalId,
    name: x.loteNumero,
    code: `NF: ${x.numeroNf}`,
  }));

  const disabled = form?.values?.loteInformacaoTecnica?.diluicaoInterna !== 100 ||
  form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna !== 1 ? 
  true : disabledForm;

  return {
    items,
    disabled
  };
};
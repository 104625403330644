import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '../../../../../../../Components';
import { IFChildren } from '../../../../../../../Components/Form';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';
import styles from './BalanceAdjustmentsRegistration.module.scss';

export interface IInputWithSearchLot {
  form: IFChildren;
  onLotSelected: (lotExternalId: string) => void;
}

export const InputWithSearchLot: FC<IInputWithSearchLot> = ({
  form,
  onLotSelected,
}) => {
  const { t } = useTranslation();
  const { lotList, isLotLoading, refetchLot, refetchNewPageLot } =
    useCreateCallAPI({ form });

  useEffect(() => {
    form?.setFieldValue('loteExternalId', undefined);
    form.values.produtoExternalId &&
      form.values.localEstoqueExternalId &&
      refetchLot({
        pageIndex: 0,
        pageSize: 10,
        filter: [
          {
            filterName: 'produtoExternalId',
            value: form?.values.produtoExternalId,
          },
          {
            filterName: 'localEstoqueExternalId',
            value: form?.values.localEstoqueExternalId,
          },
        ],
      });
  }, [form.values.produtoExternalId, form.values.localEstoqueExternalId]);

  const { lotDropdownList } = useCreateMemorizations({ lotList });

  return (
    <InputWithSearch
      items={lotDropdownList}
      className={styles['Left-Icon']}
      name="loteExternalId"
      label={t('supply.losses.create.lot')}
      placeHolder={t('supply.losses.create.lotPlaceholder')}
      disabled={
        !form.values.produtoExternalId || !form.values.localEstoqueExternalId
      }
      withoutMarginBottom={true}
      isLoading={isLotLoading}
      onScrollEnd={refetchNewPageLot}
      onChange={(_, value) => value && onLotSelected(value.loteExternalId)}
    />
  );
};

import React from 'react';
import { Row, Col } from 'antd';
import { TextInputWithMask } from '../../../../../../../../../Components/Inputs/TextInputWithMask';
import { Tooltip } from '../../../../../../../../../Components/Tooltip';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import DateUtils from '../../../../../../../../../Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';

interface ICalendarInputProps {
  name: string;
  placeHolder: string;
  label: string;
  error: boolean;
  errorMessage: string;
}

const parseDate = (date: string) => {
  const parts = date.split('/');
  return new Date(+parts[2], +parts[1] - 1, +parts[0]);
};

const SecondRow: React.FC = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();

  const validateDates = (): boolean => {
    const formDateEmission = form.values.dataEmissao;
    const formDateDelivery = form.values.dataEntrega;
    if (formDateEmission && formDateDelivery) {
      const dateEmission = parseDate(formDateEmission);
      const dateDelivery = parseDate(formDateDelivery);
      return dateEmission > dateDelivery;
    }
    return false;
  };

  const configPlaceHolder = () => {
    if (preferences) {
      return DateUtils.getDatePlaceholder(
        preferences.idioma,
        preferences.padraoData
      );
    }
  };

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <CalendarInput
          name="dataEmissao"
          placeHolder={configPlaceHolder() ?? ''}
          label={t('purchasing.invoice.create.invoiceIssueDate')}
          error={validateDates()}
          errorMessage={t('purchasing.invoice.create.invoiceIssueDateError')}
        />
      </Col>
      <Col span={4}>
        <CalendarInput
          name="dataEntrega"
          placeHolder={configPlaceHolder() ?? ''}
          label={t('purchasing.invoice.create.invoiceDeliveryDate')}
          error={validateDates()}
          errorMessage={t('purchasing.invoice.create.invoiceDeliveryDateError')}
        />
      </Col>
    </Row>
  );
};

const CalendarInput = ({
  name,
  placeHolder,
  label,
  error,
  errorMessage,
}: ICalendarInputProps) => {
  return (
    <>
      <Tooltip title={errorMessage} showMe={error}>
        <TextInputWithMask
          name={name}
          leftIcon="calendar"
          placeHolder={placeHolder}
          required
          label={label}
          mask="dataMask"
          error={error}
          withTooltip={{
            icon: 'question-mark',
            title: 'titulo',
          }}
          withStatusIcon={error}
        />
      </Tooltip>
    </>
  );
};

export default SecondRow;

import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { FormikValues, useFormikContext } from 'formik';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { RowDescription } from './Rows/DescriptionRow';
import { OtherRow } from './Rows/OtherRow';

interface IPharmaceuticalFormInfo {
  enableButton: () => void;
  changeStateButtonSave: (x: boolean) => void;
}

export const InfoCard: React.FC<IPharmaceuticalFormInfo> = ({
  enableButton,
  changeStateButtonSave,
}) => {
  const { t } = useTranslation();

  const form = useFormikContext<FormikValues>();

  useCreateFunctions({ form, changeStateButtonSave });

  return (
    <Card title={t('common.information')} withoutCollapse={true}>
      <RowDescription enableButton={enableButton} />
      <OtherRow enableButton={enableButton} />
    </Card>
  );
};

import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { useFormikContext } from "formik";
import { IUseFunctions } from "./interfaces";

export const useProductCallFunctions = ({
  index,
  totalRows,
  setProductRowAmount,
  uuidV4,
  productList,
  unitMeasurementPagination,
  setUnitMeasurementPagination,
  refetchSearchProductPage
}: IUseFunctions) => {
  const form = useFormikContext<any>();

  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
  };

  const onChange = (idProduct: string) => {
    const productListFormatted = productList.data
      ? productList.data
      : [];
    const produto = productListFormatted.find(
      (prod: IProductGroupListData) => prod.externalId === idProduct
    );
    form.setFieldValue(
      `pedidoCompraItens.${index}.unidadeMedidaId`,
      produto?.unidadeMedidaId
    );
    addRow();
    setUnitMeasurementPagination({
      ...unitMeasurementPagination,
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: produto?.classeProdutoId,
        },
      ],
    });
  };

  const onSearch = (search: string | undefined) => {
    refetchSearchProductPage({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' },
    });
  };

  return {
    onChange,
    onSearch
  };
};
import { useCallback } from 'react';
import { IEditProductGroupRequest } from '@/Data/Interfaces/request/Product/IProductRequest';
import { FormikHelpers, FormikValues } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_LIST_GROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseFunctions } from './interfaces';

export const useFormGroupModalFunctions = ({
  title,
  selectedRowsOnTable,
  createGroup,
  editGroup,
  visible,
  changeVisible,
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    async (
      values: IEditProductGroupRequest,
      helpers: FormikHelpers<FormikValues>
    ) => {
      const body: IEditProductGroupRequest = {
        ...values,
        helpers,
      };

      if (title && selectedRowsOnTable) {
        const res = await editGroup.mutateAsync({
          grupoExternalId: selectedRowsOnTable[0]?.externalId,
          descricao: body?.descricao,
          helpers,
        });
        if (res.success) queryClient.invalidateQueries([FETCH_LIST_GROUP]);
      } else {
        const res = await createGroup.mutateAsync({
          descricao: body?.descricao,
          helpers,
        });
        if (res.success === false)
          queryClient.invalidateQueries([FETCH_LIST_GROUP]);
      }
    },
    [title, selectedRowsOnTable, createGroup, editGroup]
  );

  const onOkClickForm = useCallback(
    (values: IEditProductGroupRequest, formik: FormikHelpers<FormikValues>) => {
      onSubmit(values, formik);
      changeVisible(!visible);
    },
    [changeVisible, onSubmit]
  );

  const onCancelClick = useCallback(
    () => changeVisible(!visible),
    [changeVisible, visible]
  );

  const onClose = useCallback(
    () => changeVisible(!visible),
    [changeVisible, visible]
  );

  return {
    onOkClickForm,
    onCancelClick,
    onClose,
  };
};

import { FC } from "react";
import {
  Checkbox,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ILaboratoryOnly } from "./interfaces";

import styles from "./LaboratoryOnly.module.scss";

export const LaboratoryOnly: FC<ILaboratoryOnly> = ({
  product,
  disabledForm
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={6} className={styles['checkbox']}>
      <Tooltip
        title={`${t('products.toolTips.products.originalValue')} ${
          product &&
          product.materiaPrima &&
          product.materiaPrima.somenteLaboratorio === true
            ? t('products.toolTips.products.enabled')
            : t('products.toolTips.products.disabled')
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima.somenteLaboratorio !==
            form?.values?.somenteLaboratorio
        }
      >
        <Checkbox
          withStatusIcon={!!product}
          name="somenteLaboratorio"
          label={t('products.create.onlyLab')}
          withTooltip={{
            icon: 'question-mark',
            title: t('products.create.tooltips.onlyLab'),
          }}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
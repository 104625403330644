import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { ICreatePharmaceuticalForm } from '@/Data/Interfaces/request/PharmaceuticalForm/IPharmaceuticalFormRequest';

interface IFooterButtons {
  isLoadingCreatePharmaceuticalForm: boolean;
  isDisable: boolean;
  mandatoryFieldsRequired: boolean;
  onSubmit: (values: ICreatePharmaceuticalForm) => Promise<any>;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreatePharmaceuticalForm,
  isDisable,
  mandatoryFieldsRequired,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex' }}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        style={{ marginRight: '10px' }}
        onClick={() => {
          history.push('/production/dosage-form');
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingCreatePharmaceuticalForm
            ? t('common.creating')
            : t('common.create')
        }
        htmlType="submit"
        loading={isLoadingCreatePharmaceuticalForm}
        disabled={isDisable || !mandatoryFieldsRequired}
        onClick={() => onSubmit}
      />
    </div>
  );
};

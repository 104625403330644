import { Icon, Link, Status, Tooltip } from '../../../../../../../Components';
import DateUtils from '../../../../../../../Utils/DateUtils';
import { Avatar } from '../../../../../../../Components/Avatar';
import { statusTypes } from '../../../../../../../Utils/StatusUtils';
import { formatPhoneNumber } from 'react-phone-number-input';
import { TFunction } from 'react-i18next';
import { IPreferences } from '@/Data/Interfaces/response/User/IGetUserReponse';
import {
  IListServiceChannelData,
  IListServiceData,
} from '@/Data/Interfaces/response/Sales/Service/IServiceResponse';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Color, Colors } from '@/Utils/ColorUtils';
import { statusServiceTypeProp } from '@/Pages/Sectors/Sales/Order/List/order.columns';

export const ticketsCollums = (
  t: TFunction<'translation', undefined>,
  datePattern?: IPreferences,
  serviceChannel?: IListServiceChannelData[]
) => [
  {
    title: '',
    dataIndex: 'canalIcon',
    key: 'canalIcon',
    render: (x: any, row: IListServiceData) => (
      <Divflex style={{ height: '48px', alignItems: 'center' }}>
        <div
          style={{
            width: '4px',
            height: '100%',
            background:
              row.statusAtendimentoId <= 7
                ? Colors[
                    statusServiceTypeProp[row.statusAtendimentoId]
                      .color as Color
                  ]
                : '',
            margin: '0 16px 0 -16px',
          }}
        />
        <Tooltip
          title={
            row.identificadorCanal
              ? row.identificadorCanal[0] === '+'
                ? formatPhoneNumber(row.identificadorCanal)
                : row.identificadorCanal
              : serviceChannel &&
                serviceChannel.find(
                  (channel) => channel.id === row.canalAtendimentoId
                )?.descricao
          }
          placement="topLeft"
          showMe
          style={{ width: 'fitContent' }}
        >
          <Icon icon={x} color="text-50" />
        </Tooltip>
      </Divflex>
    ),
  },
  {
    title: t('callCenter.listPage.code'),
    dataIndex: 'codigoAtendimento',
    key: 'codigoAtendimento',
    isHighlightable: true,
    sortColumn: 'codigoAtendimento',
    sorter: true,
    defaultSortOrder: 'descend',
    width: '9%',
    render: (x: string, row: IListServiceData) => (
      <Link
        type="ui-tiny-bold"
        to={`/sales/tickets/${row.externalId}/details`}
        children={`#${x}`}
      />
    ),
  },
  {
    title: t('callCenter.listPage.creationDate'),
    dataIndex: 'dataCriacao',
    key: 'dataCriacao',
    isHighlightable: true,
    sortColumn: 'dataCriacao',
    render: (date: string) =>
      datePattern &&
      `${DateUtils.convertDate(date, datePattern.padraoData)} ${t(
        'common.complementDateHour'
      )} ${DateUtils.convertDate(date, datePattern.padraoHora)}`,
  },
  {
    title: t('callCenter.listPage.lastUpdate'),
    dataIndex: 'dataAtualizacao',
    key: 'dataAtualizacao',
    sortColumn: 'dataAtualizacao',
    render: (date: string) =>
      datePattern &&
      `${DateUtils.convertDate(date, datePattern.padraoData)} ${t(
        'common.complementDateHour'
      )} ${DateUtils.convertDate(date, datePattern.padraoHora)}`,
  },
  {
    title: t('callCenter.listPage.attendant'),
    dataIndex: 'nomeAtendente',
    key: 'nomeAtendente',
    sortColumn: 'nomeAtendente',
    render: (name: string, row: IListServiceData) => (
      <Avatar
        type="ui-tiny-bold"
        size="M"
        fisrtName={name}
        description={{
          description: name,
          type: 'ui-tiny-bold',
          color: 'text-50',
        }}
        routeLink={`/admin/user/${row.externalId}/details`}
        color="white"
      />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'statusAtendimentoId',
    key: 'statusAtendimentoId',
    sortColumn: 'statusAtendimentoId',
    sorter: true,
    render: (statusId: string) => (
      <Status
        type={statusServiceTypeProp[parseInt(statusId)]?.status as statusTypes}
        children={statusServiceTypeProp[parseInt(statusId)]?.description || ''}
      />
    ),
  },
];

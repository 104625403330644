import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVENTORY_BY_STOCK_LOCATION } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const useListCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const {
    data: inventoryBySupplyPlaceList,
    isLoading: isLoadingInventoryBySupplyPlaceList,
    refetch: refetchInventorySupplyPlaceList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_INVENTORY_BY_STOCK_LOCATION,
    entityApi: StorageLocationApi.getInventoryByStockLocationList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [
        {
          filterName: 'localEstoqueExternalId',
          value: externalId,
        },
      ],
    },
    optionalParams: [externalId],
    autoIncrement: true,
  });

  useEffect(() => {
    refetchInventorySupplyPlaceList({
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [
        {
          filterName: 'localEstoqueExternalId',
          value: externalId,
        },
      ],
    });
  }, [externalId, refetchInventorySupplyPlaceList]);

  return {
    inventoryBySupplyPlaceList,
    isLoadingInventoryBySupplyPlaceList,
    refetchInventorySupplyPlaceList,
  };
};

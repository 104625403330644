import { useCallback, useState } from 'react';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Text';
import { PermissionEnum } from '../../../../../../../Data/Interfaces/model/permissionEnum';
import {
  EDIT_STATUS_POSOLOGY,
  FETCH_GET_POSOLOGY,
  FETCH_LIST_POSOLOGY
} from '../../../../../../../ControllerApiHook/UniqIds/Production/PosologyKeys';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '../../../../../../../Store';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { PosologyAPI } from '@/Data/API/Production/Posology';
import { IGetPosology } from '@/Data/Interfaces/response/Posology/IPosologyResponse';

export const DynamicEditPosologyBreadcrumb = ({ match }: any) => {
  const { data: posology } = useControllerQueryApiHook<IGetPosology>({
    uniqId: FETCH_GET_POSOLOGY,
    entityApi: PosologyAPI.getPosology,
    externalId: match.params.externalId
  });

  return <div style={{ display: 'flex' }}>{posology?.posologiaDescricao}</div>;
};

export const DynamicEditPosologyStatusBreadcrumb = ({ match }: any) => {
  const { data: posology } = useControllerQueryApiHook<IGetPosology>({
    uniqId: FETCH_GET_POSOLOGY,
    entityApi: PosologyAPI.getPosology,
    externalId: match.params.externalId
  });

  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const [status, setStatus] = useState(undefined);

  const editStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_POSOLOGY,
    entityApi: PosologyAPI.putPosologyStatus
  });

  const queryClient = useQueryClient();

  const changeStatus = useCallback(
    async (value: boolean) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: value,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]);
        history.push('/production/posology');
      }
    },
    [editStatus, match.params.externalId, queryClient]
  );

  const onEditStatus = useCallback(
    async (status: any) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: status,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]);
        history.push('/production/posology');
      }
    },
    [queryClient, match.params.externalId, editStatus]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('breadCrumb.production.posology.posologyDetailTitle')} | ${
        posology?.posologiaDescricao
      }`}
      <PermissionWrapper
        permission={PermissionEnum.Producao_Posologia_AlterarStatus}
        tooltip="noPermission.production.posology.statusEdit"
      >
        <Status
          type={posology?.ativo ? 'success' : 'disabled'}
          ative={posology?.ativo || false}
          onClick={(value) => {
            value ? changeStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
          permission={{
            permission: PermissionEnum.Producao_Posologia_AlterarStatus,
            tooltip: 'noPermission.production.posology.statusEdit',
          }}
        />
      </PermissionWrapper>
      <Modal
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text
              type="ui-tiny-content"
              children={t(
                'production.posology.details.changeStatusDescriptionPosology'
              )}
            />
            <Text
              type="ui-tiny-bold"
              children={`${posology?.posologiaDescricao}?`}
            />
          </Divflex>
        }
        title={`${t('production.posology.details.statusTypes.inactive')} ${t(
          'common.posology'
        )} "${posology?.posologiaDescricao}"?`}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => status !== undefined && onEditStatus(status)}
        onClose={() => setModalVisible(false)}
        okButtonName={t('production.posology.details.statusTypes.inactive')}
      />
    </div>
  );
};

import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFChildren } from '@/Components/Form';
import styles from '../../CapsuleColors.module.scss';

interface IFooterButtons {
  isLoadingEditCapsuleColor: boolean;
  props: IFChildren;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditCapsuleColor,
  props,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => onChangeDisabled(true)}
      />
      <Button
        type="primary"
        children={
          isLoadingEditCapsuleColor ? t('common.saving') : t('common.save')
        }
        htmlType="submit"
        disabled={!props.values.corCapsula}
        loading={isLoadingEditCapsuleColor}
      />
    </div>
  );
};

import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useProviderFunctions = ({
  refetchSearchProviderPage
}: IUseFunctions) => {
  const onSearch = useCallback((search: string | undefined) => {
    refetchSearchProviderPage({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'nome', direction: 'DESC' },
    });
  }, [refetchSearchProviderPage]);

  return {
    onSearch
  };
};
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const usePackingClassificationFunctions = ({
  refetchPackingClassification
}: IUseFunctions) => {
  const onSearch = useCallback((search?: string) => {
    refetchPackingClassification({
      pageIndex: 0,
      pageSize: 10,
      search: search,
    });
  }, [refetchPackingClassification]);

  return { onSearch };
};
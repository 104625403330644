import {
  FETCH_GET_DROPDOWN_GROUP,
  VALIDATION_NAME_SUBGROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseCallAPI } from './interfaces';
import { useControllerMutateApiHook, useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useModalBodyCallAPI = ({
  onSubGroupValidationError,
  onSubGroupValidationSuccess
}: IUseCallAPI) => {
  const { 
    data: dataGroupDropDown, 
    fetchNewPage: fetchNewGroupPage 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_DROPDOWN_GROUP,
    entityApi: ProductAPI.getGroupListDropdown,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_NAME_SUBGROUP,
    entityApi: ProductAPI.productSubGroupNameValidation,
    options: {
      onError: onSubGroupValidationError,
      onSuccess: onSubGroupValidationSuccess
    }
  });

  return {
    dataGroupDropDown,
    fetchNewGroupPage,
    mutateAsync
  };
};
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { ICreateLosse } from '@/Data/Interfaces/request/Loss/ILossesRequest';
import DateUtils from '@/Utils/DateUtils';

export const useCreateFunctions = ({
  setOnClassProductIdSelected,
  setOnProdutoExternalIdSelected,
  createLosses,
  preferences,
}: IUseFunctions) => {
  const onProductSelected = useCallback(
    (product: string, classProductId: number) => {
      setOnProdutoExternalIdSelected(product);
      setOnClassProductIdSelected(classProductId);
    },
    []
  );

  const onSubmit = async (values: ICreateLosse) => {
    var body: any = { ...values };
    if (body.dataPerda) {
      body.dataPerda = DateUtils.convertDateToApi(
        body.dataPerda,
        preferences.padraoData
      );
      const currentDate = new Date();
      const currentHourFormated = currentDate.toLocaleTimeString(
        preferences.idioma
      );
      body.dataPerda = `${body.dataPerda} ${currentHourFormated}`;
      return await createLosses({
        dataPerda: body.dataPerda,
        localEstoqueExternalId: body.localEstoqueExternalId,
        loteExternalId: body.loteExternalId,
        motivoPerdaExternalId: body.motivoPerdaExternalId,
        observacao: body.observacao,
        produtoExternalId: body.produtoExternalId,
        quantidade: body.quantidade,
        unidadeMedidaId: body.unidadeMedidaId,
      });
    }
  };

  return { onProductSelected, onSubmit };
};

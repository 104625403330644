import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';
import { formatPhoneNumber } from 'react-phone-number-input';
import { IUseMemorizations } from './interfaces';

export const useEditOrderMemorizations = ({
  purchaseOrder
}: IUseMemorizations) => {
  const preferences = StorageUtils.getPreference();

  const initialValues = purchaseOrder ? {
    ...purchaseOrder,
    fornecedorContatoPrincipal:
      purchaseOrder.tipoContatoId === 2
        ? formatPhoneNumber(purchaseOrder.fornecedorContatoPrincipal)
        : purchaseOrder.fornecedorContatoPrincipal,
    previsaoEntrega: DateUtils.format(
      purchaseOrder?.previsaoEntrega,
      preferences?.padraoData?.replace('mm', 'MM') || 'dd/MM/yyyy'
    ),
    tipoDesconto: purchaseOrder.tipoDescontoId,
  } : {};

  return {
    initialValues
  };
};
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';

export const useCreateCallAPI = () => {
  const {
    data: packingClassificationList,
    isLoading: isLoadingPackingClassification,
    refetch: refetchPackingClassificationList,
  } = useControllerQueryListApiHook({
    entityApi: PackingClassificationAPI.getListPackingClassificationDropDown,
    uniqId: FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  });
  return {
    packingClassificationList,
    isLoadingPackingClassification,
    refetchPackingClassificationList,
  };
};

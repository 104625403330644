import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { GET_USER_LIST_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';

export const useDetailsCardCallAPI = () => {
  const userList = useControllerQueryListApiHook({
    uniqId: GET_USER_LIST_TYPE,
    entityApi: UserAPI.listUser,
    autoIncrement: true,
  });

  return { userList };
};

import { useMemo } from 'react';
import { IUseUserDetailsMemorizations } from './interfaces';

export const useUserDetailsMemorizations = ({
  user,
}: IUseUserDetailsMemorizations) => {
  const initialValues = useMemo(
    () => ({
      ...user,
      grupos: user?.grupos.map((x) => x.grupoExternalId),
    }),
    [user]
  );

  return { initialValues };
};

import { FC } from 'react';
import { TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ValidateCnpj } from '@/Utils/ValidationUtils';
import { debounce } from 'lodash';

export const LegalEntity: FC = () => {
  const form = useFormikContext();
  const { t } = useTranslation();

  const validateCnpjOnChange = debounce(
    (x: string) => ValidateCnpj(x, form, t),
    500
  );

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="nome"
          required
          label={t('client.createPage.fantasyName')}
          placeHolder={t('client.createPage.fantasyNamePlaceholder')}
        />
      </Col>
      <Col span={6}>
        <TextInput
          name="razaoSocial"
          required
          label={t('client.createPage.corporateName')}
          placeHolder={t('client.createPage.fantasyNamePlaceholder')}
        />
      </Col>
      <Col flex="17%">
        <TextInputWithMask
          name="cnpj"
          placeHolder={`${t('common.exampleAbbreviation')}: 42.163.509/0001-03`}
          label={'CNPJ'}
          mask="cnpjMask"
          onChange={(x) => validateCnpjOnChange(x)}
          onBlur={(x) => ValidateCnpj(x, form, t, true)}
        />
      </Col>
    </Row>
  );
};

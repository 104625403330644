import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../../../Components';
import { FC } from 'react';
import { IGetPharmaceuticalForm } from '../../../../../../../Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { ContainerPage } from '../../../../../../../Layouts/ContainerPage';
import { useParams } from 'react-router';
import { packingClassificationColumns } from './packingClassificationColumns';
import { LinkPackingClassificationModal } from './Modal/LinkPackagingsModal';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';

interface IPackagingsClassificationsList {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
  pharmaceuticalForm?: IGetPharmaceuticalForm | null;
}

export const PackagingsClassificationsList: FC<
  IPackagingsClassificationsList
> = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    setVisibleBindPackingClassificationPharmaceuticalFormModal,
    visibleBindPackingClassificationPharmaceuticalFormModal,
  } = useListStates();

  const {
    packingClassificationList,
    isLoading,
    removePacking,
    refetchPackingClassificationList,
  } = useListCallAPI({ externalId });

  const { removePackingClassification } = useListFunctions({
    removePacking,
  });

  const { headerOptions, deleteModal } = useListMemorizations({
    packingClassificationList,
    selectedRowsOnTable,
    setVisibleBindPackingClassificationPharmaceuticalFormModal,
  });

  return (
    <ContainerPage>
      <Table
        columns={packingClassificationColumns(t)}
        hasSelection
        headerOptions={headerOptions}
        loading={isLoading}
        pagination={packingClassificationList}
        paginationCustomExternalId="classificacaoEmbalagemExternalId"
        onRefetch={(pagination) => {
          refetchPackingClassificationList({
            ...pagination,
            filter: [{ filterName: 'externalId', value: externalId }],
          });
        }}
        selectedRowsOnTable={setSelectedRowsOnTable}
        onDelete={removePackingClassification}
        deleteModal={deleteModal}
      />
      {visibleBindPackingClassificationPharmaceuticalFormModal && (
        <LinkPackingClassificationModal
          changeVisible={
            setVisibleBindPackingClassificationPharmaceuticalFormModal
          }
          visible
          pharmaceuticalFormExternalId={externalId}
        />
      )}
    </ContainerPage>
  );
};

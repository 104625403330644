import { CREATE_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { IUseCreateClientCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';

export const useCreateClientCallAPI = ({
  changeVisible,
}: IUseCreateClientCallAPI) => {
  const createClient = useControllerMutateApiHook({
    uniqId: CREATE_CLIENT,
    entityApi: ClientAPI.createClient,
    options: { onSuccess: () => changeVisible(false) },
  });

  return {
    createClient,
  };
};

import { useCallback } from "react";
import {
  setInitialValuesFormInternalDiluition
} from '../../../../../diluitionsHelpers';
import { useFormikContext } from "formik";

export const useInternalDilutionsFactorFunctions = () => {
  const form: any = useFormikContext();

  const onChange = useCallback(() => {
    setInitialValuesFormInternalDiluition(form);
  }, [setInitialValuesFormInternalDiluition]
  );

  return { onChange };
};
import { Table } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { productSynonymColumns } from './columns';
import { useParams } from 'react-router';
import { useDilutedProductStates } from './Hooks/useDilutedProductStates';
import { useDilutedProductCallAPI } from './Hooks/useDilutedProductCallAPI';
import { useDilutedProductMemorizations } from './Hooks/useDilutedProductMemorizations';
import { useDilutedProductFunctions } from './Hooks/useDilutedProductFunctions';

export const DilutedProduct: FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable
  } = useDilutedProductStates();

  const {
    currentProduct,
    deleteDilutedProduct,
    productList,
    isProductListLoading,
    refetchProductList
  } = useDilutedProductCallAPI({
    productExternalId
  });

  const {
    headerOptions,
    deleteModal,
    rowsOptions
  } = useDilutedProductMemorizations({
    currentProduct,
    selectedRowsOnTable,
    productExternalId
  });

  const {
    onDelete
  } = useDilutedProductFunctions({
    deleteDilutedProduct
  });

  useEffect(() => {
    refetchProductList({
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'produtoExternalId', value: productExternalId }],
    });
  }, [refetchProductList, productExternalId]);

  return (
    <PageLoading loading={isProductListLoading}>
      <Table
        loading={isProductListLoading}
        columns={productSynonymColumns(t)}
        hasSelection
        onDelete={onDelete}
        headerOptions={headerOptions}
        pagination={productList}
        onRefetch={refetchProductList}
        selectedRowsOnTable={setSelectedRowsOnTable}
        deleteModal={deleteModal}
        rowsOptions={rowsOptions}
      />
    </PageLoading>
  );
};

import { AuthAPI } from '@/Data/API/Identity/AuthApi';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CURRENT_USER } from '@/Utils/ConstantsUtils';
import { setRoles } from '@/Utils/PermissionUtils';
import _ from 'lodash';
import { Cookies } from 'react-cookie';
import { ILoginResponse } from '@/Data/Interfaces/response/Auth/ILoginResponse';
import { AUTH_TYPE } from '@/ControllerApiHook/UniqIds/Identity/AuthHooks';

export const useLoginCallAPI = () => {
  const authLogin = useControllerMutateApiHook({
    uniqId: AUTH_TYPE,
    entityApi: AuthAPI.login,
    options: {
      onSuccess: (data: ILoginResponse) => {
        const cookies = new Cookies();
        const dataAux = _.omit(data, 'preference');
        cookies.set(CURRENT_USER, dataAux, { path: '/' });
        setRoles(data?.access);
      },
    },
  });

  return { authLogin };
};

import { FC, useEffect } from "react";
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/Components';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import StorageUtils from "@/Utils/StorageUtils";
import { ICostValue } from "./interfaces";
import { useCostValueStates } from "./Hooks/useCostValueStates";
import { useCostValueFunctions } from "./Hooks/useCostValueFunctions";
import { useCostValueMemorizations } from "./Hooks/useCostValueMemorizations";

export const CostValue: FC<ICostValue> = ({
  product,
  disabledForm,
  listunitsMeasurements
}) => {
  const preference = StorageUtils.getPreference();
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    cost,
    setCost
  } = useCostValueStates();

  const { onChange } = useCostValueFunctions({ setCost });
  
  const { supportText } = useCostValueMemorizations({
    product,
    listunitsMeasurements
  });

  useEffect(() => {
    const currentFormValorVenda = form?.values?.valorVenda;
    const valorVenda =
      (form?.values.valorCusto * (form?.values.margemLucro + 100)) / 100;

    const hasChanges = currentFormValorVenda !== valorVenda;

    if (hasChanges) {
      form.setFieldValue('valorVenda', valorVenda || form?.values?.valorCusto);
    }
  }, [cost]);

  return (
    <Col span={4}>
      <Tooltip
        title={`${t('products.toolTips.products.costValue')} ${
          preference?.tipoMoedaId === 0 ? 'R$' : '$'
        } ${product?.valorCusto}`}
        showMe={
          product &&
          product?.valorCusto !== form?.values?.valorCusto &&
          !disabledForm
        }
      >
        <CurrencyInput
          name="valorCusto"
          label={[
            {
              children: t('products.create.costPrice'),
            },
          ]}
          placeHolder={t('products.create.costPricePlaceholder')}
          withoutMarginBottom={true}
          disabled={disabledForm}
          onChange={onChange}
          required={!product}
          withStatusIcon={!!product}
          supportText={supportText}
        />
      </Tooltip>
    </Col>
  );
};
import { FC } from 'react';
import { i18n, Modal } from '@/Components';
import { useTranslation } from 'react-i18next';
import {
  FETCH_GET_SALES_ORDER,
  UPDATE_DELIVERY_SALES_ORDER
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { EditDeliveryModalBody } from './editDeliveryModalBody';
import {
  EDIT_ADRESS_CLIENT,
  FETCH_ADDRESS_CLIENT_LIST,
  GET_CLIENT
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { IGetClientAdressResponse } from '@/Data/Interfaces/response/Client/IClientResponse';

interface IEditDeliveryModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrder: IGetSaleOrderResponse;
  selectedAddressExternalId?: string;
}

export const EditDeliveryModal: FC<IEditDeliveryModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrder,
  selectedAddressExternalId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: EDIT_ADRESS_CLIENT,
    entityApi: ClientAPI.editAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeAddress'));
        
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);

        queryClient.invalidateQueries([FETCH_ADDRESS_CLIENT_LIST]);

        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const updateDeliverySaleOrder = useControllerMutateApiHook({
    uniqId: UPDATE_DELIVERY_SALES_ORDER,
    entityApi: SalesOrderAPI.updateDeliverySaleOrder,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const { data: clientAddress } = useControllerQueryApiHook<IGetClientAdressResponse>({
    uniqId: GET_CLIENT,
    entityApi: ClientAPI.getClientAddress,
    externalId: selectedAddressExternalId
  });

  return (
    <>
      <Modal
        title={t('common.editDelivery')}
        body={<EditDeliveryModalBody />}
        visible={visibleModal}
        okButtonName={
          updateDeliverySaleOrder.isLoading
            ? t('common.saving')
            : t('common.save')
        }
        onCancelClick={() => changeVisibleModal(false)}
        onClose={() => changeVisibleModal(false)}
        widthModal={800}
        withForm
        initialValues={clientAddress}
        disabledButtonFormNames={['descricao']}
        onOkClickForm={async (x) => {
          if (saleOrder.clienteExternalId) {
            const res = await mutateAsync({
              ...x,
              clienteExternalId: saleOrder.clienteExternalId,
            });
            if (res.success) {
              changeVisibleModal(false);
            }
          }
        }}
        loadingOkButton={updateDeliverySaleOrder.isLoading}
        htmlType="submit"
      />
    </>
  );
};

export const saleOrderEN = {
  saleOrder: 'Sales Orders',
  list: {
    new: 'New Sales Order',
    searchPlaceholder:
      'Search for a customer, code, attendant, service, status, etc.',

    code: 'Code',
    service: 'Service',
    releaseDate: 'Release Date',
    attendant: 'Attendant',
    value: 'Value',
  },

  editOrder: {
    componentType: 'Component type',
    header: {
      lastUpdate: 'Last update',
      contact: 'Contact',
      attendant: 'Attendant',
      tabs: {
        order: 'Order',
        messages: 'Messages',
        attachments: 'Attachments',
        notes: 'Notes',
        historic: 'Historic',
      },
    },

    topButtons: {
      finishedProduct: 'Finished Product',
      manipulatedRecipe: 'Manipulated Recipe',

      historic: 'Historic',
    },

    withoutSaleOrder: {
      title: 'No sales orders',
      description:
        'This service does not yet have a sales order. Create a sales order by clicking the button below.',
      buttonText: 'New Sales Order',
    },

    SaleOrder: {
      withoutProducts: {
        title: 'No products',
        description: 'Add a product or recipe to quote on the sales order.',
      },

      table: {
        columns: {
          description: 'Description',
          unitValue: 'Unit Value',
          unitDiscount: 'Unit Discount',
          amount: 'Amount',
          total: 'Total',
        },
        successMessage: {
          changeItemAmount: 'Updated quantity',
        },
      },

      deleteTableItemModal: {
        title: 'To remove',
        description:
          'Are you sure you want to remove this item from the sales order?',
        success: 'Item deleted'
      },

      addProductModal: {
        title: 'Add Product',

        product: 'Product',
        productPlaceholder: 'Search for a product',
        code: 'Code',
        noBalanceAvailable: 'No balance available',
        balanceAvaible: 'available',
        balanceAvaibles: 'available',

        value: 'Unit Value.',

        amout: 'Amount',

        unitDiscount: 'Unit Discount',

        totalValue: 'Amount',

        prescriber: 'prescriber',
        prescriberPlaceholder: 'Search for a prescriber...',

        prescriptionDate: 'Prescription Date',

        add: 'Add',

        dateGreaterThanCurrentDay: `The prescription date cannot be later than today's date.`,

        success: 'Product added',

        InsufficientFunds: {
          insufficientFund: 'Insufficient funds!',
          description:
            'The product {{product}} does not have the selected quantity in stock.',
          BalanceStock: 'Balance in stock: {{stock}}',
          SelectedQuantity: 'Selected quantity: {{quantity}}',
          footerDescription:
            "Do you want to continue with the selected quantity? The product's stock balance at the stock location will become negative.",
        },
      },

      editProductModal: {
        success: 'Product edited'
      },

      addManipulatedRecipe: {
        recipe: 'Recipe',
        patient: 'Patient',
        prescriber: 'Prescriber',
        pharmaceuticalForm: 'Pharmaceutical Form',
        amount: 'Amount',
        repeat: 'Repeat',
        repeatTooltip: 'Quantity multiplied from the recipe.',
        total: 'Total',
        dose: 'Dose',
        totalDose: 'Total Doses',
        insertDose: 'Insert Dose',
        components: {
          componentCollum: 'Component',
          amountCollum: 'Amount',

          componentAmount: 'component',
          componentsAmount: 'components',
          changeProduct: 'Edit product',
          cancelEdit: 'Cancel product change',

          searchPlaceholder: 'Search for a product',
          searchFilterDescription: 'Search by Classes',
          synonymOf: 'Synonym of',

          optionsFilter: {
            rawMaterial: 'Raw Material',
            finishedProduct: 'Finished product',
            packaging: 'Packaging',
            capsuleType: 'Capsule Type',
            service: 'Service',
            readyCapsule: 'Ready Capsule (Bulk)',
            useAndConsumption: 'Use and Consumption',
          },

          optionsButton: {
            editLabel: 'Edit Label',
            saveSynonym: 'Save Synonym',

            showOnLabel: 'Show no Label',
            hideOnLabel: 'Hide no Label',
          },

          selectedComponent: {
            originalName: 'Original component name:',
            synonymSquare: 'This component is a synonym for',
            closeEye: 'This component will be hidden in the label.',
          },

          createSynonymModal: {
            createSynonym: 'Register synonym for',

            whantCreate:
              'Do you want to register {{synonymDescription}} as a synonym for the product {{descriptionComponent}} {{code}}?',
            descriptionFooter:
              'The synonym for the product will be created and replaced in the recipe component.',
          },

          existSynonymModal: {
            title: 'Synonym linked to component',

            description:
              'The synonym {{synonymDescription}} already exists for the product {{descriptionComponent}} {{code}} and has been linked to the component in the recipe.',
          },
        },
      },

      historicModal: {
        defaultCreateSaleOrderDescription:
          '{{vendedor}} created the sales order.',

        historicSaleOrderDescription:
          '{{vendedor}} changed the status to {{statusDescricao}}.',
        historicSaleOrderDescriptionReversal:
          '{{vendedor}} reversed the purchase order.',

        statusHistoricTooltips: 'Status changed to: {{status}}',
      },

      recipeCalculationModal: {
        title: 'Revenue Calculation',
        firstCard: {
          pharmaceuticalForm: 'Pharmaceutical Form',
          prescribedQuantity: 'Prescribed Quantity',
          calculatedVolume: 'Calculated Volume',
          packaging: 'Packaging',
          expectedDeliveryDate: 'Expected Delivery Date',
        },
        secondCard: {
          grossValue: 'Gross Value',
          discount: 'Discount',
          revenueValue: 'Revenue Value',
        },
      },
    },
    statusSaleOrder: {
      pending: 'Pending',
      budget: 'Budget',
      approved: 'Approved',
      rejected: 'Reproved',
      partialDelivery: 'Partial Delivery',
      delivered: 'Delivered',
      canceled: 'Canceled',
    },
    sideRightMenu: {
      infosSaleOrder: {
        orderNumber: 'Order No.',
        releaseDate: 'Release Date',
        seller: 'Seller',
        delivery: 'Delivery',
        subTextDelivery: 'Change to',
        pickUpATheCounter: 'Pick up at the Counter',
        homeDelivery: 'Home Delivery',
        addChangeOrNote: 'Add Change / Note',
        editChangeOrNote: 'Edit Change / Note',
        addProductMessage: 'Add new address',
        observations: 'Observations',
      },
      messageSuccessToast: {
        approve: 'Sales order successfully approved!',
        reprove: 'Sales order successfully reprove!',
        cancel: 'Sales order successfully cancel!',
        reverse: 'Sales order successfully reverse!',
      },
      approveModal: {
        title: 'Approve sales order',
        descriptionAllSelected:
          'Are you sure you want to approve order {{codigo}} from customer {{cliente}}?',
        descriptionSomeSelected:
          'Are you sure you want to approve order {{codigo}} from customer {{cliente}}? Only the selected products will be approved, the rest will have their status changed to Disapproved.',
      },
      reproveModal: {
        title: 'Reprove sales order',
        description:
          'Are you sure you want to reject order {{codigo}} from customer {{cliente}}? All products will be disapproved and the order status will be changed to **Disapproved**.',
        secundaryDescription: 'You can still refund the order.',
      },
      cancelModal: {
        title: 'Cancel sales order',
        description:
          'Are you sure you want to cancel order {{codigo}} from customer {{cliente}}? All products will be canceled and the order status will be changed to **Canceled**.',
        secundaryDescription: 'You can still refund the order.',
      },
      reverseModal: {
        title: 'Reverse sales order',
        description:
          'Are you sure you want to refund order {{codigo}} from customer {{cliente}}? The order status will change to **Quoting**.',
      },
    },
    bottomMenu: {
      totalProducts: 'Total Products',
      warningProducts: 'Total value of products without unit discount',
      totalDiscounts: 'Total Discounts',
      deliveryFee: 'Delivery Fee',
      amountReceived: 'Amount Received',
      totalValue: 'Total Value',
    },
    deliveryModal: {
      deliveryFee: 'Delivery Fee',
      selectAddress: 'Select an Address',
      newAddress: 'New Address',
      change: 'Change',
      deliveryNotes: 'Delivery Notes',
      success: 'Updated delivery fee',
    },
    listAddressDeliveryModal: {
      success: 'Address added',
    },
  },
};

export const saleOrderPT = {
  saleOrder: 'Pedidos de Venda',
  list: {
    new: 'Novo Pedido de Venda',
    searchPlaceholder:
      'Pesquise por um cliente, código, atendente, atendimento, status, etc.',

    code: 'Código',
    service: 'Atendimento',
    releaseDate: 'Data Lançamento',
    attendant: 'Atendente',
    value: 'Valor',
  },

  editOrder: {
    componentType: 'Tipo de componente',
    header: {
      lastUpdate: 'Última atualização',
      contact: 'Contato',
      attendant: 'Atendente',
      tabs: {
        order: 'Pedido',
        messages: 'Mensagens',
        attachments: 'Anexos',
        notes: 'Anotações',
        historic: 'Histórico',
      },
    },

    topButtons: {
      finishedProduct: 'Produto Acabado',

      manipulatedRecipe: 'Receita Manipulada',

      historic: 'Histórico',
    },

    withoutSaleOrder: {
      title: 'Nenhum pedido de venda',
      description:
        'Esse atendimento ainda não tem um pedido de venda. Crie um pedido de venda clicando no botão abaixo.',
      buttonText: 'Novo Pedido de Venda',
    },

    SaleOrder: {
      withoutProducts: {
        title: 'Nenhum produto',
        description:
          'Adicione um produto ou receita para fazer o orçamento no pedido de venda.',
      },

      table: {
        columns: {
          description: 'Descrição',
          unitValue: 'Valor Un.',
          unitDiscount: 'Desconto Un.',
          amount: 'Quantidade',
          total: 'Total',
        },
        successMessage: {
          changeItemAmount: 'Quantidade atualizada',
        },
      },

      deleteTableItemModal: {
        title: 'Remover',
        description:
          'Você tem certeza que deseja remover esse item do pedido de venda?',
        success: 'Item deletado'
      },

      addProductModal: {
        title: 'Adicionar Produto',

        product: 'Produto',
        productPlaceholder: 'Pesquise por um produto',
        code: 'Código',
        noBalanceAvailable: 'Sem saldo disponível',
        balanceAvaible: 'disponível',
        balanceAvaibles: 'disponíveis',

        value: 'Valor Un.',

        amout: 'Quantidade',

        unitDiscount: 'Desconto Unitário',

        totalValue: 'Valor Total',

        prescriber: 'Prescritor',
        prescriberPlaceholder: 'Pesquise por um prescritor...',

        prescriptionDate: 'Data Prescrição',

        add: 'Adicionar',

        dateGreaterThanCurrentDay:
          'A data de prescrição não pode ser posterior à data de hoje.',

        success: 'Produto adicionado',

        InsufficientFunds: {
          insufficientFund: 'Saldo insuficiente!',
          description:
            'O produto {{product}} não tem a quantidade selecionada em estoque.',
          BalanceStock: 'Saldo em estoque: {{stock}}',
          SelectedQuantity: 'Quantidade selecionada: {{quantity}}',
          footerDescription:
            'Deseja continuar com a quantidade selecionada? O saldo de estoque do produto no local de estoque ficará negativo.',
        },
      },

      editProductModal: {
        success: 'Produto editado'
      },

      addManipulatedRecipe: {
        recipe: 'Receita',
        patient: 'Paciente',
        prescriber: 'Prescritor',
        pharmaceuticalForm: 'Forma Farmacêutica',
        amount: 'Quantidade',
        repeat: 'Repetir',
        repeatTooltip: 'Quantidade multiplicada da receita.',
        total: 'Total',
        dose: 'Dose',
        totalDose: 'Total Doses',
        insertDose: 'Inserir Dose',
        components: {
          componentCollum: 'Componente',
          amountCollum: 'Quantidade',

          componentAmount: 'componente',
          componentsAmount: 'componentes',
          changeProduct: 'Alterar produto',
          cancelEdit: 'Cancelar alteração do produto',

          searchPlaceholder: 'Pesquise por um produto',
          searchFilterDescription: 'Pesquisa por Classes',
          synonymOf: 'Sinônimo de',

          optionsFilter: {
            rawMaterial: 'Matéria-prima',
            finishedProduct: 'Produto acabado',
            packaging: 'Embalagem',
            capsuleType: 'Tipo Cápsula',
            service: 'Serviço',
            readyCapsule: 'Cápsula pronta (Granel)',
            useAndConsumption: 'Uso e Consumo',
          },

          optionsButton: {
            editLabel: 'Editar Rótulo',
            saveSynonym: 'Salvar Sinônimo',

            showOnLabel: 'Mostrar no Rótulo',
            hideOnLabel: 'Oculatar no Rótulo',
          },

          selectedComponent: {
            originalName: 'Nome original do componente:',
            synonymSquare: 'Este componente é um sinônimo de',
            closeEye: 'Este componente será ocultado no rótulo.',
          },

          createSynonymModal: {
            createSynonym: 'Cadastrar sinônimo de',

            whantCreate:
              'Você deseja cadastrar {{synonymDescription}} como sendo um sinônimo do produto {{descriptionComponent}} {{code}}?',
            descriptionFooter:
              'Será criado o sinônimo do produto e substituído no componente da receita.',
          },

          existSynonymModal: {
            title: 'Sinônimo vinculado ao componente',

            description:
              'O sinônimo {{synonymDescription}} já existe para o produto {{descriptionComponent}} {{code}} e foi vinculado ao componente na receita.',
          },
        },
      },

      historicModal: {
        defaultCreateSaleOrderDescription:
          '{{vendedor}} criou o pedido de venda.',

        historicSaleOrderDescription:
          '{{vendedor}} alterou o status para {{statusDescricao}}.',
        historicSaleOrderDescriptionReversal:
          '{{vendedor}} estornou o pedido de compra.',

        statusHistoricTooltip: 'Status mudado para: {{status}}',
      },

      recipeCalculationModal: {
        title: 'Cálculo Receita',
        firstCard: {
          pharmaceuticalForm: 'Forma Farmacêutica',
          prescribedQuantity: 'Quantidade Prescrita',
          calculatedVolume: 'Volume Calculado',
          packaging: 'Embalagens',
          expectedDeliveryDate: 'Data Previsão Entrega',
        },
        secondCard: {
          grossValue: 'Valor Bruto',
          discount: 'Desconto',
          revenueValue: 'Valor da Receita',
        },
      },
    },
    statusSaleOrder: {
      pending: 'Pendente',
      budget: 'Orçamento',
      approved: 'Aprovado',
      rejected: 'Rejeitado',
      partialDelivery: 'Entregue Parcial',
      delivered: 'Entregue',
      canceled: 'Cancelado',
    },
    sideRightMenu: {
      infosSaleOrder: {
        orderNumber: 'Nº do Pedido',
        releaseDate: 'Data Lançamento',
        seller: 'Vendedor',
        delivery: 'Entrega',
        subTextDelivery: 'Troco para',
        pickUpATheCounter: 'Retirada no Balcão',
        homeDelivery: 'Entrega em Domicílio',
        addChangeOrNote: 'Adicionar Troco / Observação',
        editChangeOrNote: 'Editar Troco / Observação',
        addProductMessage: 'Adicionar novo endereço',
        observations: 'Observações',
      },
      messageSuccessToast: {
        approve: 'Pedido de venda aprovado com sucesso!',
        reprove: 'Pedido de venda reprovado com sucesso!',
        cancel: 'Pedido de venda cancelado com sucesso!',
        reverse: 'Pedido de venda estornado com sucesso!',
      },
      approveModal: {
        title: 'Aprovar o pedido de venda',
        descriptionAllSelected:
          'Você tem certeza que deseja aprovar o pedido {{codigo}} do(a) cliente {{cliente}}?',
        descriptionSomeSelected:
          'Você tem certeza que deseja aprovar o pedido {{codigo}} do(a) cliente {{cliente}}? Apenas os produtos selecionados serão aprovados, os demais terão o status alterado para Reprovado.',
      },
      reproveModal: {
        title: 'Reprovar o pedido de venda',
        description:
          'Você tem certeza que deseja reprovar o pedido {{codigo}} do(a) cliente {{cliente}}? Todos os produtos serão reprovados e o status do pedido será alterado para **Reprovado**.',
        secundaryDescription: 'Você ainda poderá estornar o pedido.',
      },
      cancelModal: {
        title: 'Cancelar o pedido de venda',
        description:
          'Você tem certeza que deseja cancelar o pedido {{codigo}} do(a) cliente {{cliente}}? Todos os produtos serão cancelados e o status do pedido será alterado para **Cancelado**.',
        secundaryDescription: 'Você ainda poderá estornar o pedido.',
      },
      reverseModal: {
        title: 'Estornar o pedido de venda',
        description:
          'Você tem certeza que deseja estornar o pedido {{codigo}} do(a) cliente {{cliente}}? O status do pedido será alterado para **Orçando**.',
      },
    },
    bottomMenu: {
      totalProducts: 'Total Produtos',
      warningProducts: 'Valor total dos produtos sem desconto unitário',
      totalDiscounts: 'Total Descontos',
      deliveryFee: 'Taxa de Entrega',
      amountReceived: 'Valor Recebido',
      totalValue: 'Valor Total',
    },
    deliveryModal: {
      deliveryFee: 'Taxa de Entrega',
      selectAddress: 'Selecione um endereço',
      newAddress: 'Novo endereço',
      change: 'Troco',
      deliveryNotes: 'Observações de Entrega',
      success: 'Taxa de entrega atualizada',
    },
    listAddressDeliveryModal: {
      success: 'Endereço adicionado',
    },
  },
};

import { IUseFunctions } from './interfaces';

import { useMemo } from 'react';
import _ from 'lodash';

export const useDetailFormFunctions = ({
  mutateAsyncGroupNameAlreadyRegistered,
}: IUseFunctions) => {
  const fetchProductMessageName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return value && (await mutateAsyncGroupNameAlreadyRegistered(value));
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsyncGroupNameAlreadyRegistered]
  );

  return { fetchProductMessageName };
};

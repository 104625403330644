import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { IField } from '../../interfaces/IField';
import { getScrollPercentage } from '@/Utils/ScrollUtils';
import _, { uniqBy } from 'lodash';
import { IFilterSearchItems, IItems } from '../Interface';
import { useField } from 'formik';

interface IInputWitchSearchHook {
  name: string;
  filterSearchItems?: IFilterSearchItems[];
  onScrollEnd?: () => void;
  isLoading?: boolean;

  actionOnFocus?: () => void;

  onChange?: (value: string, itemSelected?: any) => void;
  onSearch?: (search?: string, filters?: (string | number)[]) => void;

  itemsArray?: IItems[];

  currentItemsSelected?: string | string[];
  selectedItemFromForm?: string;
}

export const InputWitchSearchHook = ({
  name,
  filterSearchItems,
  onScrollEnd,
  isLoading,

  actionOnFocus,

  onChange,
  onSearch,

  itemsArray,
  currentItemsSelected,
  selectedItemFromForm,
}: IInputWitchSearchHook) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSearch, setCurrentSearch] = useState('');
  const [items, setItems] = useState(itemsArray);
  const [selectedItems, setSelectedItems] = useState<string | string[]>([]);
  const [filterSearchItem, setFilterSearchItem] = useState(filterSearchItems);

  const [{ value: selectedItem }] = useField(
    selectedItemFromForm || 'prop-not-set'
  );

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  const setFocusState = useCallback(() => {
    actionOnFocus && actionOnFocus();
  }, [actionOnFocus]);

  const onChangeItemsDisabled = useCallback(() => {
    typeof selectedItems === 'object' &&
      setItems((item) =>
        item?.map((x) => ({
          ...x,
          disabledItem: selectedItems.some((y) => y === x.id) || x.disabledItem,
        }))
      );
  }, [selectedItems]);

  const onChangeFunc = useCallback(
    (value: string | string[], props: IField) => {
      !currentItemsSelected && setSelectedItems(value);
      props.form.setFieldValue(name, value);
      const idChanged =
        typeof value === 'object' ? value[value.length - 1] : value;

      onChange &&
        onChange(
          typeof value === 'object' ? value[value.length - 1] : value,
          items?.find((x: IItems) => x.id === idChanged)?.allItem
        );
    },
    [name, onChange, items, currentItemsSelected]
  );

  const onChangeFilterFunc = useCallback(
    (filters: (string | number)[]) => {
      onSearch && onSearch('', filters);
    },
    [onSearch]
  );

  const onCloseTags = useCallback((id: string) => {
    setItems((prev) =>
      prev?.map((x) => (x.id === id ? { ...x, disabledItem: false } : x))
    );
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (selectedItem) {
      if (Array.isArray(selectedItem)) {
        setItems(uniqBy([...(itemsArray || []), ...selectedItem], 'id'));
      } else {
        setItems(uniqBy([...(itemsArray || []), selectedItem], 'id'));
      }
    } else setItems([...(itemsArray || [])]);
    return;
  }, [itemsArray, selectedItem, isLoading]);

  //set a last line with a loading
  useEffect(() => {
    setItems((prevState) =>
      prevState && !prevState[prevState.length - 1]?.loading && isLoading
        ? [...(prevState || []), { loading: true }]
        : prevState
    );
  }, [isLoading]);

  //set a uniq loading line
  useEffect(() => {
    setItems([{ loading: true }]);
  }, [currentSearch]);

  useEffect(() => {
    onChangeItemsDisabled();
  }, [onChangeItemsDisabled]);

  useEffect(() => {
    currentItemsSelected && setSelectedItems(currentItemsSelected);
  }, [currentItemsSelected]);

  const onSearchFunc = useMemo(
    () =>
      _.debounce(async (value: string) => {
        onSearch && onSearch(value);
      }, 1000),
    []
  );

  const randomId = useMemo(() => uuidV4(), []);

  return {
    randomId,

    onScroll,
    onChangeFunc,
    onChangeFilterFunc,
    onSearchFunc,
    onCloseTags,

    currentSearch,
    setCurrentSearch,
    setFocusState,

    isOpen,
    setIsOpen,

    filterSearchItem,
    setFilterSearchItem,
    items,
  };
};

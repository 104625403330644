import { FC } from "react";
import { useTranslation } from 'react-i18next';
import {
  InputWithSearch,
  Tooltip
} from '@/Components';
import { useFormikContext } from "formik";
import { IPharmaceuticalForm } from "./interfaces";
import { usePharmaceuticalFormCallAPI } from "./Hooks/usePharmaceuticalFormCallAPI";
import { usePharmaceuticalFormMemorizations } from "./Hooks/usePharmaceuticalFormMemorizations";
import { usePharmaceuticalFormFunctions } from "./Hooks/usePharmaceuticalFormFunctions";

export const PharmaceuticalForm: FC<IPharmaceuticalForm> = ({
  standardFormula,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage
  } = usePharmaceuticalFormCallAPI();

  const {
    items
  } = usePharmaceuticalFormMemorizations({
    listPharmaceuticalForm
  });

  const {
    onSearch
  } = usePharmaceuticalFormFunctions({
    refetchSearchPharmaceuticalFormPage
  });

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousPharmaceuticalForm'
      )} ${standardFormula?.descricaoFormaFarmaceutica}`}
      targetOffset={[0, -18]}
      showMe={
        !disabled &&
        standardFormula?.formaFarmaceuticaExternalId !==
          form.values.formaFarmaceuticaExternalId
      }
    >
      <InputWithSearch
        items={items}
        name="formaFarmaceuticaExternalId"
        label={t('production.standardFormula.details.pharmaceuticalForm')}
        placeHolder={t(
          'production.standardFormula.details.pharmaceuticalFormPlaceholder'
        )}
        disabled={disabled}
        isLoading={isPharmaceuticalFormLoading}
        required={!disabled}
        onScrollEnd={fetchNewFarmaceuticalFormPage}
        onSearch={onSearch}
        withStatusIcon
      />
    </Tooltip>
  );
};
import {
  DELETE_SUBGROUP,
  FETCH_LIST_SUBGROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useListCallAPI = () => {
  const deleteSubGroup: any = useControllerMutateApiHook({
    uniqId: DELETE_SUBGROUP,
    entityApi: ProductAPI.deleteProductSubGroup,
  });

  const { data: subGroupList, isLoading, refetch: refetchSubGroupList }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SUBGROUP,
    entityApi: ProductAPI.subGroupList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  return {
    deleteSubGroup,
    subGroupList,
    refetchSubGroupList,
    isLoading,
  };
};

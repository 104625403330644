import {
FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  FETCH_LIST_UNITS_BY_CLASS 
} from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import {
  IProductResponse
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook
} from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IUseCallAPI } from './interfaces';

export const useUnitaryValueCallAPI = ({
  formValues,
  unitMeasurementPagination
}: IUseCallAPI) => {
  const result = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: formValues.productExternalId,
  });

  const { 
    data: unitsMeasurements 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClassAny,
    initialPagination: unitMeasurementPagination,
  });

  return {
    result,
    unitsMeasurements
  };
};
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Form,
  Tooltip
} from '@/Components';
import { SpecialtieDetailsSchema } from './SpecialtieDetails.schema';
import { Modal } from '@/Components/Modal';
import { Col, Row } from 'antd';
import { IProductCard } from './interfaces';
import { useDetailsStates } from './Hooks/useDetailsStates';
import { useDetailsCallAPI } from './Hooks/useDetailsCallAPI';
import { useDetailsMemorizations } from './Hooks/useDetailsMemorizations';
import { useDetailsFunctions } from './Hooks/useDetailsFunctions';
import { FormDescriptionBody } from './Components/FormDescriptionBody';

import styles from './SpecialtieDetails.module.scss';

export const DetailsTab: FC<IProductCard> = ({
  disabledForm,
  toogleDisabled
}) => {
  const { t } = useTranslation();

  const {
    isBackModalOpen,
    setIsBackModalOpen
  } = useDetailsStates();
 
  const {
    editSpecialtie,
    specialtie,
  } = useDetailsCallAPI({
    toogleDisabled,
    disabledForm
  });

  const {
    initialValues,
    submitButtonChildren,
    modalBody
  } = useDetailsMemorizations({
    specialtie,
    editSpecialtie
  });

  const {
    onSubmit,
    onClick,
    onOkClick,
    onCancelClick,
    onClose
  } = useDetailsFunctions({
    editSpecialtie,
    setIsBackModalOpen,
    toogleDisabled,
    disabledForm
  });

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        schema={SpecialtieDetailsSchema}
      >
        {(props) => (
          <>
            <Card
              title={t('specialties.edit.titleCard')}
              withoutCollapse
              children={
                <Row>
                  <Col flex="28%">
                    <Tooltip
                      title={`${t('specialties.edit.tooltip')}: ${
                        specialtie?.descricao
                      }`}
                      showMe
                      placement="bottomLeft"
                    >
                      <FormDescriptionBody
                        disabledForm={disabledForm}
                        form={props}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              }
            />
            {!disabledForm && (
              <div className={styles['footer-button']}>
                <Button
                  type="secondary"
                  children={t('common.cancel')}
                  onClick={() => onClick(props)}
                />
                <Button
                  type="primary"
                  children={submitButtonChildren}
                  htmlType="submit"
                  loading={editSpecialtie.isLoading}
                  disabled={
                    !props.dirty || Object.keys(props.errors).length > 0
                  }
                />
              </div>
            )}
          </>
        )}
      </Form>
      <Modal
        visible={isBackModalOpen}
        onOkClick={onOkClick}
        onCancelClick={onCancelClick}
        onClose={onClose}
        okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
        title={t('supply.balanceAdjustment.backModal.title')}
        body={modalBody}
      />
    </>
  );
};

import React, { FC, useEffect, useMemo } from 'react';
import { App as AntdApp } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { CURRENT_USER } from './Utils/ConstantsUtils';
import preferencesUtils from './Utils/ThemeUtils';
import StorageUtils from './Utils/StorageUtils';
import ContextProvider from './Contexts/context';

import './disable-error-overlay.css';
import './Components/NProgress/NProgress.override.scss';

const App: FC = () => {
  const [cookies] = useCookies([CURRENT_USER]);
  const queryClient: QueryClient = useMemo(() => new QueryClient(), []);
  const preferences = useMemo(() => StorageUtils.getPreference(), []);

  const isAuthenticated = useMemo(
    () =>
      cookies.current_user &&
      new Date() < new Date(cookies.current_user.expiresIn),
    [cookies.current_user]
  );

  useEffect(() => {
    preferencesUtils.setAllPreferences(preferences);
  }, [preferences]);

  return (
    <QueryClientProvider client={queryClient}>
      <AntdApp>
        <ContextProvider isAuthenticated={isAuthenticated} />
      </AntdApp>
    </QueryClientProvider>
  );
};

export default App;

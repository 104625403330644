import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useRegisterTypeFunctions = ({
  refetchStateList
}: IUseFunctions) => {
  const onSearch = useCallback((search: string | undefined) => {
    refetchStateList({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' },
    });
  }, [refetchStateList]);

  return { onSearch };
};
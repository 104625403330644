import {
  FETCH_GET_INCOMPATIBLE_PRODUCT,
  PUT_INCOMPATIBLE_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { IIncompatibleProductRequest } from '@/Data/Interfaces/request/Product/IIncompatibleProductRequest';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';
import { IUseCallAPI } from './interfaces';

export const useIncompatibleProductDetailsCallAPI = ({
  associationProductId
}: IUseCallAPI) => {
  const { 
    data: incompatibleProductData 
  } = useControllerQueryApiHook<IIncompatibleProductRequest>({
    uniqId: FETCH_GET_INCOMPATIBLE_PRODUCT,
    entityApi: ProductAPI.getIncompatibleProduct,
    externalId: associationProductId
  });

  const editIncompatibleProduct: any = useControllerMutateApiHook({
    uniqId: PUT_INCOMPATIBLE_PRODUCT,
    entityApi: ProductAPI.editIncompatibleProduct,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(i18n.t('products.incompatible.editSuccess'))
    }
  });

  return {
    incompatibleProductData,
    editIncompatibleProduct
  };
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { HorizontalTabs } from '../../../../../Components';
import { PosologyDetail } from '../Detail/index';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { FETCH_GET_POSOLOGY } from '../../../../../ControllerApiHook/UniqIds/Production/PosologyKeys';
import { useParams } from 'react-router-dom';
import { DeletePosologytModal } from '../Detail/Components/DeleteModal/deleteModal';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetPosology } from '@/Data/Interfaces/response/Posology/IPosologyResponse';
import { PosologyAPI } from '@/Data/API/Production/Posology';

const FormPosology: React.FC = () => {
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const { externalId } = useParams<{ externalId: string }>();

  const { data: posology } = useControllerQueryApiHook<IGetPosology>({
    uniqId: FETCH_GET_POSOLOGY,
    entityApi: PosologyAPI.getPosology,
    externalId: externalId,
  });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('common.details'),
            key: 'details',
            children: (
              <PosologyDetail
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
        ]}
        dropdownItens={[
          {
            label: t('common.delete'),
            icon: 'trash',
            onClick: () => setVisibleModal(true),
            permission: {
              permission: PermissionEnum.Producao_Posologia_Excluir,
              tooltip: 'noPermission.production.posology.delete',
            },
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('common.moreActions')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled && {
            onClick: () => setDisabled(false),
            isVisible: disabled,
            codePermissionEdit:
              PermissionEnum.Producao_Posologia_EditarDetalhes,
            messageTooltipPermission: 'noPermission.production.posology.edit',
          }),
        }}
      />

      {posology && (
        <DeletePosologytModal
          posology={posology}
          visibleModal={visibleModal}
          changeVisibleModal={(value) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};

export default FormPosology;

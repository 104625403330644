import { FC } from "react";
import {
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IValidateDays } from "./interfaces";
import { NumberInput } from '@/Components/Inputs/NumberInput';

export const ValidateDays: FC<IValidateDays> = ({
  product,
  disabledForm
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={3}>
      <Tooltip
        title={`${t('products.toolTips.products.originalValidateDays')} ${
          product && product.materiaPrima
            ? product.materiaPrima.diasValidade
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima.diasValidade !== form?.values?.diasValidade
        }
      >
        <NumberInput
          withStatusIcon={!!product}
          name="diasValidade"
          label={t('products.create.daysValidity')}
          placeHolder="000"
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
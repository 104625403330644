import { useFormikContext } from "formik";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useProductCardCallFunctions = ({
  productRowAmount,
  setProductRowAmount
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const onProductDeleteRowAmount = useCallback(
    (index: number) => {
      if (productRowAmount.length === 1) return;
      const orderPurchase = form.values.pedidoCompraItens.slice();
      orderPurchase.splice(index, 1);
      form.setFieldValue('pedidoCompraItens', orderPurchase);

      const newUuids = productRowAmount.slice();
      newUuids.splice(index, 1);
      setProductRowAmount(newUuids);
    },
    [form, productRowAmount]
  );

  const onDelete = (index: number) => onProductDeleteRowAmount(index);

  return {
    onDelete
  };
};
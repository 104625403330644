import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputWithSearch,
} from '@/Components';
import { useFormikContext } from 'formik';
import {
  IProductGroupListData,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { IProduct } from './interfaces';
import { useProductCallAPI } from './Hooks/useProductCallAPI';
import { useProductCallFunctions } from './Hooks/useProductCallFunctions';
import { useProductCallMemorizations } from './Hooks/useProductCallMemorizations';

export const Product: FC<IProduct> = ({
  index,
  setProductRowAmount,
  uuidV4,
  totalRows,
  unitMeasurementPagination,
  setUnitMeasurementPagination
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const {
    result,
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  } = useProductCallAPI({ index });

  const {
    onChange,
    onSearch
  } = useProductCallFunctions({
    index,
    totalRows,
    setProductRowAmount,
    uuidV4,
    productList,
    unitMeasurementPagination,
    setUnitMeasurementPagination,
    refetchSearchProductPage
  });

  const {
    items,
    label
  } = useProductCallMemorizations({
    productList,
    index
  });

  useEffect(() => {
    const productListFormatted = productList.data
      ? productList.data
      : productList;
    const produto = productListFormatted.find(
      (prod: IProductGroupListData) =>
        prod.externalId ===
        form?.values?.pedidoCompraItens?.[index]?.produtoExternalId
    );

    if (!produto) return;

    setUnitMeasurementPagination({
      ...unitMeasurementPagination,
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: produto?.classeProdutoId,
        },
      ],
    });
  }, [productList, index]);

  useEffect(() => {
    if (result.isSuccess && result.data !== null) {
      const x = result.data;

      if (!!form?.values?.pedidoCompraItens?.[index]?.precoUnitario) {
        form.setFieldValue(
          `pedidoCompraItens.${index}.precoUnitario`,
          form?.values?.pedidoCompraItens?.[index]?.precoUnitario
        );
      } else {
        form.setFieldValue(
          `pedidoCompraItens.${index}.precoUnitario`,
          x?.valorCusto
        );
      }

      const productListFormatted = productList.data
        ? productList.data
        : productList;
      const produto = productListFormatted.find(
        (prod: IProductGroupListData) =>
          prod.externalId ===
          form?.values?.pedidoCompraItens?.[index]?.produtoExternalId
      );
      setUnitMeasurementPagination({
        ...unitMeasurementPagination,
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: produto?.classeProdutoId,
          },
        ],
      });
    }
  }, [result]);

  return (
    <>
      <InputWithSearch
        name={`pedidoCompraItens.${index}.produtoExternalId`}
        items={items}
        onChange={onChange}
        label={label}
        required
        placeHolder={t(
          'purchasing.purchaseOrder.create.productsCardPlaceHolders.product'
        )}
        onScrollEnd={fetchNewProductPage}
        withoutMarginBottom={true}
        onSearch={onSearch}
      />
    </>
  );
};
import { IListUnitMeasurementByClassData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { IUseMemorizations } from "./interfaces";

export const useUnitaryValueMemorizations = ({
  index,
  unitsMeasurements
}: IUseMemorizations) => {
  const formattedUnitsMeasurements =
  unitsMeasurements && unitsMeasurements?.data ? unitsMeasurements?.data : [];

  const dropdownRight = {
    name: `pedidoCompraItens.${index}.unidadeMedidaId`,
    options:
      formattedUnitsMeasurements.map(
        (x: IListUnitMeasurementByClassData) => ({
          id: x.id,
          content: x.unidadeAbreviacao,
          customDescription: `${x.unidadeAbreviacao} (${x.descricao})`,
        })
      ) || [],
  };

  return {
    dropdownRight
  };
};
import React, { CSSProperties, FC } from 'react';
import { Checkbox as AntCheckbox } from 'antd';

import './CheckBox.override.scss';
import styles from './CheckBox.module.scss';

import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { Icon, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';

export interface ICheckbox {
  name: string;
  label?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  className?: string;
  withStatusIcon?: boolean;
  withTooltip?: {
    title: string;
    icon: IconName;
  };
  onClick?: (value?: string) => void;
  style?: CSSProperties;
  onChange?: (value?: any) => void;
  value?: string;
  checked?: boolean;

  dataTestId?: string;
  containerDataTestId?: string;
}

export const Checkbox: FC<ICheckbox> = ({
  name,
  label,
  disabled,
  className,
  indeterminate,
  withStatusIcon,
  withTooltip,
  onClick,
  style,
  value,
  checked,
  dataTestId,
  containerDataTestId
}) => (
  <Field type="checkbox" name={name}>
    {(props: IField) => {
      return (
        <div className={styles['divCheckbox']} data-testid={containerDataTestId}>
          <AntCheckbox
            id={`${name}-for`}
            data-testid={dataTestId}
            disabled={disabled || props.form.isSubmitting}
            className={className}
            indeterminate={indeterminate}
            {...props.field}
            onChange={(e) => {
              onClick && onClick();
              if (value) {
                props.form.setFieldValue(name, e.target.checked ? value : null);
              } else {
                props.field.onChange(e);
              }
            }}
            style={style}
            value={value}
            checked={props.field.checked || checked}
          />
          {label ? (
            <label
              htmlFor={`${name}-for`}
              className={`${styles['label']} ${
                disabled ? styles['disabled'] : ''
              }`}
              style={style}
            >
              {label}
            </label>
          ) : null}
          {withStatusIcon && props?.meta.value !== props?.meta.initialValue && (
            <Icon
              icon="status-attention"
              size="SS"
              className={styles['icon-status']}
            />
          )}
          {withTooltip && (
            <Tooltip
              title={withTooltip.title}
              showMe
              children={
                <Icon
                  className={styles['icon-with-tooltip']}
                  icon={withTooltip.icon}
                  color="text-300"
                  size="S"
                />
              }
            />
          )}
        </div>
      );
    }}
  </Field>
);

import React, { CSSProperties } from 'react';
import { Text, Icon, Button } from '../../../../../../../../../Components';
import { Button as AntdButton } from 'antd';
import { Themes } from '../../../../../../../../../Utils/ThemeUtils';
import { IconName } from '../../../../../../../../../Components/Icon/IconClasses';
import { Divflex } from '../../../../../../../../../Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';
import { ButtonDropdown } from '../../../../../../../../../Components/Button/ButtonDropdown';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import StorageUtils from '@/Utils/StorageUtils';

import styles from './Status.module.scss';
import './Status.override.scss';

export enum StatusSalesOrderEnum {
  Orcado = 1,
  Aprovado = 2,
  Reprovado = 3,
  Entregue = 4,
  EntregueParcial = 5,
  Cancelado = 6,
}

export interface IStatus {
  typePurchase: number;
  children?: string;
  disabled?: boolean;
  onClick?: (status: any) => void;
  loading?: boolean;
  withoutDescription?: boolean;
  withoutIcon?: boolean;
  withBigSize?: boolean;
  withMidSize?: boolean;
  withButtons?: boolean;
  onOkClick?: () => void;
  onCancelClick?: () => void;
  onClickDropdownItem?: () => void;
  style?: CSSProperties;
  customIcon?: IconName;
  customFontSize?: string;
  defaultStatus?: boolean;
  disabledButton?: boolean;
}

export const StatusSalesOrder: React.FC<IStatus> = ({
  typePurchase,
  children,
  disabled,
  withoutDescription,
  withBigSize,
  withMidSize,
  withButtons,
  onClick,
  onOkClick,
  onCancelClick,
  withoutIcon,
  style,
  customIcon,
  defaultStatus,
  disabledButton,
  onClickDropdownItem,
}) => {
  const preferences = StorageUtils.getPreference();
  const { t } = useTranslation();

  const statusIcon = [
    'calculator',
    'like',
    'disapproved',
    'partialcube',
    'Box',
    'cancel',
  ];

  const statusColor = [
    'budget',
    'approved',
    'reproved',
    'partialDelivery',
    'delivery',
    'canceled',
  ];

  return (
    <>
      <AntdButton
        className={`${styles[`${statusColor[typePurchase - 1]}-status`]} ${
          styles['status']
        }  
      ${defaultStatus && styles[`default-status`]} 
      ${withoutDescription && styles['status-without-description']} 
      ${withBigSize && styles['status-with-big-Size']}
      ${withMidSize && styles['status-with-mid-Size']}
      ${preferences.temaUsuario !== Themes.lightMode ? 'darkmode' : null}`}
        disabled={disabled}
        onClick={onClick}
        style={style}
      >
        {withoutDescription ? (
          <Icon
            className={`${styles['icon-without-description']} ${
              styles[`${statusColor[typePurchase - 1]}-icon-color`]
            }`}
            icon={
              customIcon
                ? customIcon
                : (statusIcon[typePurchase - 1] as IconName)
            }
          />
        ) : withoutIcon ? (
          <Text
            type="ui-tiny-bold"
            children={children}
            style={{
              whiteSpace: 'nowrap',
              fontSize: withBigSize ? 'var(--font-size-md)' : '',
              marginLeft: withBigSize ? '10px' : '',
            }}
          />
        ) : (
          <Divflex>
            <Icon
              className={`${styles['icon']} ${
                styles[`${statusColor[typePurchase - 1]}-icon-color`]
              }`}
              icon={
                customIcon
                  ? customIcon
                  : (statusIcon[typePurchase - 1] as IconName)
              }
              backgroundColor={
                defaultStatus ? 'status-disabled-base' : undefined
              }
              customColor={defaultStatus ? 'var(--color-text-200)' : ''}
              style={{
                marginLeft: withMidSize ? '-10px' : '',
                width: defaultStatus ? 30 : '',
                height: defaultStatus ? 30 : '',
                borderRadius: defaultStatus ? 20 : '',
              }}
            />

            <Text
              type="ui-tiny-bold"
              children={children}
              style={{
                whiteSpace: 'nowrap',
                fontSize: withBigSize
                  ? 'var(--font-size-md)'
                  : withMidSize
                  ? 'var(--font-size-sm)'
                  : '',
                marginLeft: withBigSize ? '10px' : withMidSize ? '15px' : '',
              }}
            />
          </Divflex>
        )}
      </AntdButton>
      {withButtons &&
      withBigSize &&
      typePurchase === StatusSalesOrderEnum.Orcado ? (
        <>
          <Divflex>
            <div className={styles['action-buttons']}>
              <PermissionWrapper
                permission={PermissionEnum.Vendas_PedidosVenda_Aprovar}
                tooltip={t('noPermission.sale.order.toApprove')}
              >
                <Button
                  leftIcon={
                    typePurchase === StatusSalesOrderEnum.Orcado
                      ? 'like'
                      : undefined
                  }
                  type="primary"
                  status="success"
                  disabled={disabledButton}
                  children={
                    typePurchase === StatusSalesOrderEnum.Orcado
                      ? t('common.approve')
                      : ''
                  }
                  onClick={onOkClick}
                />
              </PermissionWrapper>
            </div>
            <div className={styles['action-buttons']}>
              <PermissionWrapper
                permission={PermissionEnum.Vendas_PedidosVenda_Reprovar}
                tooltip={t('noPermission.sale.order.disapprove')}
              >
                <Button
                  leftIcon={
                    typePurchase === StatusSalesOrderEnum.Orcado
                      ? 'disapproved'
                      : undefined
                  }
                  type="secondary"
                  children={
                    typePurchase === StatusSalesOrderEnum.Orcado
                      ? t('common.reprove')
                      : ''
                  }
                  onClick={onCancelClick}
                />
              </PermissionWrapper>
            </div>

            <ButtonDropdown
              type={'secondary'}
              leftIcon="menu-ellipsis-horizontal"
              fullWidth
              dropdownItens={[
                {
                  icon: 'close-x',
                  label: t('common.cancel'),
                  onClick: onClickDropdownItem,
                  permission: {
                    permission: PermissionEnum.Vendas_PedidosVenda_Cancelar,
                    tooltip: t('noPermission.sale.order.cancel'),
                  },
                },
              ]}
            />
          </Divflex>
        </>
      ) : withButtons &&
        ((withBigSize && typePurchase === StatusSalesOrderEnum.Aprovado) ||
          typePurchase === StatusSalesOrderEnum.Reprovado ||
          typePurchase === StatusSalesOrderEnum.Cancelado) ? (
        <>
          <Divflex
            style={{
              margin: '15px 0 15px 0',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: 5,
            }}
          >
            <PermissionWrapper
              permission={PermissionEnum.Vendas_PedidosVenda_Estornar}
              tooltip={t('noPermission.sale.order.reserve')}
            >
              <Button
                leftIcon={'reply'}
                type="secondary"
                wrapperStyle={{ width: '100%' }}
                fullWidth
                children={t('common.reverse')}
                onClick={onCancelClick}
              />
            </PermissionWrapper>
          </Divflex>
        </>
      ) : (
        <></>
      )}
      {withBigSize && (
        <div className={`${styles['cardTotal-separator-line']}`}></div>
      )}
    </>
  );
};

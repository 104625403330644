import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_STOCK_LOCATION,
  EDIT_STOCK_LOCATION,
  FETCH_GET_STOCK_LOCATION,
  FETCH_LIST_STOCK_LOCATION,
  THERE_IS_STOCK_LOCATION_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { IUseCallAPI } from './interfaces';
import { IGetStorageLocationResponse } from '@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse';

export const useEditCallAPI = ({ externalId, t, form }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: editStorageLocation,
    isLoading: isLoadingEditStorageLocation,
  }: any = useControllerMutateApiHook({
    uniqId: EDIT_STOCK_LOCATION,
    entityApi: StorageLocationApi.editStorageLocation,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]);
        queryClient.invalidateQueries([FETCH_GET_STOCK_LOCATION]);
        history.push('/inventory/supply-places');
      },
    },
  });

  const { mutateAsync: deleteStorageLocation }: any =
    useControllerMutateApiHook({
      uniqId: DELETE_STOCK_LOCATION,
      entityApi: StorageLocationApi.deleteStorageLocation,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]);
          queryClient.invalidateQueries([FETCH_GET_STOCK_LOCATION]);
          history.push('/inventory/supply-places');
        },
      },
    });

  const { data: listCompanys, isLoading: isCompanyLoading } =
    useControllerQueryListApiHook({
      uniqId: LIST_FOR_DROPDOWN_TYPE,
      entityApi: CompanyAPI.listCompanyForDropdown,
      initialPagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    });

  const { data: storageLocation, isLoading: loading } =
    useControllerQueryApiHook<IGetStorageLocationResponse>({
      uniqId: FETCH_GET_STOCK_LOCATION,
      entityApi: StorageLocationApi.getCurrentStorageLocation,
      externalId: externalId,
    });

  const {
    mutateAsync: fetchStorageLocationDescription,
    isLoading: isLoadingFetchStorageLocationDescription,
  }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_STOCK_LOCATION_TYPE,
    entityApi: StorageLocationApi.isStorageLocationNameAlreadyRegistred,
    options: {
      onSuccess: () => {
        form?.setErrors({
          ...form.errors,
          descricao: undefined,
        });
      },
      onError: () => {
        form?.setErrors({
          ...form.errors,
          descricao: t(
            'supply.supplyPlaces.errors.errorStorageLocationNameExist'
          ),
        });
      },
    },
  });

  return {
    editStorageLocation,
    isLoadingEditStorageLocation,
    listCompanys,
    isCompanyLoading,
    storageLocation,
    loading,
    fetchStorageLocationDescription,
    isLoadingFetchStorageLocationDescription,
    deleteStorageLocation,
  };
};

import { useMemo } from "react";
import { PermissionEnum } from "@/Data/Interfaces/model/permissionEnum";
import { useTranslation } from "react-i18next";
import { history } from '@/Store';
import { DeleteModal } from '../Modal/DeleteModal';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { IUseMemorizations } from "./interfaces";
import { IDeleteModalTable } from "@/Components/Table";

export const useListMemorizations = ({
  selectedRowsOnTable
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t('production.standardFormula.list.newButton'),
      onClick: () => history.push('/production/standard-formula/create'),
      permission: {
        permission: PermissionEnum.Producao_FormulaPadrao_Cadastrar,
        tooltip: 'noPermission.production.standardFormula.create',
      },
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Producao_FormulaPadrao_Excluir,
        tooltip: 'noPermission.production.standardFormula.delete',
      },
    },

    searchPlaceholder: t(
      'production.standardFormula.list.searchPlaceholder'
    ),
    nameKey: 'produto',
  };

  const rowsOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(
        `/production/standard-formula/${x.externalId}/details`
      ),
    permission: {
      permission: PermissionEnum.Producao_FormulaPadrao_VerDetalhes,
      tooltip: 'noPermission.production.standardFormula.details',
    },
  };

  const deleteModal: IDeleteModalTable | undefined = useMemo(() => (
    {
      title:
        selectedRowsOnTable &&
        `${t('common.delete')} ${
          selectedRowsOnTable.length > 1
            ? `${selectedRowsOnTable.length} ${t(
                'common.theManyStandardFormula'
              )}?`
            : `${t(
                'production.standardFormula.list.modalDelete.descriptionStandardFormua'
              )} "${selectedRowsOnTable[0]?.produto}"?`
        }`,
      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.deleteModal.many.title')} ${t(
              'common.theStandardFormula'
            )}:`,
            'produto',
            t('common.standardsFormulas')
          )
        ) : (
          <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
    }
  ), [selectedRowsOnTable, t]);

  return {
    headerOptions,
    rowsOptions,
    deleteModal
  };
};
import React, { useState } from 'react';
import { Form, Button, i18n } from '../../../../../Components';
import InfoSection from './Sections/InfoSection';
import ProductSection from './Sections/ProductSection';
import ValueSection from './Sections/ValueSection';
import { ButtonDraft } from '../../../../../Components/Button/ButtonDraft';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../Store';
import { InvoiceCreateCreateSchema } from './InvoiceCreate.schema';
import {
  CREATE_INVOICE,
  FETCH_GET_INVOICE,
  FETCH_LIST_INVOICE,
  SAVE_PUT_DRAFT_INVOICE
} from '../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import DateUtils from '../../../../../Utils/DateUtils';
import { configInvoiceValues } from './utils/functions';
import { useQueryClient } from '@tanstack/react-query';
import { DeleteDraftModal } from './Modal/deleteDraftModel';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '../../../../../Store/Notification/Notification.actions';
import StorageUtils from '@/Utils/StorageUtils';
import { 
  useControllerMutateApiHook,
   useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { IInvoiceGetResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';

import styles from './styles.module.scss';

const InvoiceCreate: React.FC = () => {
  const preferences = StorageUtils.getPreference();

  const createInvoice: any = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE,
    entityApi: InvoiceAPI.postInvoice,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.create')
        )
    }
  });
  
  const createInvoiceDraft = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE,
    entityApi: InvoiceAPI.postInvoiceDraft,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.createDraft')
        )
    }
  });

  const updateInvoiceDraft = useControllerMutateApiHook({
    uniqId: SAVE_PUT_DRAFT_INVOICE,
    entityApi: InvoiceAPI.savePutDraftInvoice,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.editDraft')
        )
    }
  });
  
  const queryClient = useQueryClient();
  const { externalId } = useParams<{ externalId: string }>();
  const [sendedValue, setSendValue] = useState<string>('');
  const [importedProducts, setImportedProducts] = useState<any[]>([]);
  const dispatch = useDispatch();

  const compareChangedValues = (values: any) => {
    if (sendedValue === '') return true;
    return JSON.stringify(values) !== sendedValue;
  };

  const [openDeleteDraftModal, setOpenDeleteDraftModal] = useState(false);

  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalId: externalId
  });

  const handleSubmit = async (values: any) => {
    try {
      if (!preferences) return;
      if (values.dataEmissao && values.dataEntrega) {
        values.dataEmissao = DateUtils.convertDateToApi(
          values.dataEmissao,
          preferences.padraoData
        );

        values.dataEntrega = DateUtils.convertDateToApi(
          values.dataEntrega,
          preferences.padraoData
        );
      }

      let formattedValues = configInvoiceValues(values);
      let res;
      res = await createInvoice.mutateAsync({
        ...formattedValues,
        notaFiscalEntradaExternalId: externalId,
      });

      if (res.success) {
        setSendValue(JSON.stringify(formattedValues));
        queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
        history.push(`/order/invoice`);
      }
    } catch (e) {}
  };

  const onSaveDraft = async (values: any) => {
    try {
      if (!preferences) return;
      if (values.dataEmissao && values.dataEntrega) {
        values.dataEmissao = DateUtils.convertDateToApi(
          values.dataEmissao,
          preferences.padraoData
        );

        values.dataEntrega = DateUtils.convertDateToApi(
          values.dataEntrega,
          preferences.padraoData
        );
      }

      let formattedValues = configInvoiceValues(values);
      if (!formattedValues.pedidosExternalId) {
        formattedValues = {
          ...formattedValues,
          pedidosExternalId: [],
        };
      }

      let res: any;
      if (!externalId) {
        res = await createInvoiceDraft.mutateAsync(formattedValues);
      } else {
        formattedValues = {
          ...formattedValues,
          notaFiscalEntradaExternalId: externalId,
        };
        res = await updateInvoiceDraft.mutateAsync({
          ...values,
          notaFiscalEntradaExternalId: externalId,
        });
      }

      if (res.success) {
        setSendValue(JSON.stringify(formattedValues));
        queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
        dispatch(NotificationActions.setCurrentFormIsDirty(false));
        setTimeout(() => {
          history.push(`/order/invoice/create/${res.externalId}/draft`);
        }, 1);
      }
    } catch (e) {}
  };

  const { t } = useTranslation();

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        schema={InvoiceCreateCreateSchema}
        initialValues={{
          itens: [
            { baseCalculoIcms: 0, valorIcms: 0, valorIpi: 0, aliquotaIpi: 0 },
          ],
        }}
      >
        {(props) => (
          <>
            <InfoSection setImportedProducts={setImportedProducts} />
            <ValueSection importedProducts={importedProducts} />
            <ProductSection
              importedProducts={importedProducts}
              setImportedProducts={setImportedProducts}
            />
            <div className={styles['footer-button']}>
              <div>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => history.push('/order/invoice')}
                />
                <Button
                  type="primary"
                  children={
                    createInvoice.isLoading
                      ? t('common.creating')
                      : t('common.create')
                  }
                  htmlType="submit"
                  loading={createInvoice.isLoading}
                />
              </div>

              <ButtonDraft
                type="secondary"
                htmlType="button"
                onClick={() => {
                  onSaveDraft(props.values);
                }}
                disabled={!compareChangedValues(props.values) && !!externalId}
                children={
                  createInvoiceDraft.isLoading
                    ? t('common.saving')
                    : t('common.saveDraft')
                }
                withStatusIcon={
                  !(!compareChangedValues(props.values) && !!externalId) &&
                  !!externalId
                }
                withStatusMessage={
                  !compareChangedValues(props.values) && externalId
                    ? t('common.savedChanges')
                    : !compareChangedValues(props.values) === false &&
                      !!externalId
                    ? t('common.hearChanges')
                    : ''
                }
                loading={createInvoiceDraft.isLoading}
                withDropdownEditButton={
                  !!externalId
                    ? {
                        options: [
                          {
                            label: t('common.modalDraftDelete.title'),
                            icon: 'trash',
                            onClick: () => setOpenDeleteDraftModal(true),
                          },
                        ],
                      }
                    : undefined
                }
              />
            </div>
          </>
        )}
      </Form>

      <DeleteDraftModal
        invoice={invoice}
        visibleModal={openDeleteDraftModal}
        changeVisibleModal={(value) => setOpenDeleteDraftModal(value)}
      />
    </>
  );
};

export default InvoiceCreate;

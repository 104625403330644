import { InputWithSearch } from '@/Components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

export const LossLot: FC = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { lotList, isLotLoading, refetchLot, refetchNewPageLot } =
    useCreateCallAPI({
      form,
    });

  const { lotDropdownList } = useCreateMemorizations({ lotList });

  return (
    <InputWithSearch
      items={lotDropdownList}
      name="loteExternalId"
      label={t('supply.losses.create.lot')}
      required
      placeHolder={t('supply.losses.create.lotPlaceholder')}
      disabled={
        !form.values.produtoExternalId || !form.values.localEstoqueExternalId
      }
      withoutMarginBottom={true}
      isLoading={isLotLoading}
      onScrollEnd={refetchNewPageLot}
      onSearch={(search?: string) =>
        refetchLot({
          pageIndex: 0,
          pageSize: 10,
          search: search,
          sorter: { column: 'nome', direction: 'DESC' },
        })
      }
    />
  );
};

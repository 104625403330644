import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";
import { useFormikContext } from "formik";
import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";

export const useProductFunctions = ({
  refetchSearchProductPage,
  onClassTypeSelected,
  productList
}: IUseFunctions) => {
  const form = useFormikContext();
  
  const setTechnicalInfos = useCallback((x: string) => {
    const productSelected = productList?.find(
      (value: IProductGroupListData) => value.externalId === x
    );
    form?.setFieldValue(
      'saldoInicial.unidadeId',
      productSelected && productSelected.unidadeMedidaId
    );
    form?.setFieldValue(
      'classe',
      productSelected && productSelected.classeProdutoDescricao
    );
    form?.setFieldValue(
      'classeId',
      productSelected && productSelected.classeProdutoId
    );
    onClassTypeSelected(productSelected?.classeProdutoId);
    if (productSelected?.classeProdutoId === 1) {
      form?.setFieldValue('loteInformacaoTecnica.densidade', 1);
      form?.setFieldValue('loteInformacaoTecnica.diluicaoFornecedor', 100);
      form?.setFieldValue('loteInformacaoTecnica.fatorDiluicaoFornecedor', 1);
      form?.setFieldValue('loteInformacaoTecnica.concentracaoAgua', 0);
      form?.setFieldValue('loteInformacaoTecnica.fatorConcentracaoAgua', 1);
      form?.setFieldValue('loteInformacaoTecnica.diluicaoInterna', 100);
      form?.setFieldValue('loteInformacaoTecnica.fatorDiluicaoInterna', 1);
    } else {
      form?.setFieldValue('loteInformacaoTecnica', null);
    }
  }, [productList, onClassTypeSelected]);

  const onChange = useCallback(
    (x: string) => setTechnicalInfos(x), [setTechnicalInfos]
  );

  const onSearch = useCallback((search: string | undefined) => {
    refetchSearchProductPage({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' },
    });
  }, [refetchSearchProductPage]);

  return {
    onChange,
    onSearch
  };
};
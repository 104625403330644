import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const StandardFormulaCreateSchema = yup.object().shape({
  produtoExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.standardFormula.create.errors.requiredProduct')
    ),

  formaFarmaceuticaExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t(
        'production.standardFormula.create.errors.requiredPharmaceuticaForm'
      )
    ),

  quantidadePadrao: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.standardFormula.create.errors.requiredAmount')
    ),

  formulaPadraoDesmembramento: yup
    .number()
    .nullable()
    .required(
      i18n.t(
        'production.standardFormula.create.errors.requiredDismembermentType'
      )
    ),
});

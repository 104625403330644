import { IPagination } from '@/Components/Table';
import { IGetStorageLocationDropDownResponse } from '@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse';
import { useState } from 'react';

export const useCreateStates = () => {
  const [origin, setOrigin] = useState<string>();
  const [destiny, setDestiny] = useState<string>();

  const [changeStorageLocationModal, setChangeStorageLocationModal] =
    useState(false);

  const [storageLocations, setStorageLocations] = useState<
    IGetStorageLocationDropDownResponse[]
  >([]);
  const [pagination, setPagination] = useState<IPagination>({
    pageIndex: 0,
    pageSize: 10,
    sorter: { column: 'descricao', direction: 'ASC' },
  });
  return {
    origin,
    destiny,
    setOrigin,
    setDestiny,
    changeStorageLocationModal,
    setChangeStorageLocationModal,
    storageLocations,
    setStorageLocations,
    pagination,
    setPagination,
  };
};

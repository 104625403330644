import {
  Card
} from '@/Components';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IFeedStockInfoCard } from './interfaces';
import { Dcb } from './Components/Dcb';
import { Cas } from './Components/Cas';
import { UnitPrescription } from './Components/UnitPrescription';
import { ComponentType } from './Components/ComponentType';
import { ValidateDays } from './Components/ValidateDays';
import { BaseMolecularWeight } from './Components/BaseMolecularWeight';
import { SaltMolecularWeight } from './Components/SaltMolecularWeight';
import { EquivalenceFactor } from './Components/EquivalenceFactor';
import { Valence } from './Components/Valence';
import { ToleranceWeighingBelow } from './Components/ToleranceWeighingBelow';
import { ToleranceWeighingAbove } from './Components/ToleranceWeighingAbove';
import { LaboratoryOnly } from './Components/LaboratoryOnly';
import { OnlyDiluted } from './Components/OnlyDiluted';
import { GastroResistantCapsule } from './Components/GastroResistantCapsule';
import { MonoDrug } from './Components/MonoDrug';
import { Pellets } from './Components/Pellets';
import { Excipient } from './Components/Excipient';
import { Qsp } from './Components/Qsp';
import { StorageLabelNotes } from './Components/StorageLabelNotes';
import { useFeedstockInfoCardStates } from './Hooks/useFeedstockInfoCardStates';
import { useFeedstockInfoCardCallAPI } from './Hooks/useFeedstockInfoCardCallAPI';

export const FeedStockInfoCard: React.FC<IFeedStockInfoCard> = ({
  disabledForm = false,
  product,
  feedStockClassId,
}) => {
  const { t } = useTranslation();

  const {
    equivalenceFactor,
    setEquivalenceFactor
  } = useFeedstockInfoCardStates();

  const {
    listUnitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
    dcbList,
    isLoadingDcb,
    fetchNewDcbPage,
    refetchSearchDcbPage
  } = useFeedstockInfoCardCallAPI({ feedStockClassId });

  return (
    <Card title={t('products.create.additionalInformation')}>
      <Row gutter={[16, 0]}>
        <Dcb
          product={product}
          disabledForm={disabledForm}
          isLoadingDcb={isLoadingDcb}
          fetchNewDcbPage={fetchNewDcbPage}
          refetchSearchDcbPage={refetchSearchDcbPage}
          dcbList={dcbList}
        />
        <Cas
          product={product}
          disabledForm={disabledForm}
        />
        <UnitPrescription
          product={product}
          disabledForm={disabledForm}
          listUnitsMeasurementsPrescription={listUnitsMeasurementsPrescription}
          isUnitOfMeasurePrescriptionLoading={isUnitOfMeasurePrescriptionLoading}
          fetchNewDcbPage={fetchNewDcbPage}
        />
        <ComponentType
          product={product}
          disabledForm={disabledForm}
          isUnitOfMeasurePrescriptionLoading={isUnitOfMeasurePrescriptionLoading}
          fetchNewDcbPage={fetchNewDcbPage}
        />
        <ValidateDays
          product={product}
          disabledForm={disabledForm}
        />
      </Row>
      <Row gutter={[16, 0]}>
        <BaseMolecularWeight
          product={product}
          disabledForm={disabledForm}
          setEquivalenceFactor={setEquivalenceFactor}
        />
        <SaltMolecularWeight
          product={product}
          disabledForm={disabledForm}
          setEquivalenceFactor={setEquivalenceFactor}
        />
        <EquivalenceFactor
          product={product}
          equivalenceFactor={equivalenceFactor}
        />
        <Valence
          product={product}
          disabledForm={disabledForm}        
        />
        <ToleranceWeighingBelow
          product={product}
          disabledForm={disabledForm}         
        />
        <ToleranceWeighingAbove
          product={product}
          disabledForm={disabledForm}     
        />
      </Row>
      <Row gutter={[16, 0]}>
        <LaboratoryOnly
          product={product}
          disabledForm={disabledForm}   
        />
        <OnlyDiluted 
          product={product}
          disabledForm={disabledForm}   
        />
        <GastroResistantCapsule
          product={product}
          disabledForm={disabledForm}   
        />
        <MonoDrug
          product={product}
          disabledForm={disabledForm}  
        />
      </Row>
      <Row gutter={[16, 0]} style={{ marginTop: 20 }}>
        <Pellets
          product={product}
          disabledForm={disabledForm}  
        />
        <Excipient
          product={product}
          disabledForm={disabledForm}  
        />
        <Qsp
          product={product}
          disabledForm={disabledForm}  
        />
      </Row>
      <Row gutter={[16, 0]} style={{ marginTop: 20 }}>
        <StorageLabelNotes 
          product={product}
          disabledForm={disabledForm}  
        />
      </Row>
    </Card>
  );
};

import { useEffect, useState } from 'react';
import { Modal } from '../../../../../../../Components/Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';
import { Table as SystemTable } from '../../../../../../../Components/Table';
import {
  headerPurchaseOrderModalTable,
  purchaseOrderModalTableColumns,
} from '../../utils/constants';
import { IPurchaseOrderOpenList } from '../../../../../../../Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { IQueryListParams } from '../../../../../../../Utils/Http';
import { IPaginationInput } from '@/Components/Table/components/Pagination';

interface IAddPurchaseOrderModalProps {
  visible?: boolean;
  purchaseOrderList?: IPurchaseOrderOpenList;
  changeVisibleModal: (value: boolean) => void;
  setPurchaseOrderList: any;
  isLoading?: boolean;
  purchaseOrderSelected: any[];
  refetchPurchaseOrderList: (newPagination: IQueryListParams) => void;
}

export const PurchaseOrdersModal: React.FC<IAddPurchaseOrderModalProps> = ({
  visible,
  purchaseOrderList,
  changeVisibleModal,
  setPurchaseOrderList,
  isLoading,
  purchaseOrderSelected,
  refetchPurchaseOrderList,
}) => {
  const { t } = useTranslation();

  const [hasItemSelected, setHasItemSelected] = useState(true);
  const [purchaseOrderListSelected, setPurchaseOrderListSelected] = useState<
    any[]
  >([]);

  useEffect(() => {
    if (
      purchaseOrderListSelected.length > 0 &&
      purchaseOrderSelected.length < purchaseOrderListSelected.length
    )
      return setHasItemSelected(false);
    setHasItemSelected(true);
  }, [purchaseOrderListSelected]);

  return (
    <Modal
      minWidth={650}
      onClose={() => changeVisibleModal(false)}
      onCancelClick={() => changeVisibleModal(false)}
      onOkClick={() => {
        setPurchaseOrderList(purchaseOrderListSelected);
        changeVisibleModal(false);
      }}
      disabledButton={hasItemSelected}
      okButtonName={t('common.import')}
      visible={visible}
      title={
        <Title
          translator={t}
          subtitle={
            purchaseOrderList && purchaseOrderList.data
              ? purchaseOrderList.data[0]?.nomeFornecedor
              : ''
          }
        />
      }
      footerText={
        <Text
          totalPurchaseOrderListSelected={
            purchaseOrderListSelected.length - purchaseOrderSelected.length <= 0
              ? 0
              : purchaseOrderListSelected.length - purchaseOrderSelected.length
          }
          translator={t}
        />
      }
      body={
        <div style={{ padding: '20px' }}>
          <Divflex
            style={{
              flexDirection: 'column',
              marginBottom: 15,
            }}
          >
            <SystemTable
              columns={purchaseOrderModalTableColumns}
              headerOptions={headerPurchaseOrderModalTable}
              hasSelection
              pagination={purchaseOrderList as IPaginationInput}
              hideItemsPerPage
              alignPagination="center"
              onRefetch={refetchPurchaseOrderList}
              loading={isLoading}
              selectedRowsOnTable={setPurchaseOrderListSelected}
              disabledLines={purchaseOrderSelected}
              keyToSelect="sequenciaGroupTenant"
              initialRowsSelected={purchaseOrderSelected}
            />
          </Divflex>
        </div>
      }
    />
  );
};

const Title = ({
  translator,
  subtitle,
}: {
  translator: any;
  subtitle: string;
}) => {
  return (
    <>
      <div>{translator('purchasing.invoice.create.selectPurchaseOrder')}</div>
      <p style={{ fontWeight: 'normal', fontSize: '14px' }}>{subtitle}</p>
    </>
  );
};

const Text = ({
  totalPurchaseOrderListSelected,
  translator,
}: {
  totalPurchaseOrderListSelected: number;
  translator: any;
}) => {
  if (totalPurchaseOrderListSelected === 0) {
    return (
      <span>
        <strong>
          {translator('purchasing.invoice.create.noPurchaseOrder')}
        </strong>{' '}
        {translator('purchasing.invoice.create.selected')}.
      </span>
    );
  } else if (totalPurchaseOrderListSelected === 1) {
    return (
      <span>
        <strong>
          {totalPurchaseOrderListSelected}{' '}
          {translator('purchasing.invoice.create.singlePurchaseOrder')}{' '}
        </strong>
        {translator('purchasing.invoice.create.selected')}.
      </span>
    );
  } else {
    return (
      <span>
        <strong>
          {totalPurchaseOrderListSelected}{' '}
          {translator('purchasing.invoice.create.manyPurchaseOrder')}{' '}
        </strong>
        {translator('purchasing.invoice.create.manySelected')}.
      </span>
    );
  }
};

import { FC } from 'react';
import { Form } from '@/Components';
import { Col, Row } from 'antd';
import { PurchaseOrderDetailsInfo } from './Cards/InfoCard';
import { useParams } from 'react-router';
import { PurchaseOrderProductDetails } from './Cards/ProductCard';
import { PurchaseOrderTotalDetails } from './Cards/TotalCard';
import { PageLoading } from '@/Layouts/PageLoading';
import { StatusCard } from './Cards/StatusCard';
import { TimeLineCard } from './Cards/TimeLine';
import { useTabsCallAPI } from './Hooks/useTabsCallAPI';
import { useTabsMemorizations } from './Hooks/useTabsMemorizations';

export const PurchaseOrderDetailsTabForm: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const {
    purchaseOrder,
    isLoading
  } = useTabsCallAPI({ externalId });

  const {
    initialValues
  } = useTabsMemorizations({
    purchaseOrder
  });

  return (
    <>
      <PageLoading loading={isLoading}>
        <Form
          initialValues={initialValues}
        >
          <>
            <Row justify="space-between" gutter={[16, 0]}>
              <Col span={17}>
                <PurchaseOrderDetailsInfo />
                <PurchaseOrderProductDetails />
                <PurchaseOrderTotalDetails />
              </Col>
              <Col span={7}>
                <StatusCard />
                <TimeLineCard />
              </Col>
            </Row>
          </>
        </Form>
      </PageLoading>
    </>
  );
};

export default PurchaseOrderDetailsTabForm;

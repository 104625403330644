import { Link, Text } from '../../../../../Components';
import { Avatar } from '../../../../../Components/Avatar';
import { IPreferences } from '../../../../../Data/Interfaces/response/User/IGetUserReponse';
import DateUtils from '../../../../../Utils/DateUtils';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';

export const transfersCollums = (t: any, preferences?: IPreferences) => [
  {
    title: t('supply.transfers.list.transferNumber'),
    dataIndex: 'sequenciaNumeroTransferencia',
    key: 'sequenciaNumeroTransferencia',
    width: '7%',
    sortColumn: 'sequenciaNumeroTransferencia',
    sorter: true,
    isHighlightable: true,
  },
  {
    title: t('supply.transfers.list.dateOfTransfer'),
    width: '14%',
    dataIndex: 'dataTransferencia',
    key: 'dataTransferencia',
    sortColumn: 'dataTransferencia',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: string, row: any) =>
      !hasPermission(PermissionEnum.Estoque_Transferencias_VerDetalhes) ? (
        <Text type="ui-tiny-bold" color="text-50">
          {`${DateUtils.convertDate(x, preferences?.padraoData)} ${t(
            'common.dateBreakApart'
          )} ${DateUtils.convertDate(x, preferences?.padraoHora)}`}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/inventory/transfers/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {`${DateUtils.convertDate(x, preferences?.padraoData)} ${t(
            'common.dateBreakApart'
          )} ${DateUtils.convertDate(x, preferences?.padraoHora)}`}
        </Link>
      ),
  },

  {
    title: t('supply.transfers.list.placeOfOrigin'),
    width: '28%',
    dataIndex: 'localEstoqueOrigemDescricao',
    key: 'localEstoqueOrigemDescricao',
    sortColumn: 'localEstoqueOrigemDescricao',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => `${x} (${row.localEstoqueOrigemEmpresa})`,
  },
  {
    title: t('supply.transfers.list.destination'),
    width: '28%',
    dataIndex: 'localEstoqueDestinoDescricao',
    key: 'localEstoqueDestinoDescricao',
    sortColumn: 'localEstoqueDestinoDescricao',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => `${x} (${row.localEstoqueDestinoEmpresa})`,
  },
  {
    title: t('supply.transfers.list.responsible'),
    dataIndex: 'usuarioNome',
    key: 'usuarioNome',
    sortColumn: 'usuarioNome',
    sorter: true,
    isHighlightable: true,
    render: (name: string, surname: any) => (
      <Avatar
        size="M"
        type="ui-tiny-bold"
        fisrtName={name}
        lastName={surname.usuarioSobrenome}
        description={{
          description: `${name} ${surname.usuarioSobrenome}`,
          type: 'ui-tiny-content',
          color: 'text-50',
        }}
        color="white"
      />
    ),
  },
];

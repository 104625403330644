import {
  FETCH_GET_SUPPLIER
} from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { IUseCallAPI } from './interfaces';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetSupplierResponse } from '@/Data/Interfaces/response/Purchase/ISupplierResponse';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

export const useInfoCardCallAPI = ({
  providerId
}: IUseCallAPI) => {
  const { data: provider } = useControllerQueryApiHook<IGetSupplierResponse>({
    uniqId: FETCH_GET_SUPPLIER,
    entityApi: SupplierAPI.getSupplier,
    externalId: providerId
  });

  return {
    provider
  };
};
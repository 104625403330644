import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card
} from '@/Components';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { 
  IStandardFormulaDetailForm 
} from './interfaces';
import { InputProduct } from './Components/InputProduct';
import { PharmaceuticalForm } from './Components/PharmaceuticalForm';
import { AmountDefault } from './Components/AmountDefault';
import { DismembermentType } from './Components/DismembermentType';
import { Validity } from './Components/Validity';
import { Dilution } from './Components/Dilution';
import { Reason } from './Components/Reason';
import { Density } from './Components/Density';
import { useStandardFormulaDetailStates } from './Hooks/useStandardFormulaDetailStates';
import { useStandardFormulaDetailCallAPI } from './Hooks/useStandardFormulaDetailCallAPI';

export const StandardFormulaDetailForm: FC<IStandardFormulaDetailForm> = ({
  disabled,
  produtoExternalId
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  
  const {
    classificationProductId,
    setClassificationProductId,
    unidadeMedidaId,
    setUnidadeMedidaId
  } = useStandardFormulaDetailStates();

  const {
    standardFormula,
    unitsMeasurementsDefault
  } = useStandardFormulaDetailCallAPI();

  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  useEffect(() => {
    if (unitMeasureIdDefault) {
      form.setFieldValue('unidadeMedidaId', unitMeasureIdDefault);
    }
    if (form.values.produtoExternalId === undefined) {
      form.setFieldValue('unidadeMedidaId', undefined);
    }
  }, [unitMeasureIdDefault]);

  return (
    <Card title={t('common.details')} withoutCollapse={true}>
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <InputProduct
            setClassificationProductId={setClassificationProductId}
            setUnidadeMedidaId={setUnidadeMedidaId}
            produtoExternalId={produtoExternalId}
            disabled={disabled}
            previousProduct={standardFormula?.descricaoProdutoFormula}
          />
        </Col>
        <Col span={8}>
          <PharmaceuticalForm
            standardFormula={standardFormula}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={3}>
          <AmountDefault
            standardFormula={standardFormula}
            disabled={disabled}
            classificationProductId={classificationProductId}
            unidadeMedidaId={unidadeMedidaId}
          />
        </Col>
        <Col span={5}>
          <DismembermentType 
            standardFormula={standardFormula}
            disabled={disabled}
          />
        </Col>
        <Col span={2}>
          <Validity
            standardFormula={standardFormula}
            disabled={disabled}
          />
        </Col>
        <Col span={2}>
          <Dilution
            standardFormula={standardFormula}
            disabled={disabled}
          />
        </Col>
        <Col span={2}>
          <Reason
            standardFormula={standardFormula}
            disabled={disabled}
          />
        </Col>
        <Col span={2}>
          <Density
            standardFormula={standardFormula}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Card>
  );
};

import { useControllerMutateApiHook, useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { THERE_IS_STANDARD_FORMULA_TYPE } from "@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys";
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { StandardFormulaAPI } from "@/Data/API/Production/StandardFormula";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { IUseCallAPI } from "./interfaces";

export const useInputProductCallAPI = ({
  onStandardFormulaError
}: IUseCallAPI) => {
  const {
    data: productList,
    isLoading: isProductLoading,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true
  });

  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: THERE_IS_STANDARD_FORMULA_TYPE,
    entityApi: StandardFormulaAPI.isStandardFormulaNameAlreadyRegistred,
    options: {
      onError: onStandardFormulaError,
    },
  });

  return {
    productList,
    isProductLoading,
    fetchNewProductPage,
    refetchSearchProductPage,
    mutateAsync
  };
};
import { FC } from 'react';
import { Col } from 'antd';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { IInitialQuantity } from './interfaces';
import { useInitialQuantityCallAPI } from './Hooks/useInitialQuantityCallAPI';
import { useTranslation } from 'react-i18next';
import { IListUnitMeasurementByClassData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';

export const InitialQuantity: FC<IInitialQuantity> = ({ typeClassProduct }) => {
  const { t } = useTranslation();

  const { listunitsMeasurements } = useInitialQuantityCallAPI({
    typeClassProduct,
  });

  return (
    <Col span={4}>
      <TextInputNumber
        name="saldoInicial.quantidade"
        label={t('supply.lot.create.quantityInitial')}
        placeHolder={t('supply.lot.placeholder.quantityInitial')}
        dropdownRight={{
          name: 'saldoInicial.unidadeId',
          options:
            listunitsMeasurements?.data?.map(
              (x: IListUnitMeasurementByClassData) => ({
                id: x.id,
                content: x.unidadeAbreviacao,
              })
            ) || [],
        }}
      />
    </Col>
  );
};

import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const InvoiceCreateCreateSchema = yup.object().shape({
  fornecedorExternalId: yup
    .string()
    .required(i18n.t('purchasing.invoice.create.errors.requiredProvider')),
  numero: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredNumber')),
  serie: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredSeries')),
  dataEmissao: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceIssueDate')
    ),
  dataEntrega: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceDeliveryDate')
    ),
  tipoFreteId: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceFreight')
    ),
  itens: yup.array().of(
    yup.object().shape({
      produtoExternalId: yup
        .string()
        .required(i18n.t('purchasing.invoice.create.errors.requiredProduct')),
      ncmId: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredNcm')),
      quantidadeComprada: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredQuantity')),
      valorUnitario: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredUnitValue')),
      baseCalculoIcms: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredIcmsBase')),
      valorIcms: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredIcmsValue')),
      cfopId: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredCFOP')),
      valorIpi: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredIpiValue')),
      aliquotaIpi: yup
        .number()
        .required(
          i18n.t('purchasing.invoice.create.errors.requiredAliquotIPI')
        ),
      cstCsosnId: yup
        .number()
        .required(i18n.t('purchasing.invoice.create.errors.requiredCST')),
    })
  ),
});

import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useParams } from 'react-router';
import {
  i18n
} from '@/Components';
import {
  EDIT_SPECIALTIES,
  GET_SPECIALTIES
} from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { 
  useControllerMutateApiHook,
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import { 
  messageErrorToast,
  messageSucessToast
} from '@/Utils/MessageErrorToast';
import { IGetSpecialtiesResponse } from '@/Data/Interfaces/response/Specialties/ISpecialtiesResponse';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';

export const useDetailsCallAPI = ({
  toogleDisabled,
  disabledForm
}: IUseCallAPI) => {
  const queryClient = useQueryClient();
  const { externalId } = useParams<{ externalId: string }>();

  const editSpecialtie: any = useControllerMutateApiHook({
    uniqId: EDIT_SPECIALTIES,
    entityApi: SpecialtiesAPI.editSpecialtie,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('specialties.edit.success'));
        
        queryClient.invalidateQueries([FETCH_GET_PRODUCT]);

        toogleDisabled(!disabledForm);
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const { data: specialtie } = useControllerQueryApiHook<IGetSpecialtiesResponse>({
    uniqId: GET_SPECIALTIES,
    entityApi: SpecialtiesAPI.getSpecialties,
    externalId: externalId
  });

  return {
    editSpecialtie,
    specialtie
  };
};
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { ICreateServiceRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { IPagination } from '@/Components/Table';

export const useListFunctions = ({
  createService,
  refetchServiceList,
  externalId,
  filterName,
  setVisibleCreateModal,
}: IUseFunctions) => {
  const onSubmit = useCallback(
    async (values: ICreateServiceRequest) => {
      await createService.mutateAsync(values);
    },
    [createService]
  );

  const onRefetch = useCallback(
    (pagination: IPagination) => {
      refetchServiceList({
        ...pagination,
        ...(filterName && {
          filter: [{ filterName, value: externalId }],
        }),
      });
    },
    [externalId, filterName, refetchServiceList]
  );

  const changeVisible = useCallback(
    (x: boolean) => setVisibleCreateModal(x),
    [setVisibleCreateModal]
  );

  return {
    onSubmit,
    onRefetch,
    changeVisible,
  };
};

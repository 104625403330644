import { useTranslation } from 'react-i18next';
import {
  Card,
  Checkbox,
  Text,
  TextArea,
} from '../../../../../../../Components';
import { Col, Row } from 'antd';
import React from 'react';
import _ from 'lodash';
import styles from './ProductMessage.module.scss';
import { useFormikContext } from 'formik';
import { useCreateCallAPI } from '../../Hooks/useCreateCallAPI';
import { useCreateFunctions } from '../../Hooks/useCreateFunctions';

export const InfoCard: React.FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { messageProductAlreadyRegistred } = useCreateCallAPI({ form });

  const { fetchProductMessageName } = useCreateFunctions({
    messageProductAlreadyRegistred,
  });

  return (
    <Card title={t('common.information')} withoutCollapse={true}>
      <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
        <TextArea
          name="mensagem"
          label={t('supply.productMessage.create.label')}
          placeholder={t('supply.productMessage.create.placeholder')}
          maxLength={5000}
          rows={1}
          heightTextArea={100}
          required
          onChange={fetchProductMessageName}
        />
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
        <Col span={5}>
          <div className={styles['marginBottom']}>
            <Text
              children={t('supply.productMessage.create.primaryLabel')}
              type="ui-tiny-semibold"
              color="text-100"
            />
          </div>
          <Checkbox
            name="exibeVenda"
            label={t('supply.productMessage.create.checkBox1')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={false}
          />
          <Checkbox
            name="exibeRotulagem"
            label={t('supply.productMessage.create.checkBox2')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={false}
          />
        </Col>
        <Col span={9}>
          <div className={styles['marginBottom']}>
            <Text
              children={t('supply.productMessage.create.secondaryLabel')}
              type="ui-tiny-semibold"
              color="text-100"
              style={{ marginBottom: 5 }}
            />
          </div>
          <Checkbox
            name="exibeFichaPesagem"
            label={t('supply.productMessage.create.checkBox3')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={false}
          />
          <Checkbox
            name="exibeImpressaoRotulo"
            label={t('supply.productMessage.create.checkBox4')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={false}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;

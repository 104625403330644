import i18n from 'i18next';
import { Icon, Tooltip } from '../../../../../../Components';
import { ListTooltip } from '../components/PurchaseOrderInput';

const distributorId = 1;
const shippingCompanyId = 2;
const serviceId = 3;

export const shippingCompanyPagination = {
  pageIndex: 0,
  pageSize: 10,
  filter: [{ filterName: 'tipoFornecedorId', value: shippingCompanyId }],
};

export const providerListPagination = {
  pageIndex: 0,
  pageSize: 10,
  filter: [
    { filterName: 'tipoFornecedorId', value: distributorId },
    { filterName: 'tipoFornecedorId', value: serviceId },
  ],
};

export const headerPurchaseOrderModalTable = {
  hasSearch: true,
  searchPlaceholder: i18n.t('purchasing.invoice.create.searchForPurchase'),
};

export const purchaseOrderModalTableColumns: any = [
  {
    title: i18n.t('purchasing.invoice.create.requestNumber'),
    dataIndex: 'sequenciaGroupTenant',
    sortColumn: 'sequenciaGroupTenant',
    defaultSortOrder: 'ascend',
    key: '1',
    render: (text: string, purchase: any) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <strong>#{text}</strong>
        <Tooltip
          showMe={true}
          title={<ListTooltip hasTotal={false} purchaseOrder={purchase} />}
        >
          <Icon color="status-disabled-base" icon="exclamation-upside-down" />
        </Tooltip>
      </div>
    ),
    sorter: (a: any, b: any) => {
      return a.sequenciaGroupTenant - b.sequenciaGroupTenant;
    },
  },
  {
    title: i18n.t('purchasing.invoice.create.releaseDate'),
    dataIndex: 'dataLancamento',
    key: '2',
  },
  {
    title: i18n.t('purchasing.invoice.create.deliveryForecast'),
    dataIndex: 'previsaoEntrega',
    key: '3',
  },
  {
    title: i18n.t('purchasing.invoice.create.totalValuePurchaseOrder'),
    dataIndex: 'totalPedido',
    key: '4',
  },
];

import React, { useEffect, useState } from 'react';
import { Form, Button, i18n } from '../../../../../Components';
import InfoSection from './Sections/InfoSection';
import ProductSection from './Sections/ProductSection';
import ValueSection from './Sections/ValueSection';
import { ButtonDraft } from '../../../../../Components/Button/ButtonDraft';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../Store';
import { InvoiceCreateCreateSchema } from './InvoiceCreate.schema';
import {
  CREATE_INVOICE,
  FETCH_GET_INVOICE,
  FETCH_LIST_INVOICE,
  SAVE_PUT_DRAFT_INVOICE
} from '../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import DateUtils from '../../../../../Utils/DateUtils';
import {
  configInitialPurchaseOrders,
  configInitialValues,
  configInvoiceValues,
} from './utils/functions';
import { useQueryClient } from '@tanstack/react-query';
import { DeleteDraftModal } from './Modal/deleteDraftModel';
import { useParams } from 'react-router-dom';
import StorageUtils from '@/Utils/StorageUtils';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';

import styles from './styles.module.scss';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { IInvoiceGetResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';

const DraftInvoiceEdit: React.FC = () => {
  const createInvoice: any = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE,
    entityApi: InvoiceAPI.postInvoice,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.create')
        )
    }
  });
  const createInvoiceDraft = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE,
    entityApi: InvoiceAPI.postInvoiceDraft,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.createDraft')
        )
    }
  });
  
  const updateInvoiceDraft: any = useControllerMutateApiHook({
    uniqId: SAVE_PUT_DRAFT_INVOICE,
    entityApi: InvoiceAPI.savePutDraftInvoice,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.editDraft')
        )
    }
  });

  const preferences = StorageUtils.getPreference();

  const queryClient = useQueryClient();
  const { externalId } = useParams<{ externalId: string }>();
  const [importedProducts, setImportedProducts] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({});
  const [initialPurchaseOrders, setInitialPurchaseOrders] = useState<any[]>([]);

  const compareChangedValues = (values: any) => {
    return JSON.stringify(values) === JSON.stringify(initialValues);
  };

  const [openDeleteDraftModal, setOpenDeleteDraftModal] = useState(false);

  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalId: externalId
  });

  const handleSubmit = async (values: any) => {
    try {
      if (!preferences) return;
      if (values.dataEmissao && values.dataEntrega) {
        values.dataEmissao = DateUtils.convertDateToApi(
          values.dataEmissao,
          preferences.padraoData
        );

        values.dataEntrega = DateUtils.convertDateToApi(
          values.dataEntrega,
          preferences.padraoData
        );
      }

      let formattedValues = configInvoiceValues(values);
      let res;
      res = await createInvoice.mutateAsync({
        ...formattedValues,
        notaFiscalEntradaExternalId: externalId,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
        history.push('/order/invoice/');
      }
    } catch (e) {}
  };

  const onSaveDraft = async (values: any) => {
    try {
      if (!preferences) return;
      if (values.dataEmissao && values.dataEntrega) {
        values.dataEmissao = DateUtils.convertDateToApi(
          values.dataEmissao,
          preferences.padraoData
        );

        values.dataEntrega = DateUtils.convertDateToApi(
          values.dataEntrega,
          preferences.padraoData
        );
      }

      let formattedValues = configInvoiceValues(values);

      let res;
      if (!externalId) {
        res = await createInvoiceDraft.mutateAsync(formattedValues);
      } else {
        formattedValues = {
          ...formattedValues,
          notaFiscalEntradaExternalId: externalId,
        };
        res = await updateInvoiceDraft.mutateAsync({
          ...values,
          notaFiscalEntradaExternalId: externalId,
        });
      }

      if (res.success && externalId) {
        queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
        queryClient.invalidateQueries([FETCH_GET_INVOICE]);
      }
    } catch (e) {}
  };

  const { t } = useTranslation();

  useEffect(() => {
    const initialValues = configInitialValues(invoice);
    const initialPurchaseOrders = configInitialPurchaseOrders(
      invoice?.pedidosVinculados ?? []
    );

    setInitialValues(initialValues);
    setInitialPurchaseOrders(initialPurchaseOrders);
  }, [invoice]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        schema={InvoiceCreateCreateSchema}
      >
        {(props) => (
          <>
            <InfoSection
              initialPurchaseOrders={initialPurchaseOrders}
              setImportedProducts={setImportedProducts}
            />
            <ValueSection importedProducts={importedProducts} />
            <ProductSection
              importedProducts={importedProducts}
              setImportedProducts={setImportedProducts}
              productList={initialValues?.itens || []}
            />
            <div className={styles['footer-button']}>
              <div>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => history.push('/order/invoice')}
                />
                <Button
                  type="primary"
                  children={
                    createInvoice.isLoading
                      ? t('common.creating')
                      : t('common.create')
                  }
                  htmlType="submit"
                  loading={createInvoice.isLoading}
                />
              </div>

              <ButtonDraft
                type="secondary"
                htmlType="button"
                onClick={() => {
                  onSaveDraft(props.values);
                }}
                disabled={compareChangedValues(props.values)}
                children={
                  createInvoiceDraft.isLoading
                    ? t('common.saving')
                    : t('common.saveDraft')
                }
                withStatusIcon={
                  !compareChangedValues(props.values) && !!externalId
                }
                withStatusMessage={
                  !compareChangedValues(props.values) && externalId
                    ? t('common.savedChanges')
                    : compareChangedValues(props.values) === false &&
                      !!externalId
                    ? t('common.hearChanges')
                    : ''
                }
                loading={createInvoiceDraft.isLoading}
                withDropdownEditButton={
                  !!externalId
                    ? {
                        options: [
                          {
                            label: t('common.modalDraftDelete.title'),
                            icon: 'trash',
                            onClick: () => setOpenDeleteDraftModal(true),
                          },
                        ],
                      }
                    : undefined
                }
              />
            </div>
          </>
        )}
      </Form>

      <DeleteDraftModal
        invoice={invoice}
        visibleModal={openDeleteDraftModal}
        changeVisibleModal={(value) => setOpenDeleteDraftModal(value)}
      />
    </>
  );
};

export default DraftInvoiceEdit;

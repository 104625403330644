import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IPatchLotStatusRequest } from '@/Data/Interfaces/request/Lot/IPatchLotStatusRequest';
import {
  DELETE_LOT,
  EDIT_STATUS_LOT,
  FETCH_LIST_LOTS,
  FETCH_LIST_STATUS_LOTS,
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import StorageUtils from '@/Utils/StorageUtils';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const preferences = StorageUtils.getPreference();

  const {
    data: lotsList,
    isLoading,
    refetch: refetchLotList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOTS,
    entityApi: LotsAPI.listLots,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'dataLancamento', direction: 'DESC' },
    },
    autoIncrement: true,
  });

  const { data: statusList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STATUS_LOTS,
    entityApi: LotsAPI.statusLotList,
  });

  const { data: situationLot } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STATUS_LOTS,
    entityApi: LotsAPI.statusLotList,
  });

  const deleteLot: any = useControllerMutateApiHook({
    uniqId: DELETE_LOT,
    entityApi: LotsAPI.deleteLot,
  });

  const editStatusLot: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_LOT,
    entityApi: LotsAPI.patchLotStatus,
  });

  const patchLotStatus = async (values: IPatchLotStatusRequest) => {
    const res = await editStatusLot.mutateAsync({
      ...values,
    });

    if (res.success) queryClient.invalidateQueries([FETCH_LIST_LOTS]);
  };

  return {
    preferences,
    lotsList,
    isLoading,
    refetchLotList,
    statusList,
    situationLot,
    deleteLot,
    editStatusLot,
    patchLotStatus,
  };
};

import { useTranslation } from 'react-i18next';
import DateUtils from '../../../../../../../Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';
import { FETCH_GET_BALANCE_ADJUSTMENTS } from '../../../../../../../ControllerApiHook/UniqIds/Supply/BalanceAdjustmentsKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { BalanceAdjustmentsAPI } from '@/Data/API/Supply/BalanceAdjustmentsApi';
import { IBalanceAdjustmentsResponse } from '@/Data/Interfaces/response/BalanceAdjustments/IBalanceAdjustmentsResponse';

export const DynamicEditBalanceAdjustmentBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();

  const { data: balanceAdjustment } =
    useControllerQueryApiHook<IBalanceAdjustmentsResponse>({
      uniqId: FETCH_GET_BALANCE_ADJUSTMENTS,
      entityApi: BalanceAdjustmentsAPI.getBalanceAdjustment,
      externalId: match.params.externalId,
    });

  return (
    <span style={{ display: 'flex' }}>
      {`${balanceAdjustment?.produtoDescricao} | ${DateUtils.convertDate(
        balanceAdjustment?.dataLancamento || '',
        preferences?.padraoData
      )} ${t('common.complementDateHour')} ${DateUtils.convertDate(
        balanceAdjustment?.dataLancamento || '',
        preferences?.padraoHora
      )}`}
    </span>
  );
};

export const DynamicInfoEditBalanceAdjustmentBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();

  const { data: balanceAdjustment } =
    useControllerQueryApiHook<IBalanceAdjustmentsResponse>({
      uniqId: FETCH_GET_BALANCE_ADJUSTMENTS,
      entityApi: BalanceAdjustmentsAPI.getBalanceAdjustment,
      externalId: match.params.externalId,
    });

  return (
    <span style={{ display: 'flex' }}>
      {`${t('breadCrumb.balanceAdjustments.list')} | ${
        balanceAdjustment?.produtoDescricao
      }`}
    </span>
  );
};

import { Table } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TableItemCollum } from './Collum';
import {
  IGetSaleOrderResponse,
  SaleOrderItem,
} from '../../../../../../../../../Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import {
  CHANGE_ITEM_AMOUNT,
  CHANGE_SELECTED_FINISHED_PRODUCT,
  DELETE_SALES_ORDER,
  FETCH_GET_SALES_ORDER
} from '../../../../../../../../../ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { EditItemModalSaleOrder } from './Edit';

import './TableItem.override.scss';
import { DeleteTableItemModal } from './Edit/deleteTableItemModal';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

interface ITableItem {
  items: SaleOrderItem[];
  saleOrder: IGetSaleOrderResponse;
}

export interface ISelectedTableItem {
  id: number;
  description: string;
}

export const TableItem: FC<ITableItem> = ({ items, saleOrder }) => {
  const [editProductItem, setEditProductItem] = useState<SaleOrderItem>();
  const [contentItemHeight, setContentItemHeight] = useState<number>();
  const [scrollVisible, setScrollVisible] = useState(false);
  const [selectedTableItem, setSelectedTableItem] =
    useState<ISelectedTableItem | undefined>(undefined);

  const tableRef: any = useRef(null);

  const queryClient = useQueryClient();

  const changeAmount = useControllerMutateApiHook({
    uniqId: CHANGE_ITEM_AMOUNT,
    entityApi: SalesOrderAPI.changeItemAmountSaleOrder,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        messageSucessToast(
          i18n.t(
            'saleOrder.editOrder.SaleOrder.table.successMessage.changeItemAmount'
          )
        );
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const removeItem = useControllerMutateApiHook({
    uniqId: DELETE_SALES_ORDER,
    entityApi: SalesOrderAPI.deleteSaleOrder,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        contentItemHeight && changeVisibleScroll(contentItemHeight);
        setSelectedTableItem(undefined);
        messageSucessToast(
          i18n.t(
            'saleOrder.editOrder.SaleOrder.deleteTableItemModal.success'
          )
        );
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const changeSelectedItem = useControllerMutateApiHook({
    uniqId: CHANGE_SELECTED_FINISHED_PRODUCT,
    entityApi: SalesOrderAPI.changeSelectedFinishedProduct,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const { t } = useTranslation();

  const statusSaleOrder = saleOrder?.statusPedido;

  const changeAmountCallBack = useMemo(
    () =>
      _.debounce(
        async (amount: number, saleOrderItemId: number) =>
          await changeAmount.mutateAsync({
            pedidoVendaItemId: saleOrderItemId,
            quantidade: amount,
          }),
        600
      ),
    [changeAmount]
  );

  const removeItemCallBack = useMemo(
    () =>
      _.debounce(
        async (saleOrderItemId: number) =>
          await removeItem.mutateAsync({
            pedidoVendaItemId: saleOrderItemId,
          }),
        0
      ),
    [removeItem]
  );

  const changeVisibleScroll = useCallback(
    (contentHeight: number) => {
      const visible =
        contentHeight - 52 <=
        tableRef.current?.querySelector('div')?.clientHeight;

      setScrollVisible(visible);
    },
    [tableRef]
  );

  useEffect(() => {
    const pageHeight =
      document.getElementById('content-oder-item')?.clientHeight;
    pageHeight && setContentItemHeight(pageHeight);
  }, [contentItemHeight]);

  useEffect(() => {
    if (!contentItemHeight || !items) return;
    //set timeout to finish rendering the table before getting the height
    setTimeout(() => changeVisibleScroll(contentItemHeight), 100);
  }, [contentItemHeight, changeVisibleScroll, items]);

  return (
    <>
      <Table
        ref={tableRef}
        id="table-item"
        style={{
          ...(contentItemHeight && { height: contentItemHeight - 52 }),
        }}
        columns={TableItemCollum(
          t,
          changeAmountCallBack,
          setEditProductItem,
          changeSelectedItem,
          setSelectedTableItem,
          statusSaleOrder
        )}
        dataSource={items.map((x, index) => ({
          ...x,
          code: index + 1,
          key: `table_clients_${x.pedidoVendaItemId}`,
        }))}
        pagination={false}
        scroll={{
          ...(scrollVisible && {
            y: contentItemHeight && contentItemHeight - 97,
          }),
        }}
        loading={!items}
      />

      <EditItemModalSaleOrder
        item={editProductItem}
        onClose={() => setEditProductItem(undefined)}
      />
      {selectedTableItem && (
        <DeleteTableItemModal
          changeVisibleModal={setSelectedTableItem}
          visibleModal={selectedTableItem}
          onOkClick={() =>
            selectedTableItem !== undefined &&
            removeItemCallBack(selectedTableItem?.id)
          }
          description={selectedTableItem?.description}
          okButtonName={
            removeItem.isLoading
              ? t('common.removeLoading')
              : t('common.remove')
          }
          okButtonLoading={removeItem.isLoading}
        />
      )}
    </>
  );
};

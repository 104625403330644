import { FETCH_LIST_COUNTRY } from "@/ControllerApiHook/UniqIds/Supply/CountryKeys";
import { IUseCallAPI } from "./interfaces";
import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { CountryAPI } from "@/Data/API/Supply/CountryApi";

export const useOriginCountryCallAPI = ({
  countryPagination
}: IUseCallAPI) => {
  const { 
    data: paisOrigemList 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_COUNTRY,
    entityApi: CountryAPI.listCountry,
    initialPagination: countryPagination,
    autoIncrement: true
  });

  return { paisOrigemList };
};
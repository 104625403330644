import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IBatchControl } from "./interfaces";
import { Checkbox, Tooltip } from "@/Components";
import { useFormikContext } from "formik";
import { useBatchControlCallAPI } from "./Hooks/useBatchControlCallAPI";
import { useBatchControlFunctions } from "./Hooks/useBatchControlFunctions";

import styles from "./BatchControl.module.scss";

export const BatchControl: FC<IBatchControl> = ({
  product,
  disabledForm,
  setLotControlModal
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { stockBalance } = useBatchControlCallAPI();

  const { onClick } = useBatchControlFunctions({
    stockBalance,
    setLotControlModal
  });

  return (
    <Col span={6} className={styles['checkbox']}>
      <Tooltip
        title={`${t('products.toolTips.products.originalValue')} ${
          product?.controlaLote === true
            ? t('products.toolTips.products.enabled')
            : t('products.toolTips.products.disabled')
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.controlaLote !== form?.values?.controlaLote
        }
      >
        <Checkbox
          name="controlaLote"
          label={t('products.create.partControl')}
          disabled={disabledForm}
          withStatusIcon={!!product}
          withTooltip={{
            icon: 'question-mark',
            title: t('products.create.batchControlTooltip'),
          }}
          onClick={onClick}
        />
      </Tooltip>
    </Col>
  );
};
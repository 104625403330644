import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Form } from '../../../../../../../../Components';
import { WeightInput } from '../../../../../../../../Components/Inputs/WeightInput';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { useEditMemorizations } from '../../../Hooks/useEditMemorizations';
import styles from './BalanceADjustmentCard.module.scss';

export const BalanceAdjustmentCard: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { balanceAdjustment, loadingBalance, preferences } = useEditCallAPI({
    externalId,
  });

  const { itemsPreviousBalance, itemsAdjustmentQuantity, itemsFinalBalance } =
    useEditMemorizations({
      balanceAdjustment,
      preferences,
      loadingBalance,
    });

  return (
    <Form
      initialValues={{
        ...balanceAdjustment,
      }}
    >
      <Card
        title={t('supply.balanceAdjustment.priceAdjust')}
        withoutCollapse={true}
      >
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <WeightInput
              name="saldoAnterior"
              disabled
              dropdownRight={itemsPreviousBalance}
              label={t(
                'supply.balanceAdjustment.details.balanceBeforeAdjustment'
              )}
              placeHolder={t('supply.balanceAdjustment.currentQuantity')}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t(
                  'supply.balanceAdjustment.details.toolTip.balanceBeforeAdjustment'
                ),
              }}
              hideArrow
            />
          </Col>
          <Col span={8}>
            <WeightInput
              name={'quantidadeDoAjuste'}
              disabled
              label={t('supply.balanceAdjustment.details.adjustmentAmount')}
              dropdownRight={itemsAdjustmentQuantity}
              className={
                balanceAdjustment?.tipoOperacao === 0
                  ? styles['color-Green']
                  : styles['color-Red']
              }
              hideArrow
            />
          </Col>
          <Col span={8}>
            <WeightInput
              name="saldoFinal"
              disabled
              label={t(
                'supply.balanceAdjustment.details.balanceAfterAdjustment'
              )}
              dropdownRight={itemsFinalBalance}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t(
                  'supply.balanceAdjustment.details.toolTip.balanceAfterAdjustment'
                ),
              }}
              hideArrow
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

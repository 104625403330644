import { FC } from 'react';
import { Icon, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IFinalValuesBottom } from './interfaces';
import { TotalProducts } from './Components/TotalProducts';
import { TotalDiscounts } from './Components/TotalDiscounts';
import { Total } from './Components/Total';
import { useFinalValuesBottomCallAPI } from './Hooks/useFinalValuesBottomCallAPI';
import { useFinalValuesBottomMemorizations } from './Hooks/useFinalValuesBottomMemorizations';

import styles from './FinalValuesBottom.module.scss';

export const FinalValuesBottom: FC<IFinalValuesBottom> = ({
  withSelectedRows
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { 
    purchaseOrder
  } = useFinalValuesBottomCallAPI({
    externalId
  });

  const {
    hasProducts,
    productsValue
  } = useFinalValuesBottomMemorizations({
    purchaseOrder
  });

  return (
    <>
      {withSelectedRows && (
        <Divflex className={styles['description-warning']}>
          <Icon
            icon={'exclamation-upside-down'}
            size={'SM'}
            color={'text-400'}
          />
          <Text
            type="small-text"
            color={'text-400'}
            children={t(
              'purchasing.purchaseOrder.modal.approve.attentionMessage'
            )}
          />
        </Divflex>
      )}
      {!hasProducts && withSelectedRows && (
        <Divflex className={styles['description-warning']}>
          <Icon
            icon={'exclamation-warning'}
            size={'SM'}
            color={'status-danger-base'}
          />
          <Text
            type="small-text"
            color={'status-danger-base'}
            children={t('purchasing.purchaseOrder.modal.approve.dangerMessage')}
          />
        </Divflex>
      )}
      <div
        className={styles['top-divider']}
        style={{
          marginTop: withSelectedRows ? 12 : 25,
        }}
      />
      <Row
        justify="end"
        gutter={[16, 0]}
        style={{
          marginTop: 10,
        }}
      >
        <Col span={5}>
          <TotalProducts
            withSelectedRows={withSelectedRows}
            productsValue={productsValue}
            purchaseOrder={purchaseOrder}
            hasProducts
          />
        </Col>
        <Col span={8}>
          <TotalDiscounts
            withSelectedRows={withSelectedRows}
            productsValue={productsValue}
            purchaseOrder={purchaseOrder}
            hasProducts
          />
        </Col>
        <Col span={4}>
          <Total
            withSelectedRows={withSelectedRows}
            productsValue={productsValue}
            purchaseOrder={purchaseOrder}
            hasProducts
          />
        </Col>
      </Row>
    </>
  );
};

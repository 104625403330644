import { FC } from "react";
import {
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IToleranceWeighingAbove } from "./interfaces";
import { PercentageInput } from "@/Components/Inputs/PercentageInput";

export const ToleranceWeighingAbove: FC<IToleranceWeighingAbove> = ({
  product,
  disabledForm
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'products.toolTips.products.originalToleranceWeighingAbove'
        )} ${
          product && product.materiaPrima
            ? product.materiaPrima.toleranaciaPesagemUp
            : ''
        }%`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima.toleranaciaPesagemUp !==
            form?.values?.toleranaciaPesagemUp
        }
      >
        <PercentageInput
          withStatusIcon={!!product}
          name="toleranaciaPesagemUp"
          placeHolder="0%"
          label={t('products.create.weighingToleranceAbove')}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
import { isNil } from 'lodash';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IUseCallAPI } from './interfaces';
import { useEffect } from 'react';

export const useAmountDefaultCallAPI = ({
  classificationProductId,
}: IUseCallAPI) => {
  const { data: listunitsMeasurements, refetch } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
      initialPagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      autoIncrement: true,
      enabled: !isNil(classificationProductId),
    });

  useEffect(() => {
    classificationProductId &&
      refetch({
        pageIndex: 0,
        pageSize: 10,
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: classificationProductId,
          },
        ],
      });
  }, [classificationProductId, , refetch]);

  return {
    listunitsMeasurements,
  };
};

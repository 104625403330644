import { FC, useEffect } from 'react';
import { Table } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { useTranslation } from 'react-i18next';
import { productSynonymColumns } from './columns';
import { useParams } from 'react-router';
import { useSynonymProductStates } from './Hooks/useSynonymProductStates';
import { useSynonymProductCallAPI } from './Hooks/useSynonymProductCallAPI';
import { useSynonymProductFunctions } from './Hooks/useSynonymProductFunctions';
import { useSynonymProductMemorizations } from './Hooks/useSynonymProductMemorizations';

export const SynonymProductTab: FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable
  } = useSynonymProductStates();

  const {
    currentProduct,
    deleteSynonym,
    productList,
    isProductListLoading,
    refetchProductList
  } = useSynonymProductCallAPI({
    productExternalId
  });

  const {
    onDelete,
    selectRowsOnTable
  } = useSynonymProductFunctions({
    deleteSynonym,
    setSelectedRowsOnTable
  });
  
  const {
    headerOptions,
    deleteModal,
    rowsOptions
  } = useSynonymProductMemorizations({
    currentProduct,
    selectedRowsOnTable,
    productExternalId
  });

  useEffect(() => {
    refetchProductList({
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'produtoExternalId', value: productExternalId }],
    });
  }, [productExternalId, refetchProductList]);

  return (
    <PageLoading loading={isProductListLoading}>
      <Table
        loading={isProductListLoading}
        columns={productSynonymColumns(t)}
        hasSelection
        onDelete={onDelete}
        headerOptions={headerOptions}
        pagination={productList}
        onRefetch={refetchProductList}
        selectedRowsOnTable={selectRowsOnTable}
        deleteModal={deleteModal}
        rowsOptions={rowsOptions}
      />
    </PageLoading>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputWithSearch
} from '@/Components';
import { IInputProduct } from "./interfaces";
import { useInputProductCallAPI } from './Hooks/useInputProductCallAPI';
import { useInputProductFunctions } from './Hooks/useInputProductFunctions';
import { useInputProductMemorizations } from './Hooks/useInputProductMemorizations';

export const InputProduct: FC<IInputProduct> = ({
  setClassificationProductId,
  setUnidadeMedidaId
}) => {
  const { t } = useTranslation();
  
  const {
    onStandardFormulaError
  } = useInputProductFunctions({});
  
  const {
    productList,
    isProductLoading,
    fetchNewProductPage,
    refetchSearchProductPage,
    mutateAsync
  } = useInputProductCallAPI({
    onStandardFormulaError
  });

  const {
    onSearch,
    onChange
  } = useInputProductFunctions({
    mutateAsync,
    refetchSearchProductPage,
    productList,
    setClassificationProductId,
    setUnidadeMedidaId
  });

  const {
    items
  } = useInputProductMemorizations({
    productList
  });

  return (
    <InputWithSearch
      items={items}
      name="produtoExternalId"
      placeHolder={t('production.standardFormula.create.productPlaceholder')}
      label={t('production.standardFormula.create.product')}
      required={true}
      withoutMarginBottom={true}
      isLoading={isProductLoading}
      onScrollEnd={fetchNewProductPage}
      onSearch={onSearch}
      onChange={onChange}
    />
  );
};
import {
  ICreateProductMessage,
  IDeleteProductLinkedMessageRequest,
  IDeleteProductMessageRequest,
  IEditProductMessage,
} from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';
import { IBaseResponse } from '@/Data/Interfaces/response/Base/IBase';
import {
  IGetProductMessage,
  IListMessageProductDropdownResponse,
  IProductMessageListResponse,
  IProductMessageResponse,
} from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export class ProductMessageAPI {
  public static async createProductMessage(body: ICreateProductMessage) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Mensagem`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async productMessageList(
    context: QueryFunctionContext
  ): Promise<IProductMessageListResponse> {
    return await Http.fetcher<IProductMessageListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Mensagem/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async deleteProductMessage(body: IDeleteProductMessageRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Mensagem`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getCurrentProductMessage(
    context: QueryFunctionContext
  ): Promise<IGetProductMessage | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetProductMessage>(
      `/pharma/estoque/v1/Mensagem/${context.queryKey[1]}`
    );
  }

  public static async editProductMessage(body: IEditProductMessage) {
    const res = await Http.fetcher('/pharma/estoque/v1/Mensagem', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async isMessageProductAlreadyRegistred(
    descricao?: string
  ): Promise<IBaseResponse<{}> | null> {
    if (!descricao) return null;
    return await Http.fetcher(
      '/pharma/estoque/v1/Mensagem/ValidarDescricaoMensagem',
      {
        method: 'POST',
        body: JSON.stringify({ descricao }),
      }
    );
  }

  public static async getProductLinkedByMessageList(
    context: QueryFunctionContext<any>
  ): Promise<IProductMessageResponse | null> {
    if (!context.queryKey[1]?.filter?.every((x: any) => x.value)) return null;
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/Mensagem/ListarProdutosVinculados`,
      context.queryKey[1] as IQueryListParams
    );
    return await Http.fetcher<IProductMessageResponse>(url);
  }

  public static async deleteProductLinkedMessageRequest(
    body: IDeleteProductLinkedMessageRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Mensagem/RemoverProdutosVinculados`,
      {
        method: 'DELETE',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async listMessageProductDropDown(
    context: any
  ): Promise<IListMessageProductDropdownResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Mensagem/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}

import { useTranslation } from 'react-i18next';
import {
  EDIT_STATUS_PHARMACEUTICAL_FORM,
  FETCH_GET_PHARMACEUTICAL_FORM,
  FETCH_LIST_FOR_PHARMACEUTICAL_FORM
} from '../../../../../../../ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { Status } from '../../../Status';
import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Text';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import ReactMarkdown from 'react-markdown';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';

export const DynamicEditPharmaceuticalFormBreadcrumb = ({ match }: any) => {
  const { data: pharmaceuticalForm } = useControllerQueryApiHook<IGetPharmaceuticalForm>({
    uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
    externalId: match.params.externalId
  });

  return <div style={{ display: 'flex' }}>{pharmaceuticalForm?.descricao}</div>;
};

export const DynamicEditPharmaceuticalFormStatusBreadcrumb = ({
  match,
}: any) => {
  const { data: pharmaceuticalForm } = useControllerQueryApiHook<IGetPharmaceuticalForm>({
    uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
    externalId: match.params.externalId
  });

  const editStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.patchPharmaceuticalFormStatus
  });

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);

  const changeStatus = useCallback(
    async (value: boolean) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: value,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]);
        queryClient.invalidateQueries([FETCH_GET_PHARMACEUTICAL_FORM]);
        setModalVisible(false);
      }
    },
    [editStatus, match.params.externalId, queryClient]
  );

  const onEditStatus = useCallback(
    async (status: any) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: status,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]);
        queryClient.invalidateQueries([FETCH_GET_PHARMACEUTICAL_FORM]);
        setModalVisible(false);
      }
    },
    [queryClient, match.params.externalId, editStatus]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t(
        'breadCrumb.production.pharmaceuticalForm.pharmaceuticalFormListTitle'
      )} | ${pharmaceuticalForm?.descricao}`}
      <PermissionWrapper
        permission={6016}
        tooltip="noPermission.production.pharmaceuticalForm.statusEdit"
      >
        <Status
          type={pharmaceuticalForm?.ativo ? 'success' : 'disabled'}
          ative={pharmaceuticalForm?.ativo || false}
          onClick={(value) => {
            value ? changeStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
        />
      </PermissionWrapper>

      <Modal
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t(
                  'production.pharmaceuticalForm.details.changeStatusDescription',
                  {
                    pharmaceuticalForm: `**${pharmaceuticalForm?.descricao}**?`,
                  }
                )}
              </ReactMarkdown>
            </Text>
          </Divflex>
        }
        title={`${t(
          'production.pharmaceuticalForm.details.titleInativateStatusModal'
        )} "${pharmaceuticalForm?.descricao}"?`}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => status !== undefined && onEditStatus(status)}
        onClose={() => setModalVisible(false)}
        okButtonName={t(
          'production.pharmaceuticalForm.details.statusTypes.inactive'
        )}
      />
    </div>
  );
};

import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useIncompatibleProductFunctions = ({
  refetchSearchProductPage
}: IUseFunctions) => {
  const onSearch = useCallback((search?: string) => {
    refetchSearchProductPage({
      pageIndex: 0,
      pageSize: 50,
      search: search,
    });
  }, [refetchSearchProductPage]);

  return {
    onSearch
  };
};
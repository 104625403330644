import { 
  FETCH_LIST_STOCK_BALANCE_BY_PRODUCT 
} from '@/ControllerApiHook/UniqIds/Supply/StockBalanceKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { StockBalanceAPI } from '@/Data/API/Supply/StockBalanceApi';
import { IUseCallaPI } from './interfaces';

export const useStockBalanceCallAPI = ({
  externalId
}: IUseCallaPI) => {
  const { 
    data: stockBalanceByProduct, 
    isLoading,
    refetch: refetchStockBalanceByProduct 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STOCK_BALANCE_BY_PRODUCT,
    entityApi: StockBalanceAPI.stockBalanceByProductList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'produtoExternalId', value: externalId }],
    }
  });

  return {
    stockBalanceByProduct,
    isLoading,
    refetchStockBalanceByProduct
  };
};
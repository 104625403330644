import { useControllerQueryApiHook } from "@/ControllerApiHook/Controller";
import { GET_SPECIALTIES } from "@/ControllerApiHook/UniqIds/People/SpecialtiesKeys";
import { SpecialtiesAPI } from "@/Data/API/People/SpecialtiesApi";
import { IGetSpecialtiesResponse } from "@/Data/Interfaces/response/Specialties/ISpecialtiesResponse";
import { useParams } from "react-router";

export const useEditCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();
  
  const { data, isLoading } = useControllerQueryApiHook<IGetSpecialtiesResponse>({
    uniqId: GET_SPECIALTIES,
    entityApi: SpecialtiesAPI.getSpecialties,
    externalId: externalId
  });

  return {
    data,
    isLoading
  };
};
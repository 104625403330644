import { useTranslation } from 'react-i18next';
import { FETCH_GET_CAPSULE_COLORS } from '../../../../../../../ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';
import { IGetCapsuleColor } from '@/Data/Interfaces/response/CapsuleColors/ICapsuleColorsResponse';

export const DynamicInfoEditCapsuleColorBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();

  const { data: capsuleColor } = useControllerQueryApiHook<IGetCapsuleColor>({
    uniqId: FETCH_GET_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.getCurrentCapsuleColor,
    externalId: match.params.externalId
  });

  return (
    <span style={{ display: 'flex' }}>
      {`${t(
        'breadCrumb.production.capsuleColors.capsuleColorsDetailTitle'
      )} | ${capsuleColor?.corCapsula}`}
    </span>
  );
};

export const DynamicCapsuleColorBreadcrumb = ({ match }: any) => {
  const { data: capsuleColor } = useControllerQueryApiHook<IGetCapsuleColor>({
    uniqId: FETCH_GET_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.getCurrentCapsuleColor,
    externalId: match.params.externalId
  });

  return <div style={{ display: 'flex' }}>{capsuleColor?.corCapsula}</div>;
};

import { useMemo } from 'react';
import { ISubGroupsOfGroupData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IUseMemorizations } from './interfaces';
import { BodyHandler, TitleHandler } from '../Modal/deleteModal';
import { IDeleteModalTable } from '@/Components/Table';

export const useProductFormMemorizations = ({
  subGroup,
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    nameKey: 'descricao',
    hasSearch: true,
    searchPlaceholder: t('products.productList.searchPlaceholder'),
    newButton: {
      label: t('products.productList.newButton'),
      onClick: () =>
        history.push(
          `/inventory/products/create?subgroup=${subGroup?.externalId}`
        ),
      permission: {
        permission: 3003,
        tooltip: 'noPermission.supply.products.create',
      },
    },
    deleteButton: {
      permission: {
        permission: 3017,
        tooltip: 'noPermission.supply.subGroups.delete',
      },
    },
    editButton: {
      options: [
        {
          label: t('common.status'),
          isStatusOnly: true,
        },
      ],
      permission: {
        permission: 3016,
        tooltip: 'noPermission.supply.subGroups.edit',
      },
    },
  };

  const rowOptions = {
    onRowDoubleClick: (x: ISubGroupsOfGroupData) =>
      history.push(`/inventory/products/${x.externalId}/details`),
    permission: {
      permission: 3002,
      tooltip: 'noPermission.supply.products.detail',
    },
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 399,
    }),
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
  };
};

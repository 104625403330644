import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useDcbFunctions = ({
  refetchSearchDcbPage
}: IUseFunctions) => {
  const onSearch = useCallback((search?: string) => {
    refetchSearchDcbPage({
      pageIndex: 0,
      pageSize: 10,
      search: search,
    });
  }, [refetchSearchDcbPage]);

  return { onSearch };
};
import { InputWithSearch } from '@/Components';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { IListSupplyPlaceToLaboratoryData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY } from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

interface ISixthRowProps {
  selectSupplyPlace: (supplyPlaceId: string) => void;
}

export const SixthRow: React.FC<ISixthRowProps> = ({ selectSupplyPlace }) => {
  const { t } = useTranslation();

  const {
    data: supplyPlaceList,
    isLoading: loading,
    refetch: refetchSupplyPlaceList,
    fetchNewPage,
  } = useControllerQueryListApiHook({
    uniqId: LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listSupplyPlaceForDropdownToLaboratory,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  });

  return (
    <Row gutter={[16, 0]} style={{ marginTop: '10px' }}>
      <Col span={5}>
        <InputWithSearch
          name="localEstoque"
          label={t('purchasing.invoice.registerLots.stockLocations')}
          required
          isLoading={loading}
          items={
            supplyPlaceList.data
              ? supplyPlaceList.data.map(
                  (supplyPlace: IListSupplyPlaceToLaboratoryData) => ({
                    id: supplyPlace.externalId,
                    label: supplyPlace.descricao,
                  })
                )
              : []
          }
          onSearch={(search) => {
            refetchSupplyPlaceList({
              pageIndex: 0,
              pageSize: 10,
              search: search,
            });
          }}
          onScrollEnd={() => {
            fetchNewPage();
          }}
          placeHolder={t(
            'purchasing.invoice.registerLots.supplyPlacesPlaceholder'
          )}
          onChange={(value) => selectSupplyPlace(value)}
        />
      </Col>
    </Row>
  );
};

import { FC } from "react";
import { Col } from 'antd';
import { CountryDropdown } from '@/Components/Inputs/CountryDropdown';
import { useTranslation } from "react-i18next";
import { useOriginCountryStates } from "./Hooks/useOriginCountryStates";
import { useOriginCountryCallAPI } from "./Hooks/useOriginCountryCallAPI";
import { useOriginCountryMemorizations } from "./Hooks/useOriginCountryMemorizations";
import { useOriginCountryFunctions } from "./Hooks/useOriginCountryFunctions";

export const OriginCountry: FC = () => {
  const { t } = useTranslation();

  const {
    countryPagination,
    setCountryPagination
  } = useOriginCountryStates();
  
  const { paisOrigemList } = useOriginCountryCallAPI({
    countryPagination
  });

  const { items } = useOriginCountryMemorizations({
    paisOrigemList
  });

  const { onScrollEnd } = useOriginCountryFunctions({
    countryPagination,
    setCountryPagination
  });

  return (
    <Col span={5}>
      <CountryDropdown
        name="loteInformacaoTecnica.paisOrigemExternalId"
        placeHolder={t('supply.lot.placeholder.countryOfOrigin')}
        items={items}
        label={t('supply.lot.create.countryOfOrigin')}
        onScrollEnd={onScrollEnd}
        autoIncrementBy="id"
      />
    </Col>
  );
};
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useProductRowFunctions = ({
  refetchSearchProductPage,
  disabledProductList,
  index,
  setDisabledProductList,
  addRow,
  setProductId,
  removeRow
}: IUseFunctions) => {
  const handlerFocusAmount = useCallback((id: string) => {
    const documentFocus = document.getElementById(id);
    if (documentFocus) documentFocus.focus();
  }, []);

  const onSearch = useCallback((search?: string) => {
    refetchSearchProductPage({
      pageIndex: 0,
      pageSize: 50,
      search: search,
    });
  }, [refetchSearchProductPage]);

  const onChange = useCallback((value: string) => {
    const newDisabledProductList = disabledProductList.filter(
      (_: string, i: number) => i !== index
    );
    setDisabledProductList([...newDisabledProductList, value]);
    addRow();
    setProductId(value);
    handlerFocusAmount(`materiaPrimaAssociacao.${index}.quantidade`);
  }, [
    setDisabledProductList,
    setProductId,
    addRow,
    disabledProductList
  ]);

  const onClick = useCallback(() => removeRow(), [removeRow]);

  return {
    handlerFocusAmount,
    onSearch,
    onChange,
    onClick
  };
};
import { useControllerQueryApiHook } from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { IProductGroupResponse } from "@/Data/Interfaces/response/Product/IProductRespose";
import { FETCH_GET_GROUP } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useParams } from 'react-router-dom';

export const useEditCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { 
    data, 
    isLoading 
  } = useControllerQueryApiHook<IProductGroupResponse>({
    uniqId: FETCH_GET_GROUP,
    entityApi: ProductAPI.getProductGroup,
    externalId: externalId
  });

  return {
    data,
    isLoading
  };
};
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { IEditProductMessage } from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';
import { cannotDeleteProductMessagermWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalProductMessage';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useEditFunctions = ({
  editProductMessage,
  productMessage,
  messageProductInitialValue,
  messageProductAlreadyRegistred,
  deleteProductMessage,
  changeVisibleModal,
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const app = App.useApp();

  const onSubmit = async (
    values: IEditProductMessage,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditProductMessage = { ...values, helpers };

    if (productMessage) {
      return await editProductMessage({
        externalId: productMessage.externalId,
        descricao: body.descricao,
        exibeVenda: body.exibeVenda ?? false,
        exibeRotulagem: body.exibeRotulagem ?? false,
        exibeFichaPesagem: body.exibeFichaPesagem ?? false,
        exibeImpressaoRotulo: body.exibeImpressaoRotulo ?? false,
        helpers,
      });
    }
  };

  const fetchProductMessageName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        if (messageProductInitialValue === value) {
          return;
        }
        try {
          return await messageProductAlreadyRegistred(value);
        } catch {
          return undefined;
        }
      }, 500),
    [messageProductAlreadyRegistred]
  );

  const onDelete = useCallback(async () => {
    try {
      if (!productMessage) return;
      return await deleteProductMessage(
        { mensagemExternalIds: [productMessage.externalId] },
        {
          onError: handleModalError(
            app,
            cannotDeleteProductMessagermWithMovementsInTheSystemProps(t)
          ),
        }
      );
    } catch {
      changeVisibleModal && changeVisibleModal(false);
    }
  }, [deleteProductMessage, queryClient, productMessage]);

  return {
    onSubmit,
    fetchProductMessageName,
    onDelete,
  };
};

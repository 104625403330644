import {
  DELETE_DILUTED_PRODUCT,
  FETCH_DILUTED_PRODUCT,
  FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook,
  useControllerQueryApiHook, 
  useControllerQueryListApiHook 
} from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';

export const useDilutedProductCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: productExternalId
  });

  const deleteDilutedProduct: any = useControllerMutateApiHook({
    uniqId: DELETE_DILUTED_PRODUCT,
    entityApi: ProductAPI.deleteDilutedProduct
  });

  const { 
    data: productList, 
    isLoading: isProductListLoading,
    refetch: refetchProductList 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_DILUTED_PRODUCT,
    entityApi: ProductAPI.getDilutedProductList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'produtoExternalId', value: productExternalId }]
    }
  });

  return {
    currentProduct,
    deleteDilutedProduct,
    productList,
    isProductListLoading,
    refetchProductList
  };
};
import { FC } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';
import { useDetailFormCallAPI } from '../Hooks/useDetailFormCallAPI';
import { IDetailForm } from '../interfaces';
import { FormikContextType, useFormikContext } from 'formik';
import { useDetailFormFunctions } from '../Hooks/useDetailFormFunctions';

export const DescriptionRow: FC<IDetailForm> = ({
  disabledForm,
  onDisabledForm,
}) => {
  const form: FormikContextType<any> = useFormikContext();
  const { t } = useTranslation();

  const { mutateAsyncGroupNameAlreadyRegistered } = useDetailFormCallAPI({
    onDisabledForm,
    form,
  });

  const { fetchProductMessageName } = useDetailFormFunctions({
    mutateAsyncGroupNameAlreadyRegistered,
  });

  return (
    <Row justify="space-between">
      <Col span={24}>
        <TextInput
          name="nome"
          label={t('admin.editUserGroups.detailForm.name')}
          placeHolder={t('admin.editUserGroups.detailForm.namePlaceholder')}
          disabled={disabledForm}
          onChange={fetchProductMessageName}
          required
        />
      </Col>
    </Row>
  );
};

import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE,
  GET_LOT_DETAIL_BY_STOCKLOCATION,
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { IUseCreateAPI } from './interfaces';
import { useEffect } from 'react';
import { ILotDetailtByStockLocationResponse } from '@/Data/Interfaces/response/Lot/ILotResponse';

export const useCreateCallAPI = ({ form }: IUseCreateAPI) => {
  const {
    data: lotList,
    isLoading: isLotLoading,
    refetch: refetchLot,
    fetchNewPage: refetchNewPageLot,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE,
    entityApi: LotsAPI.getLotByProductStockLocation,
    autoIncrement: true,
  });

  useEffect(() => {
    form.values.produtoExternalId &&
      form.values.localEstoqueExternalId &&
      refetchLot({
        pageIndex: 0,
        pageSize: 10,
        filter: [
          {
            filterName: 'produtoExternalId',
            value: form?.values.produtoExternalId,
          },
          {
            filterName: 'localEstoqueExternalId',
            value: form?.values.localEstoqueExternalId,
          },
        ],
      });
  }, [
    form.values.produtoExternalId,
    form.values.localEstoqueExternalId,
    refetchLot,
  ]);

  useControllerQueryApiHook<ILotDetailtByStockLocationResponse>({
    uniqId: GET_LOT_DETAIL_BY_STOCKLOCATION,
    entityApi: LotsAPI.getLotDetailstByStockLocation,
    externalId: [
      form?.values.loteExternalId,
      form?.values.localEstoqueExternalId,
    ],
    onSuccess: (value) => {
      form?.setFieldValue('fornecedor', value?.fornecedor);
      form?.setFieldValue('numeroNf', value?.numeroNf);
      form?.setFieldValue('serieNf', value?.serieNf);
      form?.setFieldValue('saldoAtual', value?.saldoLote);
      form?.setFieldValue('novoSaldo', value?.saldoLote);
      form?.setFieldValue(
        'unidadeMedidaId',
        value?.classeProduto?.unidadePadraoVisualizacao
      );
      form?.setFieldValue('quantidade', value ? 0 : undefined);
    },
  });

  return { lotList, isLotLoading, refetchLot, refetchNewPageLot };
};

import { IPagination } from "@/Components/Table";
import { useState } from "react";

export const useOriginCountryStates = () => {
  const [countryPagination, setCountryPagination] = useState<IPagination>({
    pageIndex: 0,
    pageSize: 10
  });

  return {
    countryPagination,
    setCountryPagination
  };
};
import { FC } from "react";
import { Col } from 'antd';
import { Tooltip, TextInput } from '@/Components';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { setInitialValuesFormInternalDiluition } from '../../diluitionsHelpers';
import { IInternalDilutionFactor } from "./interfaces";

export const InternalDilutionFactor: FC<IInternalDilutionFactor> = ({
  lots,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const technicalInfos = lots?.loteInformacaoTecnica;

  return (
    <Col span={6}>
      <Tooltip
        title={`${t(
          'supply.lot.details.toolTips.originalInternalDilutionFactor'
        )} ${
          !!technicalInfos
            ? lots?.loteInformacaoTecnica?.fatorDiluicaoInterna
            : 0
        }`}
        showMe={
          !!technicalInfos &&
          lots?.loteInformacaoTecnica?.fatorDiluicaoInterna !==
            form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna &&
          !disabled
        }
      >
        <TextInput
          name="loteInformacaoTecnica.fatorDiluicaoInterna"
          label={t('supply.lot.details.details.internalDilutionFactor')}
          placeHolder={t(
            'supply.lot.details.details.internalDilutionFactor'
          )}
          disabled={disabled}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('supply.lot.create.toolTips.internalDilutionFactor'),
          }}
          withStatusIcon
          type="number"
          onChange={(x) => setInitialValuesFormInternalDiluition(form)}
        />
      </Tooltip>
    </Col>
  );
};
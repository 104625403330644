import { IPostTransferLot } from '@/Data/Interfaces/request/Transfer/IPostTransferLot';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({ createTransfer }: IUseFunctions) => {
  const onSubmit = async (values: any) => {
    const body: IPostTransferLot = {
      localDeEstoqueDestinoExternalId: values.destiny,
      localDeEstoqueOrigemExternalId: values.origin,
      observacao: values.description,
      transferenciaLoteItens: values.transfers.map((x: any) => ({
        loteExternalId: x.lot,
        produtoExternalId: x.product,
        quantidadeTransferida: x.qntTransfers,
        unidadeMedidaId: x.unidadeMedidaId,
      })),
    };
    return await createTransfer(body);
  };

  return {
    onSubmit,
  };
};

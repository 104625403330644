import { ICreatePharmaceuticalForm } from '@/Data/Interfaces/request/PharmaceuticalForm/IPharmaceuticalFormRequest';
import { IUseFunctions } from './interfaces';
import DateUtils from '@/Utils/DateUtils';

export const useCreateFunctions = ({
  createPharmaceuticalForm,
  datePattern,
}: IUseFunctions) => {
  const onSubmit = async (values: ICreatePharmaceuticalForm) => {
    const body: any = { ...values };

    return await createPharmaceuticalForm({
      ordem: body.order,
      descricao: body.description,
      apresentacao: body.presentation,
      usoFormaFarmaceutica: body.use,
      tipoCalculo: body.calculationType,
      unidadeMedidaId: body.unitMeasurement,
      dataCadastro:
        datePattern?.preferencias &&
        DateUtils.getCurrentDateHour(datePattern?.preferencias?.idioma),
      percentualMinimoExcipiente: body.excipientMinimum,
      validadeDias: body.validityDurationInDays,
      laboratorioExternalId: body.laboratory,
      custoOperacional: body.operationalCost,
      embalagemClassificacaoExternalId:
        body.embalagemClassificacaoExternalId?.map((e: any) => e.externalId) ||
        [],
    });
  };
  return {
    onSubmit,
  };
};

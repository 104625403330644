import { useFormikContext } from "formik";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useProductCardFunctions = ({
  refetchNewPageSubGroupPege,
  setLotControlModal
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const handlerGroupDropdown = useCallback(
    (groupExternalId: string) => {
      refetchNewPageSubGroupPege({
        pageIndex: 0,
        pageSize: 10,
        filter: [
          {
            filterName: 'grupoExternalId',
            value: groupExternalId,
          },
        ],
      });
    },
    [form.values.grupoExternalId]
  );

  const onCancelClick = useCallback(
    () => setLotControlModal(false), [setLotControlModal]
  );

  return {
    handlerGroupDropdown,
    onCancelClick
  };
};
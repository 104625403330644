import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';

import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';
import { IGetProductMessage } from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import {
  DELETE_PRODUCT_MESSAGE,
  EDIT_PRODUCT_MESSAGE,
  FETCH_GET_PRODUCT_MESSAGE,
  FETCH_LIST_PRODUCT_MESSAGE,
  THERE_IS_MESSAGE_PRODUCT_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';

export const useEditCallAPI = ({ externalId, t, form }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: editProductMessage,
    isLoading: isLoadingEditProductMessage,
  }: any = useControllerMutateApiHook({
    uniqId: EDIT_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.editProductMessage,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_PRODUCT_MESSAGE]);
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT_MESSAGE]);
        history.push('/inventory/product-message');
      },
    },
  });

  const { data: productMessage, isLoading: isLoadingProductMessage } =
    useControllerQueryApiHook<IGetProductMessage>({
      uniqId: FETCH_GET_PRODUCT_MESSAGE,
      entityApi: ProductMessageAPI.getCurrentProductMessage,
      externalId: externalId,
    });

  const { mutateAsync: messageProductAlreadyRegistred }: any =
    useControllerMutateApiHook({
      uniqId: THERE_IS_MESSAGE_PRODUCT_TYPE,
      entityApi: ProductMessageAPI.isMessageProductAlreadyRegistred,
      options: {
        onSuccess: () => {
          form?.setErrors({
            ...form?.errors,
            descricao: undefined,
          });
        },
        onError: () => {
          form?.setErrors({
            ...form?.errors,
            descricao: t(
              'supply.productMessage.create.erros.errorMessageNameExist'
            ),
          });
        },
      },
    });

  const {
    mutateAsync: deleteProductMessage,
    isLoading: isLoadingDeleteProductMessage,
  }: any = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.deleteProductMessage,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT_MESSAGE]);
        history.push('/inventory/product-message');
      },
    },
  });

  return {
    editProductMessage,
    isLoadingEditProductMessage,
    productMessage,
    isLoadingProductMessage,
    messageProductAlreadyRegistred,
    deleteProductMessage,
    isLoadingDeleteProductMessage,
  };
};

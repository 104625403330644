import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useAddUserGroupsFunctions = ({
  addUserInUserGroup,
  externalId
}: IUseFunctions) => {
  
  const onSubmit = useCallback(
    (selectedUser?: string[]) => {
      if (!selectedUser || selectedUser.length <= 0) return;

      addUserInUserGroup.mutateAsync({
        usuariosExternalId: selectedUser,
        grupoExternalId: externalId,
      });
    },
    [addUserInUserGroup, externalId]
  );

  return { onSubmit };
};
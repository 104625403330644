import { FC } from 'react';
import { useParams } from 'react-router';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Form } from '../../../../../Components';
import { PharmaceuticalFormDetailsSchema } from './DetailsPharmaceuticalForm.schema';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import InfoCard from './Components/Cards/InfoCard';
import { FinancialCard } from './Components/Cards/FinancialCard';
import { FooterButtons } from './Components/FooterButtons';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const PharmaceuticalFormDetail: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { externalId } = useParams<{ externalId: string }>();

  const {
    editPharmaceuticalForm,
    isLoadingEditPharmaceuticalForm,
    pharmaceuticalForm,
    loading,
  } = useEditCallAPI({ externalId });

  const { onSubmit } = useEditFunctions({
    editPharmaceuticalForm,
    pharmaceuticalForm,
  });

  return (
    <PageLoading loading={loading}>
      <Form
        initialValues={{
          ...pharmaceuticalForm,
          ordem: pharmaceuticalForm?.ordem,
          descricao: pharmaceuticalForm?.descricao,
          apresentacao: pharmaceuticalForm?.apresentacao,
          usoFormaFarmaceutica: pharmaceuticalForm?.usoFormaFarmaceutica,
          tipoCalculo: pharmaceuticalForm?.tipoCalculo,
          unidadeMedidaId: pharmaceuticalForm?.unidadeMedidaId,
          percentualMinimoExcipiente:
            pharmaceuticalForm?.percentualMinimoExcipiente,
          validadeDias: pharmaceuticalForm?.validadeDias,
          laboratorioExternalId: pharmaceuticalForm?.laboratorioExternalId,
          custoOperacional: pharmaceuticalForm?.custoOperacional,
        }}
        onSubmit={onSubmit}
        schema={PharmaceuticalFormDetailsSchema}
      >
        {(props) => (
          <>
            <InfoCard disabled={disabled} onChangeDisabled={onChangeDisabled} />

            <FinancialCard
              pharmaceuticalForm={pharmaceuticalForm}
              disabled={disabled}
            />

            {!disabled && (
              <FooterButtons
                isLoadingEditPharmaceuticalForm={
                  isLoadingEditPharmaceuticalForm
                }
                onChangeDisabled={onChangeDisabled}
                props={props}
              />
            )}
          </>
        )}
      </Form>
    </PageLoading>
  );
};

export default PharmaceuticalFormDetail;

import { Dropdown, Icon, Text, TextInput, Tooltip } from '@/Components';
import { Card } from '@components/Card';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import FormBreakLine from '@/Components/FormBreakLine';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import DateUtils from '@/Utils/DateUtils';
import { CountryDropdown } from '@/Components/Inputs/CountryDropdown';
import { useEffect, useState } from 'react';
import { FETCH_LIST_COUNTRY } from '@/ControllerApiHook/UniqIds/Supply/CountryKeys';
import { IPagination } from '@/Components/Table';
import { IQueryListParams } from '@/Utils/Http';
import { LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY } from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import {
  FETCH_LIST_ALTERNATIVE_UNITS,
  FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { useFormikContext } from 'formik';
import {
  calculateDilution,
  calculateDilutionFactory,
  calculateWaterConcentration,
  calculateWaterConcentrationFactory,
} from '../../utils/functions';
import { Divflex } from '@/Layouts/DivWhithFlex';
import StorageUtils from '@/Utils/StorageUtils';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { DensityInput } from '@/Components/Inputs/DensityInput';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { IListSupplyPlaceToLaboratoryData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { CountryAPI } from '@/Data/API/Supply/CountryApi';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import {
  IIListUnitMeasurementAllData,
  IListAlternativeUnitMeasurementData,
} from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';

interface ITechnicalInformationCardProps {
  selectedProduct: any;
  selectedLotIndex: number;
  defaultSupplyPlace?: string;
  lotErrorNamesIndexes: number[];
  setLotErrorNamesIndexes: (value: number[]) => void;
}

interface ICalendarInputProps {
  name: string;
  placeHolder: string;
  label: string;
  error: boolean;
  errorMessage: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
}

export const TechnicalInformationCard: React.FC<
  ITechnicalInformationCardProps
> = ({
  selectedProduct,
  selectedLotIndex,
  defaultSupplyPlace,
  lotErrorNamesIndexes,
  setLotErrorNamesIndexes,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const preferences = StorageUtils.getPreference();
  const [countryPagination, setCountryPagination] = useState<IPagination>({
    pageIndex: 0,
    pageSize: 10,
  });
  const { data: unitOfMeasure } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 50,
      filter: [
        {
          filterName: 'tipoUnidade',
          value: selectedProduct.tipoUnidade,
        },
      ],
    },
  });

  const { data: paisOrigemList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_COUNTRY,
    entityApi: CountryAPI.listCountry,
    initialPagination: countryPagination,
    autoIncrement: true,
  });

  const [pagination, setPagination] = useState<IQueryListParams>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: supplyPlaceList, isLoading: loading } =
    useControllerQueryListApiHook({
      uniqId: LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
      entityApi: LaboratoryAPI.listSupplyPlaceForDropdownToLaboratory,
      initialPagination: pagination,
    });

  const { data: alternativeUnitList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_ALTERNATIVE_UNITS,
    entityApi: UnitMeasurementAPI.listAlternativeUnitMeasurement,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    autoIncrement: true,
  });

  const validateDates = (
    lotDataIndex: number
  ): {
    error: boolean;
    messageExpirationDate?: string;
    errorReason?: string;
    messageManufacturingDate?: string;
  } => {
    const formManufacturingDate =
      form.values.lotes[lotDataIndex]?.dataFabricacao;
    const formExpirationDate = form.values.lotes[lotDataIndex]?.dataValidade;
    const formReleaseDate = form.values.dataLancamento;
    const formDeliveryDate = form.values.dataEntrega;

    if (formManufacturingDate && formExpirationDate) {
      const manufacturingDate = parseDate(formManufacturingDate);
      const expirationDate = parseDate(formExpirationDate);
      if (manufacturingDate > expirationDate) {
        return {
          error: manufacturingDate > expirationDate,
          messageExpirationDate: t(
            'purchasing.invoice.registerLots.expiryDateError'
          ),
          messageManufacturingDate: t(
            'purchasing.invoice.registerLots.manufacturingDateError'
          ),
          errorReason: 'manufacturingDate',
        };
      }
    }

    if (formDeliveryDate && formManufacturingDate) {
      const manufacturingDate = parseDate(formManufacturingDate);
      const deliveryDate = parseDate(formDeliveryDate);

      if (manufacturingDate > deliveryDate) {
        return {
          error: manufacturingDate > deliveryDate,
          messageManufacturingDate: t(
            'purchasing.invoice.registerLots.releaseDateErrorManufacturing'
          ),
          errorReason: 'manufacturingDate',
        };
      }
    }

    if (formReleaseDate && formExpirationDate) {
      const expirationDate = parseDate(formExpirationDate);
      const releaseDate = parseDate(formReleaseDate);
      if (releaseDate > expirationDate) {
        return {
          error: releaseDate > expirationDate,
          messageExpirationDate: t(
            'purchasing.invoice.registerLots.releaseDateError'
          ),
          errorReason: 'releaseDate',
        };
      }
    }

    return { error: false };
  };

  useEffect(() => {
    if (
      defaultSupplyPlace &&
      !form.values.lotes[selectedLotIndex].localEstoqueExternalId
    ) {
      form.setFieldValue(
        `lotes.${selectedLotIndex}.localEstoqueExternalId`,
        defaultSupplyPlace
      );
    }
  }, [selectedLotIndex, defaultSupplyPlace]);

  return (
    <Card
      withoutCollapse
      title={t('purchasing.invoice.registerLots.lotsDetails')}
    >
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <TextInput
            label={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.product'
            )}
            name="productName"
            disabled
            value={selectedProduct ? selectedProduct.produtoDescricao : ''}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.productClass'
            )}
            name="productClass"
            disabled
            value={
              selectedProduct ? selectedProduct.classeProdutoDescricao : ''
            }
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <WeightInput
            label={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.quantity'
            )}
            name={`lotes.${selectedLotIndex}.quantidade`}
            dropdownRight={{
              options:
                unitOfMeasure && unitOfMeasure.data
                  ? unitOfMeasure.data.map(
                      (e: IIListUnitMeasurementAllData) => ({
                        ...e,
                        content: e.unidadeAbreviacao,
                      })
                    )
                  : [],
              name: `lotes.${selectedLotIndex}.unidadeMedidaId`,
            }}
            placeHolder="0,0000"
            required
          />
        </Col>
        <Col span={12}>
          <TextInput
            label={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.lot'
            )}
            name={`lotes.${selectedLotIndex}.numeroLote`}
            required
            placeHolder={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.lotPlaceHolder'
            )}
            onChange={(lotNumber) => {
              let hasError = false;
              const filteredLots = form.values.lotes.filter((element: any) => {
                return (
                  element.notaFiscalEntradaItemId ===
                  selectedProduct.notaFiscalEntradaItemId
                );
              });
              filteredLots.forEach((lot: any) => {
                if (lot.numeroLote === lotNumber && !!lotNumber) {
                  hasError = true;
                }
              });
              if (hasError) {
                const newLotErrorNamesIndexes: number[] = [
                  ...lotErrorNamesIndexes,
                  selectedLotIndex,
                ];
                setLotErrorNamesIndexes(newLotErrorNamesIndexes);
              } else {
                const newLotErrorNamesIndexes: number[] =
                  lotErrorNamesIndexes.filter((indexLotName) => {
                    return indexLotName !== selectedLotIndex;
                  });
                setLotErrorNamesIndexes(newLotErrorNamesIndexes);
              }
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <CalendarInput
            label={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.manufacturingDate'
            )}
            name={`lotes.${selectedLotIndex}.dataFabricacao`}
            placeHolder={configDatePlaceHolder(preferences) as string}
            error={!!validateDates(selectedLotIndex).messageManufacturingDate}
            errorMessage={
              validateDates(selectedLotIndex).messageManufacturingDate ?? ''
            }
          />
        </Col>
        <Col span={12}>
          <CalendarInput
            label={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.expiryDate'
            )}
            name={`lotes.${selectedLotIndex}.dataValidade`}
            placeHolder={configDatePlaceHolder(preferences) as string}
            error={!!validateDates(selectedLotIndex).messageExpirationDate}
            errorMessage={
              validateDates(selectedLotIndex).messageExpirationDate ?? ''
            }
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={15}>
          <Dropdown
            name={`lotes.${selectedLotIndex}.localEstoqueExternalId`}
            label={t('purchasing.invoice.registerLots.stockLocations')}
            required
            isLoading={loading}
            items={
              supplyPlaceList.data
                ? supplyPlaceList.data.map(
                    (supplyPlace: IListSupplyPlaceToLaboratoryData) => ({
                      id: supplyPlace.externalId,
                      label: supplyPlace.descricao,
                    })
                  )
                : []
            }
            onScrollEnd={() =>
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              })
            }
            placeHolder={t(
              'purchasing.invoice.registerLots.supplyPlacesPlaceholder'
            )}
          />
        </Col>
        <Col span={9}>
          <TextInputNumber
            label={t(
              'purchasing.invoice.registerLots.technicalInformationInputs.labelsQuantity'
            )}
            name={`lotes.${selectedLotIndex}.quantidadeRotulo`}
            placeHolder="0"
          />
        </Col>
      </Row>
      <FormBreakLine />
      {selectedProduct.classeProdutoDescricao === 'Matéria-Prima' && (
        <>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <CountryDropdown
                name={`lotes.${selectedLotIndex}.informacaoTecnica.paisOrigemExternalId`}
                items={paisOrigemList?.data.map((x: any) => ({
                  id: x.externalId,
                  name: x.descricao,
                  flag: x.abreviacao,
                }))}
                label={t(
                  'purchasing.invoice.registerLots.technicalInformationInputs.originCountry'
                )}
                onScrollEnd={() =>
                  setCountryPagination({
                    ...countryPagination,
                    pageIndex: countryPagination.pageIndex + 1,
                  })
                }
                autoIncrementBy="id"
              />
            </Col>
            <Col span={12}>
              <DensityInput
                label={t(
                  'purchasing.invoice.registerLots.technicalInformationInputs.density'
                )}
                name={`lotes.${selectedLotIndex}.informacaoTecnica.densidade`}
                required
                placeHolder="999"
                maxLength={5}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <PercentageInput
                label={t(
                  'purchasing.invoice.registerLots.technicalInformationInputs.dilution'
                )}
                name={`lotes.${selectedLotIndex}.informacaoTecnica.diluicaoFornecedor`}
                required
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t(
                    'purchasing.invoice.registerLots.technicalInformationInputs.tooltips.dilution'
                  ),
                }}
                placeHolder="0%"
                onChange={(e) => {
                  const value = e.replace('%', '');
                  const lot = form.values.lotes[selectedLotIndex];

                  if (!lot.informacaoTecnica) {
                    lot.informacaoTecnica = {};
                  }
                  if (!!value) {
                    const dilutionFactory = calculateDilutionFactory(+value);
                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.fatorDiluicaoFornecedor`,
                      +dilutionFactory
                    );
                  } else {
                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.fatorDiluicaoFornecedor`,
                      ''
                    );
                  }
                }}
              />
            </Col>
            <Col span={12}>
              <NumberInput
                label={t(
                  'purchasing.invoice.registerLots.technicalInformationInputs.dilutionFactory'
                )}
                name={`lotes.${selectedLotIndex}.informacaoTecnica.fatorDiluicaoFornecedor`}
                required
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t(
                    'purchasing.invoice.registerLots.technicalInformationInputs.tooltips.dilutionFactory'
                  ),
                }}
                onChange={(e) => {
                  const value = e;
                  const lot = form.values.lotes[selectedLotIndex];

                  if (!lot.informacaoTecnica) {
                    lot.informacaoTecnica = {};
                  }
                  if (!!value) {
                    const dilution = calculateDilution(+value);
                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.diluicaoFornecedor`,
                      +dilution
                    );
                  } else {
                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.diluicaoFornecedor`,
                      ''
                    );
                  }
                }}
                placeHolder="999"
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <PercentageInput
                label={t(
                  'purchasing.invoice.registerLots.technicalInformationInputs.waterConcentration'
                )}
                name={`lotes.${selectedLotIndex}.informacaoTecnica.concentracaoAgua`}
                required
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t(
                    'purchasing.invoice.registerLots.technicalInformationInputs.tooltips.waterConcentration'
                  ),
                }}
                placeHolder="0%"
                onChange={(e) => {
                  const value = e.replace('%', '').replace(',', '.');
                  const lot = form.values.lotes[selectedLotIndex];
                  if (!lot.informacaoTecnica) {
                    lot.informacaoTecnica = {};
                  }
                  if (!!value) {
                    const waterConcentrationFactory =
                      calculateWaterConcentrationFactory(+value);

                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.fatorConcentracaoAgua`,
                      +waterConcentrationFactory
                    );
                  } else {
                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.fatorConcentracaoAgua`,
                      ''
                    );
                  }
                }}
              />
            </Col>
            <Col span={12} style={{ marginBottom: '20px' }}>
              <NumberInput
                label={t(
                  'purchasing.invoice.registerLots.technicalInformationInputs.waterConcentrationFactory'
                )}
                name={`lotes.${selectedLotIndex}.informacaoTecnica.fatorConcentracaoAgua`}
                required
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t(
                    'purchasing.invoice.registerLots.technicalInformationInputs.tooltips.waterConcentrationFactory'
                  ),
                }}
                placeHolder="999"
                onChange={(e) => {
                  const value = e;
                  const lot = form.values.lotes[selectedLotIndex];
                  if (!lot.informacaoTecnica) {
                    lot.informacaoTecnica = {};
                  }
                  if (!!value) {
                    const waterConcentration = calculateWaterConcentration(
                      +value
                    );
                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.concentracaoAgua`,
                      +waterConcentration
                    );
                  } else {
                    form.setFieldValue(
                      `lotes.${selectedLotIndex}.informacaoTecnica.concentracaoAgua`,
                      ''
                    );
                  }
                }}
              />
            </Col>
          </Row>
          <Card title={t('supply.lot.create.titleConversionRates')} insideCard>
            <Row gutter={[16, 0]} style={{ paddingLeft: '16px' }}>
              <Col span={12}>
                <TextInputNumber
                  name={`lotes.${selectedLotIndex}.informacaoTecnica.loteUnidadeAlternativa1.quantidadeUnidadeAlternativa`}
                  label={[
                    {
                      children: t('supply.lot.create.alternativeUnitOne'),
                    },
                  ]}
                  placeHolder={'999'}
                  dropdownLeft={{
                    name: `lotes.${selectedLotIndex}.informacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaId`,
                    options:
                      alternativeUnitList?.data.map(
                        (x: IListAlternativeUnitMeasurementData) => ({
                          id: x.id,
                          content: x.unidadeAbreviacao,
                        })
                      ) || [],
                  }}
                  dropdownRight={{
                    options:
                      unitOfMeasure && unitOfMeasure.data
                        ? unitOfMeasure.data.map(
                            (e: IIListUnitMeasurementAllData) => ({
                              ...e,
                              content: '/' + e.unidadeAbreviacao,
                            })
                          )
                        : [],
                    name: `lotes.${selectedLotIndex}.informacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaConversaoId`,
                  }}
                  hideDropdownArrows
                />
              </Col>
              <Col span={12}>
                <TextInputNumber
                  name={`lotes.${selectedLotIndex}.informacaoTecnica.loteUnidadeAlternativa2.quantidadeUnidadeAlternativa`}
                  label={[
                    {
                      children: t('supply.lot.create.alternativeUnitTwo'),
                    },
                  ]}
                  hideDropdownArrows
                  placeHolder={'999'}
                  dropdownLeft={{
                    name: `lotes.${selectedLotIndex}.informacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaId`,
                    options:
                      alternativeUnitList?.data.map(
                        (x: IListAlternativeUnitMeasurementData) => ({
                          id: x.id,
                          content: x.unidadeAbreviacao,
                        })
                      ) || [],
                  }}
                  dropdownRight={{
                    options: unitOfMeasure
                      ? unitOfMeasure.map(
                          (e: IIListUnitMeasurementAllData) => ({
                            ...e,
                            content: '/' + e.unidadeAbreviacao,
                          })
                        )
                      : [],
                    name: `lotes.${selectedLotIndex}.informacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaConversaoId`,
                  }}
                />
              </Col>
            </Row>
          </Card>
        </>
      )}
      {selectedProduct.classeProdutoDescricao !== 'Matéria-Prima' && (
        <Divflex>
          <Icon
            icon={'exclamation-upside-down'}
            size={'SM'}
            color={'text-400'}
          />
          <Text
            type="paragraph2"
            color={'text-400'}
            style={{
              marginLeft: '6px',
              marginTop: '-1px',
              cursor: 'pointer',
            }}
          >
            {t('supply.lot.card.notRawMaterialCard')}
          </Text>
        </Divflex>
      )}
    </Card>
  );
};

const configDatePlaceHolder = (datePattern: any) => {
  if (datePattern) {
    return DateUtils.getDatePlaceholder(
      datePattern.idioma,
      datePattern.padraoData
    );
  }
};

const parseDate = (date: string) => {
  const parts = date.split('/');
  return new Date(+parts[2], +parts[1] - 1, +parts[0]);
};

const CalendarInput = ({
  name,
  placeHolder,
  label,
  error,
  errorMessage,
  onClick,
  onChange,
}: ICalendarInputProps) => {
  return (
    <div onClick={() => onClick?.()}>
      <Tooltip title={errorMessage} showMe={error}>
        <TextInputWithMask
          name={name}
          leftIcon="calendar"
          placeHolder={placeHolder}
          required
          label={label}
          mask="dataMask"
          error={error}
          onChange={(value) => onChange?.(value)}
        />
      </Tooltip>
    </div>
  );
};

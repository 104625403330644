import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CREATE_SERVICE,
  FETCH_LIST_SERVICE,
  FETCH_LIST_SERVICE_CHANNEL,
} from '@/ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { history } from '@/Store';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import { IUseListCallAPI } from './interfaces';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';
import { useEffect } from 'react';

export const useListCallAPI = ({
  externalId,
  filterName,
  setVisibleCreateModal,
}: IUseListCallAPI) => {
  const queryClient = useQueryClient();

  const {
    data: serviceList,
    isLoading: serviceListIsLoading,
    refetch: refetchServiceList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SERVICE,
    entityApi: ServiceAPI.listService,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      ...(filterName && { filter: [{ filterName, value: externalId }] }),
    },
  });

  useEffect(() => {
    refetchServiceList({
      pageIndex: 0,
      pageSize: 10,
      ...(filterName && { filter: [{ filterName, value: externalId }] }),
    });
  }, [externalId, refetchServiceList, filterName]);

  const createService = useControllerMutateApiHook({
    uniqId: CREATE_SERVICE,
    entityApi: ServiceAPI.createService,
    options: {
      onSuccess: (success: any) => {
        setVisibleCreateModal(false);
        queryClient.invalidateQueries([FETCH_LIST_SERVICE]);
        history.push(`/sales/tickets/${success.externalId}/order`);
        messageSucessToast(i18n.t('callCenter.messages.create'));
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const { data: serviceChannel } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SERVICE_CHANNEL,
    entityApi: ServiceAPI.listServiceChannel,
  });

  return {
    serviceList,
    serviceListIsLoading,
    refetchServiceList,
    createService,
    serviceChannel,
  };
};

import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { DELETE_BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationLinkedPharmaceuticalFormKeys';
import { PackingClassificationLinkedPharmaceuticalFormAPI } from '@/Data/API/Production/PackingClassificationPharmaceuticalForm';
import { useEffect } from 'react';
import { IUseCallAPI } from './interfaces';
import { isNil } from 'lodash';

export const useListCallAPI = ({ externalId }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    data: packingClassificationList,
    isLoading,
    refetch: refetchPackingClassificationList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM,
    entityApi:
      PharmaceuticalFormAPI.getPackingClassificationLinkedPharmaceuticalFormList,
    enabled: !isNil(externalId),
  });

  useEffect(() => {
    refetchPackingClassificationList({
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'externalId', value: externalId }],
    });
  }, [externalId, refetchPackingClassificationList]);

  const { mutateAsync: removePacking } = useControllerMutateApiHook({
    uniqId: DELETE_BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM,
    entityApi:
      PackingClassificationLinkedPharmaceuticalFormAPI.deletePackingClassificationLinkedPharmaceuticalFormRequest,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([
          FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM,
        ]),
    },
  });

  return {
    packingClassificationList,
    isLoading,
    removePacking,
    refetchPackingClassificationList,
  };
};

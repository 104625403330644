import { Checkbox, TextInput, Tooltip } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import styles from '../../../CapsuleColors.module.scss';
import { IFChildren } from '@/Components/Form';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { useEditFunctions } from '../../../Hooks/useEditFunctions';
import { useParams } from 'react-router';

interface IDescriptionRow {
  disabled: boolean;
  capsuleCor?: string;
  props: IFChildren;
}

export const DescriptionRow: FC<IDescriptionRow> = ({
  disabled,
  capsuleCor,
  props,
}) => {
  const { externalId } = useParams<{ externalId: string }>();

  const { t } = useTranslation();

  const { mutateAsync } = useEditCallAPI({ t, props, externalId });

  const { fetchNameCapsuleColor } = useEditFunctions({ mutateAsync, t });

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={12}>
        <Tooltip
          title={`${t(
            'production.capsuleColors.details.tooTips.description'
          )} ${capsuleCor}`}
          showMe={!disabled && capsuleCor !== props.values.corCapsula}
        >
          <TextInput
            className={styles['text-input']}
            name="corCapsula"
            label={t('production.capsuleColors.details.name')}
            placeHolder={t('production.capsuleColors.details.namePlaceholder')}
            required
            withoutMarginBottom
            disabled={disabled}
            withStatusIcon
            onChange={fetchNameCapsuleColor}
          />
        </Tooltip>
      </Col>

      <Col span={6} className={styles['checkbox']}>
        <Checkbox
          name="transparente"
          label={t('production.capsuleColors.details.transparentCapsule')}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

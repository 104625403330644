import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeleteClientWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalClient';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import DateUtils from '@/Utils/DateUtils';
import { IUseFunctions } from './interfaces';

export const useModalFunctions = ({
  clientExternalId,
  deleteClient,
  preferences,
  editInfo,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onOkClickDeleteModal = useCallback(async () => {
    if (clientExternalId)
      await deleteClient?.mutateAsync(
        {
          clientesExternalId: [clientExternalId],
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteClientWithMovementsInTheSystemProps(t)
          ),
        }
      );
  }, [deleteClient, clientExternalId, t, app]);

  const onOkClickInfoModal = useCallback(
    async (x: any) => {
      if (preferences)
        await editInfo?.mutateAsync({
          ...x,
          dataNascimento: DateUtils.convertDateToApi(
            x.dataNascimento,
            preferences.padraoData
          ),
        });
    },
    [editInfo, preferences]
  );

  return { onOkClickDeleteModal, onOkClickInfoModal };
};

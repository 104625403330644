import { isNil } from 'lodash';
import { 
  FETCH_LIST_UNITS_BY_CLASS 
} from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import {  
  useControllerQueryListApiHook
 } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IUseCallAPI } from './interfaces';

export const useAmountDefaultCallAPI = ({
  classificationProductId
}: IUseCallAPI) => {
  const { 
    data: listunitsMeasurements 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10
    },
    autoIncrement: true,
    enabled: !isNil(
      classificationProductId
    ),
  });

  return {
    listunitsMeasurements
  };
};
import { ICreateStorageLocation } from '@/Data/Interfaces/request/StorageLocation/IStorageLocationRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import { useMemo } from 'react';
import _ from 'lodash';

export const useCreateFunctions = ({
  createStorageLocation,
  selectedOptionsStock,
  fetchStorageLocationDescription,
}: IUseFunctions) => {
  const onSubmit = async (
    values: ICreateStorageLocation,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: ICreateStorageLocation = { ...values, helpers };
    return await createStorageLocation({
      empresaId: body?.empresaId,
      descricao: body?.descricao,
      ativo: true,
      tipoEstoque: selectedOptionsStock,
      helpers,
    });
  };

  const fetchStorageLocationName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await fetchStorageLocationDescription(value);
        } catch {
          return undefined;
        }
      }, 500),
    [fetchStorageLocationDescription]
  );

  return {
    onSubmit,
    fetchStorageLocationName,
  };
};

import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { ModalBody } from './Body';
import { ICreateServiceModal } from './interfaces';
import { useTranslation } from 'react-i18next';

export const CreateServiceModal: FC<ICreateServiceModal> = ({
  changeVisible,
  visible,
  clienteExternalId,
  onSubmit,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      onOkClickForm={onSubmit}
      title={t('callCenter.createService.newService')}
      body={<ModalBody />}
      visible={visible}
      onCancelClick={() => changeVisible(!visible)}
      htmlType="submit"
      loading={isLoading}
      onClose={() => changeVisible(!visible)}
      withForm
      minWidth={475}
      disabledButtonFormNames={['clienteExternalId', 'canalAtendimentoId']}
      initialValues={{ clienteExternalId }}
      noLeaveWithoutLeaving
      dataTestIdOkButton="create-button-ok"
      okButtonName={t('common.create')}
    />
  );
};

import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  InputWithSearch,
  TextInput,
} from '../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '../../../../../../../../Components/Inputs/CurrencyInput';
import { WeightInput } from '../../../../../../../../Components/Inputs/WeightInput';
import {
  FETCH_LIST_DROPDOWN_CFOP,
  FETCH_LIST_DROPDOWN_CST,
  FETCH_LIST_DROPDOWN_NCM,
} from '../../../../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import {
  configCfopList,
  configCstList,
  configNcmList,
} from '../../../../Create/utils/functions';
import {
  FETCH_GET_PRODUCT,
  FETCH_LIST_FOR_DROPDOWN_TYPE,
} from '../../../../../../../../ControllerApiHook/UniqIds/Supply/ProductKeys';
import { FETCH_LIST_UNITS_BY_CLASS } from '../../../../../../../../ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { useFormikContext } from 'formik';
import DeleteProductModal from '../../../../Create/Sections/ProductSection/modal/DeleteProductModal';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  IProductGroupListData,
  IProductResponse,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { isNil } from 'lodash';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IListUnitMeasurementByClassData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';

interface IProductRow {
  index: number;
  cardId: string;
  addNewRow: (number: number) => void;
  scrollContainerId: string;
  totalRows: number;
  removeRow: (index: number) => void;
  updateProductsValue: (number: number) => void;
}

export const ProductRow: React.FC<IProductRow> = ({
  index,
  cardId,
  scrollContainerId,
  addNewRow,
  totalRows,
  removeRow,
  updateProductsValue,
}) => {
  const { t } = useTranslation();
  const [currentProduct, setCurrentProduct] = React.useState<any>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const form: any = useFormikContext();
  const [forceRerender, setForceRerender] = useState(false);

  const { data: listunitsMeasurements, isLoading: isUnitOfMeasureLoading } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
      initialPagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      autoIncrement: true,
      enabled: !isNil(currentProduct ? currentProduct.classeProdutoId : ''),
    });

  const { data: unitsMeasurementsDefault } =
    useControllerQueryApiHook<IProductResponse>({
      uniqId: FETCH_GET_PRODUCT,
      entityApi: ProductAPI.getProduct,
      externalId: form.values.produtoExternalId,
    });

  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  const unitOfMeasureDropdown = listunitsMeasurements
    ? {
        name: 'unidadeMedidaId',
        loading: isUnitOfMeasureLoading,
        defaultValue: unitMeasureIdDefault,
        options:
          listunitsMeasurements?.map((x: IListUnitMeasurementByClassData) => ({
            id: x.id,
            content: x.unidadeAbreviacao,
          })) || [],
      }
    : undefined;

  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: { pageIndex: 0, pageSize: 50 },
  });

  const {
    data: ncmList,
    isLoading: isLoadingNcm,
    fetchNewPage: fetchNewNcmPage,
    refetch: refetchSearchNcmPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_NCM,
    entityApi: InvoiceAPI.listDropdownNcm,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true,
  });

  const {
    data: cstList,
    isLoading: isLoadingCst,
    fetchNewPage: fetchNewCstPage,
    refetch: refetchSearchCstPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CST,
    entityApi: InvoiceAPI.listDropdownCst,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true,
  });

  const {
    data: cfopList,
    isLoading: isLoadingCfop,
    fetchNewPage: fetchNewCfopPage,
    refetch: refetchSearchCfopPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CFOP,
    entityApi: InvoiceAPI.listDropdownCfop,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    autoIncrement: true,
  });

  const getTotalProduct = () => {
    if (!form.values.itens) return '';
    const unitValue = form.values.itens[index]?.valorUnitario;
    const quantity = form.values.itens[index]?.quantidadeComprada;
    if (unitValue && quantity) {
      const result = unitValue * quantity;
      return result.toString().replace('.', ',');
    } else {
      return '';
    }
  };

  const getTotalProducts = () => {
    if (form.values.itens) {
      let total = 0;

      form.values.itens.forEach((item: any) => {
        const unitValue = item.valorUnitario ?? 0;
        const quantity = item.quantidadeComprada ?? 0;
        total = total + unitValue * quantity;
      });

      return total;
    }
    return 0;
  };

  const productListFormatted = productList.data
    ? productList.data
    : productList;

  useEffect(() => {
    if (currentProduct) {
      form.values.itens[index].unidadeMedidaId = currentProduct.unidadeMedidaId;
    }
  }, [currentProduct]);

  useEffect(() => {
    if (form.values.itens) {
      let total = getTotalProducts();
      updateProductsValue(total);
    }
  }, [forceRerender]);

  useEffect(() => {
    if (productList) {
      if (!form.values?.itens || form.values?.itens.length < 1) return;
      const formProduct = form.values?.itens[index];
      let selectedProduct;

      if (formProduct && formProduct.produtoExternalId) {
        selectedProduct = productListFormatted.filter(
          (e: IProductGroupListData) =>
            e.externalId === formProduct.produtoExternalId
        )[0];
      }

      if (selectedProduct) {
        setCurrentProduct(selectedProduct);
      }
    }
  }, [productList]);
  console.log(productList);
  return (
    <>
      <DeleteProductModal
        visible={modalVisible}
        changeVisibleModal={(value) => setModalVisible(value)}
        product={currentProduct?.descricao}
        deleteRow={() => {
          if (+getTotalProduct() > 0) {
            const total = getTotalProducts();
            updateProductsValue(total - +getTotalProduct());
          }
          removeRow(index);
        }}
      />
      <Row wrap={false} gutter={[16, 0]} style={{ position: 'relative' }}>
        <Col
          span={2}
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 1,
            background: 'var(--color-background-01)',
          }}
        >
          <TextInput
            label={
              index === 0 ? t('purchasing.invoice.create.productCode') : ''
            }
            disabled
            name={`itens.${index}.codProduto`}
            required
            withStatusIcon
            value={currentProduct?.sequenciaGroupTenant.toString()}
          />
        </Col>
        <Col
          span={4}
          style={{
            position: 'sticky',
            left: '125px',
            zIndex: 1,
            background: 'var(--color-background-01)',
          }}
        >
          <InputWithSearch
            label={index === 0 ? t('purchasing.invoice.create.product') : ''}
            actionOnFocus={() => {
              const element = document.getElementById(scrollContainerId)!;
              element.scroll(0, 0);
            }}
            name={`itens.${index}.produtoExternalId`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.searchForProduct'
            )}
            getPopupContainerId={cardId}
            withStatusIcon
            items={productListFormatted.map((x: IProductGroupListData) => ({
              id: x?.externalId,
              label: x?.descricao,
              subLabel: `${t(
                'purchasing.purchaseOrder.create.subLabelProduct'
              )} ${x.sequenciaGroupTenant}`,
            }))}
            onScrollEnd={fetchNewProductPage}
            onChange={(product) => {
              const currentProduct = productList.filter(
                (e: IProductGroupListData) => e.externalId === product
              )[0];
              setCurrentProduct(currentProduct);
              addNewRow(index);
            }}
            onSearch={(search?: string) => {
              refetchSearchProductPage({
                pageIndex: 0,
                pageSize: 50,
                search: search,
              });
            }}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            label={index === 0 ? t('purchasing.invoice.create.NCM') : ''}
            name={`itens.${index}.ncmId`}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.NCMPlaceHolder'
            )}
            withStatusIcon
            isLoading={isLoadingNcm}
            onScrollEnd={fetchNewNcmPage}
            onSearch={(search?: string) =>
              refetchSearchNcmPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              })
            }
            items={configNcmList(ncmList)}
            onChange={() => addNewRow(index)}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            label={index === 0 ? t('purchasing.invoice.create.CST') : ''}
            name={`itens.${index}.cstCsosnId`}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CSTPlaceHolder'
            )}
            withStatusIcon
            isLoading={isLoadingCst}
            onScrollEnd={fetchNewCstPage}
            onSearch={(search?: string) => {
              refetchSearchCstPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              });
            }}
            items={configCstList(cstList)}
            onChange={() => addNewRow(index)}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            label={index === 0 ? t('purchasing.invoice.create.CFOP') : ''}
            name={`itens.${index}.cfopId`}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CFOPPlaceHolder'
            )}
            withStatusIcon
            isLoading={isLoadingCfop}
            onScrollEnd={fetchNewCfopPage}
            onSearch={(search?: string) => {
              refetchSearchCfopPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              });
            }}
            items={configCfopList(cfopList)}
            onChange={() => addNewRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.unitValue') : ''}
            name={`itens.${index}.valorUnitario`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
            onChange={() => {
              addNewRow(index);
              setForceRerender((prev) => !prev);
            }}
          />
        </Col>
        <Col span={3}>
          <WeightInput
            name={`itens.${index}.quantidadeComprada`}
            label={index === 0 ? t('purchasing.invoice.create.quantity') : ''}
            required={true}
            placeHolder="0,0000"
            withStatusIcon
            onChange={() => {
              addNewRow(index);
              setForceRerender((prev) => !prev);
            }}
            dropdownRight={{
              options: unitOfMeasureDropdown
                ? unitOfMeasureDropdown.options
                : [],
              name: `itens.${index}.unidadeMedidaId`,
            }}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.total') : ''}
            name={`itens.${index}.total`}
            required
            disabled
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
            value={getTotalProduct()}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.calcICMS') : ''}
            name={`itens.${index}.baseCalculoIcms`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
            onChange={() => addNewRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.ICMSVal') : ''}
            name={`itens.${index}.valorIcms`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
            onChange={() => addNewRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.IPIVal') : ''}
            name={`itens.${index}.valorIpi`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
            onChange={() => addNewRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.aliquota') : ''}
            name={`itens.${index}.aliquotaIpi`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
            onChange={() => addNewRow(index)}
          />
        </Col>
        <Col>
          <Button
            style={{ marginTop: `${index === 0 ? '20px' : 0}` }}
            type="secondary"
            disabled={totalRows - 1 === index}
            onClick={() => setModalVisible(true)}
          >
            <Icon
              icon="trash"
              size="M"
              color={
                totalRows - 1 === index ? 'status-disabled-base' : undefined
              }
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

import { useParams } from 'react-router-dom';
import { FETCH_GET_LOT } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

export const useInfoCardCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: lots } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalId: externalId
  });

  return { lots };
};
import {
  EDIT_FINANCIAL_PRODUCT,
  FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { 
  IProductResponse 
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { useParams } from 'react-router';

export const useFinanceCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const editFinancialProduct: any = useControllerMutateApiHook({
    uniqId: EDIT_FINANCIAL_PRODUCT,
    entityApi: ProductAPI.editProductFinancial
  });

  const { data: product } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: externalId
  });

  return {
    editFinancialProduct,
    product
  };
};
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '../../../../../../../../../Components/Inputs/CurrencyInput';

interface IFirstRowProps {
  importedProducts: any[];
}

const FirstRow: React.FC<IFirstRowProps> = ({ importedProducts }) => {
  const { t } = useTranslation();

  const [, setRerender] = useState(false);

  useEffect(() => {
    setRerender((prev) => !prev);
  }, [importedProducts]);
  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSBase')}
          name="valorBaseIcms"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSValue')}
          name="valorIcms"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSBaseSubstitution')}
          name="valorBaseIcmsSubstituicao"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSValueSubstitution')}
          name="valorIcmsSubstituicao"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.totalProducts')}
          name="valorProdutos"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
          disabled
        />
      </Col>
    </Row>
  );
};

export default FirstRow;

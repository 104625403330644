import { Col, Row } from 'antd';
import { FC } from 'react';
import {
  Dropdown,
  TextArea,
  TextInput,
} from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { IGetInfoSupplierResponse } from '../../../../../../../../../Data/Interfaces/response/Purchase/ISupplierResponse';
import { TypePersonEnum } from '../../../../../../../../../Data/Interfaces/model/typePersonEnum';
import { TextInputWithMask } from '../../../../../../../../../Components/Inputs/TextInputWithMask';
import { IProviderTypeResponse } from '@/Data/Interfaces/response/Person/ProviderTypeResponse';
import { useEditCallAPI } from '../../../../Hooks/useEditCallAPI';
import { useParams } from 'react-router';

export interface IEditInfoModalInterface {
  supplier: IGetInfoSupplierResponse;
}

export const EditModalInfoBody: FC<IEditInfoModalInterface> = ({
  supplier,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { providerTypes } = useEditCallAPI({ externalId });

  return (
    <>
      <div style={{ padding: '20px' }}>
        <Row gutter={[16, 0]} justify="start">
          <Col span={12}>
            <Dropdown
              name="tipoFornecedorId"
              items={
                providerTypes?.data?.map((x: IProviderTypeResponse) => ({
                  id: x.id,
                  label: x.name,
                })) || []
              }
              label={t(
                'purchasing.provider.details.cards.infoCard.supplierType'
              )}
              required
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]} justify="start">
          <Col span={10}>
            <TextInput
              name="nome"
              label={
                supplier?.tipoPessoa === TypePersonEnum.juridical_person
                  ? t('purchasing.provider.details.cards.infoCard.fantasyName')
                  : t('purchasing.provider.details.cards.infoCard.fullName')
              }
              required
            />
          </Col>
          {supplier?.tipoPessoa === TypePersonEnum.juridical_person && (
            <Col span={8}>
              <TextInput
                name="razaoSocial"
                label={t(
                  'purchasing.provider.details.cards.infoCard.corporateName'
                )}
                required
              />
            </Col>
          )}
          {supplier?.tipoPessoa === TypePersonEnum.juridical_person ? (
            <Col span={6}>
              <TextInputWithMask
                name="cnpj"
                label={t('purchasing.provider.details.cards.infoCard.cnpj')}
                required
                mask="cnpjMask"
              />
            </Col>
          ) : (
            <Col span={6}>
              <TextInputWithMask
                name="cpf"
                label={t('purchasing.provider.details.cards.infoCard.cpf')}
                required
                mask="cpfMask"
              />
            </Col>
          )}
        </Row>
        <Row gutter={[16, 0]} justify="start">
          <Col span={24}>
            <TextArea
              name="observacao"
              label={t(
                'purchasing.provider.details.cards.infoCard.observations'
              )}
              maxLength={125}
              heightTextArea={100}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

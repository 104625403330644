import { useTranslation } from 'react-i18next';
import { 
  FETCH_GET_PRODUCT_MESSAGE
} from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { TextShortner } from '@/Components/TextShortner';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetProductMessage } from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';

export const DynamicEditProductMessageTitleBreadcrumb = ({ match }: any) => {
  const { data: productMessage } = useControllerQueryApiHook<IGetProductMessage>({
    uniqId: FETCH_GET_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.getCurrentProductMessage,
    externalId: match.params.externalId
  });

  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('breadCrumb.messageProduct.productMensage')} | `}
      <TextShortner
        maxCharacters={42}
        children={productMessage ? productMessage?.descricao : ''}
        type="heading3-bold"
      />
    </div>
  );
};

export const DynamicEditProductMessageSubTitleBreadcrumb = ({ match }: any) => {
  const { data: productMessage } = useControllerQueryApiHook<IGetProductMessage>({
    uniqId: FETCH_GET_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.getCurrentProductMessage,
    externalId: match.params.externalId
  });

  return (
    <div style={{ display: 'flex' }}>
      <TextShortner
        maxCharacters={42}
        children={productMessage ? productMessage?.descricao : ''}
        type="ui-tiny-bold"
      />
    </div>
  );
};

export const DynamicEditProductMessageTabSubTitleBreadcrumb = ({
  match,
}: any) => {
  const { data: productMessage } = useControllerQueryApiHook<IGetProductMessage>({
    uniqId: FETCH_GET_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.getCurrentProductMessage,
    externalId: match.params.externalId
  });

  return (
    <div style={{ display: 'flex' }}>
      <TextShortner
        maxCharacters={42}
        children={productMessage ? productMessage?.descricao : ''}
        type="ui-tiny-bold"
      />
    </div>
  );
};

import { Card } from '../../../../../../../Components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './productSection.module.scss';
import { v4 as uuidV4 } from 'uuid';
import { ProductRow } from './ProductRow';
import { useFormikContext } from 'formik';

interface IProduct {
  products: any[];
  setTotalProducts: (number: number) => void;
  importedProducts: any[];
  setImportedProducts: any;
}

export const ProductSection: React.FC<IProduct> = ({
  products,
  setTotalProducts,
  importedProducts,
  setImportedProducts,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const [productRows, setProductRows] = useState<any>([{ uuid: uuidV4() }]);
  const cardId = 'productSection';
  const scrollContainerId = 'scrollContainer';

  //add products from get
  useEffect(() => {
    const newRows = [
      ...products.map((_) => {
        return { uuid: uuidV4() };
      }),
      { uuid: uuidV4() },
    ];
    setProductRows(newRows);
  }, [products]);

  //add new product row
  const addNewRow = (number: number) => {
    if (number === productRows.length - 1) {
      setProductRows((oldState: any) => {
        return [...oldState, { uuid: uuidV4() }];
      });
    }
  };

  //remove product row
  const removeRow = (index: number) => {
    if (!form.values.itens) return;
    form.values.itens = form.values.itens.filter(
      (_: any, i: number) => i !== index
    );

    setProductRows((prev: any) =>
      prev.filter((_: any, i: number) => i !== index)
    );
  };

  //add imported products
  useEffect(() => {
    if (importedProducts.length > 0) {
      importedProducts.forEach((product: any, index: number) => {
        form.values.itens.push({
          ...product,
          codProduto: product.codigoProduto,
          quantidadeComprada: product.quantidade,
        });
        setProductRows((oldState: any) => {
          return [...oldState, { uuid: uuidV4() }];
        });
      });

      setImportedProducts([]);
    }
  }, [importedProducts]);

  return (
    <Card
      id={cardId}
      title={t('purchasing.invoice.create.products')}
      withoutCollapse
    >
      <div
        id={scrollContainerId}
        style={{ overflowX: 'scroll' }}
        className={style.customScrollbar}
      >
        {productRows.map((row: any, index: number) => {
          return (
            <ProductRow
              scrollContainerId={scrollContainerId}
              key={row.uuid}
              index={index}
              cardId={cardId}
              addNewRow={addNewRow}
              totalRows={productRows.length}
              removeRow={removeRow}
              updateProductsValue={setTotalProducts}
            />
          );
        })}
      </div>
    </Card>
  );
};

import { ManyAvatars } from '@/Components/ManyAvatars';
import { IListUserData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { Text } from '@/Components';
import React from 'react';
import { TFunction } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

export const deleteModalTitle = (
  selectedRowsOnTable: IListUserData[],
  t: TFunction<'translation', undefined>
) => {
  return selectedRowsOnTable.length === 1
    ? `${t('admin.userList.deleteUserModal.title.delete')} "${
        selectedRowsOnTable[0]?.nome
      } ${selectedRowsOnTable[0]?.sobrenome}"?`
    : `${t('admin.userList.deleteUserModal.title.delete')} ${
        selectedRowsOnTable.length
      } ${t('admin.userList.deleteUserModal.title.users')}?`;
};

export const deleteModalBody = (
  selectedRowsOnTable: IListUserData[],
  t: TFunction<'translation', undefined>
) => (
  <div style={{ padding: '20px' }}>
    <ManyAvatars
      users={selectedRowsOnTable.map((user) => ({
        name: user.nome,
        lastName: user.sobrenome,
      }))}
    ></ManyAvatars>
    <>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Text type="ui-tiny-content" color="text-50" withoutMarginBottom>
          <ReactMarkdown>
            {selectedRowsOnTable.length > 1
              ? t('admin.userList.deleteUserModal.bodyDescriptionManyUsers', {
                  amount: selectedRowsOnTable.length,
                })
              : t('admin.userList.deleteUserModal.bodyDescription', {
                  user: `**${selectedRowsOnTable[0]?.nome} ${selectedRowsOnTable[0]?.sobrenome}**`,
                })}
          </ReactMarkdown>
        </Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={t('modal.deleteModal.simpleFooter')}
        />
      </div>
    </>
  </div>
);

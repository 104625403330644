import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { CapsuleSizeAPI } from "@/Data/API/Production/CapsuleSize";
import { FETCH_LIST_DROPDOWN_CAPSULE_SIZE } from "@/ControllerApiHook/UniqIds/Production/CapsuleSizeKeys";

export const useCapsuleNumberCallAPI = () => {
  const {
    data: listCapsuleSize,
    isLoading: isLoadingCapsuleSize,
    fetchNewPage: fetchNewPageCapsuleSize,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CAPSULE_SIZE,
    entityApi: CapsuleSizeAPI.listDropdownCapsuleSize,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  return {
    listCapsuleSize,
    isLoadingCapsuleSize,
    fetchNewPageCapsuleSize
  };
};
import { TFunction } from 'i18next';
import { Text } from '@/Components';

export const productSynonymColumns = (t: TFunction) => {
  return [
    {
      title: t('products.synonym.synonym'),
      dataIndex: 'sinonimo',
      key: 'sinonimo',
      width: '60%',
      render: (x: string, row: any) => {
        return (
          <Text type="ui-tiny-bold" color="text-50">
            {row.sinonimo}
          </Text>
        );
      },
    },
    {
      title: t('products.synonym.equivalenceFactor'),
      dataIndex: 'fatorEquivalencia',
      key: 'fatorEquivalencia',
      width: '20%',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-bold" color="text-50">
          {row.fatorEquivalencia}
        </Text>
      ),
    },
    {
      title: t('products.synonym.correctionPercentage'),
      dataIndex: 'percentualCorrecao',
      key: 'percentualCorrecao',
      width: '20%',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-bold" color="text-50">
          {row.percentualCorrecao}%
        </Text>
      ),
    },
  ];
};

import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { IFChildren } from '@/Components/Form';

interface IFooterButtons {
  isLoadingCreateTransfer: boolean;
  props: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateTransfer,
  props,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex' }}>
      <Button
        type="secondary"
        children={t('supply.transfers.actions.cancel')}
        style={{ marginRight: '10px' }}
        onClick={() => history.push('/inventory/transfers')}
      />
      <Button
        type="primary"
        children={
          isLoadingCreateTransfer
            ? t('supply.transfers.actions.registerLoading')
            : t('supply.transfers.actions.register')
        }
        htmlType="submit"
        loading={isLoadingCreateTransfer}
        disabled={!props.values.origin || !props.values.destiny}
      />
    </div>
  );
};

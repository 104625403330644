import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { useParams } from 'react-router-dom';
import { DeleteLotModal } from './Modal';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditStates } from './Hooks/useEditStates';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useEditFunctions } from './Hooks/useEditFunctions';

export const EditLot: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { externalId } = useParams<{ externalId: string }>();

  const {
    disabled,
    setDisabled,
    visibleModal,
    setVisibleModal
  } = useEditStates();

  const { 
    lot,
    isLoading
   } = useEditCallAPI({ externalId });

   const {
    items,
    rightButton,
    leftButton,
    dropdownItems
   } = useEditMemorizations({
    disabled,
    setDisabled,
    setVisibleModal,
    lot
   });

   const { onChangeVisibleModal } = useEditFunctions({ setVisibleModal })
  
  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={rightButton}
        leftButton={leftButton}
        dropdownItens={dropdownItems}
      />
      <DeleteLotModal
        dispatch={dispatch}
        lot={lot}
        visibleModal={visibleModal}
        changeVisibleModal={onChangeVisibleModal}
      />
    </PageLoading>
  );
};

import { FC } from 'react';
import { Form } from '../../../../../Components';
import { BalanceAdjustmentEditSchema } from './BalanceAdjustmentEdit.schema';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { FooterButtons } from './Components/FooterButtons/index';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { BalanceAdjustmentsInfo } from '../Create/Components/BalanceAdjustmentsInfo/index';
import { BalanceAdjustment } from '../Create/Components/BalanceAdjustment/index';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export const CreateBalanceAdjustment: FC = () => {
  const {
    lotExternalIdSelected,
    onClassProductIdSelected,
    onProdutoExternalIdSelected,
    setOnClassProductIdSelected,
    setOnProdutoExternalIdSelected,
    setStorageLocationExternalIdSelected,
    setlotExternalIdSelected,
    storageLocationExternalIdSelected,
  } = useCreateStates();

  const { createBalanceAdjustment, isLoading, preferences } =
    useCreateCallAPI();

  const {
    onSubmit,
    onLotSelected,
    onProductSelected,
    onStorageLocationSelected,
  } = useCreateFunctions({
    preferences,
    createBalanceAdjustment,
    setOnClassProductIdSelected,
    setOnProdutoExternalIdSelected,
    setStorageLocationExternalIdSelected,
    setlotExternalIdSelected,
  });

  return (
    <>
      <Form onSubmit={onSubmit} schema={BalanceAdjustmentEditSchema}>
        {(props) => (
          <>
            <ConnectedFocusError />
            <BalanceAdjustmentsInfo
              form={props}
              onProductSelected={onProductSelected}
              onLotSelectedInfo={onLotSelected}
              onStorageLocationSelected={onStorageLocationSelected}
            />
            <BalanceAdjustment
              form={props}
              productExternalId={onProdutoExternalIdSelected}
              typeClassProductId={onClassProductIdSelected}
              lotExternalId={lotExternalIdSelected}
              storageLocationExternalId={storageLocationExternalIdSelected}
            />
            <FooterButtons isLoadingCreateBalance={isLoading} />
          </>
        )}
      </Form>
    </>
  );
};

import { IListUserData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useCallback } from 'react';
import { IPagination } from '@/Components/Table';
import { IUseFunctions } from './interfaces';

export const useUserListFunctions = ({
  setSelectedRowsOnTable,
  changeUserStatus,
  deleteUser,
  refetch,
}: IUseFunctions) => {
  const onRefetch = useCallback(
    (pagination: IPagination) =>
      refetch({
        ...pagination,
        sorter: { column: 'nome', direction: 'ASC' },
      }),
    [refetch]
  );

  const onEdit = useCallback(
    async (user: any[], status: any) => {
      await changeUserStatus.mutateAsync({
        usuariosExternalId: user.map((x) => x.externalId),
        ativa: status.status === 'success' ? true : false,
      });
    },
    [changeUserStatus]
  );
  const onDelete = useCallback(
    async (users: IListUserData[]) => {
      await deleteUser.mutateAsync(users.map((user) => user.externalId));
    },
    [deleteUser]
  );

  const onSelectedRowsOnTable = useCallback(
    (rows: any) => {
      setSelectedRowsOnTable(rows);
    },
    [setSelectedRowsOnTable]
  );

  return {
    onRefetch,
    onEdit,
    onDelete,
    onSelectedRowsOnTable,
  };
};

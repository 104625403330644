import { formatPhoneNumber } from 'react-phone-number-input';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IColumn } from '@/Components/Table';
import { i18n, Icon, Link, Status, Text } from '@/Components';
import { IListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { UppercaseFirstLetter } from '@/Utils/CapitalFirstLetterUtils';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { TagsRender } from '@/Utils/TagsUtils/TagsUtils';

export const prescribersCollums = (t: any): IColumn[] => [
  {
    title: t('prescribers.listPage.prescriber'),
    dataIndex: 'nomeCompleto',
    key: 'nomeCompleto',
    sortColumn: 'nomeCompleto',
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    width: '30%',
    render: (x: string, row: IListPrescribersData) => (
      <Link
        type="ui-tiny-bold"
        to={
          getCurrentUserRoles().some(
            (x) => PermissionEnum.Vendas_Prescritores_VerDetalhes === x
          )
            ? `/sales/prescribers/${row?.externalId}/details`
            : undefined
        }
        style={{ width: 'fit-content' }}
      >
        {UppercaseFirstLetter(x)}
      </Link>
    ),
  },
  {
    title: t('prescribers.listPage.record'),
    dataIndex: 'tipoRegistroSigla',
    key: 'tipoRegistroSigla',
    width: '18%',
    isHighlightable: true,
    sortColumn: 'nomeCompleto',
    render: (x: string, row: IListPrescribersData) => (
      <Text
        type="ui-tiny-content"
        color="text-50"
        children={`${x}/${
          row.abreviacaoUfRegistro
        } ${row.codigoRegistro.toLocaleString('pt-BR')}`}
      />
    ),
  },
  {
    title: t('prescribers.listPage.mainContact'),
    dataIndex: 'contatoPrincipal',
    key: 'contatoPrincipal',
    width: '18%',
    isHighlightable: true,
    sortColumn: 'nomeCompleto',
    render: (x: string, row: IListPrescribersData) => (
      <Divflex>
        <Icon
          icon={row.tipoContatoIcon}
          size="SM"
          style={{ marginRight: 15 }}
          color="text-50"
        />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={x && x[0] === '+' ? formatPhoneNumber(x) : x}
        />
      </Divflex>
    ),
  },
  {
    title: t('prescribers.listPage.specialties'),
    dataIndex: 'especialidades',
    key: 'especialidades',
    width: '22%',
    render: (especialties: any, row: any, _index: any, rowSelected: any) => {
      return (
        <TagsRender
          data={especialties}
          widthCollum={280}
          rowSelected={rowSelected?.some(
            (rowSelectedCurrent: IListPrescribersData) =>
              row.externalId === rowSelectedCurrent.externalId
          )}
        />
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'ativo',
    key: 'ativo',
    sortColumn: 'ativo',
    width: '12%',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`common.${text ? 'activate' : 'inactivated'}`)}
      </Status>
    ),
  },
];

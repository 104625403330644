import React, { FC, useEffect, useState } from 'react';
import { IGetServiceResponse } from '../../../../../../Data/Interfaces/response/Sales/Service/IServiceResponse';
import { WithoutSaleOrder } from './WithoutSaleOrder';
import { WithSaleOrder } from './WithSaleOrder';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';

import styles from './Order.module.scss';

interface IOrder {
  service: IGetServiceResponse;
  saleOrder?: IGetSaleOrderResponse;
}

export const Order: FC<IOrder> = ({ service, saleOrder }) => {
  const [breadCrumbHeight, setBreadCrumbHeight] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [contentPageHeight, setContentPageHeight] = useState<number>(0);

  useEffect(() => {
    setBreadCrumbHeight(
      document.getElementById('breadcrumb')?.clientHeight ?? 0
    );
    setHeaderHeight(
      document.getElementById('headerSaleOrder')?.clientHeight ?? 0
    );
    setContentPageHeight(
      document.getElementById('content-pages')?.clientHeight ?? 0
    );
  }, []);

  const beadCrumbAndHeaderPadding = 82;

  return (
    <div
      className={styles['container']}
      style={{
        height:
          contentPageHeight -
          breadCrumbHeight -
          headerHeight -
          beadCrumbAndHeaderPadding,
      }}
      id="container-oder-sale"
    >
      {service.pedidoVendaExternalId ? (
        <WithSaleOrder saleOrder={saleOrder} />
      ) : (
        <WithoutSaleOrder serviceExternalId={service.externalId} />
      )}
    </div>
  );
};

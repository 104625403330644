import { FC } from 'react';
import { Table } from '@/Components';
import { UsersColumns } from './UserForm.columns';
import { AddUserModal } from './Modals/AddUserGroup';
import { IUserGroup } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useUserFormStates } from './Hooks/useUserFormStates';
import { useUserFormFunctions } from './Hooks/useUserFormFunctions';
import { useUserFormMemorizations } from './Hooks/useUserFormMemorizations';
import { useUserFormCallAPI } from './Hooks/useUserFormCallAPI';

interface IUserForm {
  externalId: string;
  userGroup?: IUserGroup | null;
}

export const UserForm: FC<IUserForm> = ({ externalId, userGroup }) => {
  const { selectedRowsOnTable, setSelectedRowsOnTable, visible, setVisible } =
    useUserFormStates();
  const {
    userListData,
    userListLoading,
    userListRefetch,
    removeUserInUserGroup,
  } = useUserFormCallAPI();

  const { headerOptions, deleteModal } = useUserFormMemorizations({
    userGroup,
    selectedRowsOnTable,
    setVisible,
    users: userListData,
  });

  const { deleteUsers, selectRowsOnTable } = useUserFormFunctions({
    externalId,
    removeUserInUserGroup,
    setSelectedRowsOnTable,
    userListRefetch,
  });

  return (
    <>
      <Table
        headerOptions={headerOptions}
        hasSelection
        columns={UsersColumns()}
        onRefetch={userListRefetch}
        onDelete={deleteUsers}
        pagination={userListData}
        loading={userListLoading}
        selectedRowsOnTable={selectRowsOnTable}
        deleteModal={deleteModal}
      />

      <AddUserModal
        visible={visible}
        setVisible={setVisible}
        externalId={externalId}
      />
    </>
  );
};

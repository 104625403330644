import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ISubGroup } from './interfaces';
import { Dropdown, Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import { useSubGroupMemorizations } from './Hooks/useSubGroupMemorizations';

export const SubGroup: FC<ISubGroup> = ({
  product,
  disabledForm,
  listSubGroups,
  fetchNewSubGroupPage,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { items } = useSubGroupMemorizations({
    listSubGroups: { data: listSubGroups?.data },
  });

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('products.toolTips.products.nameOfSubGroup')} ${
          product?.subGrupo
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.subGrupoExternalId !== form?.values?.subGrupoExternalId
        }
      >
        <Dropdown
          items={items}
          name="subGrupoExternalId"
          selectedItemFromForm="subGrupoLabelInitialValue"
          label={[
            {
              children: t('products.create.subGroup'),
            },
          ]}
          placeHolder={t('products.create.subGroupPlaceholder')}
          disabled={disabledForm || !form?.values.grupoExternalId}
          withStatusIcon={!!product}
          onScrollEnd={fetchNewSubGroupPage}
        />
      </Tooltip>
    </Col>
  );
};

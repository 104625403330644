import { useFormikContext } from 'formik';
import { RawMaterialSchema } from '../LotCreate.schema';
import { NotRawMaterialSchema } from '../TechnicalInfo.schema';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useLotMemorizations = ({ createLot }: IUseMemorizations) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const uiUnitDefault: number = 1;
  const utrUnitDefault: number = 9;
  const grUnitDefault: number = 7;
  const ufcMiUnitDefault: number = 14;

  const schema = form?.values?.classeId === 1
    ? RawMaterialSchema
    : NotRawMaterialSchema;
  
  const unitysMeasuresDefault = {
    loteUnidadeAlternativa1: {
      unidadeAlternativaId: uiUnitDefault,
      unidadeAlternativaConversaoId: grUnitDefault,
    },
    loteUnidadeAlternativa2: {
      unidadeAlternativaId: utrUnitDefault,
      unidadeAlternativaConversaoId: ufcMiUnitDefault,
    },
  };
  
  const initialValues = {
    loteInformacaoTecnica: unitysMeasuresDefault,
  };

  const buttonChildren = 
  createLot.isLoading
    ? t('supply.lot.create.saveLoading')
    : t('supply.lot.create.save');


  return {
    schema,
    initialValues,
    buttonChildren
  };
};
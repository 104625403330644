import {
  Button,
  Card,
  Checkbox,
  Form,
  TextInput
} from '@/Components';
import { history } from '@/Store';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { DosageInputs } from './Components/DosageInputs';
import { PharmaceuticalForm } from './Components/PharmaceuticalForm';
import { IDilutedDetailProps } from './interfaces';
import { useDilutedProductDetailsCallAPI } from './Hooks/useDilutedProductDetailsCallAPI';
import { useDilutedProductDetailsFunctions } from './Hooks/useDilutedProductDetailsFunctions';

export const DilutedProductDetails: FC<IDilutedDetailProps> = ({
  disabledForm,
  disableForm
}) => {
  const { t } = useTranslation();
  const { externalId: productExternalId, associationProductId } = useParams<{
    externalId: string;
    associationProductId: string;
  }>();

  const {
    currentProduct,
    editDilutedProduct,
    dilutedProductData
  } = useDilutedProductDetailsCallAPI({
    productExternalId,
    associationProductId
  });

  const {
    handleSubmit,
    disableSubmitButton,
    onClickBackButton
  } = useDilutedProductDetailsFunctions({
    editDilutedProduct,
    productExternalId,
    associationProductId
  });

  return (
    <Form onSubmit={handleSubmit} initialValues={dilutedProductData}>
      {(props: any) => (
        <>
          <Card
            title={t('products.diluted.newDilutedProductTitle')}
            withoutCollapse
          >
            <Row gutter={[16, 0]}>
              <Col span={7}>
                <TextInput
                  name="product"
                  label={t('products.incompatible.product')}
                  disabled
                  value={currentProduct ? currentProduct.descricao : ''}
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <PharmaceuticalForm
                props={props}
                dilutedProductData={dilutedProductData}
                disabledForm={disabledForm}
              />
              <DosageInputs
                disabledForm={disabledForm}
                initialValues={dilutedProductData}
              />
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <Checkbox
                  name="seTodasFormasFarmaceuticas"
                  style={{
                    marginTop: -15,
                    marginBottom: 20,
                    fontWeight: '500',
                  }}
                  label="Todas as Formas Farmacêuticas"
                  disabled={disabledForm}
                />
              </Col>
              <Col span={8}>
                <Checkbox
                  name="seQualquerDosagem"
                  style={{
                    marginTop: -15,
                    marginBottom: 20,
                    fontWeight: '500',
                  }}
                  label="Qualquer Dosagem"
                  disabled={disabledForm}
                />
              </Col>
            </Row>
            {!disabledForm && (
              <div style={{ display: 'flex' }}>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => {
                    disableForm();
                    if (props.dirty) {
                      history.push(
                        `/inventory/products/${productExternalId}/diluted`
                      );
                    }
                  }}
                />
                <Button
                  type="primary"
                  children={
                    editDilutedProduct.isLoading
                      ? t('common.saving')
                      : t('common.save')
                  }
                  htmlType="submit"
                  loading={editDilutedProduct.isLoading}
                  disabled={disableSubmitButton(props.values)}
                />
              </div>
            )}
          </Card>
          {disabledForm && (
            <Button
              type="secondary"
              onClick={onClickBackButton}
              children={t('common.back')}
            />
          )}
        </>
      )}
    </Form>
  );
};

import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormikContextType, useFormikContext } from 'formik';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';
import { LaboratoryName } from '../laboratoryName';
import { InputWithSearch } from '@/Components';

export const RowDrescription = () => {
  const form: FormikContextType<any> = useFormikContext();
  const { t } = useTranslation();

  const {
    paginationCompany,
    paginationSupplyPlace,
    setPaginationCompany,
    setPaginationSupplyPlace,
    setShowSupplyPlace,
    showSupplyPlace,
    setCompanyExternalId,
    isErrorValidateName,
  } = useCreateStates();

  const {
    companyList,
    isLoadingCompany,
    supplyPlaceList,
    isLoadingSupplyPlace,
    fetchDescriptionLaboratory,
    refetchSearchCompanyPage,
    refetchSupplyPlace,
    fetchInputCompany,
  } = useCreateCallAPI({
    form,
    t,
    paginationCompany,
    paginationSupplyPlace,
    isErrorValidateName,
  });

  const { fetchInputCompanyDescription, fetchInputCompanyInput } =
    useCreateFunctions({ fetchDescriptionLaboratory });

  const { pharmaceuticalFormList, companyListlFormList } =
    useCreateMemorizations({
      supplyPlaceList,
      companyList,
    });

  return (
    <Row gutter={[16, 0]}>
      <Col span={7}>
        <LaboratoryName
          onChange={(value) => {
            fetchInputCompanyDescription(form, value);
          }}
        />
      </Col>
      <Col span={7}>
        <InputWithSearch
          items={companyListlFormList}
          name="empresaExternalId"
          placeHolder={t('production.laboratory.create.companyPlaceholder')}
          label={t('production.laboratory.create.company')}
          required
          withoutMarginBottom={true}
          isLoading={isLoadingCompany}
          onScrollEnd={() =>
            setPaginationCompany({
              ...paginationCompany,
              pageIndex: paginationCompany.pageIndex + 1,
            })
          }
          onSearch={(search) => {
            refetchSearchCompanyPage({
              pageIndex: 0,
              pageSize: 10,
              search: search,
              sorter: { column: 'nomeFantasia', direction: 'DESC' },
            });
          }}
          onChange={(value: any) => {
            refetchSupplyPlace({
              ...paginationSupplyPlace,
              filter: [
                {
                  filterName: 'empresaExternalId',
                  value: value,
                },
              ],
            });
            fetchInputCompanyInput(form, value);
            setShowSupplyPlace(true);
            setCompanyExternalId(value);
          }}
        />
      </Col>
      <Col span={7}>
        <InputWithSearch
          items={showSupplyPlace ? pharmaceuticalFormList : []}
          name="localEstoqueExternalId"
          placeHolder={t(
            'production.laboratory.create.supplyPlacesPlaceholder'
          )}
          label={t('production.laboratory.create.supplyPlaces')}
          required
          withoutSearchIcon
          withoutMarginBottom={true}
          disabled={
            !form.values.empresaExternalId || !form.values.nomeLaboratorio
          }
          isLoading={isLoadingSupplyPlace}
          onScrollEnd={() =>
            setPaginationSupplyPlace({
              ...paginationSupplyPlace,
              pageIndex: paginationSupplyPlace.pageIndex + 1,
            })
          }
          onSearch={(search) => {
            refetchSupplyPlace({
              pageIndex: 0,
              pageSize: 10,
              search: search,
              sorter: { column: 'descricao', direction: 'DESC' },
            });
          }}
          onChange={() => {
            fetchInputCompany();
          }}
        />
      </Col>
    </Row>
  );
};

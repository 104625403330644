import { IPagination } from '@/Components/Table';
import { IQueryListParams } from '@/Utils/Http';
import { useState } from 'react';

export const useCreateStates = () => {
  const [paginationCompany, setPaginationCompany] = useState<IPagination>({
    pageIndex: 0,
    pageSize: 50,
    sorter: { column: 'nomeFantasia', direction: 'ASC' },
  });

  const [paginationSupplyPlace, setPaginationSupplyPlace] =
    useState<IQueryListParams>({
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    });

  const [showSupplyPlace, setShowSupplyPlace] = useState<Boolean>(false);

  const [companyExternalId, setCompanyExternalId] = useState<string>('');

  const [isErrorValidateName, setIsErrorValidateName] =
    useState<boolean>(false);

  return {
    paginationCompany,
    setPaginationCompany,
    paginationSupplyPlace,
    setPaginationSupplyPlace,
    showSupplyPlace,
    setShowSupplyPlace,
    companyExternalId,
    setCompanyExternalId,
    isErrorValidateName,
    setIsErrorValidateName,
  };
};

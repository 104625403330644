import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const usePharmaceuticalFormFunctions = ({
  refetchSearchPharmaceuticalFormPage
}: IUseFunctions) => {
  const onSearch = useCallback((search: string | undefined) => {
    refetchSearchPharmaceuticalFormPage({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' },
    });
  }, [refetchSearchPharmaceuticalFormPage]);

  return {
    onSearch
  };
};
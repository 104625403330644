import { FC } from "react";
import { Button } from '@/Components';
import { IFooterButton } from "./interfaces";
import { useTranslation } from "react-i18next";

import styles from "./FooterButton.module.scss";

export const FooterButton: FC<IFooterButton> = ({
  setIsBackModalOpen,
  createPrescriberIsLoading,
  props
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => setIsBackModalOpen(true)}
      />
      <Button
        type="primary"
        htmlType="submit"
        children={
          createPrescriberIsLoading
            ? `${t('common.creating')}...`
            : t('common.create')
        }
        loading={createPrescriberIsLoading}
        disabled={
          !props.values.nomeCompleto ||
          !props.values.tipoRegistroId ||
          !props.values.ufRegistroExternalId ||
          !props.values.codigoRegistro
        }
      />
    </div>
  );
};
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Table } from '@/Components/Table';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { productMessageColumns } from './productMessage.columns';
import {
  LinkedProdutoMessageModal 
} from './Modal/LinkedProductMessageModal';
import { useProductMessageStates } from './Hooks/useProductMessageStates';
import { useProductMessageCallAPI } from './Hooks/useProductMessageCallAPI';
import { useProductMessageFunctions } from './Hooks/useProductMessageFunctions';
import { useProductMessageMemorizations } from './Hooks/useProductMessageMemorizations';

export const ProductMessageListTab: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleLinkedProductMessageModal,
    setVisibleLinkedProductMessageModal
  } = useProductMessageStates();

  const {
    productMessageLinked,
    isLoading,
    refetchProductMessageLinkedList,
    removeProductLinkedMsg
  } = useProductMessageCallAPI({
    externalId
  });

  const {
    selectRowsOnTable,
    onDelete,
    changeVisibleModal
  } = useProductMessageFunctions({
    removeProductLinkedMsg,
    externalId,
    setSelectedRowsOnTable,
    setVisibleLinkedProductMessageModal
  });

  const {
    headerOptions,
    rowsOptions,
    deleteModal
  } = useProductMessageMemorizations({
    setVisibleLinkedProductMessageModal,
    selectedRowsOnTable
  });

  useEffect(() => {
    refetchProductMessageLinkedList({
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'mensagemDescricao', direction: 'ASC' },
      filter: [{ filterName: 'produtoExternalId', value: externalId }],
    });
  }, [refetchProductMessageLinkedList, externalId]);

  return (
    <ContainerPage>
      <Table
        columns={productMessageColumns(t, externalId)}
        hasSelection
        headerOptions={headerOptions}
        loading={isLoading}
        pagination={productMessageLinked}
        onRefetch={refetchProductMessageLinkedList}
        selectedRowsOnTable={selectRowsOnTable}
        onDelete={onDelete}
        rowsOptions={rowsOptions}
        deleteModal={deleteModal}
      />

      {visibleLinkedProductMessageModal && (
        <LinkedProdutoMessageModal
          visible
          changeVisible={changeVisibleModal}
          produtoExternalId={externalId}
        />
      )}
    </ContainerPage>
  );
};

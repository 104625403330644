import { useControllerQueryApiHook } from "@/ControllerApiHook/Controller";
import { GET_DOCUMENT_PROVIDER } from "@/ControllerApiHook/UniqIds/Purchase/SupplierKeys";
import { SupplierAPI } from "@/Data/API/Purchase/SupplierApi";
import { IGetDocumentProviderResponse } from "@/Data/Interfaces/response/Provider/IProviderResponse";
import { IUseCallAPI } from "./interfaces";

export const usePurchaseOrderInfoCallAPI = ({
  externalIdProvider
}: IUseCallAPI) => {
  const { 
    data: documentProvider, 
    isLoading: isLoadingDocumentProvider 
  } = useControllerQueryApiHook<IGetDocumentProviderResponse>({
      uniqId: GET_DOCUMENT_PROVIDER,
      entityApi: SupplierAPI.getDocumentProvider,
      externalId: externalIdProvider,
    });

  return {
    documentProvider,
    isLoadingDocumentProvider
  };
};
import { useCallback } from "react";
import {
  setInitialValuesFormInternalDiluitionFactor,
} from '../../../../../diluitionsHelpers';
import { useFormikContext } from "formik";

export const useInternalDilutionsFunctions = () => {
  const form: any = useFormikContext();

  const onChange = useCallback(() => {
    setInitialValuesFormInternalDiluitionFactor(form);
  }, [setInitialValuesFormInternalDiluitionFactor]
  );

  return { onChange };
};
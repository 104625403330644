import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IInfoModal } from './interfaces';
import { useModalCallAPI } from './Hooks/useModalCallAPI';
import { useModalMemorizations } from './Hooks/useModalMemorizatrions';
import { useModalFunctions } from './Hooks/useModalFunctions';

export const InfoModal: FC<IInfoModal> = ({
  visible,
  changeVisibleModal,
  client,
  preferences,
}) => {
  const { t } = useTranslation();

  const { editInfo } = useModalCallAPI({ changeVisibleModal });

  const { infoModalBody, initialValuesInfoModal, disabledButtonFormNames } =
    useModalMemorizations({
      client,
      preferences,
    });

  const { onOkClickInfoModal } = useModalFunctions({ preferences, editInfo });

  return (
    <>
      <Modal
        body={infoModalBody}
        minWidth={800}
        title={t('client.editPage.editDetails')}
        visible={visible}
        withForm
        htmlType="submit"
        onCancelClick={() => changeVisibleModal(true)}
        onClose={() => changeVisibleModal(true)}
        initialValues={initialValuesInfoModal}
        onOkClickForm={onOkClickInfoModal}
        disabledButtonFormNames={disabledButtonFormNames}
        loading={editInfo.isLoading}
      />
    </>
  );
};

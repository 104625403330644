import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';

import { IUseCallAPI } from './interfaces';
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { FETCH_LIST_STOCK_BALANCE_BY_LOT } from '@/ControllerApiHook/UniqIds/Supply/StockBalanceKeys';
import { StockBalanceAPI } from '@/Data/API/Supply/StockBalanceApi';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { FETCH_GET_BALANCE_ORIGIN_DESTINY } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import {
  CONVERT_UNIT_OF_MEASURE,
  FETCH_LIST_UNITS_BY_CLASS,
} from '@/ControllerApiHook/UniqIds/Inventory/BalanceAdjustment';

export const useCreateCallAPI = ({
  paginationLot,
  selectedLotId,
  originSupplyPlaceId,
  destinySupplyPlaceId,
  form,
  index,
}: IUseCallAPI) => {
  const {
    data: productsList,
    fetchNewPage: fetchNewProductPage,
    isLoading: isLoadingProduct,
    refetch: refetchProducts,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const {
    data: lotsList,
    isLoading: lotLoading,
    refetch: lotsRefetch,
    fetchNewPage: fetchLotsNewPage,
    pagination: lotsPagination,
  }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STOCK_BALANCE_BY_LOT,
    entityApi: StockBalanceAPI.stockBalanceByLotList,
    initialPagination: paginationLot,
  });

  const { mutateAsync: convertUnitOfMeasureAsync }: any =
    useControllerMutateApiHook({
      uniqId: CONVERT_UNIT_OF_MEASURE,
      entityApi: UnitMeasurementAPI.convertUnitOfMeasure,
    });

  const { data: balanceOriginDestiny }: any = useControllerQueryApiHook({
    entityApi: LotsAPI.getSaldosOriginAndDestiny,
    uniqId: FETCH_GET_BALANCE_ORIGIN_DESTINY,
    externalId: [selectedLotId!, originSupplyPlaceId!, destinySupplyPlaceId!],
    onSuccess: () => {
      const values: any = form.values;
      let qntTransfers = Number(
        (form.values as any)?.transfers[index]?.qntTransfers || 0
      );

      const origin = form.values.transfers[index]?.originFunds;

      if (qntTransfers > (origin || 0)) {
        qntTransfers = origin || 0;
        form.setFieldValue(`transfers.${index}.qntTransfers`, qntTransfers);
      }

      const destinyBalanceValue =
        (balanceOriginDestiny?.saldoLoteDestino || 0) + qntTransfers;

      if (values.originFunds !== origin)
        form.setFieldValue(`transfers.${index}.originFunds`, origin);

      if (values.destinyFunds !== destinyBalanceValue)
        form.setFieldValue(
          `transfers.${index}.destinyFunds`,
          destinyBalanceValue
        );
    },
  });

  const { data: unitsMeasurements }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClassAny,
    initialPagination: paginationLot,
    options: {
      staleTime: 1000 * 60 * 60 * 24 * 3,
    },
  });

  return {
    productsList,
    fetchNewProductPage,
    convertUnitOfMeasureAsync,
    lotsList,
    lotLoading,
    lotsRefetch,
    fetchLotsNewPage,
    lotsPagination,
    balanceOriginDestiny,
    unitsMeasurements,
    isLoadingProduct,
    refetchProducts,
  };
};

import { useCallback } from 'react';
import {
  FETCH_GET_SUBGROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IEditProductSubGroupRequest } from '@/Data/Interfaces/request/Product/IProductRequest';
import { FormikHelpers, FormikValues } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '@/Store';
import { IUseFunctions } from './interfaces';

export const useDetailsFunctions = ({
  data,
  editSubGroup,
  onChangeDisabled,
  disabled
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = useCallback(async (
    values: IEditProductSubGroupRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditProductSubGroupRequest = { ...values, helpers };

    if (data) {
      const res = await editSubGroup.mutateAsync({
        subGrupoExternalId: data?.externalId,
        descricao: body?.descricao,
        grupoPaiExternalId: body?.grupoPaiExternalId,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_SUBGROUP]);
        onChangeDisabled(!disabled);
      }
    }
  }, [data, editSubGroup, onChangeDisabled, disabled]);

  const onClick= useCallback(() => history.push('/inventory/sub-groups'), []);

  return { onSubmit, onClick };
};
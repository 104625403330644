import { useAppProps } from 'antd/es/app/context';
import { FC } from 'react';

import { Text } from '../../Components';
import { CannotDeleteModalDefault } from '../../Components/ModalCannotDeleteDefault';
import { CannotDeleteModal as SupplyGroupsCannotDelete } from '../../Pages/Sectors/Inventory/Products/Groups/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplyStorageLocationCannotDelete } from '../../Pages/Sectors/Inventory/SupplyPlaces/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplyProductCannotDelete } from '../../Pages/Sectors/Inventory/Products/Product/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplySubGroupsCannotDelete } from '../../Pages/Sectors/Inventory/Products/SubGroups/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplyLotsCannotDelete } from '../../Pages/Sectors/Inventory/Lot/List/Modal/CannotDeleteModal/deleteModal';
import { CannotDeleteModal as PurchasingSuppliersCannotDelete } from '../../Pages/Sectors/Order/Supplier/List/Modal/CannotDeleteModal/index';
import { CannotDeleteModal as PurchasePrderCannotDelete } from '../../Pages/Sectors/Order/Purchase/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as InvoiceCannotDelete } from '../../Pages/Sectors/Order/Invoice/List/Modal/CannotDeleteModal';
import { CreateLotErrorToast } from '../../Pages/Sectors/Inventory/Lot/CreateForm/Modal/CannotCreateToast/errorCreateToast';
import { useTranslation } from 'react-i18next';
import { CannotDeleteModalDefaultLaboratory } from '@/Pages/Sectors/Production/Laboratory/CanNotDeleteModalLaboratory/canNotDeleteModalLaboratory';

const DefaultErrorToast: FC<ICommonError> = ({ errorCode }) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column' }}>
      <Text type="ui-tiny-bold">
        {t('toast.default.title')}
        <span
          style={{
            fontWeight: 'var(--font-weight-bold)',
            fontSize: 'var(--font-size-xs)',
            lineHeight: 'var(--font-line-height-xs)',
            color: 'var(--color-status-danger-base)',
          }}
        >
          {` ${errorCode}`}
        </span>
        .
      </Text>
      <Text type="small-text" color="text-100">
        {t('toast.default.description')}
      </Text>
    </div>
  );
};

export interface IGlobalModal {
  destroy: () => void;
  update: (configUpdate: any) => void;
}

/**
 * Those properties are received through the backend error response
 *
 * Essas propriedades são recebidas através do response de erros do backend
 */
export interface ICommonError {
  errorCode: string;
  /**
   * The content of the error received from the backend, containing information about what went wrong, the name, and externalId of the entity
   *
   * o conteúdo do erro recebido pelo backend, contendo o que aconteceu de errado, o nome e externalId da entidade.
   */
  content: any[];
  errors: any;
  title: string;
  status: number;
  detail: string;
  instance: string;
}

export interface ICannotDeleteModalProps {
  titleModal: string;
  subTitleModal: string;
  reasonTranslation: string;
  warningModal?: string;
  hideObjCannotDelete?: boolean;
  complement?: string;
}
export interface IManyItemCannotDeleteModalProp
  extends Omit<ICannotDeleteModalProps, 'titleModal'> {
  entity: string;
}

export interface ICannotDeleteModalInfo {
  formName: string;
  secondFormName?: string;
  oneErrorModal: ICannotDeleteModalProps;
  manyErrorsModal: IManyItemCannotDeleteModalProp;
  hideObjCannotDelete?: boolean;
  complement?: string;
}

export const handleModalError =
  (app: useAppProps, cannotDeleteModalInfo?: ICannotDeleteModalInfo) =>
  (err: unknown, params: any) => {
    const error = err as ICommonError;
    if (error.status !== 400) return;

    switch (error.errorCode) {
      case 'EstoqueErrorCode-1':
        const modal = app.modal.info({});
        modal.update(SupplyGroupsCannotDelete(error, params, modal));
        break;

      case 'EstoqueErrorCode-2':
        const modal2 = app.modal.info({});
        modal2.update(SupplySubGroupsCannotDelete(error, params, modal2));
        break;

      case 'EstoqueErrorCode-3':
        const modal3 = app.modal.info({});
        modal3.update(SupplyStorageLocationCannotDelete(error, params, modal3));
        break;

      case 'EstoqueErrorCode-4':
        const modal4 = app.modal.info({});
        modal4.update(SupplyProductCannotDelete(error, params, modal4));
        break;

      case 'EstoqueErrorCode-5':
        const modal5 = app.modal.info({});
        modal5.update(SupplyLotsCannotDelete(error, params, modal5));
        break;

      case 'EstoqueErrorCode-6':
        app.notification.error({
          duration: 10,
          message: <CreateLotErrorToast {...error} />,
          placement: 'bottomRight',
          key: error.errorCode,
        });
        break;

      case 'PessoaErrorCode-1':
        const modal6 = app.modal.info({});
        modal6.update(PurchasingSuppliersCannotDelete(error, params, modal6));
        break;

      case 'ComprasErrorCode-1':
        const modal7 = app.modal.info({});
        modal7.update(PurchasePrderCannotDelete(error, params, modal7));
        break;

      case 'ComprasErrorCode-2':
        const modal8 = app.modal.info({});
        modal8.update(InvoiceCannotDelete(error, params, modal8));
        break;

      case 'ProducaoErrorCode-1':
        if (error.content.length === 1) {
          const modal9 = app.modal.info({ width: 400 });
          modal9.update(
            CannotDeleteModalDefaultLaboratory(error, params, modal9)
          );
        } else {
          const modalDefaultLaboratory = app.modal.info({});
          const updatedErrorContent = error.content.map((e: any) => {
            const companyName =
              error.content.length > 1 && e.nomeFantasiaEmpresa
                ? ` (${e.nomeFantasiaEmpresa})`
                : '';
            return { nomeLaboratorio: e.nomeLaboratorio + companyName };
          });

          error.content = updatedErrorContent;
          if (cannotDeleteModalInfo) {
            modalDefaultLaboratory.update(
              CannotDeleteModalDefault(
                error,
                params,
                modalDefaultLaboratory,
                cannotDeleteModalInfo
              )
            );
          }
        }
        break;

      case 'GlobalErrorCode-1':
        const modalDefault = app.modal.info({ width: 400 });
        if (cannotDeleteModalInfo) {
          modalDefault.update(
            CannotDeleteModalDefault(
              error,
              params,
              modalDefault,
              cannotDeleteModalInfo
            )
          );
        }
        break;

      default:
        app.notification.error({
          duration: 10,
          message: <DefaultErrorToast {...error} />,
          placement: 'bottomRight',
        });
        break;
    }
  };

export const emailIsValid = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

import { IUseFunctions } from './interfaces';
import { useCallback, useEffect } from 'react';
import { debounce, isEqual } from 'lodash';

export const useCreateFunctions = ({
  index,
  totalRows,
  setProductRowAmount,
  uuidV4,
  form,
  onDelete,
  balanceOriginDestiny,
  paginationLot,
  destinySupplyPlaceId,
  lotsPagination,
  lotsRefetch,
  originSupplyPlaceId,
  selectedProductId,
  convertUnitOfMeasureAsync,
  unitsMeasurements,
}: IUseFunctions) => {
  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
  };

  const deleteRow = () => {
    if (!form.values.transfers) return;
    form.setFieldValue(
      `transfers`,
      form.values.transfers.filter((_: any, i: number) => i !== index)
    );

    if (totalRows === 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
    onDelete(index);
  };

  useEffect(() => {
    const newPagination = {
      ...paginationLot,
      filter: [
        { filterName: 'produtoExternalId', value: selectedProductId },
        {
          filterName: 'localEstoqueExternalId',
          value: originSupplyPlaceId,
        },
      ],
    };

    const isEquals = isEqual(lotsPagination, newPagination);
    if (isEquals) return;

    lotsRefetch(newPagination);
  }, [lotsRefetch, selectedProductId, lotsPagination, originSupplyPlaceId]);

  useEffect(() => {
    const values: any = form.values;
    const produtctSelected: string = form.values.transfers[index]?.product;

    if (produtctSelected) {
      let qntTransfers = Number(
        (form.values as any)?.transfers[index]?.qntTransfers || 0
      );

      if (qntTransfers > (balanceOriginDestiny?.saldoLoteOrigem || 0)) {
        qntTransfers = balanceOriginDestiny?.saldoLoteOrigem || 0;
        form.setFieldValue(`transfers.${index}.qntTransfers`, qntTransfers);
      }

      const originBalanceValue = balanceOriginDestiny?.saldoLoteOrigem || 0;
      const destinyBalanceValue =
        (balanceOriginDestiny?.saldoLoteDestino || 0) + qntTransfers;

      if (values.originFunds !== originBalanceValue)
        form.setFieldValue(
          `transfers.${index}.originFunds`,
          originBalanceValue
        );

      if (values.destinyFunds !== destinyBalanceValue)
        form.setFieldValue(
          `transfers.${index}.destinyFunds`,
          destinyBalanceValue
        );
    }
  }, [balanceOriginDestiny, destinySupplyPlaceId, originSupplyPlaceId]);

  const debounceLotsSearch = debounce((e) => {
    lotsRefetch({
      ...lotsPagination,
      search: e.target.value,
    });
  }, 500);

  const onUnitOfMeasureChange = useCallback(
    async (selectedId: any) => {
      const currentUnitOfMeasureId =
        form.values.transfers[index].unidadeMedidaId;
      const quantity = form.values.transfers[index].qntTransfers;
      const currentQuantity = form.values.transfers[index].originFunds;

      const result = await convertUnitOfMeasureAsync([
        {
          quantidade: currentQuantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
        {
          quantidade: quantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
      ]);

      if (result && result.length > 0) {
        const saldoAtual = result[0].quantidadeConvertida;
        const quantity = result[1].quantidadeConvertida;
        form.setFieldValue(`transfers.${index}.originFunds`, saldoAtual);
        form.setFieldValue(`transfers.${index}.qntTransfers`, quantity);
        form.setFieldValue(
          `transfers.${index}.destinyFunds`,
          saldoAtual - quantity
        );
      }
    },
    [convertUnitOfMeasureAsync, form.values, index]
  );

  const selectedUnitOfMeasure = unitsMeasurements?.data?.find(
    (x: any) => x.id === form.values.transfers[index]?.unidadeMedidaId
  );

  useEffect(() => {
    let currentProduct: string = form.values.transfers[index]?.product;

    let quantityTransferInitial: number = parseFloat(
      form.values.transfers[index]?.originFunds
    );
    let quantityTransferCurrent: number = parseFloat(
      form.values.transfers[index]?.qntTransfers
    );

    if (isNaN(quantityTransferCurrent)) {
      quantityTransferCurrent = 0;
    }

    if (isNaN(quantityTransferInitial)) {
      quantityTransferInitial = 0;
    }

    let balanceFinal: number =
      quantityTransferInitial - quantityTransferCurrent;

    if (quantityTransferCurrent > quantityTransferInitial || !currentProduct) {
      form.setFieldValue(`transfers.${index}.destinyFunds`, undefined);
    } else form.setFieldValue(`transfers.${index}.destinyFunds`, balanceFinal);
  }, [
    form.values.transfers[index]?.product,
    form.values.transfers[index]?.qntTransfers,
  ]);

  return {
    addRow,
    deleteRow,
    debounceLotsSearch,
    onUnitOfMeasureChange,
    selectedUnitOfMeasure,
  };
};

import { FC } from "react";
import {
  InputWithSearch
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAssociatePackings } from "./interfaces";
import { useAssociateCapsulesCallAPI } from "./Hooks/useAssociatePackingCallAPI";
import { useAssociatePackingMemorizations } from "./Hooks/useAssociatePackingMemorizations";
import { useAssociatePackingFunctions } from "./Hooks/useAssociatePackingFunctions";

export const AssociatePackings: FC<IAssociatePackings> = ({
  disabledForm,
  index,
  associatePackingListController,
  setAssociatePackingListController,
  setDisabledPackingTypes,
  disabledPackingTypes
}) => {
  const { t } = useTranslation();
  const productPackingClassId = 2;

  const {
    productList,
    fetchNewProductPage,
    isLoadingProduct,
    refetchSearchProductPage
  } = useAssociateCapsulesCallAPI({
    productPackingClassId
  });

  const {
    items
  } = useAssociatePackingMemorizations({
    productList,
    productPackingClassId,
    disabledPackingTypes
  });

  const {
    onChange,
    onSearch
  } = useAssociatePackingFunctions({
    index,
    productPackingClassId,
    refetchSearchProductPage,
    setDisabledPackingTypes,
    associatePackingListController,
    setAssociatePackingListController
  });

  return(
    <Col span={4}>
      <InputWithSearch
        name={`embalagemAssociacao.${index}.produtoEmbalagemExternalId`}
        label={
          index === 0 ? t('products.create.associatePackings') : ''
        }
        items={items}
        placeHolder={t('products.create.associateCapsulesPlaceholder')}
        onScrollEnd={fetchNewProductPage}
        onChange={onChange}
        disabled={disabledForm}
        isLoading={isLoadingProduct}
        onSearch={onSearch}
      />
    </Col>
  );
};
import { useCallback } from 'react';
import DateUtils from '@/Utils/DateUtils';
import { ICreateClientRequest } from '@/Data/Interfaces/request/Client/IClientRequest';
import { FormikHelpers, FormikValues } from 'formik';
import { IUseFunctions } from './interfaces';
import { history } from '@/Store';

export const useCreateFunctions = ({
  preferences,
  createClient,
  setIsBackModalOpen,
}: IUseFunctions) => {
  const onSubmit = useCallback(
    async (
      values: ICreateClientRequest,
      formik: FormikHelpers<FormikValues>
    ) => {
      if (values.dataNascimento && preferences)
        values.dataNascimento = DateUtils.convertDateToApi(
          values.dataNascimento,
          preferences.padraoData
        );

      try {
        await createClient.mutateAsync(values);
      } catch {
        formik.setSubmitting(false);
      }
    },
    [preferences, createClient]
  );

  const onOkClick = useCallback(() => history.push('/sales/customers'), []);

  const onCancelClick = useCallback(
    () => setIsBackModalOpen(false),
    [setIsBackModalOpen]
  );

  const onClose = useCallback(
    () => setIsBackModalOpen(false),
    [setIsBackModalOpen]
  );

  return {
    onSubmit,
    onOkClick,
    onCancelClick,
    onClose,
  };
};

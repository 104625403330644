import { FC } from 'react';
import {
  InputWithSearch,
  Tooltip
} from '@/Components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IProvider } from './interfaces';
import { useProviderCallAPI } from './Hooks/useProviderCallAPI';
import { useProviderMemorizations } from './Hooks/useProviderMemorizations';
import { useProviderFunctions } from './Hooks/useProviderFunctions';

export const Provider: FC<IProvider> = ({
  orderPurchase,
  setExternalIdProvider
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const {
    listProvider,
    isLoadingProvider,
    fetchNewProviderPage,
    refetchSearchProviderPage
  } = useProviderCallAPI();

  const {
    items
  } = useProviderMemorizations({
    listProvider
  });

  const {
    onChange,
    onSearch
  } = useProviderFunctions({
    setExternalIdProvider,
    refetchSearchProviderPage
  });

  return (
    <Tooltip
      title={`${t('common.originalProvider')} ${
        orderPurchase?.nomeFornecedor
      }`}
      showMe={
        orderPurchase?.fornecedorExternalId !==
        form?.values?.fornecedorExternalId
      }
      targetOffset={[190, -5]}
    >
      <InputWithSearch
        items={items}
        name="fornecedorExternalId"
        placeHolder={t(
          'purchasing.purchaseOrder.create.placeHolder.provider'
        )}
        label={t('purchasing.purchaseOrder.create.provider')}
        required
        withoutMarginBottom={true}
        isLoading={isLoadingProvider}
        onScrollEnd={fetchNewProviderPage}
        onChange={onChange}
        withStatusIcon
        onSearch={onSearch}
      />
    </Tooltip>
  );
};
export const specialtiesEN = {
  specialties: 'Specialties',
  specialtie: 'Specialtie',
  listPage: {
    specialtiesName: 'Specialty Name',
    prescribersAmount: 'Prescribers',

    searchPlaceholder: 'Search by specialty or status',
    newButton: 'New Specialty',

    deleteModal: {
      description:
        'Are you sure you want to delete the specialty {{specialtie}}?',
      describe: 'Are you sure you want to delete the skill:',

      success: 'Specialty deleted!',
    },
  },

  create: {
    title: 'Specialty registration',
    label: 'Specialty Name',

    placeholder: 'E.g: Cardiology',

    validationDescription: 'There is already a specialty with that name!',

    success: 'Specialty successfully registered!',
  },

  edit: {
    titleCard: 'Details',

    descriptionLabel: 'Specialty Name',
    descriptionPlaceholder: 'E.g: Cardiology',
    descriptionIsRequied: 'Specialty name is required!',

    prescribers: 'Prescriber',

    tooltip: 'Original specialty name',

    statusModal: {
      confirmation:
        'Are you sure you want to deactivate the skill {{specialties}}?',
    },

    success: 'Specialty Updated!',
  },

  permition: {
    newButton: 'No permission to create specialty',
    editButton: 'No permission to edit',
    deleteButton: 'No permission to delete',
    viewDetails: 'No permission to view',
  },

  modalCannotDelete: {
    cannotDeleteSpecialtiesWithMovementsInTheSystem: {
      specialties: 'specialties',
      oneEntity: {
        messageSubtitle: 'the specialty',
        reasonTranslation:
          'This specialty has linked prescribers and therefore its exclusion is not possible.',
      },
      manyEntity: {
        messageSubtitle: 'the specialties:',
        reasonTranslation:
          'These specialties have linked prescribers and therefore it is not possible to exclude them.',
      },
      warning:
        'If you no longer want to use this skill, change its status to “Inactive”.',
    },
  },
};
export const specialtiesPT = {
  specialties: 'Especialidades',
  specialtie: 'Especialidade',
  listPage: {
    specialtiesName: 'Nome da Especialidade',
    prescribersAmount: 'Prescritores',

    searchPlaceholder: 'Pesquise por uma especialidade ou status',
    newButton: 'Nova Especialidade',

    deleteModal: {
      description:
        'Você tem certeza que deseja excluir a especialidade {{specialtie}}?',
      describe: 'Você tem certeza que deseja excluir a especialidade:',

      success: 'Especialidade Excluída!',
    },
  },

  create: {
    title: 'Cadastro de especialidade',
    label: 'Nome da Especialidade',

    placeholder: 'Ex: Cardiologia',

    validationDescription: 'Já existe uma especialidade com esse nome!',

    success: 'Especialidade cadastrada com sucesso! ',
  },

  edit: {
    titleCard: 'Detalhes',

    descriptionLabel: 'Nome da Especialidade',
    descriptionPlaceholder: 'Ex: Cardiologia',
    descriptionIsRequied: 'Nome da especialidade é obrigatório!',

    prescribers: 'Prescritores',

    tooltip: 'Nome da especialdiade original',

    statusModal: {
      confirmation:
        'Você tem certeza que deseja inativar a especialidade {{specialties}}?',
    },

    success: 'Especialidade atualizada!',
  },

  permition: {
    newButton: 'Sem permição para criar especialidade',
    editButton: 'Sem permição para editar',
    deleteButton: 'Sem permição para deletar',
    viewDetails: 'Sem permição para visualizar',
  },

  modalCannotDelete: {
    cannotDeleteSpecialtiesWithMovementsInTheSystem: {
      specialties: 'especialidades',
      oneEntity: {
        messageSubtitle: 'a especialidade',
        reasonTranslation:
          'Essa especialidade tem prescritores vinculados e por isso não é possível a sua exclusão.',
      },
      manyEntity: {
        messageSubtitle: 'as especialidades:',
        reasonTranslation:
          'Essas especialidades tem prescritores vinculados e por isso não é possível suas exclusões.',
      },
      warning:
        'Caso não queira mais utilizar essa especialidade, mude o status dela para “Inativo”.',
    },
  },
};

import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFChildren } from '@/Components/Form';

import styles from './../../Laboratory.module.scss';

interface IFooterButtons {
  isLoadingEditLaboratory: boolean;
  props: IFChildren;
  onChangeDisabled: (x: boolean) => void;
  isErrorValidadeName: boolean;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditLaboratory,
  props,
  onChangeDisabled,
  isErrorValidadeName,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          onChangeDisabled(true);
        }}
      />
      <Button
        type="primary"
        children={t('common.save')}
        htmlType="submit"
        loading={isLoadingEditLaboratory}
        disabled={
          !props.values.nome ||
          !props.values.empresaExternalId ||
          !props.values.localEstoqueExternalId ||
          isErrorValidadeName
        }
      />
    </div>
  );
};

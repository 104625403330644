import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import {
  Button,
  Icon,
  InputWithSearch,
  TextInput,
} from '../../../../../../../../../Components';
import { CurrencyInput } from '../../../../../../../../../Components/Inputs/CurrencyInput';
import {
  FETCH_GET_PRODUCT,
  FETCH_LIST_FOR_DROPDOWN_TYPE,
} from '../../../../../../../../../ControllerApiHook/UniqIds/Supply/ProductKeys';
import { WeightInput } from '../../../../../../../../../Components/Inputs/WeightInput';
import { FETCH_LIST_UNITS_BY_CLASS } from '../../../../../../../../../ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { useFormikContext } from 'formik';
import DeleteProductModal from '../../modal/DeleteProductModal';
import {
  FETCH_LIST_DROPDOWN_CFOP,
  FETCH_LIST_DROPDOWN_CST,
  FETCH_LIST_DROPDOWN_NCM,
} from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import {
  configCfopList,
  configCstList,
  configNcmList,
} from '../../../../utils/functions';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import {
  IProductGroupListData,
  IProductResponse,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { isNil } from 'lodash';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IListUnitMeasurementByClassData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';

interface IProductRow {
  index: number;
  onDelete: (index: number) => void;
  totalRows: number;
  setProductRowAmount: React.Dispatch<React.SetStateAction<string[]>>;
  uuidV4: () => string;
  cardId: string;
  scrollContainerId: string;
}

const ProductRow: React.FC<IProductRow> = ({
  index,
  onDelete,
  totalRows,
  setProductRowAmount,
  uuidV4,
  cardId,
  scrollContainerId,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const [currentProduct, setCurrentProduct] =
    React.useState<IProductGroupListData>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [classProduct, setClassProduct] = useState<number | undefined>();
  const [customFilter, setCustomFilter] = useState<any>([
    {
      filterName: 'tipoClasseProduto',
      value: classProduct,
    },
  ]);

  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: { pageIndex: 0, pageSize: 50 },
  });

  const { data: listunitsMeasurements, isLoading: isUnitOfMeasureLoading } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
      initialPagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      enabled: true,
      customFilter: customFilter,
    });

  const { data: unitsMeasurementsDefault } =
    useControllerQueryApiHook<IProductResponse>({
      uniqId: FETCH_GET_PRODUCT,
      entityApi: ProductAPI.getProduct,
      externalId: form.values.produtoExternalId,
    });

  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  const unitOfMeasureDropdown = listunitsMeasurements
    ? {
        name: 'unidadeMedidaId',
        loading: isUnitOfMeasureLoading,
        defaultValue: unitMeasureIdDefault,
        options:
          listunitsMeasurements && listunitsMeasurements.data
            ? listunitsMeasurements.data.map(
                (x: IListUnitMeasurementByClassData) => ({
                  id: x.id,
                  content: x.unidadeAbreviacao,
                })
              )
            : [],
      }
    : undefined;

  const setProductCodeValueAndProductName = (value: string) => {
    productList.data.forEach((e: IProductGroupListData) => {
      if (e.externalId === value) {
        setCurrentProduct(e);
        if (!form.values.itens) {
          form.values.itens = [];
        }
        form.values.itens[index] = form.values.itens[index] ?? {};
        form.values.itens[index].descricaoProduto = e.descricao;
        form.values.itens[index].unidadeMedidaId = e.unidadeMedidaId;
      }
    });
    addRow();
  };

  const {
    data: ncmList,
    isLoading: isLoadingNcm,
    fetchNewPage: fetchNewNcmPage,
    refetch: refetchSearchNcmPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_NCM,
    entityApi: InvoiceAPI.listDropdownNcm,
    autoIncrement: true,
    autoIncrementCustomId: 'id',
  });

  const {
    data: cfopList,
    isLoading: isLoadingCfop,
    fetchNewPage: fetchNewCfopPage,
    refetch: refetchSearchCfopPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CFOP,
    entityApi: InvoiceAPI.listDropdownCfop,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    autoIncrement: true,
    autoIncrementCustomId: 'id',
  });

  const {
    data: cstList,
    isLoading: isLoadingCst,
    fetchNewPage: fetchNewCstPage,
    refetch: refetchSearchCstPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CST,
    entityApi: InvoiceAPI.listDropdownCst,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true,
    autoIncrementCustomId: 'id',
  });

  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
  };

  const deleteRow = () => {
    if (!form.values.itens) return;
    form.values.itens = form.values.itens.filter(
      (_: any, i: number) => i !== index
    );
    if (totalRows === 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
    onDelete(index);
  };

  const getTotalProduct = () => {
    if (!form.values.itens) return '';
    const unitValue = form.values.itens[index]?.valorUnitario;
    const quantity = form.values.itens[index]?.quantidadeComprada;
    if (unitValue && quantity) {
      const result = unitValue * quantity;
      return result.toString().replace('.', ',');
    } else {
      return '';
    }
  };

  const newProductList = productList.data ? productList.data : productList;

  useEffect(() => {
    if (!!form.values.itens && !!form.values.itens[index] && !!productList) {
      const filteredProduct = newProductList.filter(
        (e: IProductGroupListData) =>
          e.externalId === form.values.itens[index].produtoExternalId
      );
      setCurrentProduct(filteredProduct[0]);
      setClassProduct((prev) => filteredProduct[0]?.classeProdutoId ?? prev);
    }
  }, [productList, form.values]);

  useEffect(() => {
    if (currentProduct) {
      form.setFieldValue(
        `itens.${index}.unidadeMedidaId`,
        currentProduct.unidadeMedidaId
      );
    }
  }, [currentProduct]);

  useEffect(() => {
    if (isNil(classProduct)) return;
    setCustomFilter([
      {
        filterName: 'tipoClasseProduto',
        value: classProduct,
      },
    ]);
  }, [classProduct]);

  return (
    <>
      <Row wrap={false} gutter={[16, 0]} style={{ position: 'relative' }}>
        <Col
          span={2}
          style={{
            position: 'sticky',
            left: 0,
            top: -10,
            zIndex: 1,
            background: 'var(--color-background-01)',
          }}
        >
          <TextInput
            label={
              index === 0 ? t('purchasing.invoice.create.productCode') : ''
            }
            disabled
            name={`itens.${index}.codProduto`}
            required
            value={currentProduct?.sequenciaGroupTenant.toString()}
          />
        </Col>
        <Col
          span={4}
          style={{
            position: 'sticky',
            left: '125px',
            zIndex: 1,
            background: 'var(--color-background-01)',
          }}
        >
          <InputWithSearch
            actionOnFocus={() => {
              const element = document.getElementById(scrollContainerId)!;
              element.scroll(0, 0);
            }}
            getPopupContainerId={cardId}
            label={index === 0 ? t('purchasing.invoice.create.product') : ''}
            name={`itens.${index}.produtoExternalId`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.searchForProduct'
            )}
            items={newProductList.map((x: IProductGroupListData) => ({
              id: x?.externalId,
              label: x?.descricao,
              subLabel: `${t(
                'purchasing.purchaseOrder.create.subLabelProduct'
              )} ${x.sequenciaGroupTenant}`,
            }))}
            dropdownMinWidth={300}
            onScrollEnd={fetchNewProductPage}
            onChange={setProductCodeValueAndProductName}
            onSearch={(search?: string) => {
              refetchSearchProductPage({
                pageIndex: 0,
                pageSize: 50,
                search: search,
              });
            }}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            label={index === 0 ? t('purchasing.invoice.create.NCM') : ''}
            name={`itens.${index}.ncmId`}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.NCMPlaceHolder'
            )}
            onChange={addRow}
            dropdownMinWidth={300}
            isLoading={isLoadingNcm}
            onScrollEnd={fetchNewNcmPage}
            onSearch={(search?: string) =>
              refetchSearchNcmPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              })
            }
            items={configNcmList(ncmList)}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            label={index === 0 ? t('purchasing.invoice.create.CST') : ''}
            name={`itens.${index}.cstCsosnId`}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CSTPlaceHolder'
            )}
            onChange={addRow}
            dropdownMinWidth={300}
            isLoading={isLoadingCst}
            onScrollEnd={fetchNewCstPage}
            onSearch={(search?: string) => {
              refetchSearchCstPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              });
            }}
            items={configCstList(cstList)}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            label={index === 0 ? t('purchasing.invoice.create.CFOP') : ''}
            name={`itens.${index}.cfopId`}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CFOPPlaceHolder'
            )}
            onChange={addRow}
            dropdownMinWidth={300}
            isLoading={isLoadingCfop}
            onScrollEnd={fetchNewCfopPage}
            onSearch={(search?: string) => {
              refetchSearchCfopPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
              });
            }}
            items={configCfopList(cfopList)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.unitValue') : ''}
            name={`itens.${index}.valorUnitario`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={addRow}
          />
        </Col>
        <Col span={3}>
          <WeightInput
            name={`itens.${index}.quantidadeComprada`}
            label={index === 0 ? t('purchasing.invoice.create.quantity') : ''}
            dropdownRight={{
              options: unitOfMeasureDropdown
                ? unitOfMeasureDropdown.options
                : [],
              name: `itens.${index}.unidadeMedidaId`,
            }}
            required={true}
            placeHolder="0,0000"
            onChange={addRow}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.total') : ''}
            name={`itens.${index}.total`}
            required
            disabled
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            value={getTotalProduct()}
            onChange={addRow}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.calcICMS') : ''}
            name={`itens.${index}.baseCalculoIcms`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={addRow}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.ICMSVal') : ''}
            name={`itens.${index}.valorIcms`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={addRow}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.IPIVal') : ''}
            name={`itens.${index}.valorIpi`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={addRow}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.aliquota') : ''}
            name={`itens.${index}.aliquotaIpi`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={addRow}
          />
        </Col>
        <Col>
          <Button
            disabled={index === 0 && !form.values?.itens?.length}
            style={{ marginTop: `${index === 0 ? '20px' : 0}` }}
            type="secondary"
            onClick={() => setModalVisible(true)}
          >
            <Icon
              style={{
                cursor:
                  index === 0 && !form.values?.itens?.length
                    ? 'not-allowed'
                    : 'default',
              }}
              icon="trash"
              size="M"
            />
          </Button>
        </Col>
      </Row>
      <DeleteProductModal
        visible={modalVisible}
        deleteRow={deleteRow}
        changeVisibleModal={(value) => setModalVisible(value)}
        product={currentProduct?.descricao}
      />
    </>
  );
};

export default ProductRow;

import { useFormikContext } from "formik";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useInfoCardFunctions = ({ setPersonType }: IUseFunctions) => {
  const form = useFormikContext();
  
  const changePersonType = useCallback(
    (personType: string) => {
      setPersonType(parseInt(personType));
      form.setFieldValue('cpf', undefined);
      form.setFieldValue('dataNascimento', undefined);
      form.setFieldValue('razaoSocial', undefined);
      form.setFieldValue('cnpj', undefined);
    },
    [form]
  );

  return { changePersonType };
};
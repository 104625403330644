import { IListUserGroupForDropdownData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useCallback, useEffect } from 'react';
import { IUseFunctions } from './interfaces';

export const useUserFormFunctions = ({
  externalId,
  removeUserInUserGroup,
  userListRefetch,
  setSelectedRowsOnTable,
}: IUseFunctions) => {
  const deleteUsers = async (users: IListUserGroupForDropdownData[]) => {
    await removeUserInUserGroup.mutateAsync({
      grupoExternalId: externalId,
      usuarios: users.map((x) => x.externalId),
    });
  };

  const selectRowsOnTable = useCallback(
    (x: any) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  useEffect(() => {
    userListRefetch({
      pageIndex: 0,
      pageSize: 10,
      filter: [
        {
          filterName: 'grupoExternalId',
          value: externalId,
        },
      ],
    });
  }, [externalId, userListRefetch]);

  return {
    deleteUsers,
    selectRowsOnTable,
  };
};

import { useTranslation } from 'react-i18next';
import { StandardFormulaDetails } from './Components/StandardFormulaDetails';
import { Form, Card, Button } from '@/Components';
import { ProductCard } from './Components/ProductCard/index';
import { Col, Row } from 'antd';
import { StandardFormulaSpecifications } from './Components/StandardFormulaSpecifications';
import { StandardFormulaCreateSchema } from './CreateStandardFormula.schema';
import { 
  IProductStandardFormulaResquest 
} from '@/Data/Interfaces/request/StandardFormula/IStandardFormulaRequest';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateFunctions } from './Hooks/useCreateFunctions';

import styles from './Create.module.scss';

export const CreateStandardFormula: React.FC = () => {
  const { t } = useTranslation();

  const {
    createStandardFormula
  } = useCreateCallAPI();

  const {
    pharmaceuticalFormExternalIdSelected,
    setPharmaceuticalFormExternalIdSelected
  } = useCreateStates();

  const {
    onSubmit,
    onClickCancelButton
  } = useCreateFunctions({
    createStandardFormula
  });

  return (
    <>
      <Form onSubmit={onSubmit} schema={StandardFormulaCreateSchema}>
        {({ values }) => (
          <>
            <Card title={t('common.details')} withoutCollapse={true}>
              <StandardFormulaDetails
                setPharmaceuticalFormSelected={
                  setPharmaceuticalFormExternalIdSelected
                }
              />
            </Card>
            <Row justify="space-between" gutter={[16, 0]}>
              <Col span={24}>
                <ProductCard
                  pharmaceuticalFormExternalId={
                    pharmaceuticalFormExternalIdSelected
                  }
                />
              </Col>
            </Row>
            <Card title={t('common.specifications')} withoutCollapse={true}>
              <StandardFormulaSpecifications />
            </Card>
            <div className={styles['footer-button']}>
              <Button
                type="secondary"
                children={t('common.cancel')}
                style={{ marginBottom: '20px' }}
                onClick={onClickCancelButton}
              />
              <Button
                type="primary"
                children={t('common.create')}
                htmlType="submit"
                loading={createStandardFormula.isLoading}
                disabled={
                  !values.produtoExternalId ||
                  !values.formaFarmaceuticaExternalId ||
                  !values.quantidadePadrao ||
                  !values.formulaPadraoDesmembramento ||
                  values.produtos?.length < 1 ||
                  !values.produtos?.every(
                    (x: IProductStandardFormulaResquest) => {
                      return (
                        !!x.produtoExternalId && !!x.tipoItem && !!x.quantidade
                      );
                    }
                  )
                }
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
};

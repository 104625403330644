import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Text, i18n, Skeleton } from '../../';
import { Colors } from '../../../Utils/ColorUtils';
import { PopOver } from '../../PopOver';
import { getMenuById, SideMenuItems } from '../SideMenu.items';
import { SectorItem } from './components/SectorItem';
import { history } from '../../../Store';
import { motion } from 'framer-motion';
import { IMenuItem } from '../MenuItem';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';
import { Context, CurrentContextType } from '@/Contexts/context';

import styles from './SectorMenu.module.scss';

export interface ISectorMenu {
  isCollapsed: boolean;
  modules?: string[];
}

export const SectorMenu: FC<ISectorMenu> = ({ isCollapsed, modules }) => {
  const [open, setOpen] = useState(false);

  const isSingleModule = useMemo(() => modules?.length === 1, [modules]);
  const { currentContext } = React.useContext(Context) as CurrentContextType;

  const menu = getMenuById(currentContext);

  return (
    <>
      <PopOver
        visible={open}
        onOpenChange={() => !isSingleModule && setOpen(!open)}
        placement="rightBottom"
        title={headerSelectorMenu()}
        content={
          <ContentSelectorMenu modules={modules || []} setOpen={setOpen} />
        }
        trigger="click"
      >
        {!!currentContext ? (
          <Button
            type="no-color"
            noIconPadding={true}
            leftIcon={menu?.context?.icon || 'setting'}
            rightIcon={'chevron-right'}
            className={styles['btn-color']}
            fullWidth={true}
            style={{
              backgroundColor: `${
                Colors[menu?.context.backgroundColor || 'white']
              }`,
            }}
            disabled={isSingleModule}
            contentChild="start"
          >
            <motion.div
              style={{ overflow: 'hidden' }}
              animate={{ width: isCollapsed ? '0px' : '100%' }}
            >
              <div style={{ marginLeft: 10, width: 100, display: 'flex' }}>
                <Text
                  type="ui-tiny-bold"
                  children={menu ? (i18n.t(menu.context.name) as string) : ''}
                />
              </div>
            </motion.div>
          </Button>
        ) : (
          <div className={styles['skeleton']}>
            <Skeleton size="gg" />
          </div>
        )}
      </PopOver>
    </>
  );
};

export const headerSelectorMenu = () => {
  return (
    <div className={styles['header']}>
      <Text type="paragraph2" color="text-400" className={styles['text']}>
        Alterar para setor
      </Text>
      <div className={styles['separator']}></div>
    </div>
  );
};

interface IContentSelectorMenu {
  setOpen: (open: boolean) => void;
  modules: string[];
}

export const ContentSelectorMenu: FC<IContentSelectorMenu> = ({
  modules,
  setOpen,
}) => {
  const { updateContext } = React.useContext(Context) as CurrentContextType;

  const getDefaultPage = useCallback((items: IMenuItem[]) => {
    const currentPermissions = getCurrentUserRoles();
    return items.find((x) =>
      currentPermissions.some((y) => y === x.permission?.permission)
    );
  }, []);

  const onClick = (contextName: string) => {
    updateContext(contextName);
    setOpen(false);
  };

  const items = SideMenuItems.filter((item) =>
    modules.includes(item.context.id)
  );

  return (
    <div className={styles['content']}>
      {items.map((item) => (
        <div
          className={styles['SectorItem']}
          key={item.context.id}
          onClick={() => {
            const defaultPage = getDefaultPage(item.items)?.link;
            defaultPage && history.push(defaultPage);
            onClick(item.context.id);
          }}
        >
          <SectorItem
            icon={item.context.icon}
            backgroundColor={item.context.backgroundColor}
            text={i18n.t(item.context.name)}
          />
        </div>
      ))}
    </div>
  );
};

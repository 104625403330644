import React, { useCallback } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputWithSearch, TextInput } from '../../../../../../../../../Components';
import { FETCH_LIST_DROPDOWN_NATURE_OF_OPERATION } from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/NatureOfOperationKeys';
import { configNatureOfOperationList } from '../../../../../Create/utils/functions';
import { NumberInput } from '../../../../../../../../../Components/Inputs/NumberInput';
import { Tooltip } from '../../../../../../../../../Components';
import { useFormikContext } from 'formik';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { NatureOfOperationAPI } from '@/Data/API/Purchase/NatureOfOperationApi';

interface IFirstRowProps {
  initialValues: any;
}

const FirstRow: React.FC<IFirstRowProps> = ({ initialValues }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    data: listNatureOfOperation,
    isLoading: isLoadingNatureOfOperation,
    fetchNewPage: fetchNewNatureOfOperationPage,
    refetch: refetchSearchNatureOfOperationPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_NATURE_OF_OPERATION,
    entityApi: NatureOfOperationAPI.listDropDownNatureOfOperation,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  const checkInitialValues = useCallback(
    (key: string) => {
      if (form.values) {
        return form.values[key] !== initialValues[key];
      }
      return false;
    },
    [form.values]
  );

  const formatNumber = (number: string) => {
    const formattedNumber = number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return formattedNumber;
  };

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="notaFiscal"
          label={t('purchasing.invoice.create.invoiceModel')}
          value={t('purchasing.invoice.create.placeHolder.invoiceModelValue')}
          disabled
        />
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('numero')}
          title={`${t('purchasing.invoice.edit.originalNumber')}${formatNumber(
            `${initialValues.numero}`
          )}`}
        >
          <NumberInput
            name="numero"
            required
            label={t('purchasing.invoice.create.invoiceNumber')}
            placeHolder={`${t('common.exampleAbbreviation')}: 0025`}
            withThousandSeparator
            withDecimalSeparator
            withStatusIcon
            maxLength={11}
          />
        </Tooltip>
      </Col>
      <Col span={2}>
        <Tooltip
          showMe={checkInitialValues('serie')}
          title={`${t('purchasing.invoice.edit.originalSerie')}${
            initialValues.serie
          }`}
        >
          <TextInputWithMask
            name="serie"
            label={t('purchasing.invoice.create.invoiceSeries')}
            placeHolder={`${t('common.exampleAbbreviation')}: 5`}
            withStatusIcon
            required
          />
        </Tooltip>
      </Col>
      <Col span={10}>
        <Tooltip
          showMe={checkInitialValues('naturezaOperacaoId')}
          title={`${t('purchasing.invoice.edit.originalNatureOperation')}${
            initialValues.naturezaOperacaoDescricao
          }`}
          targetOffset={[200, 0]}
        >
          <InputWithSearch
            label={t('purchasing.invoice.create.natureOfOperation')}
            name="naturezaOperacaoId"
            isLoading={isLoadingNatureOfOperation}
            items={configNatureOfOperationList(listNatureOfOperation ?? [])}
            onScrollEnd={fetchNewNatureOfOperationPage}
            onSearch={(search) =>
              refetchSearchNatureOfOperationPage({
                ...natureOfOperationPagination,
                search: search,
              })
            }
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.natureOfOperation'
            )}
            withStatusIcon
            required
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

const natureOfOperationPagination = {
  pageIndex: 0,
  pageSize: 10,
};

export default FirstRow;

import { useState } from "react";

export const useDetailsStates = () => {
  const [
    isErrorValidateDescription, 
    setIsErrorValidateDescription
  ] = useState<boolean>(false);

  return {
    isErrorValidateDescription,
    setIsErrorValidateDescription
  };
};
import { FC } from "react";
import { IDropdown, IIcon } from "../../types";
import { Tooltip, Text, Icon } from "@/Components";
import { Select } from "antd";
import { useField } from "formik";

import styles from '../VisualInput.module.scss';

export interface BeforeAddonProps {
    leftIcon?: IIcon;
    dropdownLeft?: IDropdown;
    disabled?: boolean;
    dataTestId?: string;
}

export const BeforeAddon: FC<BeforeAddonProps> = ({ leftIcon, dropdownLeft, dataTestId, disabled }) => {
    const dropdownLeftField = useField(dropdownLeft?.name || 'dropdownLeft');
    
    if (leftIcon?.titleString) {
        return (
            <Tooltip
            title={
              leftIcon.toolTipTitle && leftIcon.toolTipTitle[0]
            }
            showMe={!!leftIcon.toolTipTitle}
            placement="bottomRight"
          >
            <Text
              className={styles['Icon-TitleString']}
              type="ui-tiny-content"
              color="text-300"
              children={leftIcon?.titleString}
            />
          </Tooltip>
        )
    }

    if (!dropdownLeft) {
        return null
    }
    
    return (
          <Select
            data-testid={dataTestId}
            value={dropdownLeftField[1]?.value}
            defaultValue={dropdownLeft.defaultValue}
            style={{ width: 60 }}
            loading={dropdownLeft.loading}
            onChange={(selectedValue) => dropdownLeftField[2].setValue(selectedValue)}
            disabled={disabled}
            onSelect={(id) =>
              dropdownLeft.onChange && dropdownLeft.onChange(id)
            }
          >
            {
                dropdownLeft?.options.map((x) => (
                    <Select.Option
                        data-testid={`${dataTestId || dropdownLeft?.name || 'dropdownLeft'}-options-${x.id}`}
                        key={`${x.id}_${x.content}`}
                        value={x.id}
                    >
                        {x.content ? (
                        <Text
                            type="ui-tiny-content"
                            color="text-300"
                            children={x.content}
                        />
                        ) : (
                        x.icons && (
                            <Icon icon={x.icons} color="text-300" />
                        )
                        )}
                    </Select.Option>
                ))
            }
          </Select>
        )
}
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '../../../../../../../../../Components/Inputs/CurrencyInput';
import { useFormikContext } from 'formik';

interface IThirdRowProps {
  importedProducts: any[];
}

const ThirdRow: React.FC<IThirdRowProps> = ({ importedProducts }) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const calculateTotalInvoice = () => {
    const values = JSON.parse(JSON.stringify(form.values));
    const freight = values.valorFrete ? values.valorFrete : 0;
    const insurance = values.valorSeguro ? values.valorSeguro : 0;
    const discount = values.valorDesconto ? values.valorDesconto : 0;
    const otherExpenses = values.valorOutrasDespesas
      ? values.valorOutrasDespesas
      : 0;

    let result = freight + insurance - discount + otherExpenses;

    if (
      values.valorFrete === undefined &&
      values.valorSeguro === undefined &&
      values.valorDesconto === undefined &&
      values.valorOutrasDespesas === undefined &&
      values.itens === undefined
    ) {
      return;
    }

    if (values.itens) {
      const totalItems = values.itens.reduce((acc: any, item: any) => {
        const quantity = item.quantidadeComprada ? item.quantidadeComprada : 0;
        const unitValue = item.valorUnitario ? item.valorUnitario : 0;

        return acc + quantity * unitValue;
      }, 0);
      form.setFieldValue('valorProdutos', totalItems);
      result += totalItems;
    }

    form.setFieldValue('valorTotalNotaFiscal', result);
  };

  useEffect(() => {
    calculateTotalInvoice();
  }, [form.values, importedProducts]);

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.totalInvoice')}
          name="valorTotalNotaFiscal"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
          disabled
        />
      </Col>
    </Row>
  );
};

export default ThirdRow;

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_LIST_SALES_ORDER } from '../../../../../ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import { CREATE_SERVICE } from '../../../../../ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { i18n, Table } from '../../../../../Components';
import { ticketsCollums } from './tickets.columns';
import { CreateServiceModal } from '../../Order/List/Modal/CreateOrder';
import { history } from '../../../../../Store';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import StorageUtils from '@/Utils/StorageUtils';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';

export const List: FC = () => {
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);

  const { t } = useTranslation();

  const {
    data: serviceList,
    isLoading,
    refetch: refetchServiceList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SALES_ORDER,
    entityApi: SalesOrderAPI.listSalesOrder,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'codigoPedidoVenda', direction: 'DESC' },
    },
  });

  const preferences = StorageUtils.getPreference();

  const queryClient = useQueryClient();

  const createSaleOrder = useControllerMutateApiHook({
    uniqId: CREATE_SERVICE,
    entityApi: ServiceAPI.createService,
    options: {
      onSuccess: () => {
        setVisibleCreateModal(false);
        queryClient.invalidateQueries([FETCH_LIST_SALES_ORDER]);
        messageSucessToast(i18n.t('callCenter.messages.create'));
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const onSubmit = async (values: any) => {
    await createSaleOrder.mutateAsync({
      ...values,
      criarPedidoVenda: true,
    });
  };

  return (
    <PageLoading loading={isLoading}>
      <Table
        columns={ticketsCollums(t, preferences)}
        headerOptions={{
          hasSearch: true,
          searchPlaceholder: t('saleOrder.list.searchPlaceholder'),
          newButton: {
            label: t('saleOrder.list.new'),
            onClick: () => setVisibleCreateModal(true),
            permission: {
              permission: PermissionEnum.Vendas_PedidosVenda_Cadastrar,
              tooltip: t('noPermission.sale.order.create'),
            },
            dataTestId: 'create-sale-order',
          },
        }}
        rowsOptions={{
          onRowDoubleClick: (x) =>
            history.push(`/sales/tickets/${x.atendimentoExternalId}/order`),
          permission: {
            permission: PermissionEnum.Vendas_PedidosVenda_VerDetalhes,
            tooltip: t('noPermission.sale.order.detail'),
          },
        }}
        loading={isLoading}
        pagination={serviceList}
        onRefetch={refetchServiceList}
      />

      <CreateServiceModal
        changeVisible={(x) => setVisibleCreateModal(x)}
        visible={visibleCreateModal}
        onSubmit={onSubmit}
        isLoading={createSaleOrder.isLoading}
      />
    </PageLoading>
  );
};

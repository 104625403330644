import { Button } from "@/Components";
import { Divflex } from "@/Layouts/DivWhithFlex";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { history } from '@/Store';
import { useFooterButtonCallAPI } from "./Hooks/useFooterButtonCallAPI";
import { IFooterButton } from "./interfaces";

export const FooterButton: FC<IFooterButton> = ({ props }) => {
  const { t } = useTranslation();

  const { createLoading } = useFooterButtonCallAPI();

  return(
    <Divflex>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => history.push('/admin/user-group')}
        style={{ marginRight: 10 }}
        dataTestId='cancel'
      />
      <Button
        type="primary"
        children={
          createLoading ? t('common.creating') : t('common.create')
        }
        htmlType="submit"
        dataTestId='submit'
        loading={createLoading}
        disabled={!props.values.nome}
      />
    </Divflex>
  );
};
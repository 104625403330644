import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IContinueUse } from "./interfaces";
import { Checkbox, Tooltip } from "@/Components";
import { useFormikContext } from "formik";

import styles from "./ContinueUse.module.scss";

export const ContinueUse: FC<IContinueUse> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={6} className={styles['checkbox']}>
      <Tooltip
        title={`${t('products.toolTips.products.originalValue')} ${
          product?.usoContinuo === true
            ? t('products.toolTips.products.enabled')
            : t('products.toolTips.products.disabled')
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.usoContinuo !== form?.values?.usoContinuo
        }
      >
        <Checkbox
          name="usoContinuo"
          label={t('products.create.continueUse')}
          disabled={disabledForm}
          withStatusIcon={!!product}
          withTooltip={{
            icon: 'question-mark',
            title: t('products.create.continueUseTooltip'),
          }}
        />
      </Tooltip>
    </Col>
  );
};
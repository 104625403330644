import { IListUnitMeasurementByClassData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { IUseMemorizations } from "./interfaces";

export const useUnitPrescriptionMemorizations = ({
  listUnitsMeasurementsPrescription
}: IUseMemorizations) => {
  const items = listUnitsMeasurementsPrescription
  ? listUnitsMeasurementsPrescription?.data?.map((e: IListUnitMeasurementByClassData) => ({
      id: e.id,
      label: `${e.descricao} (${e.unidadeAbreviacao})`,
    }))
  : [];

  return { items };
};
import { 
  FETCH_GET_ORDER_PURCHASE 
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { 
  IPurchaseOrderGetResponse 
} from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import { IUseCallAPI } from './interfaces';

export const useDetailsCallAPI = ({
  externalId
}: IUseCallAPI) => {
  const { data: orderPurchase } = useControllerQueryApiHook<IPurchaseOrderGetResponse>({
    uniqId: FETCH_GET_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOrderPurchase,
    externalId: externalId
  });

  return {
    orderPurchase
  };
};
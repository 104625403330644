import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import { GET_USER_GROUP_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import { IUseCallAPI } from './interfaces';
import { IUserGroup } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const useEditUserGroupsCallAPI = ({ externalId }: IUseCallAPI) => {
  const { data: userGroup, isLoading } = useControllerQueryApiHook<IUserGroup>({
    uniqId: GET_USER_GROUP_TYPE,
    entityApi: UserGroupAPI.getUserGroup,
    externalId: externalId,
  });

  return { userGroup, isLoading };
};

import React, {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Modal as AntdModal } from 'antd';
import { Icon } from '../Icon';
import { Footer, IFooter } from './Components/Footer';
import { Form } from '..';
import { Color } from '../../Utils/ColorUtils';
import { IFChildren } from '../Form';
import { IconName } from '../Icon/IconClasses';
import { ButtonStatus, ButtonType } from '../Button';
import { useFormikContext } from 'formik';

import './Modal.override.scss';

export interface IModal extends IFooter {
  title: ReactNode;
  body: ReactNode;
  footerText?: ReactNode;
  visible?: boolean;
  cancelButtonName?: string;
  okButtonName?: string;
  okButtonColor?: Color;
  okButtonType?: ButtonType;
  okButtonClassName?: string;
  okButtonLeftIcon?: IconName;

  onOkClickForm?: (values: any, formik: any) => void;
  onClose?: () => void;

  disabledButton?: boolean;
  getContainer?: string | false | HTMLElement;
  footer?: boolean;
  withForm?: boolean;
  initialValues?: any;
  maxWidth?: number;
  minWidth?: number;
  maxHeight?: number;
  disabledButtonFormNames?: string[];
  okButtonStatus?: ButtonStatus;

  loading?: boolean;

  withoutOkButton?: boolean;

  formValues?: (values: any) => void;

  dropdownRight?: {
    label: string;
    onClick: () => void;
  }[];

  noLeaveWithoutLeaving?: boolean;

  onReturn?: () => void;
  showReturnButton?: boolean;
  returnButtonName?: string;
  returnButtonIcon?: IconName;

  widthModal?: number;

  dataTestIdOkButton?: string;
  dataTestIdCancelButton?: string;
}

export const Modal: FC<IModal> = ({
  title,
  body,
  footerText,
  visible,
  buttonOkWidth,
  cancelButtonName,
  okButtonName,
  okButtonColor,
  okButtonType,
  buttonCancelWidth,
  okButtonClassName,
  onClose,
  onCancelClick,
  onOkClick,
  htmlType,
  onOkClickForm,
  className,
  disabledButton,
  initialValues,
  maxWidth,
  minWidth,
  maxHeight,
  getContainer,
  footer = true,
  withForm,
  disabledButtonFormNames = [],
  loading,
  withoutOkButton,
  okButtonLeftIcon,
  okButtonStatus,
  formValues,
  dropdownRight,
  noLeaveWithoutLeaving,
  onReturn,
  showReturnButton,
  returnButtonIcon,
  returnButtonName,
  widthModal,
  dataTestIdOkButton,
  dataTestIdCancelButton,
}) => {
  const [isVisible, setIsVisible] = useState(visible);

  const enableButtonOk = (
    disabledButton: boolean | undefined,
    disabledButtonFormNames: string[],
    propsForm: any
  ) => {
    if (typeof disabledButton === 'boolean') return disabledButton;

    let disabled: boolean = false;

    disabledButtonFormNames.forEach((e) => {
      if (!propsForm.values[e]) disabled = true;
    });

    return disabled;
  };

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <AntdModal
      title={title}
      open={isVisible}
      onCancel={() => onClose && onClose()}
      footer={<></>}
      getContainer={getContainer}
      closeIcon={<Icon icon="close-x" color="text-400" size="XXL" />}
      className={className ? className : ''}
      width={widthModal || 400}
      style={{
        minWidth,
        maxWidth,
      }}
    >
      {!!withForm ? (
        <Form
          onSubmit={onOkClickForm && onOkClickForm}
          initialValues={initialValues}
          noLeaveWithoutLeaving={noLeaveWithoutLeaving}
          style={{ height: '100%' }}
        >
          {(propsForm) => {
            return (
              <div style={{ height: 'calc(100% - 60.8px)' }}>
                <BodyModalForm
                  body={body}
                  visible={!!visible}
                  changeVisible={(value) => setIsVisible(value)}
                  propsForm={propsForm}
                  formValues={(x) => formValues && formValues(x)}
                />
                {footer && (
                  <Footer
                    okButtonClassName={okButtonClassName}
                    okButtonName={okButtonName}
                    cancelButtonName={cancelButtonName}
                    buttonOkWidth={buttonOkWidth && buttonOkWidth}
                    buttonCancelWidth={buttonCancelWidth && buttonCancelWidth}
                    onCancelClick={() => {
                      propsForm.resetForm();
                      onCancelClick && onCancelClick();
                    }}
                    onOkClick={onOkClick}
                    footerText={footerText}
                    htmlType={htmlType}
                    okButtonColor={okButtonColor}
                    disabledButton={enableButtonOk(
                      disabledButton,
                      disabledButtonFormNames,
                      propsForm
                    )}
                    dataTestId={dataTestIdOkButton}
                    cancelDataTestId={dataTestIdCancelButton}
                    withForm
                    withoutOkButton={withoutOkButton}
                    propsForm={propsForm}
                    loadingOkButton={loading}
                    dropdownRight={dropdownRight}
                    onReturn={onReturn}
                    showReturnButton={showReturnButton}
                    returnButtonIcon={returnButtonIcon}
                    returnButtonName={returnButtonName}
                    okButtonType={okButtonType}
                  />
                )}
              </div>
            );
          }}
        </Form>
      ) : (
        <>
          <BodyModal
            body={body}
            visible={!!visible}
            changeVisible={(value) => setIsVisible(value)}
            maxWidth={maxWidth}
            minWidth={minWidth}
            maxHeight={maxHeight}
          />
          {footer && (
            <Footer
              okButtonClassName={okButtonClassName}
              okButtonName={okButtonName}
              status={okButtonStatus}
              cancelButtonName={cancelButtonName}
              buttonOkWidth={buttonOkWidth && buttonOkWidth}
              buttonCancelWidth={buttonCancelWidth && buttonCancelWidth}
              onCancelClick={onCancelClick}
              onOkClick={onOkClick}
              footerText={footerText}
              htmlType={htmlType}
              dataTestId={dataTestIdOkButton}
              leftIcon={okButtonLeftIcon}
              okButtonColor={okButtonColor}
              disabledButton={disabledButton}
              withoutOkButton={withoutOkButton}
              loadingOkButton={loading}
              okButtonType={okButtonType}
            />
          )}
        </>
      )}
    </AntdModal>
  );
};

interface IBodyModal {
  body: ReactNode;
  changeVisible?: (value: boolean) => void;
  visible?: boolean;

  style?: CSSProperties;

  maxWidth?: number | string;
  minWidth?: number | string;
  maxHeight?: number | string;
  propsForm?: IFChildren;
  formValues?: (values: any) => void;
}

export const BodyModal: FC<IBodyModal> = ({
  body,
  visible,
  changeVisible,
  maxWidth,
  minWidth,
  maxHeight,
  style,
  propsForm,
  formValues,
}) => {
  return (
    <div
      style={{
        maxWidth: maxWidth,
        minWidth: minWidth,
        maxHeight: maxHeight,
        height: '100%',
        ...style,
      }}
    >
      {body}
    </div>
  );
};
export const BodyModalForm: FC<IBodyModal> = ({
  body,
  visible,
  changeVisible,
  maxWidth,
  minWidth,
  maxHeight,
  style,
  propsForm,
  formValues,
}) => {
  const form = useFormikContext();
  useEffect(() => {
    if (!visible) {
      propsForm?.resetForm();
      changeVisible && changeVisible(false);
    } else changeVisible && changeVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    formValues && formValues(form?.values);
  }, [form?.values, formValues]);

  return (
    <div
      style={{
        maxWidth: maxWidth,
        minWidth: minWidth,
        maxHeight: maxHeight,
        height: '100%',
        ...style,
      }}
    >
      {body}
    </div>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../Components/Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetPosology } from '../../../../../../../Data/Interfaces/response/Posology/IPosologyResponse';
import { useEditCallAPI } from '../../Hooks/useEditCallAPI';
import { useEditFunctions } from '../../Hooks/useEditFunctions';
import ReactMarkdown from 'react-markdown';

interface IDeletePosologyModal {
  posology: IGetPosology;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeletePosologytModal: FC<IDeletePosologyModal> = ({
  posology,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const externalId = posology.externalId;

  const { deletePosology } = useEditCallAPI({ externalId });

  const { onDelete } = useEditFunctions({ posology, deletePosology });

  return (
    <Modal
      title={
        <ReactMarkdown>
          {t('common.deletePosologynDetail', {
            posology: `${posology?.posologiaDescricao}`,
          })}
        </ReactMarkdown>
      }
      body={
        <div style={{ padding: '20px' }}>
          <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('production.posology.details.modal.messageQuestionDelete', {
                  posology: `**${posology?.posologiaDescricao}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onDelete}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      widthModal={400}
      okButtonType="danger"
    />
  );
};

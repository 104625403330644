import {
  FETCH_GET_PRODUCT,
  FETCH_GET_SYNONYM_PRODUCT,
  PUT_SYNONYM_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook,
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { 
  IProductResponse 
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { 
  ProductAPI 
} from '@/Data/API/Supply/Product';
import {
  messageErrorToast,
  messageSucessToast 
} from '@/Utils/MessageErrorToast';
import { 
  ISynonymRequest 
} from '@/Data/Interfaces/request/Product/ISynonymRequest';
import { IUseCallAPI } from './interfaces';
import { i18n } from '@/Components';

export const useSynonymDetailsCallAPI = ({
  productExternalId,
  associationProductId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: productExternalId
  });

  const updateSynonym: any = useControllerMutateApiHook({
    uniqId: PUT_SYNONYM_PRODUCT,
    entityApi: ProductAPI.editSynonymProduct,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(i18n.t('products.incompatible.editSuccess'))
    }
  });

  const { data: synonymData } = useControllerQueryApiHook<ISynonymRequest>({
    uniqId: FETCH_GET_SYNONYM_PRODUCT,
    entityApi: ProductAPI.getSynonymProduct,
    externalId: associationProductId
  });

  return {
    currentProduct,
    updateSynonym,
    synonymData
  };
};
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdderList, Card } from '../../../../../../../../Components';
import { IFChildren } from '@/Components/Form';
import { DocumentModal } from '@/Components/EntityInformation/Modal';

interface IDocumentCard {
  props: IFChildren;
  documentList: any;
}

export const DocumentCard: FC<IDocumentCard> = ({ props, documentList }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('document.documents')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        collum={[
          {
            label: t('document.documentType'),
            width: 8,
            key: 'tipoDocumentoId',
          },
          {
            label: t('document.document'),
            width: 8,
            key: 'identificacao',
          },
          {
            label: t('document.observation'),
            width: 8,
            key: 'observacao',
          },
        ]}
        modal={{
          editTitle: t('document.editDocument'),
          addTitle: t('document.addDocument'),
          modalFormName: ['tipoDocumentoId', 'identificacao', 'observacao'],
          modalFormRequiredName: ['tipoDocumentoId', 'identificacao'],
          body: (
            <DocumentModal
              entityType={props?.values?.tipoPessoa}
              props={props}
              documentList={documentList}
            />
          ),
          okAddButtonLabel: t('common.add'),
          okEditButtonLabel: t('common.save'),
          widthModal: 600,
        }}
        onChange={(values) => props.setFieldValue('documentos', values)}
        buttonLabel={t('document.addDocument')}
        empytDataDescription={t('document.documentEmpyt')}
        actions
        entityExternalIdName="prescritorDocumentoExternalId"
        type="documentType"
      />
    </Card>
  );
};

import {
  GET_DOCUMENT_PROVIDER
} from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { 
FETCH_GET_ORDER_PURCHASE 
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import {
  useControllerQueryApiHook
} from '@/ControllerApiHook/Controller';
import { 
  IPurchaseOrderGetResponse 
} from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import {
  IGetDocumentProviderResponse
} from '@/Data/Interfaces/response/Provider/IProviderResponse';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';
import { IUseCallAPI } from './interfaces';

export const useInfoCardCallAPI = ({
  externalId,
  externalIdProvider
}: IUseCallAPI) => {
  const { 
    data: orderPurchase 
  } = useControllerQueryApiHook<IPurchaseOrderGetResponse>({
    uniqId: FETCH_GET_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOrderPurchase,
    externalId: externalId,
  });

  const { 
    data: documentProvider, 
    isLoading: isLoadingDocumentProvider 
  } = useControllerQueryApiHook<IGetDocumentProviderResponse>({
    uniqId: GET_DOCUMENT_PROVIDER,
    entityApi: SupplierAPI.getDocumentProvider,
    externalId: externalIdProvider,
  });

  return {
    orderPurchase,
    documentProvider,
    isLoadingDocumentProvider
  };
};
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useFormSubGroupMemorizations = ({
  title,
  createSubGroup,
  editSubGroup,
  selectedRowsOnTable,
  group,
}: IUseMemorizations) => {
  const abbreviatedI18n = 'products.subGroupList.modal';
  const { t } = useTranslation();

  const onOkButtonName = useMemo(() => (
    !title
    ? createSubGroup.isLoading
      ? t(`${abbreviatedI18n}.signingUp`)
      : t(`${abbreviatedI18n}.register`)
    : editSubGroup.isLoading
    ? t('common.changing')
    : t('common.change')
  ), [title, t]);

  const initialValues = useMemo(() => (
    {
      descricao: selectedRowsOnTable && selectedRowsOnTable[0]?.descricao,
      grupoPaiExternalId: group && group[0]?.grupoExternalId,
      groupLabel: {
        id: group[0]?.grupoExternalId,
        label: group[0]?.grupoNome,
      },
    }
  ), [selectedRowsOnTable, group]);

  return {
    onOkButtonName,
    initialValues
  };
};
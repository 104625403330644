import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentModal } from '@/Components/EntityInformation/Modal';
import { IFChildren } from '@/Components/Form';

export const useDocumentCardMemorizations = (props: IFChildren) => {
  const { t } = useTranslation();

  const column = useMemo(
    () => [
      {
        label: t('document.documentType'),
        width: 8,
        key: 'tipoDocumentoId',
      },
      {
        label: t('document.document'),
        width: 8,
        key: 'identificacao',
      },
      {
        label: t('document.observation'),
        width: 8,
        key: 'observacao',
      },
    ],
    [t]
  );

  const modal = useMemo(
    () => ({
      editTitle: t('document.editDocument'),
      addTitle: t('document.addDocument'),
      modalFormName: ['tipoDocumentoId', 'identificacao', 'observacao'],
      modalFormRequiredName: ['tipoDocumentoId', 'identificacao'],
      body: <DocumentModal entityType={props.values.pessoa} />,
      okAddButtonLabel: t('common.add'),
      okEditButtonLabel: t('common.save'),
      widthModal: 600,
    }),
    [t, props.values.pessoa]
  );

  return {
    column,
    modal,
  };
};

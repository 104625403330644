import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { TextArea } from '../../../../../../../../Components/Inputs/TextArea';
import { FormikContextType, useFormikContext } from 'formik';
import { ChangeStorageLocationModal } from '../../../Modals/changeStorageLocationModal';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { StorageLocationRow } from './StorageLocationRow';

export interface IInfoCard {
  onOriginChange: (value: string) => void;
  onDestinyChange: (value: string) => void;
}

export const InfoCard: FC<IInfoCard> = ({
  onOriginChange,
  onDestinyChange,
}) => {
  const form: FormikContextType<any> = useFormikContext();
  const { t } = useTranslation();

  const {
    origin,
    changeStorageLocationModal,
    destiny,
    pagination,
    setChangeStorageLocationModal,
    setDestiny,
    setOrigin,
    setPagination,
    setStorageLocations,
    storageLocations,
  } = useCreateStates();

  const { storageLocationList, isLoadingStorageLocationList } =
    useCreateCallAPI({ pagination });

  useCreateFunctions({ storageLocationList, setStorageLocations });

  return (
    <>
      <Card title={t('supply.transfers.info')} withoutCollapse={true}>
        <StorageLocationRow
          destiny={destiny}
          isLoadingStorageLocationList={isLoadingStorageLocationList}
          onDestinyChange={onDestinyChange}
          onOriginChange={onOriginChange}
          origin={origin}
          pagination={pagination}
          setChangeStorageLocationModal={setChangeStorageLocationModal}
          setDestiny={setDestiny}
          setOrigin={setOrigin}
          setPagination={setPagination}
          storageLocations={storageLocations}
        />
        <Row style={{ marginTop: '16px' }}>
          <Col span={24}>
            <TextArea
              name={'description'}
              label={t('supply.transfers.fields.observation')}
              maxLength={200}
              rows={4}
              placeholder={t('supply.transfers.placeHolders.observation')}
            />
          </Col>
        </Row>
      </Card>

      <ChangeStorageLocationModal
        changeVisibleModal={setChangeStorageLocationModal}
        visible={changeStorageLocationModal}
        onOkClick={() => {
          setChangeStorageLocationModal(false);
          form.resetForm();
        }}
        origin={origin}
      />
    </>
  );
};

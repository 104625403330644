import { IPagination } from '@/Components/Table';
import { useState } from 'react';

export const useCreateStates = () => {
  const [paginationLaboratory, setPaginationLaboratory] = useState<IPagination>(
    {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'laboratorio', direction: 'ASC' },
    }
  );
  return {
    paginationLaboratory,
    setPaginationLaboratory,
  };
};

import * as yup from 'yup';
import { i18n } from '../../../../../Components';
import { Http } from '../../../../../Utils/Http';

export const CreateSchema = yup.object().shape({
  corCapsula: yup
    .string()
    .required(i18n.t('production.capsuleColors.create.erros.requiredName'))
    .test(
      'already_exist_validation',
      `${i18n.t('production.capsuleColors.errors.errorCapsuleColorNameExist')}`,
      (value: any) =>
        new Promise((resolve: any, reject: any) => {
          const response = Http.fetcher(
            `/pharma/producao/v1/CapsulaCor/ValidarDescricao`,
            { method: 'POST', body: JSON.stringify({ descricao: value }) }
          )
            .then((res) => {
              return resolve(true);
            })
            .catch((error) => {
              return resolve(false);
            });
        })
    ),
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form } from '../../../../../Components';
import { CreateSchema } from './CreateForm.schema';
import _ from 'lodash';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunction';
import { FooterButtons } from './Components/FooterButton';
import { RowDescription } from './Components/Rows/DescriptionRow/index';

export const CreatePackingClassification: React.FC = () => {
  const { t } = useTranslation();

  const { createPacking, createPackingIsLoading } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({
    createPackingClassification: createPacking,
  });

  return (
    <Form onSubmit={onSubmit} schema={CreateSchema}>
      {(props) => (
        <>
          <Card
            title={t('production.packingClassification.create.card')}
            withoutCollapse={true}
          >
            <RowDescription />
          </Card>

          <FooterButtons
            isLoadingCreatePackingClassification={createPackingIsLoading}
            props={props}
          />
        </>
      )}
    </Form>
  );
};

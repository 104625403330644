import { Icon, Text, Highlight, Tooltip } from '@/Components';
import { IItems } from '@/Components/Inputs/InputWithSearch/Interface';
import { FC } from 'react';

import styles from './Description.module.scss';

interface IDescriptionOption {
  item: IItems;
  currentSearch: string;

  dataTestId?: string;
}

export const DescriptionOption: FC<IDescriptionOption> = ({
  item,
  currentSearch,
  dataTestId
}) => (
  <div className={styles['content']} data-testid={dataTestId}>
    {item.icon && (
      <Icon
        icon={item.icon.icon}
        color={item.icon.color}
        className={styles['left-icon']}
        size="ML"
      />
    )}
    <div className={styles['div-description']}>
      <Tooltip
        title={item.titleTooltipItem}
        showMe={item.disabledItem}
        placement="topLeft"
      >
        <Text
          className={styles['description']}
          type="ui-tiny-content"
          color={item.disabledItem ? 'text-400' : 'text-50'}
        >
          <Highlight className={styles['text']} highlight={currentSearch}>
            {item.label || ''}
          </Highlight>
        </Text>
      </Tooltip>
      {item.subLabel && (
        <Text
          className={styles['text']}
          type="small-text"
          color="text-300"
          children={item.subLabel}
        />
      )}
    </div>
  </div>
);

import { FC } from "react";
import { Col } from 'antd';
import { Tooltip, InputWithSearch } from '@/Components';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IBatchSearch } from "./interfaces";

export const BatchSearch: FC<IBatchSearch> = ({
  lots,
  disabled,
  lotList,
  isLotLoading,
  fetchNewLotPage
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('supply.lot.details.toolTips.originalLot')} ${
          lots?.loteInformacaoTecnica?.loteOrigemExternalId
        } / ${lots?.numeroNf}`}
        showMe={
          lots?.loteInformacaoTecnica?.loteOrigemExternalId !==
            form?.values?.loteInformacaoTecnica?.loteOrigemExternalId &&
          !disabled
        }
      >
        <InputWithSearch
          name="loteInformacaoTecnica.loteOrigemExternalId"
          items={lotList?.map((x: any) => ({
            id: x.externalId,
            label: x.loteNumero,
            subLabel: `NF: ${x.numeroNf}`,
          }))}
          label={t('supply.lot.details.details.lotOrigin')}
          placeHolder={t('supply.lot.details.details.lotOrigin')}
          disabled={
            form?.values?.loteInformacaoTecnica?.diluicaoInterna !==
              100 ||
            form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna !==
              1
              ? true
              : disabled
          }
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('supply.lot.create.toolTips.lotOrigin'),
          }}
          withStatusIcon
          onScrollEnd={fetchNewLotPage}
          isLoading={isLotLoading}
        />
      </Tooltip>
    </Col>
  );
};
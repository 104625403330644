import React, { FC } from 'react';
import { Text } from '../../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../../Components/Table/components/Modals/DeleteModal';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import { DeleteModalOnTable } from '../../../../../../../../Utils/DeleteModalUtils';
import ReactMarkdown from 'react-markdown';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length === 1
    ? `${t('common.delete')} ${t('common.product')} "${
        selectedRowsOnTable[0]?.descricao
      }"?`
    : `${t('common.delete')} ${selectedRowsOnTable?.length} ${t(
        'common.products'
      )}?`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theProducts')}:`,
      'descricao',
      t('common.products')
    )
  ) : (
    <>
      <div style={{ padding: '20px' }}>
        <Divflex style={{ flexDirection: 'column', width: '400px' }}>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.productQuestion', {
                produto: `**${
                  selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
                }**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    </>
  );

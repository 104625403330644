import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { ITotal } from './interfaces';
import { useTotalMemorizations } from './Hooks/useTotalMemorizations';

export const Total: FC<ITotal> = ({
  totalProductValue,
  orderPurchase,
  totalEnding
}) => {
  const { t } = useTranslation();
  
  const {
    withStatusIconInLabel,
    withTooltip
  } = useTotalMemorizations({
    totalEnding,
    totalProductValue,
    orderPurchase
  });

  return (
    <>
      <InputLabel
        labelTitle={t('purchasing.purchaseOrder.create.total')}
        withStatusIconInLabel={withStatusIconInLabel}
        withTooltip={withTooltip}
        labelValue={currencyFormater(totalEnding)}
      />
    </>
  );
};
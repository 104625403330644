import { Col, Row } from 'antd';
import { FormikProps, FormikValues } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Dropdown,
  TextInput,
  Tooltip,
} from '../../../../../../../Components';
import { TextArea } from '../../../../../../../Components/Inputs/TextArea';
import DateUtils from '../../../../../../../Utils/DateUtils';
import { WeightInput } from '../../../../../../../Components/Inputs/WeightInput';
import { ILossDetailResponse } from '@/Data/Interfaces/response/Loss/ILossDetailResponse';
import { useEditCallAPI } from '../LossRegistration/Hooks/useEditCallAPI';
import { useEditMemorizations } from '../LossRegistration/Hooks/useEditMemorizations';

interface ILoss {
  form: FormikProps<FormikValues>;
  disableForm: boolean;
  getLossSingle?: ILossDetailResponse;
}

export const LossRegistration: FC<ILoss> = ({
  form,
  disableForm,
  getLossSingle,
}) => {
  const { t } = useTranslation();

  const { lossesReason, loadingReasonLoss, fetchNewPage, datePattern } =
    useEditCallAPI();

  const { lossReasonsList, dropdownRigthUntMedidaId, itemsReasonDropdown } =
    useEditMemorizations({
      lossesReason,
      getLossSingle,
    });

  return (
    <Card
      title={t('supply.losses.create.titleInformationLossRecord')}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]}>
        <Col span={2}>
          <Tooltip
            title={t('supply.losses.tooltips.lossDate', {
              date: DateUtils.format(
                getLossSingle?.dataPerda || '',
                datePattern || 'dd/MM/yyyy HH:mm'
              ),
              interpolation: { escapeValue: false },
            })}
            showMe={
              !disableForm && getLossSingle?.dataPerda !== form.values.lossDate
            }
          >
            <TextInput
              name="lossDate"
              leftIcon="calendar"
              disabled={disableForm}
              label={t('supply.losses.create.dateLoss')}
              placeHolder={t('supply.losses.create.dateLoss')}
              withStatusIcon
            />
          </Tooltip>
        </Col>
        <Col span={8}>
          <Tooltip
            title={`${t('supply.losses.tooltips.reason')}  ${
              getLossSingle?.motivoPerdaDescricao
            }`}
            showMe={!disableForm}
          >
            <Dropdown
              name="lossReason"
              disabled={disableForm}
              label={t('supply.losses.create.reasonloss')}
              items={itemsReasonDropdown}
              onScrollEnd={fetchNewPage}
              isLoading={loadingReasonLoss}
              withStatusIcon
            />
          </Tooltip>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Tooltip
            title={t('supply.losses.tooltips.beforeAmount')}
            showMe={!disableForm}
          >
            <WeightInput
              name="beforeLoss"
              disabled
              label={t('supply.losses.create.prevAmount')}
              placeHolder={t('supply.losses.create.prevAmount')}
              dropdownRight={dropdownRigthUntMedidaId}
            />
          </Tooltip>
        </Col>
        <Col span={8}>
          <WeightInput
            name="lossQuantity"
            disabled
            label={t('supply.losses.create.amountLoss')}
            placeHolder={t('supply.losses.create.amountLoss')}
            dropdownRight={dropdownRigthUntMedidaId}
          />
        </Col>

        <Col span={8}>
          <Tooltip
            title={t('supply.losses.tooltips.afterAmount')}
            showMe={!disableForm}
          >
            <WeightInput
              name="afterLoss"
              disabled
              dropdownRight={dropdownRigthUntMedidaId}
              label={t('supply.losses.create.nextAmount')}
              placeHolder={t('supply.losses.create.nextAmount')}
            />
          </Tooltip>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Tooltip
            title={t('supply.losses.tooltips.comments', {
              comments: getLossSingle?.observacao,
            })}
            showMe={
              !disableForm && getLossSingle?.observacao !== form.values.comments
            }
            placement="topLeft"
          >
            <TextArea
              name="comments"
              disable={disableForm}
              label={t('supply.losses.create.comments')}
              maxLength={400}
              rows={6}
              withStatusIcon
            />
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};

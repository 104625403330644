import { FC } from 'react';
import { Form } from '../../../../../Components';
import styles from './suppliersDetails.module.scss';
import { useParams } from 'react-router';
import { ContactCard } from '../../../../../Components/EntityInformation/ContactCard';
import { AdressCard } from '../../../../../Components/EntityInformation/AddressCard';
import { DocumentCard } from '../../../../../Components/EntityInformation/DocumentCard';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { InfoSupplierCard } from './Components/Cards/InfoCard';
import { useEditCallAPI } from './Hooks/useEditCallAPI';

export const SupplierDetailsTabForm: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const {
    addAddress,
    addContact,
    addDocument,
    changeFavoriteAddress,
    changeFavoriteContact,
    deleteAddress,
    deleteContact,
    deleteDocument,
    editAddress,
    editContact,
    editDocument,
    supplier,
    documentList,
  } = useEditCallAPI({ externalId });

  return (
    <Form>
      <>
        <div className={styles['content']}>
          <div className={styles['side-left']}>
            <InfoSupplierCard supplier={supplier} />
            <ContactCard
              addEntity={addContact}
              editEntity={editContact}
              deleteEntity={deleteContact}
              entityContact={supplier?.fornecedorContatos}
              entityExternalId={supplier?.fornecedorExternalId}
              entityContactExternalIdName="fornecedorContatoExternalId"
              entityExternalIdName="fornecedorExternalId"
              changeFavorite={changeFavoriteContact}
              entityContactPermission={{
                permission: PermissionEnum.Compras_Fornecedores_EditarContatos,
                tooltip: 'noPermission.purchasing.supplier.editContact',
              }}
            />
          </div>
          <div className={styles['side-right']}>
            <AdressCard
              addEntity={addAddress}
              editEntity={editAddress}
              deleteEntity={deleteAddress}
              entityAdress={supplier?.fornecedorEnderecos}
              entityExternalId={supplier?.fornecedorExternalId}
              entityAddressExternalIdName="fornecedorEnderecoExternalId"
              entityExternalIdName="fornecedorExternalId"
              changeFavorite={changeFavoriteAddress}
              entityAddressPermission={{
                permission: PermissionEnum.Compras_Fornecedores_EditarEndereco,
                tooltip: 'noPermission.purchasing.supplier.editAddress',
              }}
            />
            <DocumentCard
              addEntity={addDocument}
              editEntity={editDocument}
              deleteEntity={deleteDocument}
              entityDocuments={supplier?.fornecedorDocumentos}
              entityExternalId={supplier?.fornecedorExternalId}
              entityDocumentExternalIdName="fornecedorDocumentoExternalId"
              entityExternalIdName="fornecedorExternalId"
              entityType={supplier?.tipoPessoa!}
              entityDocumentPermission={{
                permission: PermissionEnum.Compras_Fornecedores_EditarDocumento,
                tooltip: 'noPermission.purchasing.supplier.editDocument',
              }}
              documentList={documentList}
            />
          </div>
        </div>
      </>
    </Form>
  );
};

export default SupplierDetailsTabForm;

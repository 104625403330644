import { IUseFunctions } from './interfaces';
import { useMemo } from 'react';
import { ICreateProductMessage } from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';
import _ from 'lodash';

export const useCreateFunctions = ({
  createProductMessage,
  messageProductAlreadyRegistred,
}: IUseFunctions) => {
  const onSubmit = async (values: ICreateProductMessage) => {
    const body: any = { ...values };

    return await createProductMessage({
      descricao: body.mensagem,
      exibeVenda: body.exibeVenda ?? false,
      exibeRotulagem: body.exibeRotulagem ?? false,
      exibeFichaPesagem: body.exibeFichaPesagem ?? false,
      exibeImpressaoRotulo: body.exibeImpressaoRotulo ?? false,
    });
  };

  const fetchProductMessageName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await messageProductAlreadyRegistred(value);
        } catch {
          return undefined;
        }
      }, 500),
    [messageProductAlreadyRegistred]
  );

  return {
    onSubmit,
    fetchProductMessageName,
  };
};

import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_INVOICE,
  FETCH_LIST_INVOICE,
} from '@/ControllerApiHook/UniqIds/Invoice/InvoiceHooks';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { useQueryClient } from '@tanstack/react-query';

export const useInvoiceListCallAPI = () => {
  const queryClient = useQueryClient();
  const invoiceList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_INVOICE,
    entityApi: InvoiceAPI.getInvoiceList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'dataLancamento', direction: 'DESC' },
    },
  });

  const deleteInvoice = useControllerMutateApiHook({
    uniqId: DELETE_INVOICE,
    entityApi: InvoiceAPI.deleteInvoice,
    options: {
      onSuccess: () => queryClient.invalidateQueries([FETCH_LIST_INVOICE]),
    },
  });

  return {
    invoiceList,
    deleteInvoice,
  };
};

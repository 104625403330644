import React, { FC, useEffect, useState } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Icon, Link, Text } from '../../..';

import { routes, titles } from './Routes';
import { ExactLink } from './ExactLink';

import styles from './BreadCrumb.module.scss';
import './BreadCrumb.override.scss';
import { useTranslation } from 'react-i18next';

export interface IBreadCrumb {
  hideAmount: number;
}

const BreadCrumb: FC<IBreadCrumb> = ({ hideAmount }) => {
  const [lenght, setLenght] = useState(0);

  const title = useBreadcrumbs(titles, { disableDefaults: false });
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  const { t } = useTranslation();

  useEffect(() => {
    setLenght(breadcrumbs.length);
  }, [breadcrumbs]);

  return (
    <React.Fragment>
      {title.map((x: any, index: any) => {
        const { props }: any = x.breadcrumb;

        return (
          x.breadcrumb &&
          index === title.length - 1 && (
            <Text
              className={styles['title']}
              type="heading3-bold"
              color="text-50"
              key={`breadcrumb-${index}`}
            >
              {props.children ? t(props.children) : x.breadcrumb}
            </Text>
          )
        );
      })}

      <AntdBreadcrumb
        className={styles['breadCrumb']}
        separator={
          <Icon
            className={styles['icon']}
            icon="chevron-right"
            color="text-400"
          />
        }
      >
        {breadcrumbs.map(({ breadcrumb, match, hide }: any, index: any) => {
          const { props }: any = breadcrumb;
          return !hide ? (
            breadcrumb && hideAmount > 0 ? (
              index === 1 ? (
                <AntdBreadcrumb.Item key={`breadcrumb-${index}`}>
                  <Text type="link" color="text-400">
                    ...
                  </Text>
                </AntdBreadcrumb.Item>
              ) : (
                (index === 0 || index > hideAmount) && (
                  <AntdBreadcrumb.Item key={`breadcrumb-${index}`}>
                    {index !== lenght - 1 ? (
                      <Link
                        type="link"
                        color="text-200"
                        to={ExactLink(match.url)}
                      >
                        {props.children ? t(props.children) : breadcrumb}
                      </Link>
                    ) : (
                      <Link
                        type="paragraph2-bold"
                        color="primary-base"
                        to={ExactLink(match.url)}
                      >
                        {props.children ? t(props.children) : breadcrumb}
                      </Link>
                    )}
                  </AntdBreadcrumb.Item>
                )
              )
            ) : (
              breadcrumb && (
                <AntdBreadcrumb.Item key={`breadcrumb-${index}`}>
                  {breadcrumb ? (
                    index !== breadcrumbs.length - 1 ? (
                      <Link
                        type="link"
                        color="text-200"
                        to={ExactLink(match.url)}
                      >
                        {props.children ? t(props.children) : breadcrumb}
                      </Link>
                    ) : (
                      <Link
                        type="paragraph2-bold"
                        color="primary-base"
                        to={ExactLink(match.url)}
                      >
                        {props.children ? t(props.children) : breadcrumb}
                      </Link>
                    )
                  ) : null}
                </AntdBreadcrumb.Item>
              )
            )
          ) : (
            <></>
          );
        })}
      </AntdBreadcrumb>
    </React.Fragment>
  );
};

export default BreadCrumb;

import React from 'react';
import { Col, Row } from 'antd';
import { Button, Icon } from '@/Components';
import { useFormikContext } from 'formik';
import { ProductCode } from './Components/ProductCode';
import { ProductName } from './Components/ProductName';
import { Quantity } from './Components/Quantity';
import { Phase } from './Components/Phase';
import { Type } from './Components/Type';
import { IProductLine } from './interfaces';
import { useProductLineStates } from './Hooks/useProductLineStates';
import { useProductLineFunctions } from './Hooks/useProductLineFunctions';

export const ProductLine: React.FC<IProductLine> = ({
  index,
  onDelete,
  totalRows,
  setProductRowAmount,
  uuidV4,
  pharmaceuticalFormExternalId,
}) => {
  const form: any = useFormikContext();

  const {
    currentProduct,
    setCurrentProduct,
    currentProductExternalId,
    setCurrentProductExternalId,
  } = useProductLineStates();

  const { addRow, deleteRow } = useProductLineFunctions({
    index,
    totalRows,
    setProductRowAmount,
    uuidV4,
    onDelete,
  });

  return (
    <>
      <Row gutter={[16, 0]}>
        <ProductCode index={index} currentProduct={currentProduct} />
        <ProductName
          index={index}
          setCurrentProduct={setCurrentProduct}
          addRow={addRow}
          setCurrentProductExternalId={setCurrentProductExternalId}
        />
        <Quantity
          index={index}
          currentProductExternalId={currentProductExternalId}
          pharmaceuticalFormExternalId={pharmaceuticalFormExternalId}
          addRow={addRow}
        />
        <Phase index={index} addRow={addRow} />
        <Type index={index} addRow={addRow} />
        <Col
          style={{
            width: '50px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            type="secondary"
            onClick={deleteRow}
            disabled={index === totalRows - 1}
            style={{ marginTop: `${index === 0 ? '0px' : '-20px'}` }}
          >
            <Icon
              icon="trash-line"
              size="L"
              style={{
                cursor: index === totalRows - 1 ? 'not-allowed' : 'default',
                color:
                  index === totalRows - 1
                    ? 'var(--color-status-disabled-base)'
                    : '',
              }}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

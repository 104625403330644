import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../Components';
import { v4 as uuidV4 } from 'uuid';
import ProductRow from './rows/ProductRow';
import style from './productSection.module.scss';
import { useFormikContext } from 'formik';

interface IProductSectionProps {
  importedProducts: any[];
  setImportedProducts: React.Dispatch<React.SetStateAction<any[]>>;
}

const ProductSection: React.FC<IProductSectionProps> = ({
  importedProducts,
  setImportedProducts,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const [productRowAmount, setProductRowAmount] = useState([uuidV4()]);
  const [purchaseOrderToFilter, setPurchaseOrderToFilter] = useState<any[]>([]);
  const cardId = 'productSection';
  const scrollContainerId = 'scrollContainer';

  const removeRow = (index: number) => {
    setProductRowAmount((oldState) => {
      return oldState.filter((_, i) => i !== index);
    });
  };

  useEffect(() => {
    if (!form.values.itens) {
      form.values.itens = [];
    }
    if (importedProducts.length === 0) return;
    const newRows: string[] = [];
    let temporaryPurchaseToFilter: any[] = [];
    importedProducts.forEach((product) => {
      if (!purchaseOrderToFilter.includes(product.pedidoCompraItemExternalId)) {
        newRows.push(uuidV4());
      }

      temporaryPurchaseToFilter.push(product.pedidoCompraItemExternalId);
    });

    setPurchaseOrderToFilter((prev) => [...prev, ...temporaryPurchaseToFilter]);
    newRows.push(uuidV4());
    if (productRowAmount.length === 1) {
      setProductRowAmount([]);
      setTimeout(() => {
        importedProducts.forEach((e, i) => {
          form.setFieldValue(`itens.${i}`, {
            produtoExternalId: e.produtoExternalId,
            codProduto: e.codigoProduto,
            valorUnitario: e.valorUnitario,
            quantidadeComprada: e.quantidade,
            unidadeMedidaId: e.unidadeMedidaId,
          });
        });
        setImportedProducts([]);
      }, 1);
    } else {
      setProductRowAmount((prev) => {
        prev.pop();
        return prev;
      });
      setTimeout(() => {
        importedProducts.forEach((e, i) => {
          if (!purchaseOrderToFilter.includes(e.pedidoCompraItemExternalId)) {
            const newIndex = form.values.itens.length;
            form.setFieldValue(`itens.${newIndex}`, {
              produtoExternalId: e.produtoExternalId,
              codProduto: e.codigoProduto,
              valorUnitario: e.valorUnitario,
              quantidadeComprada: e.quantidade,
              unidadeMedidaId: e.unidadeMedidaId,
            });
          }
        });
        setImportedProducts([]);
      }, 1);
    }

    setProductRowAmount((oldRows) => [...oldRows, ...newRows]);
  }, [importedProducts]);

  return (
    <Card
      id={cardId}
      title={t('purchasing.invoice.create.products')}
      withoutCollapse
    >
      <div
        id={scrollContainerId}
        style={{ overflowX: 'scroll' }}
        className={style.customScrollbar}
      >
        {productRowAmount.map((uuid, index) => (
          <ProductRow
            scrollContainerId={scrollContainerId}
            cardId={cardId}
            totalRows={productRowAmount.length}
            setProductRowAmount={setProductRowAmount}
            index={index}
            key={uuid}
            onDelete={removeRow}
            uuidV4={uuidV4}
          />
        ))}
      </div>
    </Card>
  );
};

export default ProductSection;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '../../../../../Components';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { CapsuleColorDetail } from '../Details';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { useParams } from 'react-router';
import { FETCH_GET_CAPSULE_COLORS } from '../../../../../ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';
import { DeleteCapsuleColortModal } from '../Details/Components/Modal/deleteModal';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetCapsuleColor } from '@/Data/Interfaces/response/CapsuleColors/ICapsuleColorsResponse';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';

export const EditPackingClassification: React.FC = () => {
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const { t } = useTranslation();

  const { externalId } = useParams<{ externalId: string }>();

  const { data: capsuleColor } = useControllerQueryApiHook<IGetCapsuleColor>({
    uniqId: FETCH_GET_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.getCurrentCapsuleColor,
    externalId: externalId,
  });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t(
              'production.capsuleColors.details.editCapsuleColor.tab.details'
            ),
            key: 'details',
            children: (
              <CapsuleColorDetail
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
        ]}
        dropdownItens={[
          {
            label: t(
              'production.capsuleColors.details.editCapsuleColor.buttonActions.delete'
            ),
            icon: 'trash',
            permission: {
              permission: PermissionEnum.Producao_CapsulaCor_Excluir,
              tooltip: 'noPermission.production.capsuleColors.delete',
            },
            onClick: () => setVisibleModal(true),
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled && {
            onClick: () => setDisabled(false),
            isVisible: disabled,
            codePermissionEdit:
              PermissionEnum.Producao_CapsulaCor_EditarDetalhes,
            messageTooltipPermission:
              'noPermission.production.capsuleColors.edit',
          }),
        }}
      />

      {capsuleColor && (
        <DeleteCapsuleColortModal
          capsuleColor={capsuleColor}
          visibleModal={visibleModal}
          changeVisibleModal={(values) => setVisibleModal(values)}
        />
      )}
    </PageLoading>
  );
};

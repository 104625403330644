import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import DateUtils from '../../../Utils/DateUtils';
import { Http, IQueryListParams } from '../../../Utils/Http';
import StorageUtils from '../../../Utils/StorageUtils';
import { IUpdatePermissionRequest } from '../../Interfaces/request/User/IUpdatePermissionRequest';
import {
  IChangePasswordRequest,
  IPutUserRequest,
  ICreateUserRequest,
  IPatchUserStatusRequest,
} from '../../Interfaces/request/User/IUserRequest';
import {
  IGetUserResponse,
  IListUserResponse,
  IUserPreferencesResponse,
} from '../../Interfaces/response/User/IGetUserReponse';

export class UserAPI {
  public static async getMe(): Promise<IGetUserResponse> {
    return await Http.fetcher<IGetUserResponse>(
      '/pharma/accountmanager/v1/Usuario'
    );
  }
  public static async createUser(body: ICreateUserRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Usuario', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async editUser(body: IPutUserRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Usuario', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async deleteUser(externalId: string[]) {
    return await Http.fetcher('/pharma/accountmanager/v1/Usuario', {
      method: 'DELETE',
      body: JSON.stringify({ externalId: externalId }),
    });
  }

  public static async changeUserStatus(
    body: IPatchUserStatusRequest
  ): Promise<string> {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Usuario/AtualizarStatus',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async updateUserPermission(body: IUpdatePermissionRequest) {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Usuario/AtualizarPermissao',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async getUser(
    context: QueryFunctionContext
  ): Promise<IGetUserResponse> {
    let res = await Http.fetcher<IGetUserResponse>(
      `/pharma/accountmanager/v1/Usuario${
        context.queryKey[1] ? `/${context.queryKey[1]}` : ''
      }`
    );

    const preferences = await StorageUtils.getPreference();

    if (res.dataNascimento) {
      const dateFormated = DateUtils.convertDate(
        res.dataNascimento,
        preferences.padraoData
      );
      if (dateFormated) res.dataNascimento = dateFormated;
    }

    return res;
  }

  public static async getCurrentUser(
    externalId?: string
  ): Promise<AxiosResponse<IGetUserResponse>> {
    const axios = await Http.axios();
    let url = '/pharma/accountmanager/v1/Usuario';
    if (externalId) {
      url += `/${externalId}`;
    }
    return axios.get<IGetUserResponse>(url);
  }

  public static async patchCurrentUserPreference(data: any) {
    return Http.fetcher(
      '/pharma/accountmanager/v1/Usuario/AtualizarPreferencias',
      {
        method: 'PATCH',
        body: JSON.stringify(Http.ConvertFieldsToPatch(data)),
      }
    );
  }

  public static async changePassword(body: IChangePasswordRequest) {
    return Http.fetcher('/identity/v1/User/ChangePassword', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async listUser(
    context: QueryFunctionContext
  ): Promise<IListUserResponse> {
    return await Http.fetcher<IListUserResponse>(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Usuario/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listActiveUserAdmin(
    context: any
  ): Promise<IListUserResponse | null> {
    if (context.queryKey[1]?.filter?.every((x: any) => x.value))
      return await Http.fetcher(
        Http.AddQueryParams(
          '/pharma/accountmanager/v1/Usuario/ListarDetalhadoAtivosAdmin',
          context.queryKey[1] as IQueryListParams
        )
      );

    return null;
  }
  public static async listActiveUser(
    context: any
  ): Promise<IListUserResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Usuario/ListarDetalhadoAtivos',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async listUserWithoutGroup(
    context: any
  ): Promise<IListUserResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Usuario/ListarSemGrupo',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getPreferences(): Promise<IUserPreferencesResponse> {
    const res = await Http.fetcher<IUserPreferencesResponse>(
      '/pharma/accountmanager/v1/Usuario/ObterPreferencias'
    );
    res.preferencias.padraoData = res.preferencias.padraoData.replace(
      'mm',
      'MM'
    ) as any;

    return res;
  }
}

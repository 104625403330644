import React from 'react';
import { Form } from '../../../../../Components';
import { InfoCard } from './Components/Cards/InfoCard';
import { FinancialCard } from './Components/Cards/FinancialCard';
import { PackagingsCard } from './Components/Cards/PackagingsCard';
import { PharmaceuticalFormCreateSchema } from './CreatePharmaceuticalForm.schema';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButtons';

export const CreatePharmaceuticalForm: React.FC = () => {
  const {
    isDisable,
    setIsDisable,
    mandatoryFieldsRequired,
    setMandatoryFieldsRequired,
  } = useCreateStates();

  const {
    createPharmaceuticalForm,
    isLoadingCreatePharmaceuticalForm,
    datePattern,
  } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({
    createPharmaceuticalForm,
    datePattern,
  });

  return (
    <>
      <Form onSubmit={onSubmit} schema={PharmaceuticalFormCreateSchema}>
        <>
          <InfoCard
            enableButton={() => setIsDisable(false)}
            changeStateButtonSave={(x: boolean) =>
              setMandatoryFieldsRequired(x)
            }
          />
          <FinancialCard enableButton={() => setIsDisable(false)} />
          <PackagingsCard />
          <FooterButtons
            isDisable={isDisable}
            isLoadingCreatePharmaceuticalForm={
              isLoadingCreatePharmaceuticalForm
            }
            mandatoryFieldsRequired={mandatoryFieldsRequired}
            onSubmit={onSubmit}
          />
        </>
      </Form>
    </>
  );
};

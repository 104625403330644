import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { ProductAPI } from "@/Data/API/Supply/Product";

export const useProductCallAPI = () => {
  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  };
};
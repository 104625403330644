import { useFormikContext } from "formik";
import { IUseMemorizations } from "./interfaces";

export const useSaleValueMemorizations = ({
  product,
  listunitsMeasurements
}: IUseMemorizations) => {
  const form: any = useFormikContext();

  const supportText= form.values.unidadeEstoqueId
  ? `/${
      listunitsMeasurements?.data?.find(
        (x) => x.id === form.values.unidadeEstoqueId
      )?.unidadeAbreviacao
    }`
  : product && `/${product.unidadeMedida.abreviacao}`;

  return {
    supportText
  };
};
import { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Icon
} from '@/Components';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { useFormikContext } from 'formik';
import { Product } from './Components/Product';
import { UnitaryValue } from './Components/UnitaryValue';
import { UnitaryDiscount } from './Components/UnitaryDiscount';
import { Amount } from './Components/Amount';
import { IProductLine } from './interfaces';
import { useProductLineStates } from './Hooks/useProductLineStates';

export const ProductLine: FC<IProductLine> = ({
  index,
  onDelete,
  setProductRowAmount,
  totalRows,
  uuidV4
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const {
    unitMeasurementPagination,
    setUnitMeasurementPagination
  } = useProductLineStates();

  useEffect(() => {
    const currentValorTotal = form?.values?.pedidoCompraItens?.[index]?.total;
    const valorTotal =
      form?.values?.pedidoCompraItens?.[index]?.quantidade *
      form?.values?.pedidoCompraItens?.[index]?.precoUnitario;

    const hasChanges = currentValorTotal !== valorTotal;

    const currencyDiscount =
      form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario *
      form?.values?.pedidoCompraItens?.[index]?.quantidade;

    const porcentageDiscount =
      (form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario / 100) *
      form?.values?.pedidoCompraItens?.[index]?.quantidade;

    const currencyId = 2;

    if (
      hasChanges ||
      form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario !==
        undefined
    ) {
      form.setFieldValue(
        `pedidoCompraItens.${index}.total`,
        form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario ===
          undefined
          ? valorTotal
          : form?.values?.pedidoCompraItens?.[index]?.tipoDescontoProdutoId ===
            currencyId
          ? (valorTotal || currentValorTotal) - currencyDiscount
          : (valorTotal || currentValorTotal) - porcentageDiscount,
        false
      );
    }
    if (
      form?.values?.pedidoCompraItens?.[index]?.precoUnitario === undefined ||
      form?.values?.pedidoCompraItens?.[index]?.quantidade === undefined
    ) {
      form.setFieldValue(`pedidoCompraItens.${index}.total`, '');
    }
  }, [
    form?.values?.pedidoCompraItens?.[index]?.quantidade,
    form?.values?.pedidoCompraItens?.[index]?.precoUnitario,
    form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario,
    form?.values?.pedidoCompraItens?.[index]?.tipoDescontoId,
    index,
  ]);

  useEffect(() => {
    const currentValorTotalProducts =
      form?.values?.pedidoCompraItens?.[index]?.totalProducts;
    const valorTotal =
      form?.values?.pedidoCompraItens?.[index]?.quantidade *
      form?.values?.pedidoCompraItens?.[index]?.precoUnitario;

    const hasChanges = currentValorTotalProducts !== valorTotal;

    if (hasChanges) {
      form.setFieldValue(
        `pedidoCompraItens.${index}.totalProducts`,
        valorTotal
      );
    }
    if (
      form?.values?.pedidoCompraItens?.[index]?.precoUnitario === undefined ||
      form?.values?.pedidoCompraItens?.[index]?.quantidade === undefined
    ) {
      form.setFieldValue(`pedidoCompraItens.${index}.totalProducts`, '');
    }
  }, [
    form?.values?.pedidoCompraItens?.[index]?.quantidade,
    form?.values?.pedidoCompraItens?.[index]?.precoUnitario,
    index,
  ]);

  return (
    <Row justify="start" style={{ marginBottom: '16px' }} gutter={[16, 0]}>
      <Col span={6}>
        <Product
          index={index}
          setProductRowAmount={setProductRowAmount}
          uuidV4={uuidV4}
          totalRows={totalRows}
          unitMeasurementPagination={unitMeasurementPagination}
          setUnitMeasurementPagination={setUnitMeasurementPagination}
        />
      </Col>
      <Col span={4}>
        <UnitaryValue
          index={index}
          unitMeasurementPagination={unitMeasurementPagination}
        />
      </Col>
      <Col span={4}>
        <UnitaryDiscount index={index} />
      </Col>
      <Col span={4}>
        <Amount index={index} />
      </Col>
      <Col span={4}>
        <CurrencyInput
          name={`pedidoCompraItens.${index}.total`}
          label={
            index === 0
              ? t('purchasing.purchaseOrder.create.productsCardField.total')
              : ''
          }
          placeHolder={t(
            'purchasing.purchaseOrder.create.productsCardPlaceHolders.total'
          )}
          withoutMarginBottom={true}
          disabled
        />
      </Col>
      <Col
        style={{
          width: '50px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          type="secondary"
          size="xl"
          onClick={() => onDelete(index)}
          disabled={index === form.values.pedidoCompraItens.length - 1}
          style={{ marginTop: `${index === 0 ? '20px' : 0}` }}
        >
          <Icon
            icon="trash-line"
            size="L"
            style={{
              cursor:
                index === form.values.pedidoCompraItens.length - 1
                  ? 'not-allowed'
                  : 'default',
              color:
                index === form.values.pedidoCompraItens.length - 1
                  ? 'var(--color-status-disabled-base)'
                  : '',
            }}
          />
        </Button>
      </Col>
    </Row>
  );
};

import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useCasFunctions = ({
  refetchSearchCasPage
}: IUseFunctions) => {
  const onSearch = useCallback((search?: string) => {
    refetchSearchCasPage({
      pageIndex: 0,
      pageSize: 10,
      search: search,
    });
  }, [refetchSearchCasPage]);

  return { onSearch };
};
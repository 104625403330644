import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { FC } from 'react';
import { useParams } from 'react-router';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { RowDescription } from './Rows/DescriptionRow';
import { OtherRow } from './Rows/OtherRow';
import { useFormikContext } from 'formik';

interface IInfoCard {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const InfoCard: FC<IInfoCard> = ({ disabled }) => {
  const { externalId } = useParams<{ externalId: string }>();
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { laboratoryList, pharmaceuticalForm } = useEditCallAPI({
    externalId,
    form,
    t,
  });

  const { initialLaboratoryName, calculationType, useType } = useEditFunctions({
    pharmaceuticalForm,
    laboratoryList,
  });

  return (
    <Card title={t('common.information')} withoutCollapse={true}>
      <RowDescription
        disabled={disabled}
        pharmaceuticalForm={pharmaceuticalForm}
        calculationType={calculationType}
        externalId={externalId}
      />
      <OtherRow
        disabled={disabled}
        initialLaboratoryName={initialLaboratoryName}
        pharmaceuticalForm={pharmaceuticalForm}
        useType={useType}
      />
    </Card>
  );
};

export default InfoCard;

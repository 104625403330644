import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useSubGroupModalMemorizations = ({
  title,
  createSubGroup,
  editSubGroup,
  productGroup,
  selectedRowsOnTable
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.subGroupList.modal';

  const okButtonName = !title
  ? createSubGroup.isLoading
    ? t(`${abbreviatedI18n}.signingUp`)
    : t(`${abbreviatedI18n}.register`)
  : editSubGroup.isLoading
  ? t(`${abbreviatedI18n}.editing`)
  : t(`${abbreviatedI18n}.edit`);

  const initialValues = {
    descricao: selectedRowsOnTable && selectedRowsOnTable[0]?.descricao,
    grupoPaiExternalId: productGroup?.externalId,
    groupLabel: {
      id: productGroup?.externalId,
      label: productGroup?.descricao,
    },
  };

  return {
    okButtonName,
    initialValues
  };
};
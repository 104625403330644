import { InputWithSearch, Dropdown } from '@/Components';
import { SelectedAvatar } from '@/Components/SelectedAvatar';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateClientModal } from '../../CreateClient/CreateClientModal';
import { useBodyStates } from './Hooks/useBodyStates';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';
import { useBodyMemorizations } from './Hooks/useBodyMemorizations';

export const ModalBody: FC = () => {
  const { t } = useTranslation();

  const {
    clientName,
    setClientName,
    visibleClientModal,
    setVisibleClientModal,
  } = useBodyStates();

  const { clientList, myUser, serviceChannelList } = useBodyCallAPI();

  const { clientDataList, clientEmptyState, serviceChannelDataList } =
    useBodyMemorizations({
      clientList: clientList?.data?.data,
      setVisibleClientModal,
      serviceChannelList: serviceChannelList?.data?.data,
    });

  return (
    <div style={{ padding: '20px' }}>
      <InputWithSearch
        label={t('callCenter.createService.client')}
        required
        items={clientDataList}
        dataTestId="client-dropdown"
        name="clienteExternalId"
        withoutSearchIcon
        placeHolder={t('callCenter.createService.clientPlaceholder')}
        placeholderWithAvatar
        isLoading={clientList.isLoading}
        onScrollEnd={clientList.fetchNewPage}
        onSearch={(search) =>
          clientList.refetch({
            pageIndex: 0,
            pageSize: 10,
            search: search,
            sorter: { column: 'nome', direction: 'DESC' },
          })
        }
        onChangeInputText={(x) => setClientName(x)}
        emptyState={clientEmptyState}
      />
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Dropdown
            name="canalAtendimentoId"
            items={serviceChannelDataList}
            isLoading={serviceChannelList.isLoading}
            label={t('callCenter.createService.channel')}
            required
            withoutMarginBottom
            dataTestId="channel-dropdown"
            id="channel"
          />
        </Col>
        <Col span={12}>
          <SelectedAvatar
            name={myUser?.nome}
            lastName={myUser?.sobrenome}
            label={t('callCenter.createService.attendant')}
            withoutMarginBottom
          />
        </Col>
      </Row>
      <CreateClientModal
        visible={visibleClientModal}
        changeVisible={() => setVisibleClientModal(false)}
        nameClient={clientName}
      />
    </div>
  );
};

import { FC } from "react";
import {
  InputWithSearch,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IPackingClassification } from "./interfaces";
import { usePackingClassificationCallAPI } from "./Hooks/usePackingClassificationCallAPI";
import { usePackingClassificationFunctions } from "./Hooks/usePackingClassificationFunctions";
import { usePackingClassificationMemorizations } from "./Hooks/usePackingClassificationMemorizations";

export const PackingClassification: FC<IPackingClassification> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    packingClassificationList,
    isPackingClassificationList,
    fetchNewPagePackingClassification,
    refetchPackingClassification
  } = usePackingClassificationCallAPI();

  const { onSearch } = usePackingClassificationFunctions({
    refetchPackingClassification
  });

  const { items } = usePackingClassificationMemorizations({
    packingClassificationList
  });

  return(
    <Col span={6}>
      <Tooltip
        title={`${t(
          'products.toolTips.products.originalPackingClassification'
        )} ${
          product && product.embalagem
            ? product.embalagem.classificacaoEmbalagemDescricao
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product?.embalagem?.classificacaoEmbalagemExternalId !==
            form?.values?.classificacaoEmbalagemExternalId
        }
      >
        <InputWithSearch
          name="classificacaoEmbalagemExternalId"
          label={t('products.create.packingClassification')}
          placeHolder={t(
            'products.create.packingClassificationPlaceholder'
          )}
          required
          onScrollEnd={fetchNewPagePackingClassification}
          isLoading={isPackingClassificationList}
          disabled={disabledForm}
          items={items}
          onSearch={onSearch}
          withStatusIcon={!!product}
        />
      </Tooltip>
    </Col>
  );
};
import { FC } from 'react';
import { TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { ICreateModalBody } from './interfaces';
import { useModalBodyCallAPI } from './Hooks/useModalBodyCallAPI';
import { useModalBodyFunctions } from './Hooks/useModalBodyFunctions';

export const CreateModalBody: FC<ICreateModalBody> = ({ specialtiesName }) => {
  const { t } = useTranslation();

  const {
    mutateAsync
  } = useModalBodyCallAPI();

  const {
    onChange
  } = useModalBodyFunctions({
    mutateAsync,
    specialtiesName
  });

  return (
    <div style={{ padding: '20px', minWidth: '400px' }}>
      <TextInput
        name="descricao"
        label={t('specialties.create.label')}
        placeHolder={t('specialties.create.placeholder')}
        required
        onChange={onChange}
        withoutMarginBottom
      />
    </div>
  );
};

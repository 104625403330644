import { FC } from 'react';
import { Col, Row } from 'antd';
import { ITotalTableColumns } from "./interfaces";
import { FreightCost } from './Components/FreightCost';
import { AdditionalValue } from './Components/AdditionalValue';
import { FullDiscount } from './Components/FullDiscount';
import { TotalOrder } from './Components/TotalOrder';

export const TotalColumns: FC<ITotalTableColumns> = ({ withSelectedRows }) => {
  return (
    <Row gutter={[16, 0]} justify="space-between">
      <Col span={5}>
        <FreightCost />
      </Col>
      <Col span={5}>
        <AdditionalValue />
      </Col>
      <Col span={5}>
        <FullDiscount />
      </Col>
      <Col span={5}>
        <TotalOrder withSelectedRows={withSelectedRows} />
      </Col>
    </Row>
  );
};

import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IHeaderTableOptions } from '@/Components/Table/components/HeaderTable';
import { IDeleteModalTable } from '@/Components/Table';
import {
  TitleHandler,
  BodyHandler,
} from '../../Modal/LinkedRemoveModal/packingClassificationLinkedListUtils';

export const useEditMemorizations = ({
  selectedRowsOnTable,
  packingClassification,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions: IHeaderTableOptions = useMemo(
    () => ({
      nameKey: 'descricao',
      hasSearch: true,
      searchPlaceholder: t('products.productList.searchPlaceholder'),
      deleteButton: {
        icon: 'Broken-Chain-3',
        label: t('production.packingClassification.linkedList.buttonUnlink'),
        permission: {
          permission:
            PermissionEnum.Producao_ClassificacaoEmbalagem_EditarDetalhes,
          tooltip: 'noPermission.supply.messageProduct.removeLink',
        },
      },
    }),
    []
  );

  const deleteModal = useMemo((): IDeleteModalTable | undefined => {
    return {
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t, packingClassification),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
      okButtonName: 'common.remove',
    };
  }, [selectedRowsOnTable, t]);

  return { headerOptions, deleteModal };
};

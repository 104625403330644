import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

interface IRemovePurchaseOrdersModalProps {
  visible?: boolean;
  changeVisibleModal: (value: boolean) => void;
  providerDescription: string;
  onOkClick: () => void;
  onCancelClick: () => void;
}

export const RemovePurchaseOrdersModal: React.FC<IRemovePurchaseOrdersModalProps> =
  ({
    visible,
    changeVisibleModal,
    providerDescription,
    onOkClick,
    onCancelClick,
  }) => {
    const { t } = useTranslation();
    return (
      <Modal
        visible={visible}
        title={t('purchasing.invoice.modal.changeSupplier.title')}
        body={
          <div style={{ padding: '20px' }}>
            <Divflex
              style={{
                flexDirection: 'column',
                marginBottom: 15,
                width: '400px',
              }}
            >
              <ReactMarkdown>
                {t('purchasing.invoice.modal.changeSupplier.description', {
                  nomeFornecedor: `**${providerDescription}**`,
                })}
              </ReactMarkdown>
            </Divflex>
          </div>
        }
        okButtonName={t(
          'purchasing.invoice.modal.changeSupplier.okButtonDescription'
        )}
        onCancelClick={() => {
          changeVisibleModal(false);
          onCancelClick();
        }}
        onClose={() => {
          changeVisibleModal(false);
          onCancelClick();
        }}
        onOkClick={() => {
          onOkClick();
          changeVisibleModal(false);
        }}
      />
    );
  };

import { useMemo } from 'react';
import DateUtils from '@/Utils/DateUtils';
import { MaskForCNPJ } from '@/Utils/MasksUtils';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import StorageUtils from '@/Utils/StorageUtils';

export const useDetailsCardsMemorizations = ({ lots }: IUseMemorizations) => {
  const { t } = useTranslation();
  const unityMeasureUfcId = 10;
  const unityMeasureConvesionGrId = 7;
  const unitysMeasuresDefault = useMemo(
    () => ({
      loteUnidadeAlternativa1: {
        unidadeAlternativaId: unityMeasureUfcId,
        unidadeAlternativaConversaoId: unityMeasureConvesionGrId,
      },
      loteUnidadeAlternativa2: {
        unidadeAlternativaId: unityMeasureUfcId,
        unidadeAlternativaConversaoId: unityMeasureConvesionGrId,
      },
    }),
    []
  );

  const preferences = useMemo(() => StorageUtils.getPreference(), []);

  const initalValues = useMemo(
    () =>
      lots && {
        ...lots,
        dataLancamento: `${DateUtils.format(
          lots.dataLancamento,
          preferences?.padraoData || 'dd/MM/yyyy HH:mm'
        )} ${t('common.dateBreakApart')} ${DateUtils.toTime(
          lots?.dataLancamento
        )}`,

        dataFabricacao: DateUtils.format(
          lots.dataFabricacao,
          preferences?.padraoData.replace('mm', 'MM') || 'dd/MM/yyyy'
        ),
        dataValidade: DateUtils.format(
          lots.dataValidade,
          preferences?.padraoData.replace('mm', 'MM') || 'dd/MM/yyyy'
        ),
        cnpj: MaskForCNPJ(lots?.cnpj),
        saldoLote: `${lots.saldoLote} ${lots.unidadeMedida.abreviacao}`,
        loteInformacaoTecnica: lots.loteInformacaoTecnica
          ? lots.loteInformacaoTecnica
          : { unitysMeasuresDefault },
      },
    [lots, preferences, t, unitysMeasuresDefault]
  );

  const modalBody = useMemo(
    () => (
      <div style={{ padding: 20, maxWidth: 400 }}>
        {t('supply.balanceAdjustment.backModal.body')}
      </div>
    ),
    [t]
  );

  return {
    initalValues,
    modalBody,
    preferences,
  };
};

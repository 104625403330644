import {
  FETCH_LIST_DROPDOWN_SUPPLIER
} from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

export const useProviderCallAPI = () => {
  const {
    data: providersList,
    isLoading: isProviderLoading,
    fetchNewPage: fetchNewProviderPage,
    refetch: refetchSearchProviderPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_SUPPLIER,
    entityApi: SupplierAPI.listDropdownSupplier,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  return {
    providersList,
    isProviderLoading,
    fetchNewProviderPage,
    refetchSearchProviderPage
  };
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { subGroupCollums } from './SubGroupForm.columns';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { CreateSubGroupModal } from './Modal/SubGroupModal';
import { PageLoading } from '@/Layouts/PageLoading';
import { useSubGroupFormStates } from './Hooks/useSubGroupFormStates';
import { useSubGroupFormCallAPI } from './Hooks/useSubGroupFormCallAPI';
import { useSubGroupFormMemorizations } from './Hooks/useSubGroupFormMemorizations';
import { useSubGroupFormFunctions } from './Hooks/useSubGroupFormFunctions';

export const SubGroupForm: React.FC = () => {
  const { t } = useTranslation();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleModal,
    setVisibleModal,
    visibleEditModal,
    setVisibleEditModal,
  } = useSubGroupFormStates();

  const {
    deleteSubGroup,
    subGroupList,
    isLoading,
    productGroup,
    refetchGroupList,
  } = useSubGroupFormCallAPI();

  const {
    onDelete,
    changeVisibleModal,
    changeVisibleEditModal,
    selectRowsOnTable,
  } = useSubGroupFormFunctions({
    deleteSubGroup,
    setVisibleModal,
    setVisibleEditModal,
    setSelectedRowsOnTable,
  });

  const { headerOptions, deleteModal } = useSubGroupFormMemorizations({
    setVisibleModal,
    setVisibleEditModal,
    selectedRowsOnTable,
  });

  return (
    <>
      <PageLoading loading={isLoading}>
        <ContainerPage>
          <Table
            columns={subGroupCollums(t)}
            headerOptions={headerOptions}
            selectedRowsOnTable={selectRowsOnTable}
            loading={isLoading}
            hasSelection
            pagination={subGroupList}
            onDelete={onDelete}
            onRefetch={refetchGroupList}
            cleanSelectedRows={!!visibleEditModal}
            deleteModal={deleteModal}
          />
          {productGroup && (
            <CreateSubGroupModal
              changeVisible={changeVisibleModal}
              visible={visibleModal}
              group={{
                externalId: productGroup.externalId,
                groupName: productGroup.descricao,
              }}
            />
          )}
          {productGroup && (
            <CreateSubGroupModal
              changeVisible={changeVisibleEditModal}
              visible={visibleEditModal}
              group={{
                externalId: productGroup.externalId,
                groupName: productGroup.descricao,
              }}
              title={`${t('common.edit')} "${
                selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
              }"`}
              selectedRowsOnTable={selectedRowsOnTable}
            />
          )}
        </ContainerPage>
      </PageLoading>
    </>
  );
};

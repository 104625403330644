import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from '../../../../../../../../../Components';

import styles from './WithoutProducts.module.scss';

export const WithoutProducts: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['div-content']}>
        <Icon icon="box-wrong" size="XXLL" color="text-500" />
        <Text
          type="ui-tiny-semibold"
          children={t('saleOrder.editOrder.SaleOrder.withoutProducts.title')}
          color="text-50"
        />
        <Text
          type="ui-tiny-content"
          children={t(
            'saleOrder.editOrder.SaleOrder.withoutProducts.description'
          )}
          color="text-200"
        />
      </div>
    </div>
  );
};

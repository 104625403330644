import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LIST_REASON_LOSS } from '@/ControllerApiHook/UniqIds/Inventory/LossesHooks';
import { LossesAPI } from '@/Data/API/Supply/LossesApi';
import DateUtils from '@/Utils/DateUtils';

export const useEditCallAPI = () => {
  const datePattern = DateUtils.getCurrentDatePattern();

  const {
    data: lossesReason,
    isLoading: loadingReasonLoss,
    fetchNewPage,
  } = useControllerQueryListApiHook({
    uniqId: LIST_REASON_LOSS,
    entityApi: LossesAPI.getReasonLossListDropdown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'id', direction: 'ASC' },
    },
  });
  return {
    lossesReason,
    loadingReasonLoss,
    fetchNewPage,
    datePattern,
  };
};

import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { IUseFunctions } from './interfaces';
import { messageErrorToast } from '@/Utils/MessageErrorToast';

export const useListFunctions = ({
  deleteProductLinkedByMessage,
  externalId,
}: IUseFunctions) => {
  const app = App.useApp();

  const onDelete = useCallback(
    async (selectedRowsOnTable: any[]) => {
      try {
        return await deleteProductLinkedByMessage(
          {
            mensagemExternalId: externalId,
            produtosExternalIds: selectedRowsOnTable.map(
              (x) => x?.produtoExternalId
            ),
          },
          { onError: handleModalError(app) }
        );
      } catch (error) {
        messageErrorToast(error);
      }
    },
    [deleteProductLinkedByMessage, app]
  );

  return {
    onDelete,
  };
};

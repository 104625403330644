import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tooltip, TextInput } from '../../../../../../../../Components';
import { FormikContextType, useFormikContext } from 'formik';
import _ from 'lodash';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import styles from '../../FooterButtons/DetailsForm.module.scss';
import { IEditPackingClassification } from '@/Data/Interfaces/request/PackingClassification/IPackingClassificationRequest';

interface IDescriptionRow {
  disabled: boolean;
  descriptionPackingClassification?: string;
}

export const DescriptionRow: FC<IDescriptionRow> = ({
  disabled,
  descriptionPackingClassification,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<IEditPackingClassification> =
    useFormikContext();

  const { mutateAsync } = useEditCallAPI({ form, t });

  const { fetchPackingClassificationName } = useEditFunctions({ mutateAsync });
  return (
    <Card title={t('common.details')} withoutCollapse={true}>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={12}>
          <Tooltip
            title={`${t(
              'production.packingClassification.details.tooTips.description'
            )} ${descriptionPackingClassification}`}
            showMe={
              !disabled &&
              descriptionPackingClassification !== form.values.descricao
            }
          >
            <TextInput
              className={styles['text-input']}
              name="descricao"
              label={[
                {
                  children: t('production.packingClassification.details.name'),
                },
              ]}
              placeHolder={t(
                'production.packingClassification.details.namePlaceholder'
              )}
              required
              withoutMarginBottom
              disabled={disabled}
              withStatusIcon
              onChange={fetchPackingClassificationName}
            />
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};

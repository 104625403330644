import {
  CHANGE_PRESCRIBER_STATUS,
  DELETE_PRESCRIBER,
  FETCH_LIST_PRESCRIBERS,
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { useQueryClient } from '@tanstack/react-query';
import { IChangePrescriberStatusRequest } from '@/Data/Interfaces/request/Prescribers/IPrescribersRequest';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeletePrescitorWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPrescriber';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();
  const app = App.useApp();
  const { t } = useTranslation();

  const changeStatus: any = useControllerMutateApiHook({
    uniqId: CHANGE_PRESCRIBER_STATUS,
    entityApi: PrescribersAPI.changePrescriberStatus,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(i18n.t('prescribers.messages.changeStatus')),
    },
  });

  const prescribersList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribers,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'nomeCompleto', direction: 'ASC' },
    },
  });

  const { mutateAsync: deletePrescriber }: any = useControllerMutateApiHook({
    uniqId: DELETE_PRESCRIBER,
    entityApi: PrescribersAPI.deletePrescribers,
    options: {
      onError: (x: any) => x && !x.errorCode && messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('prescribers.listPage.deleteModal.success'));

        queryClient.invalidateQueries([FETCH_LIST_PRESCRIBERS]);
      },
    },
  });

  const changePrescriberStatus = async (
    values: IChangePrescriberStatusRequest
  ) => {
    var body: IChangePrescriberStatusRequest = { ...values };

    const res = await changeStatus.mutateAsync({
      ...body,
    });
    if (res.success) queryClient.invalidateQueries([FETCH_LIST_PRESCRIBERS]);
  };

  const deletePrescribers = async (externalId: string[]) => {
    await deletePrescriber(externalId, {
      onError: handleModalError(
        app,
        cannotDeletePrescitorWithMovementsInTheSystemProps(t)
      ),
    });
  };

  return {
    changeStatus,
    prescribersList,
    deletePrescriber,
    changePrescriberStatus,
    deletePrescribers,
  };
};

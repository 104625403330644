import { FC } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ContactCard } from '../../../../../../../Components/EntityInformation/ContactCard';
import { AdressCard } from '../../../../../../../Components/EntityInformation/AddressCard';
import { DocumentCard } from '../../../../../../../Components/EntityInformation/DocumentCard';
import { InfoCard } from './Components/InfoCard';
import { IGetClientResponse } from '../../../../../../../Data/Interfaces/response/Client/IClientResponse';
import {
  ADD_ADRESS_CLIENT,
  ADD_CONTACT_CLIENT,
  ADD_DOCUMENT_CLIENT,
  CHANGE_FAVORITE_ADRESS_CLIENT,
  CHANGE_FAVORITE_CONTACT_CLIENT,
  DELETE_ADRESS_CLIENT,
  DELETE_CONTACT_CLIENT,
  DELETE_DOCUMENT_CLIENT,
  EDIT_ADRESS_CLIENT,
  EDIT_CONTACT_CLIENT,
  EDIT_DOCUMENT_CLIENT,
  GET_CLIENT
} from '../../../../../../../ControllerApiHook/UniqIds/People/ClientKeys';

import styles from './ClientDetails.module.scss';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

interface IDetailsTab {
  client: IGetClientResponse;
}

export const DetailsTab: FC<IDetailsTab> = ({ client }) => {
  const queryClient = useQueryClient();

  const addContact: any = useControllerMutateApiHook({
    uniqId: ADD_CONTACT_CLIENT,
    entityApi: ClientAPI.addContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.createContact'));

        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const editContact: any = useControllerMutateApiHook({
    uniqId: EDIT_CONTACT_CLIENT,
    entityApi: ClientAPI.editContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.changeContact'));

        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const deleteContact: any = useControllerMutateApiHook({
    uniqId: DELETE_CONTACT_CLIENT,
    entityApi: ClientAPI.deleteContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.deleteContact'));

        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const changeFavoriteContact: any = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_CONTACT_CLIENT,
    entityApi: ClientAPI.changeFavoriteContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.changeFavoriteContact'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const addAdress: any = useControllerMutateApiHook({
    uniqId: ADD_ADRESS_CLIENT,
    entityApi: ClientAPI.addAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.createAddress'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const editAdress: any = useControllerMutateApiHook({
    uniqId: EDIT_ADRESS_CLIENT,
    entityApi: ClientAPI.editAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeAddress'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const deleteAdress: any = useControllerMutateApiHook({
    uniqId: DELETE_ADRESS_CLIENT,
    entityApi: ClientAPI.deleteAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.deleteAddress'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const changeFavoriteAddress: any = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_ADRESS_CLIENT,
    entityApi: ClientAPI.changeFavoriteAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeFavoriteAddress'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const addDocument = useControllerMutateApiHook({
    uniqId: ADD_DOCUMENT_CLIENT,
    entityApi: ClientAPI.addDocumentClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.changeDocument'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const editDocument = useControllerMutateApiHook({
    uniqId: EDIT_DOCUMENT_CLIENT,
    entityApi: ClientAPI.editDocumentClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.createDocument'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  const deleteDocument = useControllerMutateApiHook({
    uniqId: DELETE_DOCUMENT_CLIENT,
    entityApi: ClientAPI.deleteDocumentClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.deleteDocument'));
        
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    }
  });

  return (
    <div className={styles['content']}>
      <div className={styles['side-left']}>
        <InfoCard client={client} />
        <ContactCard
          addEntity={addContact}
          editEntity={editContact}
          deleteEntity={deleteContact}
          entityContact={client?.clienteContatos}
          entityExternalId={client?.externalId}
          entityContactExternalIdName="clienteContatoExternalId"
          entityExternalIdName="clienteExternalId"
          changeFavorite={changeFavoriteContact}
          entityContactPermission={{
            permission: PermissionEnum.Vendas_Clientes_EditarContatos,
            tooltip: 'noPermission.sale.client.editContacts',
          }}
        />
      </div>
      <div className={styles['side-right']}>
        <AdressCard
          addEntity={addAdress}
          editEntity={editAdress}
          deleteEntity={deleteAdress}
          entityAdress={client?.clienteEnderecos}
          entityExternalId={client?.externalId}
          entityAddressExternalIdName="clienteEnderecoExternalId"
          entityExternalIdName="clienteExternalId"
          changeFavorite={changeFavoriteAddress}
          entityAddressPermission={{
            permission: PermissionEnum.Vendas_Clientes_EditarEndereco,
            tooltip: 'noPermission.sale.client.editAddress',
          }}
        />
        <DocumentCard
          addEntity={addDocument}
          editEntity={editDocument}
          deleteEntity={deleteDocument}
          entityDocuments={client?.clienteDocumentos}
          entityExternalId={client?.externalId}
          entityDocumentExternalIdName="clienteDocumentoExternalId"
          entityExternalIdName="clienteExternalId"
          entityType={client?.tipoPessoa}
          entityDocumentPermission={{
            permission: PermissionEnum.Vendas_Clientes_EditarDocumento,
            tooltip: 'noPermission.sale.client.editDocuments',
          }}
        />
      </div>
    </div>
  );
};

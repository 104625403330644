import { Color } from '../../../../../../../Utils/ColorUtils';
import { IconName } from '../../../../../../../Components/Icon/IconClasses';
import { history } from '@/Store';
import { TFunction } from 'react-i18next';
import { IPreferences } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { IListSalesOrderData } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { Avatar, Link, Status, Tooltip } from '@/Components';
import { statusServiceTypeProp } from '@/Pages/Sectors/Sales/Order/List/order.columns';
import DateUtils from '@/Utils/DateUtils';
import { currencyFormater } from '@/Utils/Currency';
import { StatusSaleOrderType } from '@/Utils/StatusUtils';
import { StatusTag } from '@/Components/StatusChip';

export const orderCollums = (
  t: TFunction<'translation', undefined>,
  datePattern?: IPreferences
) => [
  {
    title: t('saleOrder.list.code'),
    dataIndex: 'codigoPedidoVenda',
    key: 'codigoPedidoVenda',
    sortColumn: 'codigoPedidoVenda',
    sorter: true,
    defaultSortOrder: 'descend',
    isHighlightable: true,
    width: '8%',
    render: (x: string, row: IListSalesOrderData) => (
      <Link
        type="ui-tiny-bold"
        to={`/sales/sale-order/${row.externalId}/details`}
        children={`#${x}`}
      />
    ),
  },
  {
    title: t('saleOrder.list.service'),
    dataIndex: 'codigoAtendimento',
    key: 'codigoAtendimento',
    render: (x: string, row: IListSalesOrderData) => (
      <Tooltip
        title={statusServiceTypeProp[row.statusAtendimentoId].description}
        showMe
        placement="topLeft"
      >
        <StatusTag
          children={`#${x}`}
          statusColor={
            statusServiceTypeProp[row.statusAtendimentoId].color as Color
          }
          icon={row.canalIcon as IconName}
          onClick={() =>
            history.push(`/sales/tickets/${row.atendimentoExternalId}/order`)
          }
        />
      </Tooltip>
    ),
  },
  {
    title: t('saleOrder.list.releaseDate'),
    dataIndex: 'dataLancamento',
    key: 'dataLancamento',
    isHighlightable: true,
    sortColumn: 'dataLancamento',
    render: (date: string) =>
      datePattern &&
      `${DateUtils.convertDate(date, datePattern.padraoData)} ${t(
        'common.complementDateHour'
      )} ${DateUtils.convertDate(date, datePattern.padraoHora)}`,
  },
  {
    title: t('saleOrder.list.attendant'),
    dataIndex: 'nomeCliente',
    key: 'nomeCliente',
    sortColumn: 'nomeCliente',
    render: (name: string, row: IListSalesOrderData) => (
      <Avatar
        type="ui-tiny-bold"
        size="M"
        fisrtName={name}
        description={{
          description: name,
          type: 'ui-tiny-bold',
          color: 'text-50',
        }}
        routeLink={`/admin/user/${row.externalId}/details`}
        color="white"
      />
    ),
  },
  {
    title: t('saleOrder.list.value'),
    dataIndex: 'valorPedido',
    key: 'valorPedido',
    sortColumn: 'valorPedido',
    render: (value: string) => currencyFormater(parseFloat(value)),
  },
  {
    title: 'Status',
    dataIndex: 'statusPedidoVenda',
    key: 'statusPedidoVenda',
    sortColumn: 'statusPedidoVenda',
    sorter: true,
    render: (statusId: string) => (
      <Status
        type={StatusSaleOrderType({ statusId: parseInt(statusId), t }).type}
        children={
          StatusSaleOrderType({ statusId: parseInt(statusId), t }).children
        }
      />
    ),
  },
];

import { CSSProperties, FC, ReactNode } from 'react';

import { Tooltip as AntdTooltip } from 'antd';

import './Tooltip.override.scss';

export type Placements =
  | 'topRight'
  | 'topLeft'
  | 'bottomLeft'
  | 'bottomRight'
  | 'top';

export interface ITooltip {
  title: ReactNode;
  placement?: Placements;
  children: ReactNode;
  showMe?: boolean;
  copied?: boolean;
  targetOffset?: number[];
  style?: CSSProperties;
}

export const Tooltip: FC<ITooltip> = ({
  title,
  children,
  placement,
  showMe,
  copied,
  style,
  targetOffset,
}) => (
  <AntdTooltip
    placement={placement}
    title={title}
    overlayClassName={copied ? 'tooltip-is-copied' : ''}
    align={{ targetOffset: targetOffset }}
    overlayStyle={{ display: showMe ? undefined : 'none', zIndex: 1050 }}
  >
    <div style={style}>{children}</div>
  </AntdTooltip>
);

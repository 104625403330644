import { FC } from "react";
import {
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IValence } from "./interfaces";
import { WeightInput } from "@/Components/Inputs/WeightInput";

export const Valence: FC<IValence> = ({
  product,
  disabledForm
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'products.toolTips.products.originalEquivalenceFactor'
        )} ${
          product && product.materiaPrima
            ? product.materiaPrima.valencia
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima.valencia !== form?.values?.valencia
        }
      >
        <WeightInput
          withStatusIcon={!!product}
          name="valencia"
          placeHolder="0000,00"
          label={t('products.create.valence')}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.create.tooltips.valence'),
          }}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Skeleton, Text } from '../../../../../..';
import { ConfirmBody } from '../../../../../../../Pages/Sectors/Administrative/User/List/Modals/ConfirmStatusModal';
import i18n from '../../../../../../i18n';
import { Modal } from '../../../../../../Modal';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { UppercaseFirstLetter } from '../../../../../../../Utils/CapitalFirstLetterUtils';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CHANGE_USER_STATUS_TYPE,
  GET_USER_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const DynamicUserBreadcrumbEmail = ({ match }: any) => {
  const { data: user } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_USER_TYPE,
    entityApi: UserAPI.getUser,
    externalId: match.params.externalId,
  });
  if (user) {
    const { email } = user;
    return <span>{email}</span>;
  }
  return <span></span>;
};

export const DynamicEditUserStatusBreadcrumb = ({ match }: any) => {
  const queryClient = useQueryClient();
  const [visibleConfirmationModal, setVisibleConfirmationModal] =
    useState(false);

  const { data: user } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_USER_TYPE,
    entityApi: UserAPI.getUser,
    externalId: match.params.externalId,
  });
  const changeUserStatus = useControllerMutateApiHook({
    uniqId: CHANGE_USER_STATUS_TYPE,
    entityApi: UserAPI.changeUserStatus,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_TYPE]);
        setVisibleConfirmationModal(false);
      },
    },
  });

  if (!user)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text
          type="heading3-bold"
          color="text-50"
          children={`${UppercaseFirstLetter(i18n.t('common.user'))} | ${
            user.nome
          }`}
        />
        <PermissionWrapper
          permission={1005}
          tooltip="noPermission.administrative.users.statusEdit"
        >
          <Status
            type={user.ativo ? 'success' : 'disabled'}
            ative={user.ativo || false}
            onClick={(value) =>
              value
                ? changeUserStatus.mutateAsync({
                    usuariosExternalId: [match.params.externalId],
                    ativa: value,
                  })
                : setVisibleConfirmationModal(true)
            }
            loading={changeUserStatus.isLoading}
          />
        </PermissionWrapper>
      </div>
      {user && (
        <Modal
          title={`${i18n.t('common.inactivate')} ${i18n.t('common.user')}`}
          body={
            <ConfirmBody
              itemNames={[
                {
                  name: user.nome,
                  lastName: user.sobrenome,
                },
              ]}
            />
          }
          visible={visibleConfirmationModal}
          onOkClick={() =>
            changeUserStatus.mutateAsync({
              usuariosExternalId: [match.params.externalId],
              ativa: false,
            })
          }
          onCancelClick={() => setVisibleConfirmationModal(false)}
          onClose={() => setVisibleConfirmationModal(false)}
          okButtonName={i18n.t('common.inactivate')}
          loading={changeUserStatus.isLoading}
        />
      )}
    </>
  );
};

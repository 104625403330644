import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';
import _ from 'lodash';

type ResultType = number | string;

export const calculateDilutionFactory = (dilution: number): ResultType => {
  let result: ResultType = 100 / dilution;
  if (!Number.isInteger(result)) {
    result = result.toFixed(3);
  }

  return result;
};

export const calculateDilution = (dilutionFactory: number): ResultType => {
  let result: ResultType = 100 / dilutionFactory;
  if (!Number.isInteger(result)) {
    result = result.toFixed(3);
  }
  return result;
};

export const calculateWaterConcentrationFactory = (
  waterConcentration: number
): ResultType => {
  const divider = 100 - waterConcentration;
  let result: ResultType = 100 / divider;
  if (!Number.isInteger(result)) {
    result = result.toFixed(3);
  }
  return result;
};

export const calculateWaterConcentration = (
  waterConcentrationFactory: number
): ResultType => {
  let result: ResultType =
    (100 * waterConcentrationFactory - 100) / waterConcentrationFactory;

  if (!Number.isInteger(result)) {
    result = result.toFixed(3);
  }
  return result;
};

const parseDate = (date: string) => {
  const parts = date.split('/');
  return new Date(+parts[2], +parts[1] - 1, +parts[0]);
};

export const defineLotStatus = (
  form: any,
  totalQuantity: number,
  productItemId: number,
  productClass: string,
  error?: boolean
): 'check' | 'default' | 'error' => {
  if (error) return 'error';

  const lots: any[] = form.values.lotes;

  const filteredLots = lots.filter(
    (e) => e.notaFiscalEntradaItemId === productItemId
  );
  const lotsNames: any[] = filteredLots
    .map((e) => e.numeroLote)
    .filter((e) => e !== undefined);
  const lotsNamesSet = [...(new Set(lotsNames) as any)];

  let withError = false;
  let isProductLotsOk = true;
  let totalQuantityLots = 0;

  filteredLots.forEach((lot) => {
    totalQuantityLots += lot.quantidade ?? 0;

    if (lot.dataFabricacao && lot.dataValidade) {
      const manufacturingDate = parseDate(lot.dataFabricacao);
      const expirationDate = parseDate(lot.dataValidade);

      withError = manufacturingDate > expirationDate;
    }
  });

  if (totalQuantityLots > totalQuantity) {
    withError = true;
  }

  if (lotsNamesSet.length !== lotsNames.length) {
    withError = true;
  }

  if (withError) {
    return 'error';
  }

  if (productClass === 'Matéria-Prima') {
    filteredLots.forEach((lot) => {
      isProductLotsOk = checkTechnicalInformation(lot, true);
    });
  } else {
    filteredLots.forEach((lot) => {
      isProductLotsOk = checkTechnicalInformation(lot, false);
    });
  }

  if (isProductLotsOk) {
    return 'check';
  }

  return 'default';
};

export const formatQuantityExcess = (value: number): string => {
  const formattedValue = value.toFixed(4);
  return formattedValue.replace('.', ',');
};

export const checkTechnicalInformation = (
  lot: any,
  verifyTechnical = true
): boolean => {
  if (!lot.quantidade) return false;
  if (!lot.numeroLote) return false;
  if (!lot.dataFabricacao) return false;
  if (!lot.dataValidade) return false;
  if (!lot.localEstoqueExternalId) return false;
  if (!lot.informacaoTecnica && verifyTechnical) {
    return false;
  } else if (verifyTechnical) {
    if (!lot.informacaoTecnica.densidade) return false;
    if (!lot.informacaoTecnica.diluicaoFornecedor) return false;
    if (!lot.informacaoTecnica.fatorDiluicaoFornecedor) return false;
    if (!lot.informacaoTecnica.concentracaoAgua) return false;
    if (!lot.informacaoTecnica.fatorConcentracaoAgua) return false;
  }
  return true;
};

export const enableSubmitButton = (lots: any[], lotsItems: any[]): boolean => {
  let isDisabled = false;
  lots.forEach((lot) => {
    const lotProduct = lotsItems.find(
      (item) => item.notaFiscalEntradaItemId === lot.notaFiscalEntradaItemId
    );
    if (!lotProduct) return;

    const shouldCheckTechnicalInformation =
      lotProduct.classeProdutoDescricao === 'Matéria-Prima';

    if (!checkTechnicalInformation(lot, shouldCheckTechnicalInformation)) {
      isDisabled = true;
    }
  });

  return isDisabled;
};

export const debounceQuantity = _.debounce((configNewRow: () => void) => {
  configNewRow();
}, 1000);

export const formatLotsGet = (lots: any[]): any[] => {
  const preferences = StorageUtils.getPreference();

  const formattedLots = lots.map((lot) => {
    return {
      ...lot,
      dataFabricacao: DateUtils.format(
        lot.dataFabricacao,
        preferences.padraoData ?? 'MM/dd/yyyy'
      ),
      dataValidade: DateUtils.format(
        lot.dataValidade,
        preferences.padraoData ?? 'MM/dd/yyyy'
      ),
      informacaoTecnica: lot.informacaoTecnica ?? undefined,
    };
  });

  return formattedLots;
};

export const enableDraftButton = (
  lots: any,
  initialValues: any,
  currentValue: any
): boolean => {
  if (!lots || !lots.length) return false;
  const initialValuesJSON = JSON.stringify(initialValues);
  const currentValueJSON = JSON.stringify(currentValue);

  if (initialValuesJSON !== currentValueJSON) return false;

  return true;
};

export const defineButtonDraftStatusMessage = (
  isSaved: boolean,
  initialValues: any,
  currentValue: any
) => {
  if (!initialValues || !initialValues.length) return '';
  const initialValuesJSON = JSON.stringify(initialValues);
  const currentValueJSON = JSON.stringify(currentValue);

  if (isSaved && initialValuesJSON === currentValueJSON)
    return 'common.savedChanges';
  if (initialValuesJSON !== currentValueJSON) return 'common.hearChanges';
  return '';
};

export const enableStatusIcon = (
  isSaved: boolean,
  initialValues: any,
  currentValue: any
) => {
  if (!initialValues || !initialValues.length) return false;

  const initialValuesJSON = JSON.stringify(formatLotsGet(initialValues));
  const currentValueJSON = JSON.stringify(currentValue);

  if (isSaved && initialValuesJSON !== currentValueJSON) return false;
  if (initialValuesJSON !== currentValueJSON) return true;
  return false;
};

export const createLotsState = (items: any[]): any[] => {
  const finalLots: any[] = [];
  items.forEach((item) => {
    item.lotes.forEach((lot: any) => {
      const lotFormatted = {
        ...lot,
        notaFiscalEntradaItemId: item.notaFiscalEntradaItemId,
      };
      finalLots.push(lotFormatted);
    });
  });
  return finalLots;
};

export const configColumnsSpacing = (
  canShowDatesFields: boolean,
  showTechnicalInformation: boolean
) => {
  if (canShowDatesFields && showTechnicalInformation) {
    return {
      firstColumn: 7,
      restColumns: 4,
    };
  }

  if (!canShowDatesFields && showTechnicalInformation) {
    return {
      firstColumn: 15,
      restColumns: 4,
    };
  }

  return {
    firstColumn: 10,
    restColumns: 3,
  };
};

import { useCallback } from "react";
import {
  setInitialValuesFormWaterConcentrationFactor
} from '../../../../../diluitionsHelpers';
import { useFormikContext } from "formik";

export const useWaterConcentrationFunctions = () => {
  const form: any = useFormikContext();

  const onChange = useCallback(() => {
    setInitialValuesFormWaterConcentrationFactor(form);
  }, [setInitialValuesFormWaterConcentrationFactor]);

  return { onChange };
};
import DateUtils from '@/Utils/DateUtils';
import { currencyFormater } from '@/Utils/Currency';
import { MaskNumberWithCustomDecimalsAndSuffix } from '@/Utils/MasksUtils';
import { formatPhoneNumber } from 'react-phone-number-input';
import StorageUtils from '@/Utils/StorageUtils';
import { IUseMemorizations } from './interfaces';

export const useTabsMemorizations = ({
  purchaseOrder
}: IUseMemorizations) => {
  const preferences = StorageUtils.getPreference();

  const initialValues = purchaseOrder && {
    ...purchaseOrder,
    fornecedorContatoPrincipal:
      purchaseOrder.tipoContatoId === 2
        ? formatPhoneNumber(purchaseOrder.fornecedorContatoPrincipal)
        : purchaseOrder.fornecedorContatoPrincipal,
    previsaoEntrega: DateUtils.format(
      purchaseOrder?.previsaoEntrega,
      preferences?.padraoData.replace('mm', 'MM') || 'dd/MM/yyyy'
    ),
    unidadeMedida: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map(
          (x: any) => x.unidadeMedidaAbreviacao
        )
      : '',
    descricao: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map((x: any) => x.descricao)
      : '',
    precoUnitario: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map((x: any) =>
          currencyFormater(
            x.precoUnitario,

            x.unidadeMedidaAbreviacao
          )
        )
      : '',
    tipoDescontoId: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map(
          (x: any) => x.tipoDescontoId
        )
      : '',
    valorDescontoUnitario: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map((x: any) =>
          x.tipoDescontoId === 1
            ? `${x.percentualDesconto}%`
            : currencyFormater(x.valorDescontoUnitario)
        )
      : '',
    quantidade: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map((x: any) =>
          MaskNumberWithCustomDecimalsAndSuffix(
            x.quantidade,
            4,
            x.unidadeMedidaAbreviacao
          )
        )
      : '',
    precoTotalLiquido: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map((x: any) =>
          currencyFormater(x.precoTotalLiquido)
        )
      : '',
    pedidoCompraItensExternalId: purchaseOrder
      ? purchaseOrder?.pedidoCompraItens?.map(
          (x: any) => x.pedidoCompraItemExternalId
        )
      : '',
  };

  return {
    initialValues
  };
};
import { useState } from 'react';
import { FETCH_ADDRESS_CLIENT_LIST } from '../../ControllerApiHook/UniqIds/People/ClientKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';

export const useAddressModal = (clienteExternalId: string) => {
  const [openAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [openDeliveryFeeModal, setOpenDeliveryFeeModal] = useState(false);
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false);
  const [openCreateAddressModal, setOpenCreateAddressModal] = useState(false);

  const [selectedAddressExternalId, setSelectedAddressExternalId] =
    useState('');

  const addressClientList = useControllerQueryListApiHook({
    uniqId: FETCH_ADDRESS_CLIENT_LIST,
    entityApi: ClientAPI.addressClientList,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'clienteExternalId', value: clienteExternalId }],
    },
  });

  return {
    addresses: addressClientList,
    setIsAddressModalOpen,
    openAddressModalOpen,
    setOpenDeliveryFeeModal,
    openDeliveryFeeModal,
    setOpenEditAddressModal,
    openEditAddressModal,
    setOpenCreateAddressModal,
    openCreateAddressModal,
    setSelectedAddressExternalId,
    selectedAddressExternalId,
    select: () => {
      setIsAddressModalOpen(false);
      setOpenDeliveryFeeModal(true);
    },
    edit: () => {
      setIsAddressModalOpen(false);
      setOpenEditAddressModal(true);
    },
    create: () => {
      setIsAddressModalOpen(false);
      setOpenCreateAddressModal(true);
    },
  };
};

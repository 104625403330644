import React, { FC } from 'react';
import { Color, Colors } from '../../Utils/ColorUtils';
import styles from './Icon.module.scss';
import { iconClasses, IconName } from './IconClasses';
import { DraggableSyntheticListeners } from '@dnd-kit/core';

export interface IIconProps {
  icon?: IconName;
  className?: string;
  backgroundColor?: Color | undefined;
  color?: Color;
  size?:
    | 'SS'
    | 'SG'
    | 'Ss'
    | 'S'
    | 'SM'
    | 'M'
    | 'ML'
    | 'L'
    | 'XXL'
    | 'XXLL'
    | 'XXX';
  customSize?: number;
  style?: React.CSSProperties;
  customColor?: string;
  id?: string;
  onClick?: (value: any) => void;

  onMouseLeave?: () => void;

  ref?: (node: HTMLElement | null) => void;
  listeners?: DraggableSyntheticListeners;
  attributes?: Record<string, any>;

  disabled?: boolean;
}

export const Icon: FC<IIconProps> = ({
  icon,
  size,
  color,
  backgroundColor,
  customSize,
  style,
  className,
  customColor,
  id,
  onClick,
  onMouseLeave,
  ref,
  listeners,
  attributes,

  disabled,
}) => {
  const currentSize = styles[size || 'xs'];
  return (
    <i
      id={id}
      data-testid="icon"
      onClick={onClick}
      style={{
        fontSize: customSize ? `${customSize}px` : undefined,
        backgroundColor: Colors[backgroundColor || 'transparent'],
        color: customColor ? customColor : Colors[color || 'black'],
        cursor: disabled ? 'no-drop' : 'pointer',
        ...style,
      }}
      className={`icon ${icon && iconClasses[icon]} ${
        styles['Icon']
      } ${currentSize} ${className}`}
      onMouseLeave={onMouseLeave}
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
      <span className="path4" />
      <span className="path5" />
    </i>
  );
};

import { FC, useCallback } from 'react';
import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { useInfoCardCallAPI } from './Hooks/useInfoCardCallAPI';
import { Name } from './Components/Name';
import { RegisterInput } from './Components/RegisterInput';
import { UfRegister } from './Components/UfRegister';
import { RegisterNumber } from './Components/RegisterNumber';
import { BirthDay } from './Components/Birthday';
import { Specialty } from './Components/Specialty';
import { ProductDiscount } from './Components/ProductDiscount';
import { FormulaDiscount } from './Components/FormulaDiscount';
import { Observation } from './Components/Observation';
import { FormikContextType, useFormikContext } from 'formik';
import _ from 'lodash';

export const InfoCard: FC = () => {
  const form: FormikContextType<any> = useFormikContext();
  const { t } = useTranslation();

  const {
    listState,
    refetchStateList,
    fetchNewStatePage,
    recordTypeList,
    refetchRecordTypeList,
    fetchNewRecordPage,
    specialtiesData,
    refetchSearchSpecialties,
    fetchNewSpecialtiesPage,
    fetchValidateRegistration,
  } = useInfoCardCallAPI(form, t);

  const fetchValidateRegistrationPrescriber = useCallback(
    _.debounce(
      async (
        registerCode: string,
        typeRegister: number,
        ufRegister: string
      ) => {
        await fetchValidateRegistration({
          tipoRegistroId: typeRegister,
          ufRegistroExternalId: ufRegister,
          codigoRegistro: registerCode,
        });
      },
      500
    ),
    [
      fetchValidateRegistration,
      form.values.tipoRegistroId,
      form.values.ufRegistroExternalId,
    ]
  );

  return (
    <Card title={t('prescribers.createPage.info.infos')} withoutCollapse>
      <Row gutter={[16, 0]}>
        <Name />
        <RegisterInput
          recordTypeList={recordTypeList}
          fetchNewRecordPage={fetchNewRecordPage}
          refetchRecordTypeList={refetchRecordTypeList}
          fetchValidateRegistrationPrescriber={
            fetchValidateRegistrationPrescriber
          }
          form={form}
        />
        <UfRegister
          listState={listState}
          fetchNewStatePage={fetchNewStatePage}
          refetchStateList={refetchStateList}
          fetchValidateRegistrationPrescriber={
            fetchValidateRegistrationPrescriber
          }
          form={form}
        />
        <RegisterNumber
          fetchValidateRegistrationPrescriber={
            fetchValidateRegistrationPrescriber
          }
          form={form}
        />
        <BirthDay />
      </Row>
      <Row gutter={[16, 0]}>
        <Specialty
          specialtiesData={specialtiesData}
          fetchNewSpecialtiesPage={fetchNewSpecialtiesPage}
          refetchSearchSpecialties={refetchSearchSpecialties}
        />
      </Row>
      <Row gutter={[16, 0]}>
        <ProductDiscount />
        <FormulaDiscount />
      </Row>
      <Row gutter={[16, 0]}>
        <Observation />
      </Row>
    </Card>
  );
};

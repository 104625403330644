import React, { useCallback } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '../../../../../../../../../Components/Inputs/CurrencyInput';
import { Tooltip } from '../../../../../../../../../Components';
import { currencyFormater } from '../../../../../../../../../Utils/Currency';
import { useFormikContext } from 'formik';

interface ISecondRowProps {
  initialValues: any;
}

export const SecondRow: React.FC<ISecondRowProps> = ({ initialValues }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const checkInitialValues = useCallback(
    (key: string) => {
      if (form.values) {
        return form.values[key] !== initialValues[key];
      }
      return false;
    },
    [form.values]
  );
  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorFrete')}
          title={`${t(
            'purchasing.invoice.edit.originalFreight'
          )}${currencyFormater(initialValues.valorFrete)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.freight')}
            name="valorFrete"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorSeguro')}
          title={`${t(
            'purchasing.invoice.edit.originalInsurance'
          )}${currencyFormater(initialValues.valorSeguro)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.insurance')}
            name="valorSeguro"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorDesconto')}
          title={`${t(
            'purchasing.invoice.edit.originalDiscount'
          )}${currencyFormater(initialValues.valorDesconto)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.discount')}
            name="valorDesconto"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorOutrasDespesas')}
          title={`${t(
            'purchasing.invoice.edit.originalOtherExpenses'
          )}${currencyFormater(initialValues.valorOutrasDespesas)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.otherExpenses')}
            name="valorOutrasDespesas"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={checkInitialValues('valorIpi')}
          title={`${t(
            'purchasing.invoice.edit.originalIpiValue'
          )}${currencyFormater(initialValues.valorIpi)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.IPIValue')}
            name="valorIpi"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { TextInput } from '@/Components';
import { useDensityMemorizations } from "./Hooks/useDensityMemorizations";

export const Density: FC = () => {
  const { t } = useTranslation();

  const { label } = useDensityMemorizations();

  return (
    <Col span={3}>
      <TextInput
        name="loteInformacaoTecnica.densidade"
        label={label}
        placeHolder={t('supply.lot.create.density')}
        required
        type="number"
      />
    </Col>
  );
};
import { IFChildren } from '@/Components/Form';
import { FC } from 'react';
import { Card } from '@/Components';
import { AdderList } from '@/Components/AdderList';
import { useTranslation } from 'react-i18next';
import { useDocumentCardMemorizations } from './Hooks/useDocumentCardMemorizations';
import { useDocumentCardFunctions } from './Hooks/useDocumentCardFunctions';

export const DocumentCard: FC<{ props: IFChildren }> = ({ props }) => {
  const { t } = useTranslation();

  const { column, modal } = useDocumentCardMemorizations(props);

  const { onChange } = useDocumentCardFunctions({ props });

  return (
    <Card title={t('document.documents')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        collum={column}
        modal={modal}
        onChange={onChange}
        buttonLabel={t('document.addDocument')}
        empytDataDescription={t('document.documentEmpyt')}
        actions
        entityExternalIdName="prescritorDocumentoExternalId"
        type="documentType"
      />
    </Card>
  );
};

import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../../../../../../../Components';
import _ from 'lodash';

interface IApresentationPharmaceuticalForm {
  enableButton: () => void;
}

const ApresentationPharmaceuticalForm: React.FC<
  IApresentationPharmaceuticalForm
> = ({ enableButton }) => {
  const { t } = useTranslation();
  return (
    <TextInput
      name="presentation"
      placeHolder={t(
        'production.pharmaceuticalForm.create.presentationPlaceholder'
      )}
      required
      onChange={enableButton}
      label={t('production.pharmaceuticalForm.create.presentation')}
    />
  );
};

export default ApresentationPharmaceuticalForm;

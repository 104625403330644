import { ILossDetailResponse } from '../../../../../../../../Data/Interfaces/response/Loss/ILossDetailResponse';
import { IPreferences } from '../../../../../../../../Data/Interfaces/response/User/IGetUserReponse';
import DateUtils from '../../../../../../../../Utils/DateUtils';

interface IDetailInitialValues {
  lossSingle?: ILossDetailResponse | null;
  datePattern?: IPreferences;
}

export const DetailInitialValues = ({
  lossSingle,
  datePattern,
}: IDetailInitialValues) => ({
  dataLancamentoFormatted:
    lossSingle &&
    `${DateUtils.format(
      lossSingle?.dataLancamento,
      datePattern?.padraoData || 'dd/MM/yyyy HH:mm'
    )} ${DateUtils.toTime(lossSingle?.dataLancamento)}`,
  responsavel: lossSingle?.responsavel,
  descricaoProduto: lossSingle?.descricaoProduto,
  descricaoLocalEstoque: lossSingle?.localEstoqueDescricao,
  numeroLote: lossSingle?.numeroLote,
  nomeFornecedor: lossSingle?.fornecedor,
  numeroNotaFiscal: lossSingle?.numeroNf,
  serieNotaFiscal: lossSingle?.serieNf,
  usuarioExternalId: lossSingle?.usuarioExternalId,
  beforeLoss: lossSingle?.quantidadeAntesPerda,
  lossQuantity: lossSingle?.quantidadePerdida,
  afterLoss: lossSingle?.quantidadeAposPerda,

  lossReason: lossSingle?.motivoPerdaExternalId,
  lossDate: DateUtils.convertDate(
    lossSingle?.dataPerda,
    datePattern?.padraoData
  ),
  comments: lossSingle?.observacao,
});

import { IListUnitMeasurementByClassData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useUnitaryValueMemorizations = ({
  unitsMeasurements,
  index,
  onUnitOfMeasureChange
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const formattedUnitsMeasurements =
  unitsMeasurements && unitsMeasurements.data ? unitsMeasurements.data : [];

  const label = index === 0
  ? t(
      'purchasing.purchaseOrder.create.productsCardField.unitaryValue'
    )
  : '';

  const dropdownRight = {
    name: `pedidoCompraItens.${index}.unidadeMedidaId`,
    defaultValue: 'g',
    options:
      formattedUnitsMeasurements.map(
        (x: IListUnitMeasurementByClassData) => ({
          id: x.id,
          content: x.unidadeAbreviacao,
        })
      ) || [],
    onChange: onUnitOfMeasureChange,
  };

  return {
    label,
    dropdownRight
  };
};
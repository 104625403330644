import React from 'react';
import { Avatar } from '@/Components/Avatar';
import { i18n } from '@/Components';
import { IUserListFromUserGroupData } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const UsersColumns = () => [
  {
    dataIndex: 'nome',
    title: i18n.t('admin.editUserGroups.userForm.nameColumns'),
    key: 'nome',
    sorter: true,
    sortColumn: 'nome',
    render: (firstName: string, row: IUserListFromUserGroupData) => (
      <Avatar
        type="ui-tiny-bold"
        size="M"
        fisrtName={firstName}
        lastName={row.sobrenome}
        description={{
          description: `${firstName} ${row.sobrenome}`,
          type: 'ui-tiny-bold',
          color: 'text-50',
        }}
        routeLink={`/admin/user/${row.externalId}/details`}
        color="white"
      />
    ),
  },
  {
    dataIndex: 'email',
    title: i18n.t('admin.editUserGroups.userForm.emailColumns'),
    key: 'email',
    sortColumn: 'email',
    sorter: true,
  },
];

import { IPagination } from "@/Components/Table";
import { useState } from "react";

export const useDosageInputsStates = () => {
  const [unitAbbreviation, setUnitAbbreviation] = useState<string>('');

  const [paginationUnitMeasurement] = useState<IPagination>({
    pageIndex: 0,
    pageSize: 50,
    sorter: { column: 'descricao', direction: 'ASC' },
  });

  const [ratio, setRatio] = useState<string>('');

  return {
    unitAbbreviation,
    setUnitAbbreviation,
    paginationUnitMeasurement,
    ratio,
    setRatio
  };
};
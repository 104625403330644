import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tooltip
} from '@/Components';
import { useFormikContext } from 'formik';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { IAmountDefault } from "./interfaces";
import { useAmountDefaultCallAPI } from './Hooks/useAmountDefaultCallAPI';
import { useAmountDefaultMemorizations } from './Hooks/useAmountDefaultMemorizations';

export const AmountDefault: FC<IAmountDefault> = ({
  standardFormula,
  disabled,
  classificationProductId,
  unidadeMedidaId
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    listunitsMeasurements
  } = useAmountDefaultCallAPI({
    classificationProductId
  });

  const {
    dropdownRight
  } = useAmountDefaultMemorizations({
    unidadeMedidaId,
    listunitsMeasurements
  });

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousAmountDefault'
      )} ${standardFormula?.quantidadePadrao}`}
      targetOffset={[0, 0]}
      showMe={
        !disabled &&
        standardFormula?.quantidadePadrao !== form.values.quantidadePadrao
      }
    >
      <WeightInput
        name="quantidadePadrao"
        label={t('production.standardFormula.details.amoundDefault')}
        placeHolder={t(
          'production.standardFormula.details.amoundDefaultPlaceholder'
        )}
        required={!disabled}
        withoutMarginBottom={true}
        disabled={disabled}
        dropdownRight={dropdownRight}
        withStatusIcon
      />
    </Tooltip>
  );
};
import { useTranslation } from 'react-i18next';
import {
  Card,
  InputWithSearch,
  TextInput,
  Tooltip,
} from '../../../../../../../../Components';
import { Col, Row } from 'antd';
import { FC, useEffect } from 'react';
import { FETCH_GET_LABORATORY } from '../../../../../../../../ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router';
import { IGetLaboratory } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useCreateFunctions } from './Hooks/useEditFunctions';
import { useCreateMemorizations } from './Hooks/useEditMemorizations';

import styles from './laboratoryName.module.scss';

interface IDescriptionRow {
  disabled: boolean;
  validateError: (value: boolean) => void;
}

export const DescriptionRow: FC<IDescriptionRow> = ({ disabled }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const { externalId } = useParams<{ externalId: string }>();

  const { data: laboratory } = useControllerQueryApiHook<IGetLaboratory>({
    uniqId: FETCH_GET_LABORATORY,
    entityApi: LaboratoryAPI.getCurrentLaboratory,
    externalId: externalId,
  });

  const {
    companyList,
    isLoadingCompany,
    refetchSearchCompanyPage,
    fetchInputCompany,
    isLoadingSupplyPlace,
    refetchSupplyPlace,
    supplyPlaceList,
    fetchDescriptionLaboratory,
    fetchNewPageSupplyPlace,
  } = useEditCallAPI({
    form,
    t,
  });

  const { fetchInputCompanyDescription, fetchInputCompanyInput } =
    useCreateFunctions({ fetchDescriptionLaboratory });

  const { suplyFormList, companyListlFormList } = useCreateMemorizations({
    supplyPlaceList,
    companyList,
  });

  useEffect(() => {
    refetchSupplyPlace({
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [
        {
          filterName: 'empresaExternalId',
          value: form.values.empresaExternalId,
        },
      ],
    });
  }, [form.values.empresaExternalId, refetchSupplyPlace]);

  return (
    <Card title={t('common.information')} withoutCollapse={true}>
      <Row gutter={[16, 0]}>
        <Col span={7}>
          <Tooltip
            title={`${t(
              'production.laboratory.details.tooltips.previousLaboratory'
            )}: ${laboratory?.laboratorio}`}
            targetOffset={[0, -12]}
            showMe={
              !disabled &&
              laboratory?.laboratorio !== form.values.nomeLaboratorio
            }
          >
            <TextInput
              className={styles['text-input']}
              name="nome"
              placeHolder={t('production.laboratory.create.namePlaceholder')}
              required={!disabled}
              withStatusIcon
              disabled={disabled}
              label={t('production.laboratory.create.name')}
              onChange={(value) => {
                fetchInputCompanyDescription(form, value);
              }}
            />
          </Tooltip>
        </Col>
        <Col span={7}>
          <Tooltip
            title={`${t(
              'production.laboratory.details.tooltips.previousCompany'
            )}: ${laboratory?.empresa}`}
            targetOffset={[0, -12]}
            showMe={
              !disabled &&
              laboratory?.empresaExternalId !== form.values.empresaExternalId
            }
          >
            <InputWithSearch
              items={companyListlFormList}
              name="empresaExternalId"
              placeHolder={t('production.laboratory.create.companyPlaceholder')}
              label={t('production.laboratory.create.company')}
              required={!disabled}
              withoutMarginBottom
              withStatusIcon
              disabled={disabled}
              isLoading={isLoadingCompany}
              onScrollEnd={fetchInputCompany}
              onSearch={(search) =>
                refetchSearchCompanyPage({
                  pageIndex: 0,
                  pageSize: 10,
                  search: search,
                  sorter: { column: 'nomeFantasia', direction: 'DESC' },
                })
              }
              onChange={(value: any) => {
                refetchSupplyPlace({
                  pageIndex: 0,
                  pageSize: 10,
                  sorter: { column: 'nomeFantasia', direction: 'DESC' },
                  filter: [
                    {
                      filterName: 'empresaExternalId',
                      value: value,
                    },
                  ],
                });
                fetchInputCompanyInput(form, value);
                fetchInputCompany();
              }}
            />
          </Tooltip>
        </Col>
        <Col span={7}>
          <Tooltip
            title={`${t(
              'production.laboratory.details.tooltips.previousSupplyPlace'
            )}: ${laboratory?.localEstoque}`}
            targetOffset={[0, -12]}
            showMe={
              !disabled && laboratory?.localEstoque !== form.values.localEstoque
            }
          >
            <InputWithSearch
              items={suplyFormList}
              name="localEstoqueExternalId"
              placeHolder={t(
                'production.laboratory.create.supplyPlacesPlaceholder'
              )}
              label={t('production.laboratory.create.supplyPlaces')}
              required={!disabled}
              withoutSearchIcon
              withStatusIcon
              withoutMarginBottom
              disabled={disabled}
              isLoading={isLoadingSupplyPlace}
              onScrollEnd={fetchNewPageSupplyPlace}
              onSearch={(search) =>
                refetchSupplyPlace({
                  pageIndex: 0,
                  pageSize: 10,
                  search: search,
                  sorter: { column: 'descricao', direction: 'DESC' },
                })
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};

export default DescriptionRow;

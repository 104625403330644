import DateUtils from '@/Utils/DateUtils';
import { formatPhoneNumber } from 'react-phone-number-input';
import { IDropdownEditButton, IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useCreateMemorizations = ({
  externalId,
  purchaseOrder,
  isEdit,
  setOpenDeleteDraftModal
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const initialValues = externalId
  ? {
      ...purchaseOrder,
      previsaoEntrega: DateUtils.convertDate(
        purchaseOrder?.previsaoEntrega
      ),
      fornecedorContatoPrincipal:
        purchaseOrder?.tipoContatoId === 2
          ? formatPhoneNumber(
              purchaseOrder?.fornecedorContatoPrincipal
            )
          : purchaseOrder?.fornecedorContatoPrincipal,
      pedidoCompraItens: [{}],
    }
  : { pedidoCompraItens: [{}] };

  const withDropdownEditButton: IDropdownEditButton |  undefined = 
    isEdit || !!externalId
      ? {
          options: [
            {
              label: t('common.modalDraftDelete.title'),
              icon: 'trash',
              onClick: () => setOpenDeleteDraftModal(true),
            },
          ],
        }
      : undefined;

  return {
    initialValues,
    withDropdownEditButton
  };
};
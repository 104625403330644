import { IQueryListParams } from "@/Utils/Http";
import { useState } from "react";

export const useUnitaryValueStates = () => {
  const [
    unitMeasurementPagination, 
    setUnitMeasurementPagination
  ] = useState<IQueryListParams>({
    pageIndex: 0,
    pageSize: 50,
  });

  return {
    unitMeasurementPagination,
    setUnitMeasurementPagination
  };
};
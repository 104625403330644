import { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Card,
  TextArea,
  TextInput
} from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router';
import { Provider } from './Componentes/Provider';
import { MainContact } from './Componentes/MainContact';
import { DeliveryForecast } from './Componentes/DeliveryForecast';
import { useInfoCardStates } from './Hooks/useInfoCardStates';
import { useInfoCardCallAPI } from './Hooks/useInfoCardCallAPI';

export const PurchaseOrderInfoEditCard = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const {
    externalIdProvider,
    setExternalIdProvider,
    iconContact,
    setIconContact
  } = useInfoCardStates();

  const {
    orderPurchase,
    documentProvider,
    isLoadingDocumentProvider
  } = useInfoCardCallAPI({
    externalId,
    externalIdProvider
  });

  useEffect(() => {
    if (documentProvider && form) {
      form.setFieldValue('razaoSocial', documentProvider.razaoSocial);
      form.setFieldValue('cnpj', documentProvider.cnpj);
      form.setFieldValue(
        'fornecedorContatoPrincipal',
        documentProvider.fornecedorContatoFavorito
      );
      setIconContact(documentProvider.icon);
    }
  }, [externalIdProvider, documentProvider]);

  return (
    <Card
      title={t('purchasing.purchaseOrder.create.info')}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Provider
            orderPurchase={orderPurchase}
            setExternalIdProvider={setExternalIdProvider}
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="razaoSocial"
            label={t('purchasing.purchaseOrder.create.corporateName')}
            withoutMarginBottom={true}
            disabled={true}
            isLoading={isLoadingDocumentProvider}
          />
        </Col>
        <Col span={6}>
          <TextInputWithMask
            name="cnpj"
            mask="cnpjMask"
            label={t('purchasing.purchaseOrder.create.cnpj')}
            withoutMarginBottom={true}
            disabled={true}
            isLoading={isLoadingDocumentProvider}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={6}>
          <MainContact
            iconContact={iconContact}
            documentProvider={documentProvider}
          />
        </Col>
        <Col span={4}>
          <DeliveryForecast orderPurchase={orderPurchase} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={24}>
          <TextArea
            name="observacao"
            withStatusIcon
            label={t('supply.losses.create.comments')}
            placeholder={t(
              'purchasing.purchaseOrder.create.placeHolder.comments'
            )}
            maxLength={150}
            rows={3}
          />
        </Col>
      </Row>
    </Card>
  );
};

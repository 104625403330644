import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import {
  ADD_ADRESS_PRESCRIBERS,
  ADD_CONTACT_PRESCRIBERS,
  ADD_DOCUMENT_PRESCRIBERS,
  CHANGE_FAVORITE_ADRESS_PRESCRIBERS,
  CHANGE_FAVORITE_CONTACT_PRESCRIBERS,
  DELETE_ADRESS_PRESCRIBERS,
  DELETE_CONTACT_PRESCRIBERS,
  DELETE_DOCUMENT_PRESCRIBERS,
  EDIT_ADRESS_PRESCRIBERS,
  EDIT_CONTACT_PRESCRIBERS,
  EDIT_DOCUMENT_PRESCRIBERS,
  GET_PRESCRIBER
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useDetailsCallAPI = () => {
  const queryClient = useQueryClient();

  const addContact: any = useControllerMutateApiHook({
    uniqId: ADD_CONTACT_PRESCRIBERS,
    entityApi: PrescribersAPI.addContactPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.createContact'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const editContact: any = useControllerMutateApiHook({
    uniqId: EDIT_CONTACT_PRESCRIBERS,
    entityApi: PrescribersAPI.editContactPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.changeContact'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const deleteContact: any = useControllerMutateApiHook({
    uniqId: DELETE_CONTACT_PRESCRIBERS,
    entityApi: PrescribersAPI.deleteContactPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.deleteContact'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const changeFavoriteContact: any = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_CONTACT_PRESCRIBERS,
    entityApi: PrescribersAPI.changeFavoriteContactPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.changeFavoriteContact'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const addAdress: any = useControllerMutateApiHook({
    uniqId: ADD_ADRESS_PRESCRIBERS,
    entityApi: PrescribersAPI.addAddressPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.createAddress'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const editAdress: any = useControllerMutateApiHook({
    uniqId: EDIT_ADRESS_PRESCRIBERS,
    entityApi: PrescribersAPI.editAddressPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeAddress'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const deleteAdress: any = useControllerMutateApiHook({
    uniqId: DELETE_ADRESS_PRESCRIBERS,
    entityApi: PrescribersAPI.deleteAddressPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.deleteAddress'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const changeFavoriteAddress: any = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_ADRESS_PRESCRIBERS,
    entityApi: PrescribersAPI.changeFavoriteAddressPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeFavoriteAddress'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const editDocument: any = useControllerMutateApiHook({
    uniqId: EDIT_DOCUMENT_PRESCRIBERS,
    entityApi: PrescribersAPI.editDocumentPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.changeDocument'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const addDocument: any = useControllerMutateApiHook({
    uniqId: ADD_DOCUMENT_PRESCRIBERS,
    entityApi: PrescribersAPI.addDocumentPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.createDocument'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  const deleteDocument: any = useControllerMutateApiHook({
    uniqId: DELETE_DOCUMENT_PRESCRIBERS,
    entityApi: PrescribersAPI.deleteDocumentPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.deleteDocument'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    }
  });

  return {
    addContact,
    editContact,
    deleteContact,
    changeFavoriteContact,
    addAdress,
    editAdress,
    deleteAdress,
    changeFavoriteAddress,
    editDocument,
    addDocument,
    deleteDocument
  };
};
import { history } from '@/Store';
import {
  FETCH_LIST_CLIENT,
  GET_CLIENT,
  DELETE_CLIENT,
  EDIT_INFO_CLIENT
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';

import { useQueryClient } from "@tanstack/react-query";
import { IUseCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useModalCallAPI = ({ changeVisibleModal }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const deleteClient: any = useControllerMutateApiHook({
    uniqId: DELETE_CLIENT,
    entityApi: ClientAPI.deleteClient,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_CLIENT]);

        history.push(`/sales/clients`);

        changeVisibleModal(false);
      }
    }
  });

  const editInfo: any = useControllerMutateApiHook({
    uniqId: EDIT_INFO_CLIENT,
    entityApi: ClientAPI.editInfoClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('client.messages.success.changeInfo'));

        queryClient.invalidateQueries([GET_CLIENT]);
        
        changeVisibleModal(true);
      }
    }
  });

  return {
    deleteClient,
    editInfo
  };
};
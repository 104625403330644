import { FC, useState } from 'react';
import { Col, Row } from 'antd';
import { TextInputPhoneMask } from '@/Components/Inputs/TextInputPhoneMask';
import { history } from '@/Store';
import { TFunction } from 'react-i18next';
import { i18n, InputWithSearch, Modal, TextInput } from '@/Components';
import { useControllerMutateApiHook, useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_RECORD_TYPE } from '@/ControllerApiHook/UniqIds/People/RecordKeys';
import { RecordAPI } from '@/Data/API/People/RecordApi';
import { IListRecordTypeData } from '@/Data/Interfaces/response/Record/IRecordResponse';
import { GET_LIST_STATE } from '@/ControllerApiHook/UniqIds/Locality/StateKeys';
import { StateAPI } from '@/Data/API/Locality/StateApi';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { FETCH_LIST_SPECIALTIES_FOR_DROPDOWN } from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import { CREATE_FAST_PROVIDER } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';

const getUrlFormValues = (formValues: any) => {
  const keys = Object.keys(formValues);
  return keys
    .map((x) =>
      x !== 'phone' && x !== 'contatos' && x !== 'pessoa'
        ? `&${x}=${formValues[x]}`
        : ''
    )
    .toString()
    .replaceAll(',', '');
};

interface ICreateFastPrescriberModal {
  visible: boolean;
  changeVisible: (value: boolean) => void;
  nameClient: string;
  t: TFunction<'translation', undefined>;
}

export const CreateFastPrescriberModal: FC<ICreateFastPrescriberModal> = ({
  changeVisible,
  visible,
  nameClient,
  t,
}) => {
  const [formValues, setFormValues] = useState();

  const createPrescriber: any = useControllerMutateApiHook({
    uniqId: CREATE_FAST_PROVIDER,
    entityApi: PrescribersAPI.createFastPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('prescribers.createPage.messages.success'));
        
        changeVisible(false);
      }
    }
  });

  return (
    <Modal
      noLeaveWithoutLeaving
      onOkClickForm={(x) =>
        createPrescriber.mutateAsync({
          ...x,
          identificacaoContato: x.identificacaoContato,
        })
      }
      title={t('common.createNewPrescriber')}
      body={<ModalBody t={t} />}
      visible={visible}
      onCancelClick={() => changeVisible(!visible)}
      htmlType="submit"
      onClose={() => changeVisible(!visible)}
      withForm
      widthModal={650}
      disabledButtonFormNames={[
        'nomeCompleto',
        'tipoRegistroId',
        'ufRegistroExternalId',
        'codigoRegistro',
      ]}
      initialValues={{ nomeCompleto: nameClient }}
      okButtonName={t('common.create')}
      formValues={(x) => setFormValues(x)}
      dropdownRight={[
        {
          label: t(
            'callCenter.createService.createClientModal.createCompleteData'
          ),
          onClick: () =>
            history.push(
              `/sales/prescribers/create?${getUrlFormValues(formValues)}`
            ),
        },
      ]}
    />
  );
};

interface IModalBody {
  t: TFunction<'translation', undefined>;
}

const ModalBody: FC<IModalBody> = ({ t }) => {
  const {
    data: listState,
    refetch: refetchStateList,
    fetchNewPage: fetchNewStatePage,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 1,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: recordTypeList,
    refetch: refetchRecordTypeList,
    fetchNewPage: fetchNewRecordPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_RECORD_TYPE,
    entityApi: RecordAPI.listRecordType,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 1,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: specialtiesData,
    refetch: refetchSearchSpecialties,
    fetchNewPage: fetchNewSpecialtiesPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SPECIALTIES_FOR_DROPDOWN,
    entityApi: SpecialtiesAPI.listSpecialtiesForDropdown,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 1,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <TextInput
            name="nomeCompleto"
            label={t('callCenter.createService.createClientModal.fullName')}
            required
          />
        </Col>
        <Col span={12}>
          <InputWithSearch
            name="tipoRegistroId"
            placeHolder={t('prescribers.createPage.info.registerPlaceholder')}
            label={t('prescribers.createPage.info.register')}
            required
            items={recordTypeList?.data?.map((x: IListRecordTypeData) => ({
              id: x.id,
              label: `${x.sigla} (${x.descricao})`,
            }))}
            onScrollEnd={fetchNewRecordPage}
            onSearch={(search) => {
              refetchRecordTypeList({
                pageIndex: 0,
                pageSize: 10,
                search: search,
                sorter: { column: 'descricao', direction: 'DESC' },
              });
            }}
            withoutSearchIcon
          />
        </Col>
        <Col span={4}>
          <InputWithSearch
            name="ufRegistroExternalId"
            placeHolder="UF"
            label={t('prescribers.createPage.info.UF')}
            required
            items={listState?.data?.map((x: IGetStateResponse) => ({
              id: x.externalId,
              label: `${x.abreviacao} - ${x.descricao}`,
              content: x.descricao,
            }))}
            onScrollEnd={fetchNewStatePage}
            onSearch={(search) => {
              refetchStateList({
                pageIndex: 1,
                pageSize: 10,
                search: search,
                sorter: { column: 'descricao', direction: 'ASC' },
              });
            }}
            withoutSearchIcon
          />
        </Col>
        <Col span={8}>
          <TextInput
            name="codigoRegistro"
            placeHolder={`${t('common.exampleAbbreviation')}: 14.975`}
            label={t('prescribers.createPage.info.registerNumber')}
            required
          />
        </Col>
        <Col span={24}>
          <InputWithSearch
            name="especialidadesIds"
            label={t('prescribers.createPage.info.specialties')}
            placeHolder={t(
              'prescribers.createPage.info.specialtiesPlaceholder'
            )}
            items={specialtiesData?.data?.map((x: any) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            multipleSelection
            typeTag="tertiary"
            onScrollEnd={fetchNewSpecialtiesPage}
            onSearch={(search) => {
              refetchSearchSpecialties({
                pageIndex: 1,
                pageSize: 10,
                search: search,
                sorter: { column: 'descricao', direction: 'DESC' },
              });
            }}
            withoutSearchIcon
          />
        </Col>
        <Col span={10}>
          <TextInputPhoneMask
            name="identificacaoContato"
            label={t('callCenter.createService.createClientModal.cellphone')}
            placeHolder={`(DDD) + ${t(
              'callCenter.createService.createClientModal.cellphone'
            )}`}
          />
        </Col>
      </Row>
    </div>
  );
};

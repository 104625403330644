import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useCapsuleColorFunctions = ({
  refetchCapsuleColor
}: IUseFunctions) => {
  const onSearch = useCallback((search?: string) => {
    refetchCapsuleColor({
      pageIndex: 0,
      pageSize: 10,
      search: search,
    });
  }, [refetchCapsuleColor]);

  return { onSearch };
};
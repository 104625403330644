import { IListUnitPrescriptionMeasurementData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { IUseMemorizations } from "./interfaces";

export const useQuantityMemorizations = ({
  unitsMeasurementsDefault,
  listunitsMeasurementsPrescription,
  isUnitOfMeasurePrescriptionLoading,
  index
}: IUseMemorizations) => {
  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;
  
  const unitOfMeasureDropdownPrescription = listunitsMeasurementsPrescription
  ? {
      name: 'unidadeMedidaId',
      loading: isUnitOfMeasurePrescriptionLoading,
      defaultValue: unitMeasureIdDefault,
      options:
        listunitsMeasurementsPrescription?.data?.map((x: IListUnitPrescriptionMeasurementData) => ({
          id: x.unidadeMedidaId,
          content: x.abreviacao,
        })) || [],
    }
  : undefined;

  const dropdownRight = {
    options: unitOfMeasureDropdownPrescription
      ? unitOfMeasureDropdownPrescription.options
      : [],
    name: `produtos.${index}.unidadeMedidaId`,
  };

  return {
    dropdownRight
  };
};
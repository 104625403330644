import { 
  useControllerMutateApiHook, 
  useControllerQueryListApiHook 
} from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { 
  FETCH_GET_DROPDOWN_GROUP, 
  VALIDATION_NAME_SUBGROUP 
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { IUseCallAPI } from "./interfaces";

export const useModalBodyCallAPI = ({
  onSubGroupValidationError
}: IUseCallAPI) => {
  const { 
    data: listGroups, 
    fetchNewPage: fetchNewGroupPage 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_DROPDOWN_GROUP,
    entityApi: ProductAPI.getGroupListDropdown,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  const { 
    mutateAsync 
  }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_NAME_SUBGROUP,
    entityApi: ProductAPI.productSubGroupNameValidation,
    options: {
      onError: onSubGroupValidationError
    }
  });

  return {
    listGroups,
    fetchNewGroupPage,
    mutateAsync
  };
};
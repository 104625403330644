import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useRegisterInputFunctions = ({
  refetchRecordTypeList
}: IUseFunctions) => {
  const onSearch = useCallback((search: string | undefined) => {
    refetchRecordTypeList({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' },
    });
  }, [refetchRecordTypeList]);

  return { onSearch };
};
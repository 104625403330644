import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IUnitStock } from "./interfaces";
import { Dropdown, Tooltip } from "@/Components";
import { useFormikContext } from "formik";
import { FETCH_LIST_UNITS_BY_CLASS } from "@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys";
import { isNil } from "lodash";
import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { UnitMeasurementAPI } from "@/Data/API/Supply/UnitMeasurementApi";
import { IListUnitMeasurementByClassData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";

export const UnitStock: FC<IUnitStock> = ({
  product,
  disabledForm,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { data: listunitsMeasurements } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    initialPagination: {
      pageIndex: 0,
      pageSize: 50
    },
    autoIncrement: true,
    enabled: !isNil(form?.values?.classeProdutoId),
  });

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('products.toolTips.products.unitStock')} ${
          product?.unidadeMedida.abreviacao
        } (${product?.unidadeMedida.descricao})`}
        showMe={
          product &&
          !disabledForm &&
          product.unidadeEstoqueId !== form?.values?.unidadeEstoqueId
        }
      >
        <Dropdown
          items={listunitsMeasurements?.data?.map((x: IListUnitMeasurementByClassData) => ({
            id: x.id,
            label: `${x.descricao} (${x.unidadeAbreviacao})`,
          }))}
          name="unidadeEstoqueId"
          label={[
            {
              children: t('products.create.measure'),
            },
          ]}
          placeHolder={t('products.create.measurePlaceholder')}
          disabled={disabledForm || !form?.values.classeProdutoId}
          withStatusIcon={!!product}
        />
      </Tooltip>
    </Col>
  );
};
import { 
  FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM 
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { IUseCallAPI } from "./interfaces";
import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";

export const usePharmaceuticalFormCallAPI = ({
  productExternalId,
  associatedProductExternalId
}: IUseCallAPI) => {
  const {
    data: listPharmaceuticalForm,
    isLoading: isPharmaceuticalFormLoading,
    fetchNewPage: fetchNewFarmaceuticalFormPage,
    refetch: refetchSearchPharmaceuticalFormPage
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM,
    entityApi: ProductAPI.getListAssociatedProductPharmaceuticalFormDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [
        { filterName: 'produtoExternalId', value: productExternalId },
        {
          filterName: 'produtoAssociadoExternalId',
          value: associatedProductExternalId,
        },
      ],
    },
    autoIncrement: true
  })

  return {
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage
  };
};
import { FC, useEffect } from 'react';
import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@/Utils/Http';
import { Options } from './Components/Options';
import { PhysicalPerson } from './Components/PhysicalPerson';
import { LegalEntity } from './Components/LegalEntity';
import { Discounts } from './Components/Discounts';
import { Observation } from './Components/Observation';
import { useInfoCardStates } from './Hooks/useInfoCardStates';
import { useInfoCardFunctions } from './Hooks/useInfoCardFunctions';

export const InfoCard: FC = () => {
  const queryParams = useQueryParams();
  const { t } = useTranslation();

  const { personType, setPersonType } = useInfoCardStates();

  const { changePersonType } = useInfoCardFunctions({ setPersonType });

  useEffect(() => {
    setPersonType(parseInt(queryParams.get('clientType') ?? '0'));
  }, [queryParams, setPersonType]);

  return (
    <Card title={t('client.createPage.info')} withoutCollapse>
      <Options personType={personType} changePersonType={changePersonType} />
      {personType === 0 ? <PhysicalPerson /> : <LegalEntity />}
      <Discounts />
      <Observation />
    </Card>
  );
};

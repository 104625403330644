import {
  DELETE_INCOMPATIBLE_PRODUCT,
  FETCH_GET_PRODUCT,
  FETCH_INCOMPATIBLE_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook, 
  useControllerQueryListApiHook 
} from '@/ControllerApiHook/Controller';
import { 
  IProductResponse 
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';

export const useIncompatibleProductCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: productExternalId
  });

  const { 
    data: productList, 
    isLoading: isProductListLoading, 
    refetch: refetchProductList
  } = useControllerQueryListApiHook({
    uniqId: FETCH_INCOMPATIBLE_PRODUCT,
    entityApi: ProductAPI.getIncompatibleProductList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'produtoExternalId', value: productExternalId }],
    }
  });

  const deleteProductIncompatible = useControllerMutateApiHook({
    uniqId: DELETE_INCOMPATIBLE_PRODUCT,
    entityApi: ProductAPI.deleteIncompatibleProduct
  });

  return {
    currentProduct,
    productList,
    isProductListLoading,
    deleteProductIncompatible,
    refetchProductList
  };
};
import { Modal, Text } from '@/Components';
import { IPreferences } from '@/Data/Interfaces/response/User/IGetUserReponse';
import DateUtils from '@/Utils/DateUtils';
import { UseMutationResult } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface IInsufficientFunds {
  productName?: string;
  BalanceStock?: number;
  datePattern?: IPreferences;
  addProduct: UseMutationResult;
  editProduct: UseMutationResult;
  editModal: boolean;
  changeVisible: () => void;
  visible: boolean;
}

export const InsufficientFunds: FC<IInsufficientFunds> = ({
  productName,
  BalanceStock,
  addProduct,
  editProduct,
  datePattern,
  editModal,
  changeVisible,
  visible,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Modal
      title={t(
        'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.insufficientFund'
      )}
      body={
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content" color="text-50">
            <ReactMarkdown>
              {t(
                'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.description',
                {
                  product: `**${productName}**`,
                }
              )}
            </ReactMarkdown>
          </Text>
          <Text type="ui-tiny-content" color="text-50">
            <ReactMarkdown>
              {t(
                'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.BalanceStock',
                {
                  stock: `**${BalanceStock} un**`,
                }
              )}
            </ReactMarkdown>
          </Text>
          <Text type="ui-tiny-content" color="text-50">
            <ReactMarkdown>
              {t(
                'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.SelectedQuantity',
                {
                  quantity: `**${form.values?.quantidade} un**`,
                }
              )}
            </ReactMarkdown>
          </Text>
          <Text
            type="ui-tiny-content"
            children={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.footerDescription'
            )}
            color="text-50"
            withoutMarginBottom
          />
        </div>
      }
      visible={visible}
      maxWidth={400}
      okButtonColor="status-danger-base"
      okButtonName={t('common.continue')}
      onOkClick={async () => {
        changeVisible();
        if (editModal)
          await editProduct.mutateAsync({
            ...form.values,
            ...(form.values.dataPrescricao && {
              dataPrescricao: DateUtils.convertDateToApi(
                form.values.dataPrescricao,
                datePattern?.padraoData
              ),
            }),
          });
        else
          await addProduct.mutateAsync({
            ...form.values,
            ...(form.values.dataPrescricao && {
              dataPrescricao: DateUtils.convertDateToApi(
                form.values.dataPrescricao,
                datePattern?.padraoData
              ),
            }),
          });
      }}
      onCancelClick={() => {
        form.setSubmitting(false);
        changeVisible();
      }}
      onClose={() => {
        form.setSubmitting(false);
        changeVisible();
      }}
    />
  );
};

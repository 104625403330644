import { useCallback } from "react"
import { IUseFunctions } from "./interfaces";

export const useOriginCountryFunctions = ({
  countryPagination,
  setCountryPagination
}: IUseFunctions) => {
  const onScrollEnd= useCallback(
    () =>
    setCountryPagination({
      ...countryPagination,
      pageIndex: countryPagination.pageIndex + 1,
    }), [countryPagination, setCountryPagination]
  );

  return { onScrollEnd };
};
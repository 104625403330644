import { Dropdown, InputWithSearch, TextInput, Tooltip } from '@/Components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { ILaboratoryListData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { useEditStates } from '../../Hooks/useEditStates';
import { useEditCallAPI } from '../../Hooks/useEditCallAPI';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { useTypes } from '../../../../utils';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

interface IOtherRow {
  disabled: boolean;
  pharmaceuticalForm: IGetPharmaceuticalForm | undefined;
  initialLaboratoryName: any;
  useType: any;
}

export const OtherRow: FC<IOtherRow> = ({
  disabled,
  pharmaceuticalForm,
  initialLaboratoryName,
  useType,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { paginationLaboratory, setPaginationLaboratory } = useEditStates();

  const {
    unitMeasurementPosologyList,
    isLoadingUnitMeasurement,
    laboratoryList,
    isLoadingLaboratoryList,
    refetchSearchLaboratoryPage,
  } = useEditCallAPI({ externalId, form, t });

  return (
    <Row gutter={[16, 0]}>
      <Col span={9}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousLaboratory'
          )} ${initialLaboratoryName}`}
          targetOffset={[0, -12]}
          showMe={
            !disabled &&
            pharmaceuticalForm?.laboratorioExternalId !==
              form.values.laboratorioExternalId
          }
        >
          <InputWithSearch
            items={laboratoryList?.data?.map((value: ILaboratoryListData) => {
              return {
                id: value?.externalId,
                label: value?.laboratorio,
              };
            })}
            name="laboratorioExternalId"
            placeHolder={t(
              'production.pharmaceuticalForm.create.laboratoryPlaceholder'
            )}
            label={t('production.pharmaceuticalForm.create.laboratory')}
            required={!disabled}
            withStatusIcon
            disabled={disabled}
            withoutMarginBottom={true}
            isLoading={isLoadingLaboratoryList}
            onScrollEnd={() =>
              setPaginationLaboratory({
                ...paginationLaboratory,
                pageIndex: paginationLaboratory.pageIndex + 1,
              })
            }
            onSearch={(search) => {
              refetchSearchLaboratoryPage({
                pageIndex: 0,
                pageSize: 10,
                search: search,
                sorter: { column: 'laboratorio', direction: 'DESC' },
              });
            }}
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousUse'
          )} ${useType?.name}`}
          targetOffset={[0, -12]}
          showMe={
            !disabled &&
            pharmaceuticalForm?.usoFormaFarmaceutica !==
              form.values.usoFormaFarmaceutica
          }
        >
          <Dropdown
            name="usoFormaFarmaceutica"
            items={useTypes?.map((values) => ({
              id: values.id,
              label: values.name,
            }))}
            placeHolder={t(
              'production.pharmaceuticalForm.create.usePlaceholder'
            )}
            required={!disabled}
            withStatusIcon
            label={t('production.pharmaceuticalForm.create.use')}
            disabled={disabled}
          />
        </Tooltip>
      </Col>
      <Col span={3}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousPresentation'
          )} ${pharmaceuticalForm?.apresentacao}`}
          targetOffset={[0, -5]}
          showMe={
            !disabled &&
            pharmaceuticalForm?.apresentacao !== form.values.apresentacao
          }
        >
          <TextInput
            name="apresentacao"
            placeHolder={t(
              'production.pharmaceuticalForm.create.presentationPlaceholder'
            )}
            required={!disabled}
            withStatusIcon
            label={t('production.pharmaceuticalForm.create.presentation')}
            disabled={disabled}
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousUnitMeasurement'
          )} ${pharmaceuticalForm?.descricaoUnidadeMedida} (${
            pharmaceuticalForm?.abreviacaoUnidadeMedida
          })`}
          targetOffset={[0, -5]}
          showMe={
            !disabled &&
            pharmaceuticalForm?.unidadeMedidaId !== form.values.unidadeMedidaId
          }
        >
          <InputWithSearch
            items={unitMeasurementPosologyList?.data?.map(
              (value: IIListUnitMeasurementAllData) => {
                return {
                  id: value?.id,
                  label: `${value?.descricao} (${value?.unidadeAbreviacao})`,
                };
              }
            )}
            name="unidadeMedidaId"
            placeHolder={t(
              'production.pharmaceuticalForm.create.unitMeasurementPlaceholder'
            )}
            label={t('production.pharmaceuticalForm.create.unitMeasurement')}
            disabled={disabled}
            required={!disabled}
            withoutSearchIcon
            withStatusIcon
            withoutMarginBottom={true}
            isLoading={isLoadingUnitMeasurement}
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousValidityDurationInDays'
          )} ${pharmaceuticalForm?.validadeDias} ${t(
            'production.pharmaceuticalForm.details.tooltips.previousDays'
          )}`}
          targetOffset={[0, -5]}
          showMe={
            !disabled &&
            pharmaceuticalForm?.validadeDias !== form.values.validadeDias
          }
        >
          <TextInputNumber
            name="validadeDias"
            placeHolder={t(
              'production.pharmaceuticalForm.create.validityDurationInDaysPlaceholder'
            )}
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t(
                'production.pharmaceuticalForm.create.validityDurationInDaysTooltips'
              ),
            }}
            required={!disabled}
            withStatusIcon
            label={t(
              'production.pharmaceuticalForm.create.validityDurationInDays'
            )}
            disabled={disabled}
            withoutArrows
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

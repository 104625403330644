export const clientEN = {
  client: 'client',
  clients: 'clients',
  listPage: {
    code: 'Code',
    clientName: 'Client name',
    contact: 'Main Contact',
    personType: 'Person type',

    newButton: 'New client',

    searchPlaceholder:
      'Search for a customer, code, CNPJ/CPF, main contact, etc.',
    deleteModal: {
      manyClientDescription: 'Are you sure you want to delete the customers:',
      description: 'Are you sure you want to delete the client {{client}}?',
    },
  },

  createPage: {
    info: 'Information',

    legalEntity: 'Physical person',
    juridicalPerson: 'Legal person',

    fullName: 'Full name',
    fullNamePlaceholder: 'E.g: Marcos Almeida',

    birthDate: 'Date of birth',

    fantasyName: 'Fancy name',
    fantasyNamePlaceholder: 'E.g: Farmácia Souza e Filhos',

    corporateName: 'Corporate reason',

    descProduct: 'Discount in Prod. finished',
    descFormula: 'Discount on formulas',

    observation: 'Observation',
    observationPlaceholder: 'add a note...',

    success: 'Client created!',
  },

  editPage: {
    personType: 'Person type',

    legalEntity: 'Physical person',
    juridicalPerson: 'Legal person',

    birthDate: 'Date of birth',

    descProduct: 'Discount in Prod. finished',
    descFormula: 'Discount on formulas',

    observation: 'Observation',

    editDetails: 'Edit details',

    statusModal: {
      confirmation:
        'Are you sure you want to deactivate the client {{client}}?',
    },
  },

  modalCannotDelete: {
    cannotDeleteClientWithMovementsInTheSystem: {
      clients: 'clients',
      oneEntity: {
        messageSubtitle: 'the client',
        reasonTranslation:
          'This client has already had movement within the system, is associated with some service or sales order and therefore it is not possible to delete it.',
      },
      manyEntity: {
        couldNotDelete: 'Could not delete',
        messageSubtitle: 'Cannot delete clients:',
        reasonTranslation:
          'These clients have already had transactions within the system, are associated with some service or sales order and therefore it is not possible to exclude them.',
      },
      warning:
        'If you no longer want to use this client, change its status to “Inactive”.',
    },
  },
  messages: {
    success: {
      createClient: 'Client created!',
      changeStatus: 'Updated status!',
      changeInfo: 'Updated info!',
    },
  },
};

export const clientPT = {
  client: 'cliente',
  clients: 'clientes',
  listPage: {
    code: 'Código',
    clientName: 'Nome do Cliente',
    contact: 'Contato Principal',
    personType: 'Tipo Pessoa',

    newButton: 'Novo cliente',

    searchPlaceholder:
      'Pesquise por um cliente, código, CNPJ/CPF, contato principal, etc.',
    deleteModal: {
      manyClientDescription: 'Você tem certeza que deseja excluir os clientes:',
      description: 'Você tem certeza que deseja excluir o cliente {{client}}?',
    },
  },

  createPage: {
    info: 'Informações',

    legalEntity: 'Pessoa física',
    juridicalPerson: 'Pessoa jurídica',

    fullName: 'Nome completo',
    fullNamePlaceholder: 'Ex: Marcos Almeida',

    birthDate: 'Data de Nascimento',

    fantasyName: 'Nome fantasia',
    fantasyNamePlaceholder: 'Ex: Farmácia Souza e Filhos',

    corporateName: 'Razão social',

    descProduct: 'Desc. em Prod. Acabados',
    descFormula: 'Desconto em Fórmulas',

    observation: 'Observação',
    observationPlaceholder: 'Adicione uma observação...',

    success: 'Cliente criado!',
  },

  editPage: {
    personType: 'Tipo de Pessoa',

    legalEntity: 'Pessoa física',
    juridicalPerson: 'Pessoa jurídica',

    birthDate: 'Data de Nascimento',

    descProduct: 'Desc. em Prod. Acabados',
    descFormula: 'Desconto em Fórmulas',

    observation: 'Observações',

    editDetails: 'Editar detalhes',

    statusModal: {
      confirmation:
        'Você tem certeza que deseja inativar o cliente {{client}}?',
    },
  },

  modalCannotDelete: {
    cannotDeleteClientWithMovementsInTheSystem: {
      clients: 'clientes',
      oneEntity: {
        messageSubtitle: 'o cliente',
        reasonTranslation:
          'Esse cliente já teve movimentação dentro do sistema, está associado a algum atendimento ou pedido de venda e por isso não é possível a sua exclusão.',
      },
      manyEntity: {
        couldNotDelete: 'Não foi possível excluir',
        messageSubtitle: 'Não é possível excluir os clientes:',
        reasonTranslation:
          'Esses clientes já tiveram movimentações dentro do sistema, estão associados a algum atendimento ou pedido de venda e por isso não é possível suas exclusões.',
      },
      warning:
        'Caso não queira mais utilizar esse cliente, mude o status dele para “Inativo”.',
    },
  },

  messages: {
    success: {
      createClient: 'Cliente criado!',
      changeStatus: 'Status atualizado!',
      changeInfo: 'Informações atualidadas!',
    },
  },
};

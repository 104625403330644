import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { GET_USER_WITHOUT_GROUP_LIST_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';

export const useBodyCallAPI = (groupExternalId: string) => {
  const usersList = useControllerQueryListApiHook({
    uniqId: GET_USER_WITHOUT_GROUP_LIST_TYPE,
    entityApi: UserAPI.listUserWithoutGroup,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'nome', direction: 'DESC' },
      filter: [{ filterName: 'grupoExternalId', value: groupExternalId }],
    },
  });

  return { usersList };
};

import { FC } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text, Icon } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { useLotControlModalCallAPI } from './Hooks/useLotControlModalCallAPI';

export const ModalBody: FC = () => {
  const {
    product
  } = useLotControlModalCallAPI();

  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px', maxWidth: 400 }}>
      <Divflex style={{ marginBottom: '20px', flexWrap: 'wrap' }}>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={t('products.modal.products.theProduct')}
        />
        <Text
          color="text-50"
          type="ui-tiny-bold"
          children={`${product?.descricao} `}
        />
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={t('products.modal.products.amount')}
        />
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={t('products.modal.products.desative')}
        />
      </Divflex>
      <Divflex>
        <Icon
          icon={'exclamation-upside-down'}
          size={'SM'}
          color={'text-400'}
          style={{ padding: '18px 18px 0 8px' }}
        />
        <Text
          type="paragraph2"
          color={'text-400'}
          children={t('products.modal.products.warningFooter')}
        />
      </Divflex>
    </div>
  );
};

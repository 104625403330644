import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/Components';
import { TextInput } from '@/Components/Inputs/TextInput';
import { IModalBody } from './interfaces';
import { useModalBodyCallAPI } from './Hooks/useModalBodyCallAPI';
import { useModalBodyFunctions } from './Hooks/useModalBodyFunctions';
import { useModalBodyStates } from './Hooks/useModalBodyStates';
import { useModalBodyMemorizations } from './Hooks/useModalBodyMemorizations';

export const ModalBody: React.FC<IModalBody> = ({ onDisabledButton }) => {
  const abbreviatedI18n = 'products.subGroupList.modal';
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { groupExternalId, setGroupExternalId } = useModalBodyStates();

  const { onSubGroupValidationError } = useModalBodyFunctions({});

  const { listGroups, fetchNewGroupPage, mutateAsync } = useModalBodyCallAPI({
    onSubGroupValidationError,
  });

  const { onChangeInput, onChangeDropdown } = useModalBodyFunctions({
    mutateAsync,
    groupExternalId,
    setGroupExternalId,
  });

  const { items } = useModalBodyMemorizations({
    listGroups,
  });

  useEffect(() => {
    onDisabledButton &&
      onDisabledButton(
        form?.values?.descricao && form?.values?.grupoPaiExternalId
      );
  }, [
    onDisabledButton,
    form?.values?.descricao,
    form?.values?.grupoPaiExternalId,
  ]);

  return (
    <div style={{ padding: '20px' }}>
      <TextInput
        name="descricao"
        label={t(`${abbreviatedI18n}.subGroupLabel`)}
        placeHolder={t(`${abbreviatedI18n}.subGroupPlaceholder`)}
        type="text"
        onChange={onChangeInput}
      />
      <Dropdown
        name="grupoPaiExternalId"
        items={items}
        label={t(`${abbreviatedI18n}.groupLabel`)}
        withoutMarginBottom
        disabled
        onChange={onChangeDropdown}
        onScrollEnd={fetchNewGroupPage}
        selectedItemFromForm="groupLabel"
      />
    </div>
  );
};

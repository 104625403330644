import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { UnitMeasurementAPI } from "@/Data/API/Supply/UnitMeasurementApi";
import { FETCH_LIST_UNITS_BY_CLASS } from "@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys";
import { useFormikContext } from "formik";
import { isNil } from "lodash";

export const useFinanceProductCardCallAPI = () => {
  const form: any = useFormikContext();

  const { data: listunitsMeasurements } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10
    },
    autoIncrement: true,
    enabled: !isNil(form?.values?.classeProdutoId),
  });

  return {
    listunitsMeasurements
  };
};
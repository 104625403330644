import { FC } from 'react';
import {
  Tooltip
} from '@/Components';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { isNumeric } from '@/Utils/IsNumericUtils';
import { IUnitaryDiscount } from './interfaces';
import { useUnitaryDiscountMemorizations } from './Hooks/useUnitaryDiscountMemorizations';
import { useUnitaryDiscountFunctions } from './Hooks/useUnitaryDiscountFunctions';

export const UnitaryDiscount: FC<IUnitaryDiscount> = ({
  index,
  formValues,
  setTotal,
  getTotalValue
}) => {
  const {
    title,
    label,
    dropdownLeft
  } = useUnitaryDiscountMemorizations({
    formValues,
    index,
    setTotal,
    getTotalValue
  });

  const {
    onChange
  } = useUnitaryDiscountFunctions({
    setTotal,
    getTotalValue
  })

  return (
    <>
      <Tooltip
        title={title}
        showMe={
          isNumeric(formValues.discountValue) &&
          isNumeric(formValues.discountType) &&
          isNumeric(formValues.unitValue)
        }
      >
        <DiscountInput
          name={`pedidoCompraItens.${index}.valorDescontoUnitario`}
          label={label}
          dropdownLeft={dropdownLeft}
          withoutMarginBottom
          disabled={!formValues.productExternalId}
          error={formValues.unitValue < formValues.discountValue}
          onChange={onChange}
        />
      </Tooltip>
    </>
  );
};
import { useState } from 'react';

export const useCreateStates = () => {
  const [isDisable, setIsDisable] = useState<boolean>(true);

  const [mandatoryFieldsRequired, setMandatoryFieldsRequired] =
    useState<boolean>(false);
  return {
    isDisable,
    setIsDisable,
    mandatoryFieldsRequired,
    setMandatoryFieldsRequired,
  };
};

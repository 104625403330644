import {
  CREATE_LOT,
  FETCH_LIST_LOTS
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { GET_PREFERENCES_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { history } from '@/Store';
import { IUserPreferencesResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { IErrorState, IUseCallAPI } from './interfaces';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

export const useLotCallAPI = ({
  setErrors
}: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const { data: datePattern } =
  useControllerQueryApiHook<IUserPreferencesResponse>({
    uniqId: GET_PREFERENCES_TYPE,
    entityApi: UserAPI.getPreferences,
  });

  const createLot: any = useControllerMutateApiHook({
    uniqId: CREATE_LOT,
    entityApi: LotsAPI.createLot,
    options: {
      onError: () => {
        setErrors((state: IErrorState) => ({ ...state, creatingLotError: true }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_LOTS]);
        history.push('/inventory/lot');
      }
    }
  });

  return {
    datePattern,
    createLot
  };
};
import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const LossCreateSchema = yup.object().shape({
  produtoExternalId: yup
    .string()
    .required(i18n.t('supply.losses.create.errors.requiredName')),

  localEstoqueExternalId: yup
    .string()
    .required(i18n.t('supply.losses.create.errors.localEstoqueId')),

  loteExternalId: yup
    .string()
    .nullable()
    .required(i18n.t('supply.losses.create.errors.requiredLoteExternalId')),

  dataPerda: yup
    .string()
    .required(i18n.t('supply.losses.create.errors.dataPerda')),

  motivoPerdaExternalId: yup
    .string()
    .required(i18n.t('supply.losses.create.errors.motivoPerdaId')),

  quantidade: yup
    .number()
    .nullable()
    .required(i18n.t('supply.losses.create.errors.quantidade')),
});

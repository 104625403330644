import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { IListUserGroupData } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const useListFunctions = ({
  changeUserGroupStatus,
  deleteUserGroup,
}: IUseFunctions) => {
  const onEdit = useCallback(
    async (userGroup: any[], status: any) => {
      await changeUserGroupStatus.mutateAsync({
        gruposId: userGroup.map((x) => x.externalId),
        ativa: status.status === 'success' ? true : false,
      });
    },
    [changeUserGroupStatus]
  );

  const onDelete = useCallback(
    (userGroups: IListUserGroupData[]) =>
      deleteUserGroup.mutateAsync(userGroups.map((x) => x.externalId)),
    [deleteUserGroup]
  );

  return { onEdit, onDelete };
};

import { FC } from 'react';
import { Button, Form } from '@/Components';
import { ProductCreateSchema } from './ProductCreate.schema';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@/Utils/Http';
import { ProductInfoCard } from '../Components/ProductCard';
import { FinanceSaleCard } from '../Components/FinanceProductCard';
import { CreateProductInitialValues } from './InitialValues';
import { FeedStockInfoCard } from '../Components/FeedstockInfoCard';
import { CapsuleTypeCard } from '../Components/CapsuleTypeCard';
import { PackingCard } from '../Components/PackingCard';
import { CapsuleGranelCard } from '../Components/CapsuleGranelCard';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';

import styles from './ProductCreate.module.scss';
import { InfoForFormCalc } from '../Components/InfoForFormCalc';

export const CreateProduct: FC = () => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const feedStockClassId = 1;
  const capsuleTypeClassId = 7;
  const packagingId = 2;
  const capsuleGranelId = 5;

  const {
    createProduct
  } = useCreateCallAPI();
  
  const {
    onSubmit,
    onClick
  } = useCreateFunctions({ createProduct });

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={CreateProductInitialValues(queryParams)}
        schema={ProductCreateSchema}
      >
        {(props) => (
          <>
            <ConnectedFocusError />
            <ProductInfoCard disabledForm={false} />
            <FinanceSaleCard disabledForm={false} />
            {props.values.classeProdutoId === feedStockClassId && (
              <FeedStockInfoCard feedStockClassId={feedStockClassId} />
            )}
            {props.values.classeProdutoId === feedStockClassId && (
              <InfoForFormCalc />
            )}
            {props.values.classeProdutoId === capsuleTypeClassId && (
              <CapsuleTypeCard />
            )}
            {props.values.classeProdutoId === packagingId && <PackingCard />}
            {props.values.classeProdutoId === capsuleGranelId && (
              <CapsuleGranelCard />
            )}

            <div className={styles['footer-button']}>
              <Button
                type="secondary"
                children={t('products.create.cancel')}
                onClick={onClick}
              />
              <Button
                type="primary"
                htmlType="submit"
                children={
                  createProduct.isLoading
                    ? t('products.create.registerLoading')
                    : t('products.create.register')
                }
                loading={createProduct.isLoading}
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
};

import { useFormikContext } from "formik";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useBaseMolecularWeightFunctions = ({
  setEquivalenceFactor
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const onChange = useCallback((value: any) => {
    if (!value) {
      return setEquivalenceFactor('');
    }
    const useDot = !value.includes(',');
    const formattedValue = +value.replace(',', '.');
    const saltMolecularWeight = form.values.pesoMolecularSal;
    if (!saltMolecularWeight) return;
    const equivalenceFactor = saltMolecularWeight / formattedValue;

    if (!useDot) {
      return setEquivalenceFactor(
        equivalenceFactor.toFixed(3).replace('.', ',')
      );
    }
    setEquivalenceFactor(equivalenceFactor.toFixed(3));
  }, [setEquivalenceFactor]);

  return {
    onChange
  };
};
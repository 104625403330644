import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IFormGroupModal } from './interfaces';
import { useFormSubGroupModalCallAPI } from './Hooks/useFormSubGroupModalCallAPI';
import { useFormSubGroupModalFunctions } from './Hooks/useFormSubGroupModalFunctions';
import { useFormSubGroupMemorizations } from './Hooks/useFormSubGroupMemorizations';
import { ModalBody } from './Components/ModalBody';

export const FormSubGroupModal: React.FC<IFormGroupModal> = ({
  visible,
  changeVisible,
  title,
  selectedRowsOnTable,
  group,
}) => {
  const abbreviatedI18n = 'products.subGroupList.modal';
  const { t } = useTranslation();

  const { createSubGroup, editSubGroup } = useFormSubGroupModalCallAPI();

  const { onOkClickForm, onCancelClick, onClose } =
    useFormSubGroupModalFunctions({
      title,
      selectedRowsOnTable,
      editSubGroup,
      createSubGroup,
      changeVisible,
      visible,
    });

  const { onOkButtonName, initialValues } = useFormSubGroupMemorizations({
    title,
    createSubGroup,
    editSubGroup,
    selectedRowsOnTable,
    group,
  });

  return (
    <Modal
      onOkClickForm={onOkClickForm}
      title={title ? title : t(`${abbreviatedI18n}.title`)}
      body={<ModalBody />}
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={onOkButtonName}
      loading={title ? editSubGroup.isLoading : createSubGroup.isLoading}
      onClose={onClose}
      initialValues={initialValues}
      withForm
      disabledButtonFormNames={['descricao', 'grupoPaiExternalId']}
      dataTestIdOkButton="submit-subgroup"
    />
  );
};

import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { VALIDATION_SPECIALTIES_NAME } from "@/ControllerApiHook/UniqIds/People/SpecialtiesKeys";
import { SpecialtiesAPI } from "@/Data/API/People/SpecialtiesApi";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export const useModalBodyCallAPI = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_SPECIALTIES_NAME,
    entityApi: SpecialtiesAPI.validationSpecialtiesName,
    options: {
      onError: () =>
        form.setErrors({
          ...form.errors,
          descricao: t('specialties.create.validationDescription'),
        }),
      onSuccess: () => form.setErrors({ descricao: undefined })
    }
  });

  return {
    mutateAsync
  };
};
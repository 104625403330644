import { useQueryClient } from '@tanstack/react-query';
import {
  FETCH_LIST_SUPPLIER,
  DELETE_SUPPLIER,
  EDIT_STATUS_SUPPLIER,
} from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: supplierList,
    isLoading: isLoadingList,
    refetch: refetchSupplierList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SUPPLIER,
    entityApi: SupplierAPI.getSupplierList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'nomeFantasia', direction: 'ASC' },
    },
  });

  const deleteSupplier = useControllerMutateApiHook({
    uniqId: DELETE_SUPPLIER,
    entityApi: SupplierAPI.deleteSupplier,
    options: {
      onSuccess: () => queryClient.invalidateQueries([FETCH_LIST_SUPPLIER]),
    },
  });

  const editSupplierStatus = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_SUPPLIER,
    entityApi: SupplierAPI.editSupplierStatus,
    options: {
      onSuccess: () => queryClient.invalidateQueries([FETCH_LIST_SUPPLIER]),
    },
  });

  return {
    deleteSupplier,
    editSupplierStatus,
    refetchSupplierList,
    supplierList,
    isLoadingList,
  };
};

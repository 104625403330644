import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IListNatureOfOperationResponse } from '../../Interfaces/response/Purchase/INatureOfOperationResponse';
export class NatureOfOperationAPI {
  public static async listDropDownNatureOfOperation(
    context: QueryFunctionContext
  ): Promise<IListNatureOfOperationResponse> {
    return await Http.fetcher<IListNatureOfOperationResponse>(
      Http.AddQueryParams(
        '/pharma/compras/v1/NaturezaOperacao/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}

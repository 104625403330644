import * as yup from 'yup';
import i18n from '../../../../../../../Components/i18n';
import { DatePatternType } from '../../../../../../../Utils/DateUtils';
import { DateTime } from 'luxon';

export const DetailSchema = (datePattern: DatePatternType) =>
  yup.object().shape({
    nome: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredName')
      ),
    sobrenome: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredLastName')
      ),

    email: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredEmail')
      ),
    emailAlternativo: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .notOneOf(
        [yup.ref('email')],
        i18n.t(
          'admin.editUserPage.personalDataForm.errors.invalidAlternativeEmail'
        )
      )
      .nullable(),

    grupos: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredGroup')
      ),

    dataNascimento: yup
      .string()
      .test('date validation', i18n.t('common.dateInvalid'), (val) => {
        if (!val || !datePattern) return true;
        return DateTime.fromFormat(val, datePattern).isValid;
      }),
  });

import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { ProductAPI } from "@/Data/API/Supply/Product";

export const useProductNameCallAPI = () => {
  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: { pageIndex: 0, pageSize: 50 }
  });

  return {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  };
};
import { IUseCallAPI } from './interfaces';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook, 
  useControllerQueryListApiHook 
} from '@/ControllerApiHook/Controller';
import { 
  DELETE_ASSOCIATED_PRODUCT, 
  FETCH_ASSOCIATED_PRODUCT, 
  FETCH_GET_PRODUCT 
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useAssociatedProductCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: productExternalId
  });

  const deleteAssociatedProduct: any = useControllerMutateApiHook({
    uniqId: DELETE_ASSOCIATED_PRODUCT,
    entityApi: ProductAPI.deleteAssociatedProduct
  });

  const { 
    data: productList, 
    isLoading: isProductListLoading,
    refetch: refetchProductList, 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_ASSOCIATED_PRODUCT,
    entityApi: ProductAPI.getAssociatedProductList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [{ filterName: 'produtoExternalId', value: productExternalId }],
    }
  });

  return {
    currentProduct,
    deleteAssociatedProduct,
    productList,
    isProductListLoading,
    refetchProductList
  };
};
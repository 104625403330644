import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../Components/Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetPharmaceuticalForm } from '../../../../../../../Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { useEditCallAPI } from '../../Hooks/useEditCallAPI';
import { useEditFunctions } from '../../Hooks/useEditFunctions';
import ReactMarkdown from 'react-markdown';

interface IDeletePharmaceuticalFormModal {
  pharmaceuticalForm: IGetPharmaceuticalForm;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeletePharmaceuticalFormModal: FC<
  IDeletePharmaceuticalFormModal
> = ({ pharmaceuticalForm, visibleModal, changeVisibleModal }) => {
  const { t } = useTranslation();

  const { deletePharmaceuticalForm, isLoadingDeletePharmaceuticalForm } =
    useEditCallAPI({});

  const { onDelete } = useEditFunctions({
    changeVisibleModal,
    deletePharmaceuticalForm,
    pharmaceuticalForm,
  });

  return (
    <Modal
      title={`${t('common.delete')} ${t(
        'production.pharmaceuticalForm.details.oneDeleteTitle'
      )}  "${pharmaceuticalForm?.descricao}"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('production.pharmaceuticalForm.details.deleteInfo', {
                  description: `**${pharmaceuticalForm?.descricao}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onDelete}
      okButtonName={
        isLoadingDeletePharmaceuticalForm
          ? t('common.deleting')
          : t('common.delete')
      }
      loadingOkButton={isLoadingDeletePharmaceuticalForm}
      loading={isLoadingDeletePharmaceuticalForm}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      okButtonType="danger"
      widthModal={400}
    />
  );
};

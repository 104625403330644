import { useFormikContext } from 'formik';

export const useSupplierDilutionFactorFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (value: string) => {
    const supplierDilutionFactor = +value.replace(',', '.');
    if (isNaN(supplierDilutionFactor) || !value)
      return form.setFieldValue('loteInformacaoTecnica.diluicaoFornecedor', '');

    const supplierDilution = 100 / supplierDilutionFactor;

    form.setFieldValue(
      'loteInformacaoTecnica.diluicaoFornecedor',
      supplierDilution
    );
  };

  return { onChange };
};

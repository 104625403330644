import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_PACKING_CLASSIFICATION,
  EDIT_STATUS_PACKING_CLASSIFICATION,
  FETCH_LIST_PACKING_CLASSIFICATION,
} from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: packingClassificationFormList,
    isLoading: isLoadingPackingListFormList,
    refetch: refetchPackingList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PACKING_CLASSIFICATION,
    entityApi: PackingClassificationAPI.getPackingClassificationList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const editPackingClassificationFormStatus = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PACKING_CLASSIFICATION,
    entityApi: PackingClassificationAPI.patchPackingClassificationStatus,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_PACKING_CLASSIFICATION]),
    },
  });

  const deletePackingClassificationForm = useControllerMutateApiHook({
    uniqId: DELETE_PACKING_CLASSIFICATION,
    entityApi: PackingClassificationAPI.useDeletePackingClassification,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_PACKING_CLASSIFICATION]),
    },
  });

  return {
    packingClassificationFormList,
    isLoadingPackingListFormList,
    refetchPackingList,
    editPackingClassificationFormStatus,
    deletePackingClassificationForm,
  };
};

import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '../../../../../../../Components';
import { PageLoading } from '../../../../../../../Layouts/PageLoading';
import { CreateTransferSchema } from '../../EditLosses.schema';
import { DetailInitialValues } from './InitialValues';
import { LossInfo } from '../LossInfo';
import { LossRegistration } from '../LossRegistration';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { FooterButton } from '../FooterButton';

interface IDetailForm {
  disabledForm: boolean;
  onDisableEditButton: () => void;
}

export const DetailForm: FC<IDetailForm> = ({
  disabledForm,
  onDisableEditButton,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { getLossSingle, isLoadingLosse, preferences, editLoss } =
    useEditCallAPI({
      externalId,
    });

  const { onSubmit } = useEditFunctions({ editLoss, externalId });

  return (
    <PageLoading loading={isLoadingLosse}>
      <Form
        schema={CreateTransferSchema}
        onSubmit={onSubmit}
        initialValues={DetailInitialValues({
          lossSingle: getLossSingle,
          datePattern: preferences,
        })}
      >
        {(form) => (
          <>
            <LossInfo getLossSingle={getLossSingle} />
            <LossRegistration
              form={form}
              disableForm={disabledForm}
              getLossSingle={getLossSingle}
            />
            <FooterButton
              form={form}
              disabledForm={disabledForm}
              onDisableEditButton={onDisableEditButton}
              editLoss={editLoss}
            />
          </>
        )}
      </Form>
    </PageLoading>
  );
};

import { 
  LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE 
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { 
  useControllerQueryListApiHook
 } from '@/ControllerApiHook/Controller';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';

export const usePharmaceuticalFormCallAPI = () => {
  const {
    data: listPharmaceuticalForm,
    isLoading: isPharmaceuticalFormLoading,
    fetchNewPage: fetchNewFarmaceuticalFormPage,
    refetch: refetchSearchPharmaceuticalFormPage,
  } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.listPharmaceuticalFormForDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  return {
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage
  };
};
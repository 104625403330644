import { FETCH_LIST_FOR_DROPDOWN_TYPE } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { IUseCallAPI } from "./interfaces";
import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";

export const useAssociateCapsulesCallAPI = ({
  productPackingClassId
}: IUseCallAPI) => {
  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    isLoading: isLoadingProduct,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      filter: [
        {
          filterName: 'classesProdutoIds',
          value: [productPackingClassId],
        },
      ]
    }
  });

  return {
    productList,
    fetchNewProductPage,
    isLoadingProduct,
    refetchSearchProductPage
  };
};
import { TFunction } from 'i18next';
import { Text } from '@/Components';

export const productSynonymColumns = (t: TFunction) => {
  return [
    {
      title: t('products.associated.associatedProduct'),
      dataIndex: 'produtoAssociadoDescricao',
      key: 'produtoAssociadoDescricao',
      width: '60%',
      render: (x: string, row: any) => {
        return (
          <Text type="ui-tiny-bold" color="text-50">
            {row.produtoAssociadoDescricao}
          </Text>
        );
      },
    },
    {
      title: t('products.associated.pharmaceuticalForm'),
      dataIndex: 'formaFarmaceuticaDescricao',
      key: 'formaFarmaceuticaDescricao',
      width: '20%',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-content" color="text-50">
          {row.formaFarmaceuticaDescricao}
        </Text>
      ),
    },
    {
      title: t('products.associated.associatedQuantity'),
      dataIndex: 'quantidadeAssociada',
      key: 'quantidadeAssociada',
      width: '20%',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-content" color="text-50">
          {row.quantidadeAssociada}%
        </Text>
      ),
    },
  ];
};

import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const RawMaterialSchema = yup.object().shape({
  produtoExternalId: yup
    .string()
    .required(i18n.t('supply.lot.create.errors.requiredProduct')),
  numero: yup
    .string()
    .required(i18n.t('supply.lot.create.errors.requiredCodeLot')),
  dataFabricacao: yup
    .string()
    .required(i18n.t('supply.lot.create.errors.requiredManufacturingDate')),
  dataValidade: yup
    .string()
    .required(i18n.t('supply.lot.create.errors.requiredValidityDate')),
  fornecedorExternalId: yup
    .string()
    .required(i18n.t('supply.lot.create.errors.requiredProvider')),
  numeroNf: yup
    .number()
    .required(i18n.t('supply.lot.create.errors.requiredNumberInvoice')),
  serieNf: yup
    .number()
    .required(i18n.t('supply.lot.create.errors.requiredSeriesInvoice')),
  densidade: yup
    .number()
    .required(i18n.t('supply.lot.create.errors.requiredSeriesInvoice')),
  paisOrigemExternalId: yup
    .string()
    .required(i18n.t('supply.lot.create.errors.requiredCountryOfOrigin')),
  diluicaoFornecedor: yup
    .number()
    .required(i18n.t('supply.lot.create.errors.requiredSupplierDilution')),
  fatorDiluicaoFornecedor: yup
    .number()
    .required(
      i18n.t('supply.lot.create.errors.requiredSupplierDilutionFactor')
    ),
  concentracaoAgua: yup
    .number()
    .required(i18n.t('supply.lot.create.errors.requiredWaterConcentration')),
  fatorConcentracaoAgua: yup
    .number()
    .required(
      i18n.t('supply.lot.create.errors.requiredWaterConcentrationFactor')
    ),
});

import { FC, useCallback } from 'react';
import { Modal } from '../../Modal';
import { Skeleton, Text } from '../..';
import { AdderListType, ItemsType } from '..';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { UppercaseFirstLetter } from '../../../Utils/CapitalFirstLetterUtils';
import { GET_DOCUMENT } from '../../../ControllerApiHook/UniqIds/People/DocumentKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IListDocumentData } from '@/Data/Interfaces/response/Document/IDocumentResponse';
import { DocumentAPI } from '@/Data/API/People/DocumentApi';
import { GET_CITY } from '../../../ControllerApiHook/UniqIds/Locality/CityKeys';
import { GET_STATE } from '../../../ControllerApiHook/UniqIds/Locality/StateKeys';
import { GET_COUNTRY } from '../../../ControllerApiHook/UniqIds/Locality/CountryKeys';
import { IGetCityResponse } from '@/Data/Interfaces/response/Locality/ICityResponse';
import { CityAPI } from '@/Data/API/Locality/CityApi';
import { IGetCountryResponse } from '@/Data/Interfaces/response/Locality/ICountryResponse';
import { CountryAPI } from '@/Data/API/Locality/CountryApi';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { StateAPI } from '@/Data/API/Locality/StateApi';

interface IDeleteModal {
  type?: AdderListType;
  onChangeModalOpen: () => void;
  visible: boolean;
  deleteItem: (index: any) => void;
  deleteItemIndex: number;
  dataItems: ItemsType[];
}

export const DeleteModal: FC<IDeleteModal> = ({
  onChangeModalOpen,
  visible,
  type,
  deleteItem,
  deleteItemIndex,
  dataItems,
}) => {
  const { t } = useTranslation();

  const getDescription = useCallback(() => {
    return type === 'contactType'
      ? t('contact.areYouSureDelete')
      : type === 'adrressType'
      ? dataItems[deleteItemIndex] && t('address.areYouSureDelete')
      : t('document.areYouSureDelete');
  }, [deleteItemIndex, dataItems, type, t]);

  return (
    <Modal
      body={
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content" children={getDescription()} />
        </div>
      }
      title={
        type === 'contactType'
          ? `${t('common.delete')} ${t('contact.contact')}?`
          : type === 'adrressType'
          ? `${t('common.delete')} ${t('address.address')}?`
          : `${t('common.delete')} ${t('document.document')}?`
      }
      onCancelClick={onChangeModalOpen}
      visible={visible}
      onOkClick={() => deleteItem(deleteItemIndex)}
      onClose={onChangeModalOpen}
      htmlType="submit"
      okButtonName={t('common.delete')}
      okButtonColor="status-danger-base"
    />
  );
};

interface IAddresDescription {
  dataItems: ItemsType[];
  deleteItemIndex: number;
}

const AddresDescription: FC<IAddresDescription> = ({
  dataItems,
  deleteItemIndex
}) => {
  const { data: city, isLoading: cityLoading } = useControllerQueryApiHook<IGetCityResponse>({
    uniqId: GET_CITY,
    entityApi: CityAPI.getCity,
    externalId: dataItems[deleteItemIndex]?.values?.cidadeExternalId
  });

  const { data: state, isLoading: stateLoading } = useControllerQueryApiHook<IGetStateResponse>({
    uniqId: GET_STATE,
    entityApi: StateAPI.getState,
    externalId: dataItems[deleteItemIndex]?.values?.estadoExternalId
  });

  const { data: country, isLoading: countryLoading } = useControllerQueryApiHook<IGetCountryResponse>({
    uniqId: GET_COUNTRY,
    entityApi: CountryAPI.getCountry,
    externalId: dataItems[deleteItemIndex]?.values?.paisExternalId
  });

  const { t } = useTranslation();

  if (cityLoading || stateLoading || countryLoading)
    return <Skeleton size="md" />;

  return (
    <ReactMarkdown>
      {city && state && country
        ? t('address.areYouSureDeleteWithAddress', {
            description: `**${dataItems[deleteItemIndex].values.descricao}**`,
            address: `**${UppercaseFirstLetter(
              dataItems[deleteItemIndex]?.values.logradouro
            )}, ${UppercaseFirstLetter(
              dataItems[deleteItemIndex]?.values.numero
            )}, ${UppercaseFirstLetter(
              dataItems[deleteItemIndex]?.values.bairro
            )}, ${UppercaseFirstLetter(
              city?.descricao
            )}, ${UppercaseFirstLetter(
              state?.descricao
            )}, ${UppercaseFirstLetter(country?.descricao)}.**`,
          })
        : t('address.areYouSureDelete', {
            description: `**${dataItems[deleteItemIndex].values.descricao}**`,
          })}
    </ReactMarkdown>
  );
};
interface IDocumentDescription {
  dataItems: ItemsType[];
  deleteItemIndex: number;
}

const DocumentDescription: FC<IDocumentDescription> = ({
  dataItems,
  deleteItemIndex,
}) => {
  let documentType;
  if (typeof dataItems[deleteItemIndex]?.values.tipoDocumentoId === 'object')
    documentType = dataItems[deleteItemIndex]?.values.tipoDocumentoId.id;
  else documentType = dataItems[deleteItemIndex]?.values.tipoDocumentoId;

  const { data: document } = useControllerQueryApiHook<IListDocumentData>({
    uniqId: GET_DOCUMENT,
    entityApi: DocumentAPI.getDocument,
    externalId: documentType
  });

  const { t } = useTranslation();

  return (
    <ReactMarkdown>
      {t('document.areYouSureDelete', {
        description: `**${document?.nome}**`,
      })}
    </ReactMarkdown>
  );
};

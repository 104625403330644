import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Form } from '../../../../../Components';
import _ from 'lodash';
import { FooterButtons } from './components/FooterButtons';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { DescriptionRow } from './components/Rows/DescriptionRow';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const LaboratoryDetail: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { isErrorValidadeName, setIsErrorValidadeName } = useEditStates();

  const { editLaboratory, isLoadingEditLaboratory, laboratory, loading } =
    useEditCallAPI({
      externalId,
      t,
    });

  const { onSubmit } = useEditFunctions({
    editLaboratory,
    laboratory,
  });

  return (
    <PageLoading loading={loading}>
      <Form
        initialValues={{
          ...laboratory,
          nome: laboratory?.laboratorio,
          empresa: laboratory?.empresa,
          empresaExternalId: laboratory?.empresaExternalId,
          localEstoque: laboratory?.localEstoqueExternalId,
          localEstoqueLabelInitialValue: {
            id: laboratory?.localEstoqueExternalId,
            label: laboratory?.laboratorio,
          },
        }}
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <DescriptionRow
              disabled={disabled}
              validateError={(value) => setIsErrorValidadeName(value)}
            />
            {!disabled && (
              <FooterButtons
                props={props}
                isErrorValidadeName={isErrorValidadeName}
                isLoadingEditLaboratory={isLoadingEditLaboratory}
                onChangeDisabled={onChangeDisabled}
              />
            )}
          </>
        )}
      </Form>
    </PageLoading>
  );
};

export default LaboratoryDetail;

import { Avatar, Button, Card, InputWithSearch, TextInput } from '@/Components';
import { TextInputPhoneMask } from '@/Components/Inputs/TextInputPhoneMask';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import {
  IGetUserResponse,
  IListUserGroupData,
} from '@/Data/Interfaces/response/User/IGetUserReponse';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';
import { usePersonalDataCardCallAPI } from './Hooks/usePersonalDataCardCallAPI';

import styles from './PersonalDataCard.module.scss';

interface IPersonalDataCard {
  user?: IGetUserResponse;

  editDisabled: boolean;
  editLoading: boolean;
  changeEditDisabled: () => void;
}

export const PersonalDataCard: FC<IPersonalDataCard> = ({
  user,

  editDisabled,
  editLoading,
  changeEditDisabled,
}) => {
  const { group, refetchGroupList, fetchNewPageGroup, isLoadingGroupList } =
    usePersonalDataCardCallAPI();

  const { t } = useTranslation();

  return (
    <Card title={t('admin.createUserPage.personalData')} withoutCollapse>
      <Row justify="space-between">
        <Col span={24}>
          {user && (
            <Avatar
              fisrtName={user.nome}
              lastName={user.sobrenome}
              className={styles['avatar']}
              type="heading2"
              size="XXL"
              color="white"
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <TextInput
            name="nome"
            dataTestId="nome"
            label={t('admin.editUserPage.personalDataForm.name')}
            placeHolder={t(
              'admin.editUserPage.personalDataForm.namePlaceholder'
            )}
            disabled={editDisabled}
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="sobrenome"
            dataTestId="sobrenome"
            label={t('admin.editUserPage.personalDataForm.lastName')}
            placeHolder={t(
              'admin.editUserPage.personalDataForm.lastNamePlaceholder'
            )}
            disabled={editDisabled}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <TextInputWithMask
            name="dataNascimento"
            dataTestId="dataNascimento"
            label={t('admin.editUserPage.personalDataForm.birthDate')}
            placeHolder={t(
              'admin.editUserPage.personalDataForm.birthDatePlaceholder'
            )}
            disabled={editDisabled}
            mask="dataMask"
          />
        </Col>
        <Col span={12}>
          <TextInputPhoneMask
            name="celular"
            dataTestId="celular"
            label={t('admin.editUserPage.personalDataForm.telephone')}
            placeHolder={t(
              'admin.editUserPage.personalDataForm.telephonePlaceholder'
            )}
            disabled={editDisabled}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <TextInput
            name="email"
            dataTestId="email"
            label={t('admin.editUserPage.personalDataForm.email')}
            placeHolder={t(
              'admin.editUserPage.personalDataForm.emailPlaceholder'
            )}
            disabled={editDisabled}
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="emailAlternativo"
            dataTestId="emailAlternativo"
            label={t('admin.editUserPage.personalDataForm.alternativeEmail')}
            placeHolder={t(
              'admin.editUserPage.personalDataForm.alternativeEmailPlaceholder'
            )}
            disabled={editDisabled}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <PermissionWrapper
            permission={1006}
            tooltip="noPermission.administrative.users.editGroup"
          >
            <InputWithSearch
              name="grupos"
              dataTestId="grupos"
              label={t('admin.editUserPage.personalDataForm.group')}
              placeHolder={t(
                'admin.editUserPage.personalDataForm.groupPlaceholder'
              )}
              multipleSelection
              typeTag="secondary"
              items={group?.data?.map((x: IListUserGroupData) => ({
                id: x.externalId,
                label: x.nome,
                disabledItem: user?.grupos.some(
                  (group) => group.grupoExternalId === x.externalId
                ),
              }))}
              onScrollEnd={fetchNewPageGroup}
              disabled={editDisabled}
              isLoading={isLoadingGroupList}
              onSearch={(search) =>
                refetchGroupList({
                  pageIndex: 0,
                  pageSize: 10,
                  search: search,
                  sorter: { column: 'nome', direction: 'DESC' },
                })
              }
            />
          </PermissionWrapper>
        </Col>
      </Row>
      <Row>
        <div className={styles['footer-button']}>
          {editDisabled ? (
            <PermissionWrapper
              permission={1004}
              tooltip="noPermission.administrative.users.edit"
            >
              <Button
                type="secondary"
                children={t('admin.editUserPage.footerButtons.editDetails')}
                dataTestId="edit-user"
                onClick={changeEditDisabled}
              />
            </PermissionWrapper>
          ) : (
            <>
              <Button
                type="secondary"
                children={t('admin.editUserPage.footerButtons.cancel')}
                onClick={() => history.push('/admin/user')}
              />
              <Button
                type="primary"
                children={t('admin.editUserPage.footerButtons.save')}
                htmlType="submit"
                dataTestId="submit"
                loading={editLoading}
              />
            </>
          )}
        </div>
      </Row>
    </Card>
  );
};

import { FC } from 'react';
import { Form } from '../../../../../Components';
import { InfoCard } from './Components/Sections/InfoSection';
import { ProviderCreateSchema } from './ProviderCreate.schema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButton';
import { ContactCard } from './Components/Cards/ContactCard';
import { AddressCard } from './Components/Cards/AddressCard';
import { DocumentCard } from './Components/Cards/DocumentCard';
import { LIST_DOCUMENT } from '@/ControllerApiHook/UniqIds/People/DocumentKeys';
import { DocumentAPI } from '@/Data/API/People/DocumentApi';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';

export const CreateSupplier: FC = () => {
  const { createProvider, isLoadingCreateProvider } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({ createProvider });

  const { data: documentList, refetch } = useControllerQueryListApiHook({
    uniqId: LIST_DOCUMENT,
    entityApi: DocumentAPI.listDocument,
    autoIncrement: true,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'nome', direction: 'ASC' },
      filter: [
        { filterName: 'tipoPessoa', value: 1 },
        { filterName: 'ordem', value: [0, 1, 2] },
      ],
    },
  });

  return (
    <>
      <Form
        initialValues={{ tipoPessoa: 1 }}
        onSubmit={onSubmit}
        schema={ProviderCreateSchema}
      >
        {(props) => (
          <>
            <InfoCard refetch={refetch} entityType={props.values.tipoPessoa} />
            <ContactCard props={props} />
            <AddressCard props={props} />
            <DocumentCard props={props} documentList={documentList} />
            <FooterButtons
              isLoadingCreateProvider={isLoadingCreateProvider}
              props={props}
            />
          </>
        )}
      </Form>
    </>
  );
};

import { IUseFunctions } from './interfaces';
import { ILinkPackingClassificationRequest } from '@/Data/Interfaces/request/PackingClassificationLinkedPharmaceuticalForm/IPackingClassificationLinkedPharmaceuticalForm';
import _ from 'lodash';
import { useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';

export const useEditFunctions = ({
  setPackingClassificationRowAmount,
  linkPackingClassification,
  pharmaceuticalFormExternalId,
  index,
  totalRows,
  setProductRowAmount,
  onDelete,
  form,
  externalId,
  mutateAsync,
}: IUseFunctions) => {
  const removeRow = (index: number) => {
    setPackingClassificationRowAmount((oldState) => {
      return oldState.filter((_, i) => i !== index);
    });
  };

  const onSubmit = async (form: ILinkPackingClassificationRequest) => {
    pharmaceuticalFormExternalId &&
      (await linkPackingClassification({
        embalagemClassificacaoExternalId:
          form.embalagemClassificacaoExternalId.map((x: any) => x.externalId),
        formaFarmaceuticaExternalId: pharmaceuticalFormExternalId,
      }));
  };

  const addRow = () => {
    if (index === totalRows! - 1) {
      setProductRowAmount &&
        setProductRowAmount((oldState) => [...oldState, uuidV4()]);

      const itensContainer = document.querySelector(
        '#dropdown-itens-container'
      );
      setTimeout(() => {
        itensContainer?.scroll(0, itensContainer.clientHeight + 200);
      }, 1);
    }
  };

  const deleteRow = (index: number) => {
    if (!form.values.embalagemClassificacaoExternalId) return;
    form.values.embalagemClassificacaoExternalId =
      form.values.embalagemClassificacaoExternalId.filter(
        (_: any, i: number) => i !== index
      );
    if (totalRows === 1) {
      setProductRowAmount &&
        setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
    onDelete && onDelete(index);
  };

  const fetchInputPharmaceuticalFormDescription = useMemo(
    () =>
      _.debounce(async (embalagemClassificacaoExternalId: string) => {
        try {
          return await mutateAsync!({
            embalagemClassificacaoExternalId,
            formaFarmaceuticaExternalId: externalId,
          });
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync, externalId]
  );

  const disabledSelectedItems = (id: string) => {
    if (!form?.values?.embalagemClassificacaoExternalId) return;
    const externalIdList = form?.values?.embalagemClassificacaoExternalId?.map(
      (e: any) => {
        return e?.externalId;
      }
    );
    if (externalIdList?.includes(id)) return true;
  };

  return {
    removeRow,
    onSubmit,
    deleteRow,
    addRow,
    fetchInputPharmaceuticalFormDescription,
    disabledSelectedItems,
  };
};

import React, { CSSProperties, ReactNode } from 'react';
import { TextType, Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { Icon } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { IIconProps } from '@/Components/Icon';

import styles from './LabelTotal.module.scss';

export interface IInputLabelTotal {
  labelTitle?: string;
  labelValue: number | string | ReactNode;
  customSize?: number;
  type?: TextType;
  style?: CSSProperties;
  withStatusIconInLabel?: boolean;
  withStatusIconInText?: boolean;

  typeLabel?: TextType;
  leftIcon?: {
    icon: IIconProps['icon'];
    size: IIconProps['size'];
    color: IIconProps['color'];
  };

  className?: string;

  withTooltip?: {
    title: string;
    showMe: boolean;
    placement?: 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';
    targetOffset?: number[];
  };
}

export const InputLabel: React.FC<IInputLabelTotal> = ({
  labelTitle,
  labelValue,
  customSize,
  type,
  typeLabel,
  style,
  withStatusIconInLabel,
  withStatusIconInText,
  withTooltip,
  className,
  leftIcon,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <Text
          className={styles['description']}
          type={type ? type : 'ui-tiny-bold'}
          withStatusIconRight={withStatusIconInLabel}
          color="text-300"
        >
          {labelTitle}
        </Text>

        <Tooltip
          title={withTooltip?.title}
          showMe={withTooltip?.showMe}
          placement={withTooltip?.placement}
          targetOffset={withTooltip?.targetOffset}
        >
          <Divflex className={styles['div-children']}>
            {leftIcon?.icon && (
              <Icon
                className={styles['left-icon']}
                icon={leftIcon.icon}
                size={leftIcon.size}
                color={leftIcon.color}
              />
            )}
            <Text
              type={typeLabel ? typeLabel : 'ui-tiny-bold'}
              withStatusIconRight={withStatusIconInText}
              className={className}
              style={{
                fontSize: customSize
                  ? `${customSize}px`
                  : ' var(--font-size-md)',
                ...style,
              }}
            >
              {labelValue}
            </Text>
          </Divflex>
        </Tooltip>
      </div>
    </div>
  );
};

import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';

import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { IUseCallAPI } from './interfaces';
import {
  BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM,
  THERE_IS_BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM,
} from '@/ControllerApiHook/UniqIds/Production/PackingClassificationLinkedPharmaceuticalFormKeys';
import { PackingClassificationLinkedPharmaceuticalFormAPI } from '@/Data/API/Production/PackingClassificationPharmaceuticalForm';
import { messageSucessToast } from '@/Utils/MessageErrorToast';
import { FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { useEffect } from 'react';
import { isNil } from 'lodash';

export const useEditCallAPI = ({
  t,
  changeVisible,
  externalId,
  index,
  form,
}: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: linkPackingClassification,
    isLoading: isLoadingLinkPackingClassification,
  } = useControllerMutateApiHook({
    uniqId: BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM,
    entityApi:
      PackingClassificationLinkedPharmaceuticalFormAPI.linkPackingClassification,
    options: {
      onSuccess: () => {
        messageSucessToast(
          t(
            'production.pharmaceuticalForm.details.packingClassification.newLink'
          )
        );
        changeVisible && changeVisible(false);
        queryClient.invalidateQueries([
          FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM,
        ]);
      },
    },
  });

  const {
    data: packingClassification,
    refetch: RefetchSearchPackingClassification,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.getListPackagingClassification,
    autoIncrement: true,
    enable: !isNil(externalId),
  });

  useEffect(() => {
    externalId &&
      RefetchSearchPackingClassification({
        pageIndex: 0,
        pageSize: 10,
        filter: [{ filterName: 'externalId', value: externalId }],
      });
  }, [externalId, RefetchSearchPackingClassification]);

  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: THERE_IS_BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM,
    entityApi:
      PackingClassificationLinkedPharmaceuticalFormAPI.pharmaceuticalFormBindPackingClassification,
    options: {
      onError: () => {
        form?.setErrors({
          ...form.errors,
          embalagemClassificacaoExternalId:
            form.values.embalagemClassificacaoExternalId.map(
              (_: any, i: any) => ({
                externalId:
                  index === i
                    ? t(
                        'production.pharmaceuticalForm.details.packingClassification.errorPharmaceuticalFormBindPackingClassificationExist'
                      )
                    : '',
              })
            ),
        });
      },
    },
  });

  return {
    linkPackingClassification,
    isLoadingLinkPackingClassification,
    packingClassification,
    mutateAsync,
    RefetchSearchPackingClassification,
  };
};

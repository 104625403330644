import { 
  IPurchaseOrderGetResponse 
} from "@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse";
import { useFormikContext } from "formik";
import { getTotalValuesCard } from '@/Store/Purchasing/Purchasing.selector';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { IUseMemorizations } from "./interfaces";

export const useTotalCardMemorizations = ({
  orderPurchaseData
}: IUseMemorizations) => {
  const form: any = useFormikContext();

  const orderPurchase: IPurchaseOrderGetResponse = {
    ...orderPurchaseData,
    externalId: orderPurchaseData?.externalId ?? '',
    fornecedorExternalId: orderPurchaseData?.fornecedorExternalId ?? '',
    numeroPedido: orderPurchaseData?.numeroPedido ?? 0,
    status: orderPurchaseData?.status ?? 0,
    nomeFornecedor: orderPurchaseData?.nomeFornecedor ?? '',
    razaoSocial: orderPurchaseData?.razaoSocial ?? '',
    cnpj: orderPurchaseData?.cnpj ?? '',
    cpf: orderPurchaseData?.cpf ?? '',
    fornecedorContatoPrincipal: orderPurchaseData?.fornecedorContatoPrincipal ?? '',
    tipoContatoId: orderPurchaseData?.tipoContatoId ?? 0,
    icon: orderPurchaseData?.icon ?? '',
    desconto: orderPurchaseData?.desconto ?? 0,
    previsaoEntrega: orderPurchaseData?.previsaoEntrega ?? '',
    totalizadores: {
      ...orderPurchaseData?.totalizadores,
      totalProdutosBruto: orderPurchaseData?.totalizadores?.totalProdutosBruto ?? 0,
      totalProdutosDescontos: orderPurchaseData?.totalizadores?.totalProdutosDescontos ?? 0,
      totalProdutosLiquido: orderPurchaseData?.totalizadores?.totalProdutosLiquido ?? 0,
      totalPedido: orderPurchaseData?.totalizadores?.totalPedido ?? 0,
    },
    frete: orderPurchaseData?.frete ?? 0,
    valorAdicional: orderPurchaseData?.valorAdicional ?? 0,
    valorDesconto: orderPurchaseData?.valorDesconto ?? 0,
    tipoDescontoId: orderPurchaseData?.tipoDescontoId ?? 0,
    observacao: orderPurchaseData?.observacao ?? '',
    pedidoCompraItens: orderPurchaseData?.pedidoCompraItens ?? [],
    pedidoCompraStatus: orderPurchaseData?.pedidoCompraStatus ?? []
  };

  const totalItems = useSelector(getTotalValuesCard);

  const totalProductValueWithoutDiscount = totalItems
    ?.map((x) => x.totalProducts)
    .filter((x) => isNumber(x))
    .reduce((a, b) => a + b, 0);

  const totalProductValue = totalItems
    ?.map((x) => x.total)
    .filter((x) => isNumber(x))
    .reduce((a, b) => a + b, 0);

  let totalUnitaryDiscountValue = 0;

  for (const itemKey in totalItems) {
    if (totalItems.hasOwnProperty(itemKey)) {
      const item = totalItems[itemKey];

      if (isNumber(item.quantidade) && isNumber(item.precoUnitario)) {
        let unitaryDiscountValue;

        if (item.tipoDescontoProdutoId === 1) {
          const valueDiscount = item.valorDescontoUnitario;
          const discountAmount = (valueDiscount / 100) * item.precoUnitario;
          unitaryDiscountValue = discountAmount * item.quantidade;
        } else {
          unitaryDiscountValue =
            (item.valorDescontoUnitario || 0) * item.quantidade;
        }

        totalUnitaryDiscountValue += unitaryDiscountValue || 0;
      }
    }
  }

  const totalEnding =
    totalProductValueWithoutDiscount - totalUnitaryDiscountValue;

  let totalValueOrderPurchase =
    totalEnding +
    (form?.values?.frete || 0) +
    (form?.values?.valorAdicional || 0);

  const tipoDescontoId = form?.values?.tipoDesconto || 0;
  const valorDesconto = form?.values?.valorDesconto || 0;

  if (tipoDescontoId === 1) {
    const discountPercentage = valorDesconto;
    const discountAmount = (discountPercentage / 100) * totalValueOrderPurchase;
    totalValueOrderPurchase -= discountAmount;
  } else {
    totalValueOrderPurchase -= valorDesconto;
  }

  return {
    orderPurchase,
    totalProductValue,
    totalEnding,
    totalProductValueWithoutDiscount,
    totalUnitaryDiscountValue,
    totalValueOrderPurchase
  };
};
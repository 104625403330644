import { history } from '@/Store';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import {
  EDIT_LABORATORY,
  FETCH_GET_LABORATORY,
  FETCH_LIST_FOR_LABORATORY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { useQueryClient } from '@tanstack/react-query';
import { IGetLaboratory } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';

export const useEditCallAPI = ({ externalId, t }: IUseCallAPI) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: editLaboratory,
    isLoading: isLoadingEditLaboratory,
  }: any = useControllerMutateApiHook({
    uniqId: EDIT_LABORATORY,
    entityApi: LaboratoryAPI.editLaboratory,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_LABORATORY]);
        history.push('/production/labs');
      },
    },
  });

  const { data: laboratory, isLoading: loading } =
    useControllerQueryApiHook<IGetLaboratory>({
      uniqId: FETCH_GET_LABORATORY,
      entityApi: LaboratoryAPI.getCurrentLaboratory,
      externalId: externalId,
    });

  return { editLaboratory, isLoadingEditLaboratory, laboratory, loading };
};

import { useCallback, useState } from 'react';
import { Skeleton, Text } from '../../../../../..';
import { Status } from '../../../Status';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';
import {
  EDIT_STATUS_SUPPLIER,
  FETCH_GET_SUPPLIER,
} from '../../../../../../../ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import ReactMarkdown from 'react-markdown';
import { PermissionEnum } from '../../../../../../../Data/Interfaces/model/permissionEnum';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { IGetSupplierResponse } from '@/Data/Interfaces/response/Purchase/ISupplierResponse';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

export const DynamicEditSupplierTitleBreadcrumb = ({ match }: any) => {
  const { data } = useControllerQueryApiHook<IGetSupplierResponse>({
    uniqId: FETCH_GET_SUPPLIER,
    entityApi: SupplierAPI.getSupplier,
    externalId: match.params.externalId,
  });

  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);
  const queryClient = useQueryClient();

  const editSupplierStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_SUPPLIER,
    entityApi: SupplierAPI.editSupplierStatus,
  });

  const onEditStatus = useCallback(
    async (value: any) => {
      const res = await editSupplierStatus.mutateAsync({
        fornecedoresExternalId: [match.params.externalId],
        ativo: value,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      }
    },
    [editSupplierStatus, match.params.externalId, queryClient]
  );

  if (!data) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('purchasing.provider.details.breadCrumb.title')} | ${data.nome}`}
      <Status
        type={data?.ativo ? 'success' : 'disabled'}
        ative={data?.ativo || false}
        onClick={(value) => {
          value ? onEditStatus(value) : setModalVisible(true);
          setStatus(value);
        }}
        permission={{
          permission: PermissionEnum.Compras_Fornecedores_AlterarStatus,
          tooltip: 'noPermission.purchasing.supplier.statusEdit',
        }}
      />
      <Modal
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('purchasing.provider.details.status.confirmationQuestion', {
                  fornecedor: `**${data?.nome}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
        }
        title={t('purchasing.provider.details.status.confirmationTitle')}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => {
          status !== undefined && onEditStatus(status);
          setModalVisible(false);
        }}
        widthModal={400}
        onClose={() => setModalVisible(false)}
        loading={editSupplierStatus.isLoading}
        okButtonName={
          editSupplierStatus.isLoading
            ? t('common.inactivating')
            : t('common.inactivate')
        }
      />
    </div>
  );
};

export const DynamicEditSupplierBreadcrumb = ({ match }: any) => {
  const { data } = useControllerQueryApiHook<IGetSupplierResponse>({
    uniqId: FETCH_GET_SUPPLIER,
    entityApi: SupplierAPI.getSupplier,
    externalId: match.params.externalId,
  });
  return <span style={{ display: 'flex' }}>{data?.nome}</span>;
};

import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Inventory/BalanceAdjustment';
import { CONVERT_UNIT_OF_MEASURE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { isNil } from 'lodash';
import { IUseCreateCallAPI } from './interfaces';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { useEffect } from 'react';

export const useCreateCallAPI = ({
  typeClassProduct,
  form,
}: IUseCreateCallAPI) => {
  const { mutateAsync: convertUnitOfMeasureAsync }: any =
    useControllerMutateApiHook({
      uniqId: CONVERT_UNIT_OF_MEASURE,
      entityApi: UnitMeasurementAPI.convertUnitOfMeasure,
    });

  const {
    data: listunitsMeasurements,
    isLoading: isUnitOfMeasureLoading,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    autoIncrement: true,
    enabled: !isNil(typeClassProduct),
  });

  useEffect(() => {
    typeClassProduct &&
      refetch({
        pageIndex: 0,
        pageSize: 10,
        filter: [{ filterName: 'tipoClasseProduto', value: typeClassProduct }],
      });
  }, [refetch, typeClassProduct]);

  const { data: unitsMeasurementsDefault } =
    useControllerQueryApiHook<IProductResponse>({
      uniqId: FETCH_GET_PRODUCT,
      entityApi: ProductAPI.getProduct,
      externalId: form.values.produtoExternalId,
    });

  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  return {
    convertUnitOfMeasureAsync,
    listunitsMeasurements,
    isUnitOfMeasureLoading,
    unitMeasureIdDefault,
  };
};

import { FC } from "react";
import { TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ClientType } from '../../../Utils';
import { Col } from 'antd';
import { IIdentityInfo } from "./interfaces";

export const IdentityInfo: FC<IIdentityInfo> = ({ 
  createClientModalI18n,
  clientType,
  validateCpf
 }) => {
  const { t } = useTranslation();
  const form = useFormikContext();

  return (
    <Col flex="40%">
      {clientType !== ClientType.cnpj ? (
        <TextInputWithMask
          name="cpf"
          label={'CPF'}
          mask="cpfMask"
          placeHolder={`${t('common.exampleAbbreviation')}: 914.708.028-05`}
          onChange={(x) => validateCpf(x)}
        />
      ) : (
        <TextInput
          name="razaoSocial"
          label={t(`${createClientModalI18n}.socialReason`)}
          placeHolder={`${t(
            'common.exampleAbbreviation'
          )}: FARMACIA SOUZA E FILHOS LTDA`}
        />
      )}
    </Col>
  );
};
import { i18n } from '@/Components';
import {
  EDIT_STANDARD_FORMULA,
  GET_STANDARD_FORMULA
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { 
  IGetStandardFormula 
} from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';
import {
  messageErrorToast, 
  messageSucessToast 
} from '@/Utils/MessageErrorToast';
import { IUseCallAPI } from './interfaces';

export const useDetailCallAPI = ({
  externalId
}: IUseCallAPI) =>  {
  const { data: standardFormula } = useControllerQueryApiHook<IGetStandardFormula>({
    uniqId: GET_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.getStandardFormula,
    externalId: externalId
  });

  const editStandardFormula: any = useControllerMutateApiHook({
    uniqId: EDIT_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.editStandardFormula,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('production.standardFormula.details.successMessageToasts.edit')
        )
    }
  });

  return {
    standardFormula,
    editStandardFormula
  };
};
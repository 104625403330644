import { FC } from 'react';
import { IGetSaleOrderResponse } from '../../../../../../../../Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { TopButtons } from './TopButtons';
import { TableItem } from './TableItem';
import { WithoutProducts } from './WithoutProducts';

import styles from './BodyOrder.module.scss';

interface IBodyOrderContent {
  saleOrder: IGetSaleOrderResponse;
}

export const BodyOrderContent: FC<IBodyOrderContent> = ({ saleOrder }) => {
  return (
    <div className={styles['container']}>
      <div>
        <TopButtons
          saleOrderExternalId={saleOrder?.externalId}
          customerDiscount={saleOrder.cliente.descontoProdutosAcabados}
          clientExternalId={saleOrder.clienteExternalId}
        />

        {saleOrder?.pedidoVendaItens.length > 0 ? (
          <TableItem items={saleOrder.pedidoVendaItens} saleOrder={saleOrder} />
        ) : (
          <WithoutProducts />
        )}
      </div>
    </div>
  );
};

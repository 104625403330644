import { FC } from "react";
import { NumberInput, Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ISupplierDilutionFactor } from "./interfaces";
import { 
  useSupplierDilutionFactorFunctions 
} from "./Hooks/useSupplierDilutionFactorFunctions";

export const SupplierDilutionFactor: FC<ISupplierDilutionFactor> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    onChange
  } = useSupplierDilutionFactorFunctions ();

  return (
    <Tooltip
      title={`${t(
        'products.create.originalSupplierDilutionFactorTooltip'
      )} ${
        product && product.materiaPrima
          ? product.materiaPrima.fatorDiluicaoFornecedor
          : ''
      }`}
      showMe={
        product &&
        !disabledForm &&
        product.materiaPrima.fatorDiluicaoFornecedor !==
          form?.values?.informacaoTecnica?.fatorDiluicaoFornecedor
      }
    >
      <NumberInput
        label={t('products.create.supplierDilutionFactor')}
        name="informacaoTecnica.fatorDiluicaoFornecedor"
        placeHolder="1"
        maxLength={6}
        withTooltip={{
          icon: 'question-mark',
          title: t('products.create.supplierDilutionFactorTooltip'),
        }}
        acceptOnlyNumbers
        disabled={disabledForm}
        withStatusIcon={!!product}
        withDecimalSeparator
        onChange={onChange}
      />
    </Tooltip>
  );
};
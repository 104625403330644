import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import {
  InputWithSearch,
  TextInput,
} from '../../../../../../../../../Components';
import { TextInputWithMask } from '../../../../../../../../../Components/Inputs/TextInputWithMask';
import { useTranslation } from 'react-i18next';
import {
  IGetDocumentProviderResponse,
  IListProviderData,
} from '../../../../../../../../../Data/Interfaces/response/Provider/IProviderResponse';
import { listProviderConfig } from '../../../../utils/functions';
import { useFormikContext } from 'formik';
import { GET_DOCUMENT_PROVIDER } from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { FETCH_GET_OPEN_ORDER_PURCHASE } from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import AddPurchaseOrderModal from '../../modal/AddPurchaseOrderModal';
import { useQueryClient } from '@tanstack/react-query';
import { IPurchaseOrderOpenGetResponseData } from '../../../../../../../../../Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { PurchaseOrdersModal } from '../../../../components/PurchaseOrdersModal';
import { IPagination } from '../../../../../../../../../Components/Table';
import { RemovePurchaseOrdersModal } from '@/Pages/Sectors/Order/Invoice/Edit/components/RemovePurchaseOrdersModal';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

interface IThirdRow {
  listProvider: IListProviderData[];
  isLoading: boolean;
  onScrollEnd: () => void;
  onSearch: (search?: string) => void;
  setPurchaseOrderSelected: React.Dispatch<
    React.SetStateAction<IPurchaseOrderOpenGetResponseData[]>
  >;
  modalTablePurchaseOrderVisible: boolean;
  setModalTablePurchaseOrder: React.Dispatch<React.SetStateAction<boolean>>;
  purchaseOrderSelected: IPurchaseOrderOpenGetResponseData[];
}

const ThirdRow: React.FC<IThirdRow> = ({
  listProvider,
  onScrollEnd,
  isLoading,
  onSearch,
  setPurchaseOrderSelected,
  modalTablePurchaseOrderVisible,
  setModalTablePurchaseOrder,
  purchaseOrderSelected,
}) => {
  const { t } = useTranslation();
  const [externalIdProvider, setExternalIdProvider] = useState<string>('');
  const [lastProviderId, setLastProviderId] = useState<string | null>(null);
  const [modalFoundVisible, setModalFoundVisible] = useState<boolean>(false);
  const [customFilter, setCustomFilter] = useState<any>([
    {
      filterName: 'fornecedorExternalId',
      value: externalIdProvider,
    },
  ]);
  const [
    removePurchaseOrdersModalVisible,
    setRemovePurchaseOrdersModalVisible,
  ] = useState<boolean>(false);
  const [providerDescription, setProviderDescription] = useState<string>('');
  const queryClient = useQueryClient();
  const form: any = useFormikContext();

  const { data: documentProvider, isLoading: isLoadingDocumentProvider } =
    useControllerQueryApiHook<IGetDocumentProviderResponse>({
      uniqId: GET_DOCUMENT_PROVIDER,
      entityApi: SupplierAPI.getDocumentProvider,
      externalId: externalIdProvider,
    });

  const {
    data: purchaseOrderList,
    isLoading: purchaseOrderListLoading,
    refetch: refetchPurchaseOrderList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_OPEN_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOpenPurchaseOrderList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'sequenciaGroupTenant', direction: 'DESC' },
    },
    customFilter: customFilter,
  });

  const invalidatePurchaseOrderListQuery = () => {
    queryClient.invalidateQueries([FETCH_GET_OPEN_ORDER_PURCHASE]);
  };
  const setPurchaseOrderList = () => {
    if (purchaseOrderList?.data.length === 1) {
      setPurchaseOrderSelected(purchaseOrderList.data);
    } else {
      setModalTablePurchaseOrder(true);
    }
  };

  useEffect(() => {
    if (form && documentProvider) {
      setCustomFilter([
        { filterName: 'fornecedorExternalId', value: externalIdProvider },
      ]);
      let newListProvider: any = listProvider;
      newListProvider = newListProvider.data
        ? newListProvider.data
        : newListProvider;

      const currentProviderDescription = newListProvider.filter(
        (e: any) => e.externalId === externalIdProvider
      )[0].nome;

      console.log('currentProviderDescription', currentProviderDescription);
      setProviderDescription(currentProviderDescription);
      form.setFieldValue('razaoSocial', documentProvider.razaoSocial);
      form.setFieldValue('cnpj', documentProvider.cnpj);
    }
  }, [externalIdProvider, documentProvider]);

  useEffect(() => {
    if (
      purchaseOrderList.data &&
      purchaseOrderList?.data.length &&
      externalIdProvider !== lastProviderId
    ) {
      setLastProviderId(externalIdProvider);
      setModalFoundVisible(true);
    }
  }, [purchaseOrderList]);

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <InputWithSearch
            name="fornecedorExternalId"
            withoutMarginBottom
            placeHolder={t('purchasing.invoice.create.placeHolder.provider')}
            required
            label={t('purchasing.invoice.create.provider')}
            items={listProviderConfig(listProvider)}
            onScrollEnd={onScrollEnd}
            isLoading={isLoading}
            onSearch={onSearch}
            onChange={(value) => {
              const values = form.values;
              if (values.pedidosExternalId && values.pedidosExternalId.length) {
                setRemovePurchaseOrdersModalVisible(true);
              } else {
                invalidatePurchaseOrderListQuery();
                setExternalIdProvider(value);
              }
            }}
          />
        </Col>
        <Col span={9}>
          <TextInput
            name="razaoSocial"
            label={t('purchasing.invoice.create.socialReason')}
            disabled
            isLoading={isLoadingDocumentProvider}
          />
        </Col>
        <Col span={7}>
          <TextInputWithMask
            name="cnpj"
            mask="cnpjMask"
            label={t('purchasing.invoice.create.cnpj')}
            withoutMarginBottom={false}
            disabled
            isLoading={isLoadingDocumentProvider}
          />
        </Col>
      </Row>
      <AddPurchaseOrderModal
        purchaseOrderList={purchaseOrderList?.data ?? []}
        visible={modalFoundVisible}
        changeVisibleModal={setModalFoundVisible}
        setPurchaseOrderList={setPurchaseOrderList}
        totalPurchaseOrder={purchaseOrderList?.count ?? 0}
      />
      <PurchaseOrdersModal
        visible={modalTablePurchaseOrderVisible}
        purchaseOrderList={purchaseOrderList}
        changeVisibleModal={setModalTablePurchaseOrder}
        setPurchaseOrderList={setPurchaseOrderSelected}
        isLoading={purchaseOrderListLoading}
        refetchPurchaseOrderList={refetchPurchaseOrderList}
        purchaseOrderSelected={purchaseOrderSelected}
        externalIdProvider={externalIdProvider}
      />
      <RemovePurchaseOrdersModal
        visible={removePurchaseOrdersModalVisible}
        changeVisibleModal={(state: boolean) =>
          setRemovePurchaseOrdersModalVisible(state)
        }
        providerDescription={
          providerDescription
            ? providerDescription
            : form.initialValues.fornecedorDescricao
        }
        onOkClick={() => {
          form.setFieldValue('pedidosExternalId', []);
          setExternalIdProvider(form.values.fornecedorExternalId);
          invalidatePurchaseOrderListQuery();
          setPurchaseOrderSelected([]);
        }}
        onCancelClick={() => {
          form.values.fornecedorExternalId = externalIdProvider
            ? externalIdProvider
            : form.initialValues.fornecedorExternalId;
          setRemovePurchaseOrdersModalVisible(false);
        }}
      />
    </>
  );
};

export default ThirdRow;

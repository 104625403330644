import { Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
import PurchaseOrderInput from '../../../../../Create/components/PurchaseOrderInput';
import { useFormikContext } from 'formik';
import { Tooltip } from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';

interface ISixthRow {
  purchaseOrders?: any[];
  initialValues: any;
  setPurchaseOrderSelected: any;
  setModalTablePurchaseOrder: any;
}

const SixthRow: React.FC<ISixthRow> = ({
  purchaseOrders,
  initialValues,
  setPurchaseOrderSelected,
  setModalTablePurchaseOrder,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const [initialPurchaseOrderCodes, setInitialPurchaseOrderCodes] =
    React.useState<string>('');

  const checkInitialValues = useCallback(
    (key: string) => {
      if (form.values) {
        return form.values[key] !== initialValues[key];
      }
      return false;
    },
    [form.values]
  );

  useEffect(() => {
    if (initialValues.pedidosVinculados) {
      const initialPurchaseOrderCodes = initialValues.pedidosVinculados.map(
        (purchaseOrder: any) => '#' + purchaseOrder.sequenciaGroupTenant
      );

      setInitialPurchaseOrderCodes(initialPurchaseOrderCodes.join(', '));
    }
  }, [initialValues]);

  return (
    <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
      <Tooltip
        showMe={checkInitialValues('pedidosExternalId')}
        title={`${t(
          'purchasing.invoice.edit.originalPurchaseOrder'
        )}${initialPurchaseOrderCodes}`}
      >
        <PurchaseOrderInput
          purchaseOrderList={purchaseOrders ?? []}
          setPurchaseOrderSelected={setPurchaseOrderSelected}
          setModalTablePurchaseOrder={setModalTablePurchaseOrder}
          showEdit={checkInitialValues('pedidosExternalId')}
        />
      </Tooltip>
    </Row>
  );
};

export default SixthRow;

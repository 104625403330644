import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useAssociatedProductFunctions = ({
  refetchSearchProductPage,
  setAssociatedProductExternalId
}: IUseFunctions) => {
  const onSearch = useCallback((search?: string) => {
    refetchSearchProductPage({
      pageIndex: 0,
      pageSize: 50,
      search: search,
    });
  }, [refetchSearchProductPage]);

  const onChange = useCallback((value: string) => {
    setAssociatedProductExternalId(value);
  }, [setAssociatedProductExternalId]);

  return {
    onSearch,
    onChange
  };
};
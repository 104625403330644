import { useQuery, useMutation } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { UnitMeasurementAPI } from '../../Data/API/Supply/UnitMeasurementApi';
import { IQueryListParams } from '../../Utils/Http';
import { useCallback, useEffect, useState } from 'react';
import { getLastPageNumber } from '../../Utils/PaginationUtils';
import { uniqBy } from 'lodash';
import {
  IIListUnitMeasurementAllData,
  IListUnitMeasurementByClassData,
} from '../../Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Inventory/BalanceAdjustment';

export const FETCH_LIST_ALTERNATIVE_UNITS =
  '[UNIT_MEASUREMENT] FETCH_LIST_ALTERNATIVE_UNITS';

export const GET_UNITS_MEASURE_CONVERSION =
  '[UNIT_MEASUREMENT] CREATE_UNITS_MEASURE_CONVERSION';

export const FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE';

export const FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE';

export const FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY';

export const useUnitMeasurementByClass = (pagination: IQueryListParams) =>
  useQuery(
    [FETCH_LIST_UNITS_BY_CLASS, pagination],
    UnitMeasurementAPI.listUnitMeasurementByClassAny,
    { staleTime: 1000 * 60 * 60 * 24 * 3 } //3 dias
  );

export const useUnitMeasurementByClassGetDropdown = (
  pagination: IPagination,
  tipoClasseProduto?: number
) =>
  useQuery(
    [FETCH_LIST_UNITS_BY_CLASS, pagination, tipoClasseProduto],
    UnitMeasurementAPI.listUnitMeasurementByClass,
    { staleTime: 1000 * 60 * 60 } //1 hora
  );

export const useUnitMeasurementByClassIdGetDropdown = (
  initialPagination?: IQueryListParams,
  tipoClasseProduto?: number
) => {
  const [data, setData] = useState<IListUnitMeasurementByClassData[]>([]);
  const [pagination, setPagination] = useState(
    initialPagination || { pageIndex: 0, pageSize: 10 }
  );

  const query = useQuery(
    [FETCH_LIST_UNITS_BY_CLASS, pagination, tipoClasseProduto],
    UnitMeasurementAPI.listUnitMeasurementByClass
  );

  const refetch = useCallback((newPagination: IPagination) => {
    setPagination(newPagination);
  }, []);

  const fetchNewPage = useCallback(() => {
    if (query.data) {
      const lastPage = getLastPageNumber(
        query.data?.count,
        query.data?.pageSize
      );
      if (pagination.pageIndex >= lastPage) return;
    }

    setPagination((prevState) => ({
      ...prevState,
      pageIndex: prevState.pageIndex + 1,
    }));
  }, [query.data, pagination]);

  useEffect(() => {
    const currentDataList = query?.data?.data;
    if (!currentDataList) return;
    setData((prevState) => uniqBy([...prevState, ...currentDataList], 'id'));
  }, [query.data, pagination]);

  return { ...query, data, refetch, fetchNewPage };
};

export const useAlternativeUnitMeasurementDropdown = (
  pagination: IPagination
) =>
  useQuery(
    [FETCH_LIST_ALTERNATIVE_UNITS, pagination],
    UnitMeasurementAPI.listAlternativeUnitMeasurement
  );

export const useGetConversionUnitMeasure = () =>
  useMutation(
    [GET_UNITS_MEASURE_CONVERSION],
    UnitMeasurementAPI.postConversionUnitMeasure
  );

export const useUnitMeasurementGetDropdown = (
  initialPagination?: IQueryListParams
) => {
  const [data, setData] = useState<IIListUnitMeasurementAllData[]>([]);
  const [pagination, setPagination] = useState(
    initialPagination || { pageIndex: 0, pageSize: 10 }
  );

  const query = useQuery(
    [FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE, pagination],
    UnitMeasurementAPI.getListUnitMeasurementDropDown
  );

  const refetch = useCallback((newPagination: IPagination) => {
    setPagination(newPagination);
  }, []);

  const fetchNewPage = useCallback(() => {
    if (query.data) {
      const lastPage = getLastPageNumber(
        query.data.count,
        query.data?.pageSize
      );
      if (pagination.pageIndex >= lastPage) return;
    }

    setPagination((prevState) => ({
      ...prevState,
      pageIndex: prevState.pageIndex + 1,
    }));
  }, [query.data, pagination]);

  useEffect(() => {
    const currentDataList = query?.data?.data;
    if (!currentDataList) return;

    setData((prevState) => uniqBy([...prevState, ...currentDataList], 'id'));
  }, [query.data, pagination]);

  return { ...query, data, refetch, fetchNewPage };
};

export const useUnitMeasurementPrescriptionGetDropdown = (
  pagination?: IQueryListParams
) =>
  useQuery(
    [FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE, pagination],
    UnitMeasurementAPI.getListUnitMeasurementPrescriptionDropDown,
    { staleTime: 1000 * 60 * 60 } //1 hora
  );

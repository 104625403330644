import { FC } from 'react';
import { useFormikContext } from 'formik';
import { IUnitaryDiscount } from './interfaces';
import { Tooltip } from '@/Components';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { useUnitaryDiscountMemorizations } from './Hooks/useUnitaryDiscountMemorizations';

export const UnitaryDiscount: FC<IUnitaryDiscount> = ({
  index
}) => {
  const form = useFormikContext<any>();

  const {
    title,
    label,
    dropdownLeft
  } = useUnitaryDiscountMemorizations({
    index
  });

  return (
    <Tooltip
      title={title}
      showMe={
        form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario &&
        form?.values?.pedidoCompraItens?.[index]?.tipoDescontoProdutoId &&
        form?.values?.pedidoCompraItens?.[index]?.precoUnitario
      }
    >
      <DiscountInput
        name={`pedidoCompraItens.${index}.valorDescontoUnitario`}
        label={label}
        dropdownLeft={dropdownLeft}
        withoutMarginBottom
        required
        disabled={
          !form?.values?.pedidoCompraItens?.[index]?.produtoExternalId
        }
        error={
          form?.values?.pedidoCompraItens?.[index]?.precoUnitario <
          form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario
        }
      />
    </Tooltip>
  );
};
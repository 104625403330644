import { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../../../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import {
  CREATE_FINISHED_PRODUCT,
  EDIT_FINISHED_PRODUCT,
  FETCH_GET_SALES_ORDER
} from '../../../../../../../../../../../ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { FETCH_LIST_FINISHED_PRODUCT } from '../../../../../../../../../../../ControllerApiHook/UniqIds/Supply/ProductKeys';
import DateUtils from '../../../../../../../../../../../Utils/DateUtils';
import { FormikHelpers, FormikValues } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import { IFinishedProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { AddProductModalBody } from './AddProductModalBody';
import StorageUtils from '@/Utils/StorageUtils';
import { 
  useControllerMutateApiHook,
  useControllerQueryListApiHook
} from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';
import { ProductAPI } from '@/Data/API/Supply/Product';

interface IAddProductModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  initialValues?: any;
  editModal?: boolean;
}

export const AddProductModal: FC<IAddProductModal> = ({
  changeVisibleModal,
  visibleModal,
  initialValues,
  editModal,
}) => {
  const [insufficientFundsModalVisible, setInsufficientFundsModalVisible] =
    useState(false);

  const [productSelected, setProductSelected] = useState<
    IFinishedProductListData | undefined
  >(undefined);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const preferences = StorageUtils.getPreference();

  const productList: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FINISHED_PRODUCT,
    entityApi: ProductAPI.finishedProductList,
    initialPagination: {
      sorter: { column: 'descricao', direction: 'DESC' },
      pageIndex: 0,
      pageSize: 50
    }
  });

  const addProduct = useControllerMutateApiHook({
    uniqId: CREATE_FINISHED_PRODUCT,
    entityApi: SalesOrderAPI.addFinishedProduct,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        setProductSelected(undefined);
        messageSucessToast(
          i18n.t('saleOrder.editOrder.SaleOrder.addProductModal.success')
        );
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const editProduct = useControllerMutateApiHook({
    uniqId: EDIT_FINISHED_PRODUCT,
    entityApi: SalesOrderAPI.editFinishedProduct,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        setProductSelected(undefined);
        messageSucessToast(
          i18n.t('saleOrder.editOrder.SaleOrder.editProductModal.success')
        );
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  useEffect(() => {
    editModal &&
      setProductSelected(
        productList.data.find(
          (value: IFinishedProductListData) =>
            value.externalId === initialValues.produtoExternalId
        )
      );
  }, [initialValues.produtoExternalId, productList.data, editModal]);

  return (
    <>
      <Modal
        title={
          editModal
            ? 'Editar Item'
            : t('saleOrder.editOrder.SaleOrder.addProductModal.title')
        }
        body={
          <AddProductModalBody
            productList={productList}
            addProduct={addProduct}
            editProduct={editProduct}
            datePattern={preferences}
            insufficientFundsModalVisible={insufficientFundsModalVisible}
            setInsufficientFundsModalVisible={(x) =>
              setInsufficientFundsModalVisible(x)
            }
            productSelected={productSelected}
            setProductSelected={(value) => setProductSelected(value)}
            editModal={!!editModal}
          />
        }
        loading={addProduct.isLoading}
        visible={visibleModal}
        okButtonName={
          editModal
            ? 'Salvar'
            : t('saleOrder.editOrder.SaleOrder.addProductModal.add')
        }
        dataTestIdOkButton="add-product"
        onCancelClick={() => {
          changeVisibleModal(false);
          setProductSelected(undefined);
        }}
        onClose={() => {
          changeVisibleModal(false);
          setProductSelected(undefined);
        }}
        widthModal={600}
        withForm
        disabledButtonFormNames={[
          'produtoExternalId',
          'valorUnitario',
          'quantidade',
        ]}
        htmlType="submit"
        initialValues={initialValues}
        onOkClickForm={async (x, formik: FormikHelpers<FormikValues>) => {
          try {
            const balanceInStock = productList.data.find(
              (value: IFinishedProductListData) => value.externalId === x.produtoExternalId
            )?.saldoEstoque;

            if (balanceInStock !== undefined && balanceInStock < x.quantidade)
              setInsufficientFundsModalVisible(true);
            else if (editModal)
              await editProduct.mutateAsync({
                ...x,
                ...(x.dataPrescricao && {
                  dataPrescricao: DateUtils.convertDateToApi(
                    x.dataPrescricao,
                    preferences?.padraoData
                  ),
                }),
              });
            else
              await addProduct.mutateAsync({
                ...x,
                ...(x.dataPrescricao && {
                  dataPrescricao: DateUtils.convertDateToApi(
                    x.dataPrescricao,
                    preferences?.padraoData
                  ),
                }),
              });
          } catch {
            formik.setSubmitting(false);
          }
        }}
      />
    </>
  );
};

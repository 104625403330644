import { FC } from "react";
import { Col } from 'antd';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { useTranslation } from "react-i18next";
import { IQuantity } from "./interfaces";
import { useQuantityCallAPI } from "./Hooks/useQuantityCallAPI";
import { useQuantityMemorizations } from "./Hooks/useQuantityMemorizations";

export const Quantity: FC<IQuantity> = ({
  index,
  disabled,
  pharmaceuticalFormExternalId,
  addRow
}) => {
  const { t } = useTranslation();

  const {
    unitsMeasurementsDefault,
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading
  } = useQuantityCallAPI({
    pharmaceuticalFormExternalId
  })

  const {
    dropdownRight
  } = useQuantityMemorizations({
    unitsMeasurementsDefault,
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
    index
  });

  return (
    <Col span={3}>
      <WeightInput
        name={`produtos.${index}.quantidade`}
        label={
          index === 0
            ? t('production.standardFormula.details.productLine.quantity')
            : ''
        }
        dropdownRight={dropdownRight}
        required={!disabled}
        placeHolder={t(
          'production.standardFormula.details.productLine.quantityPlaceholder'
        )}
        disabled={disabled}
        onChange={addRow}
      />
    </Col>
  );
};
import { useMemo } from 'react';
import { IUseFunctions } from './interface';
import _ from 'lodash';

export const useEditFunctions = ({ mutateAsync }: IUseFunctions) => {
  const fetchPackingClassificationName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await mutateAsync(value);
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync]
  );

  return {
    fetchPackingClassificationName,
  };
};

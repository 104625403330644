import {
  FETCH_GET_PRODUCT,
  FETCH_LIST_FOR_DROPDOWN_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useFormikContext } from 'formik';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  IProductResponse,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';

export const useProductCallAPI = ({
  index
}: IUseCallAPI) => {
  const form = useFormikContext<any>();

  const result = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: form?.values?.pedidoCompraItens?.[index]?.produtoExternalId,
  });

  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return {
    result,
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  };
};
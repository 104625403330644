import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_PRODUCT_MESSAGE,
  FETCH_LIST_PRODUCT_MESSAGE,
} from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';
import { useQueryClient } from '@tanstack/react-query';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: productMessageList,
    isLoading: isLoadingProductMessageList,
    refetch: refetchProductMessageList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.productMessageList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const { mutateAsync: deleteProductMessage } = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.deleteProductMessage,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT_MESSAGE]),
    },
  });

  return {
    productMessageList,
    isLoadingProductMessageList,
    deleteProductMessage,
    refetchProductMessageList,
  };
};

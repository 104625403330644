import { useState } from 'react';

export const useCreateStates = () => {
  const [onProdutoExternalIdSelected, setOnProdutoExternalIdSelected] =
    useState<string>();

  const [onClassProductIdSelected, setOnClassProductIdSelected] =
    useState<number>();

  const [
    externalIdStorageLocationSelected,
    setExternalIdStorageLocationSelected,
  ] = useState<string | undefined>(undefined);

  return {
    externalIdStorageLocationSelected,
    setExternalIdStorageLocationSelected,
    onProdutoExternalIdSelected,
    setOnProdutoExternalIdSelected,
    onClassProductIdSelected,
    setOnClassProductIdSelected,
  };
};

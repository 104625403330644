import React from 'react';
import { Modal } from '../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { IPurchaseOrderOpenGetResponseData } from '../../../../../../Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { currencyFormater } from '../../../../../../Utils/Currency';

interface IAddPurchaseOrderModalProps {
  visible?: boolean;
  purchaseOrderList: IPurchaseOrderOpenGetResponseData[];
  changeVisibleModal: (value: boolean) => void;
  setPurchaseOrderList: () => void;
  totalPurchaseOrder?: number;
}

const AddPurchaseOrderModal: React.FC<IAddPurchaseOrderModalProps> = ({
  visible,
  purchaseOrderList,
  changeVisibleModal,
  setPurchaseOrderList,
  totalPurchaseOrder = 0,
}) => {
  const { t } = useTranslation();

  const title =
    purchaseOrderList.length === 1
      ? 'purchasing.invoice.modal.foundPurchaseOrderModal.titleSingle'
      : 'purchasing.invoice.modal.foundPurchaseOrderModal.titleMany';

  const body =
    purchaseOrderList.length === 1
      ? 'purchasing.invoice.modal.foundPurchaseOrderModal.descriptionSingle'
      : 'purchasing.invoice.modal.foundPurchaseOrderModal.descriptionMany';

  const bodyVariables =
    purchaseOrderList.length === 1
      ? {
          numero: `**#${purchaseOrderList[0].sequenciaGroupTenant}**`,
          nomeFornecedor: `**${purchaseOrderList[0].nomeFornecedor}**`,
          totalPedido: `**${currencyFormater(
            purchaseOrderList[0].totalPedido
          )}**`,
        }
      : {
          numeroPedidos: `**${totalPurchaseOrder}**`,
          nomeFornecedor: `**${purchaseOrderList[0]?.nomeFornecedor}**`,
        };

  return (
    <Modal
      visible={visible}
      title={t(title)}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex
            style={{
              flexDirection: 'column',
              marginBottom: 15,
            }}
          >
            <ReactMarkdown>{t(body, { ...bodyVariables })}</ReactMarkdown>
          </Divflex>
        </div>
      }
      okButtonName={t(
        `${
          purchaseOrderList.length === 1 ? 'common.import' : 'common.continue'
        }`
      )}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      onOkClick={() => {
        setPurchaseOrderList();
        changeVisibleModal(false);
      }}
    />
  );
};

export default AddPurchaseOrderModal;

import { useCallback, useEffect } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  form,
  convertUnitOfMeasureAsync,
}: IUseFunctions) => {
  const onUnitOfMeasureChange = useCallback(
    async (selectedId: any) => {
      const currentUnitOfMeasureId = form.values.unidadeMedidaId;
      const quantity = form.values.amount;
      const currentQuantity = form.values.prevQnt;

      const result = await convertUnitOfMeasureAsync([
        {
          quantidade: currentQuantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
        {
          quantidade: quantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
      ]);

      if (result && result.length > 0) {
        const saldoAtual = result[0].quantidadeConvertida;
        const quantity = result[1].quantidadeConvertida;
        form.setFieldValue('prevQnt', saldoAtual);
        form.setFieldValue('amount', quantity);
        form.setFieldValue('newQnt', saldoAtual - quantity);
      }
    },
    [convertUnitOfMeasureAsync, form]
  );

  const validadeCalcBalance = useEffect(() => {
    let amountNumber: number = parseFloat(form.values.amount);
    let newValueNumber: number = parseFloat(form.values.prevQnt);

    if (isNaN(newValueNumber)) {
      newValueNumber = 0;
    }

    if (isNaN(amountNumber)) {
      amountNumber = 0;
    }

    let newValue = newValueNumber + amountNumber;

    form?.setFieldValue('newQnt', newValue || 0);
  }, [form.values.amount, form.values.prevQnt]);

  return { onUnitOfMeasureChange, validadeCalcBalance };
};

import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import {
  TitleHandler,
  BodyHandler,
} from '../Modal/packingClassificationListUtils';

export const useListMemorizations = ({
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t('production.packingClassification.list.newButton'),
      onClick: () => history.push('/production/packing-classification/create'),
      permission: {
        permission: PermissionEnum.Producao_ClassificacaoEmbalagem_Cadastrar,
        tooltip: 'noPermission.production.packingClassification.create',
      },
    },
    editButton: {
      options: [
        {
          label: t('production.packingClassification.list.editStatus'),
          isStatusOnly: true,
          permission: {
            permission:
              PermissionEnum.Producao_ClassificacaoEmbalagem_AlterarStatus,
            tooltip: 'noPermission.production.packingClassification.statusEdit',
          },
        },
      ],
      permission: {
        permission:
          PermissionEnum.Producao_ClassificacaoEmbalagem_EditarDetalhes,
        tooltip: 'noPermission.production.packingClassification.edit',
      },
    },
    searchPlaceholder: t(
      'production.packingClassification.list.searchPlaceholder'
    ),
    deleteButton: {
      permission: {
        permission: PermissionEnum.Producao_ClassificacaoEmbalagem_Excluir,
        tooltip: 'noPermission.production.packingClassification.delete',
      },
    },
    nameKey: 'descricao',
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(
        `/production/packing-classification/${x.externalId}/details`
      ),
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
    }),
    [t, selectedRowsOnTable]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
  };
};

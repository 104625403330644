import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { FETCH_GET_DROPDOWN_GROUP } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";

export const useGroupCallAPI = () => {
  const {
    data: listGroups, 
    fetchNewPage: fetchNewGroupPage
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_DROPDOWN_GROUP,
    entityApi: ProductAPI.getGroupListDropdown,
    initialPagination: { pageIndex: 0, pageSize: 10 },
    autoIncrement: true
  });

  return {
    listGroups,
    fetchNewGroupPage
  };
};
import React, { FC } from 'react';
import { Form } from '../../../../../../../Components';
import { DetailSchema } from './DetailForm.schema';
import { useParams } from 'react-router';
import { PersonalDataCard } from './Components/Cards/PersonalDataCard';
import { useUserDetailsCallAPI } from './Hooks/useUserDetailsCallAPI';
import { useUserDetailsStates } from './Hooks/useUserDetailsStates';
import { useUserDetailsFunctions } from './Hooks/useUserDetailsFunctions';
import { useUserDetailsMemorizations } from './Hooks/useUserDetailsMemorizations';
import StorageUtils from '@/Utils/StorageUtils';

export const DetailForm: FC = () => {
  const params: Readonly<Partial<{ externalId: string | undefined }>> =
    useParams();

  const preferences = StorageUtils.getPreference();

  const { buttonDisabled, setButtonDisabled } = useUserDetailsStates();

  const { editUser, user } = useUserDetailsCallAPI({
    userExternalId: params.externalId,
    changeEditDisabled: () => setButtonDisabled(true),
  });

  const { initialValues } = useUserDetailsMemorizations({ user });

  const { onSubmit } = useUserDetailsFunctions({
    editUser,
    datePattern: user?.preferencias.padraoData,
  });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      schema={DetailSchema(preferences.padraoData)}
    >
      <PersonalDataCard
        user={user}
        editDisabled={buttonDisabled}
        changeEditDisabled={() => setButtonDisabled(false)}
        editLoading={editUser.isLoading}
      />
    </Form>
  );
};

import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IInputProductName } from './interfaces';
import { TextInput } from '@/Components';
import { useFormikContext } from 'formik';
import { useInputProductNameFunctions } from './Hooks/useInputProductNameFunctions';
import { useInputProductNameStates } from './Hooks/useInputProductNameStates';
import { useInputProductNameMemorizations } from './Hooks/useInputProductNameMemorizations';

export const InputProductName: FC<IInputProductName> = ({
  disabledForm,
  product,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { showIconCopy, setShowIconCopy } = useInputProductNameStates();

  const { onChange, error } = useInputProductNameFunctions();

  const { rightIcon } = useInputProductNameMemorizations({ showIconCopy });

  useEffect(() => {
    if (!form.values.descricao) {
      setShowIconCopy(false);
    } else {
      setShowIconCopy(true);
    }
  }, [form.values.descricao]);

  return (
    <TextInput
      name="descricao"
      label={[
        {
          children: t('products.create.productName'),
        },
      ]}
      placeHolder={t('products.create.productName')}
      disabled={disabledForm}
      onChange={onChange}
      withStatusIcon={!!product}
      rightIcon={rightIcon}
      maxLength={100}
      error={!!error}
      required
    />
  );
};

import React, { useCallback } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '../../../../../../../../../Components/Inputs/CurrencyInput';
import { Tooltip } from '../../../../../../../../../Components';
import { currencyFormater } from '../../../../../../../../../Utils/Currency';
import { useFormikContext } from 'formik';

interface IThirdRowProps {
  initialValues: any;
  showTotalTooltip: boolean;
}

export const ThirdRow: React.FC<IThirdRowProps> = ({
  initialValues,
  showTotalTooltip,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const checkInitialValues = useCallback(
    (key: string) => {
      if (form.values) {
        return form.values[key] !== initialValues[key];
      }
      return false;
    },
    [form.values]
  );

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <Tooltip
          showMe={showTotalTooltip}
          title={`${t(
            'purchasing.invoice.edit.originalTotalInvoice'
          )}${currencyFormater(initialValues.valorTotalNotaFiscal)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.totalInvoice')}
            name="valorTotalNotaFiscal"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            disabled
            withStatusIcon
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

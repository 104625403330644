import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  form,
  changeStateButtonSave,
  refetchSearchLaboratoryPage,
}: IUseFunctions) => {
  useEffect(() => {
    if (
      !!form?.values.order &&
      !!form.values.description &&
      !!form.values.calculationType &&
      !!form.values.excipientMinimum &&
      !!form.values.laboratory &&
      !!form.values.use &&
      !!form.values.presentation &&
      !!form.values.unitMeasurement &&
      !!form.values.validityDurationInDays &&
      !!form.values.operationalCost
    ) {
      changeStateButtonSave && changeStateButtonSave(true);
    } else {
      changeStateButtonSave && changeStateButtonSave(false);
    }
  }, [form]);

  const onSearch = useCallback(
    (search: string | undefined) => {
      refetchSearchLaboratoryPage &&
        refetchSearchLaboratoryPage({
          pageIndex: 0,
          pageSize: 10,
          search: search,
          sorter: { column: 'laboratorio', direction: 'DESC' },
        });
    },
    [refetchSearchLaboratoryPage]
  );

  return { onSearch };
};

import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Icon,
  InputWithSearch,
  Tooltip,
} from '../../../../../../../../../Components';
import { IListUnitMeasurementByClassData } from '../../../../../../../../../Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { WeightInput } from '../../../../../../../../../Components/Inputs/WeightInput';
import { useCreateStates } from '../Hooks/useCreateStates';
import { useCreateCallAPI } from '../Hooks/useCreateCallAPI';
import { useCreateFunctions } from '../Hooks/useCreateFunctions';

export interface ITransferLine {
  index: number;

  originSupplyPlaceId?: string;
  destinySupplyPlaceId?: string;
  onDelete: (index: number) => void;
  totalRows: number;
  uuidV4: () => string;
  setProductRowAmount: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TransferLine: FC<ITransferLine> = ({
  index,
  originSupplyPlaceId,
  destinySupplyPlaceId,
  onDelete,
  totalRows,
  uuidV4,
  setProductRowAmount,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const selectedProductId = form.values?.transfers[index]?.product;
  const selectedLotId = form.values?.transfers[index]?.lot;

  const {
    paginationLot,
    unitMeasurementPagination,
    setUnitMeasurementPagination,
  } = useCreateStates();

  const {
    convertUnitOfMeasureAsync,
    fetchLotsNewPage,
    fetchNewProductPage,
    lotLoading,
    lotsList,
    lotsPagination,
    lotsRefetch,
    productsList,
    balanceOriginDestiny,
    unitsMeasurements,
    isLoadingProduct,
    refetchProducts,
  } = useCreateCallAPI({
    paginationLot,
    selectedLotId,
    originSupplyPlaceId,
    destinySupplyPlaceId,
    unitMeasurementPagination,
    form,
    index,
  });

  const {
    addRow,
    deleteRow,
    debounceLotsSearch,
    onUnitOfMeasureChange,
    selectedUnitOfMeasure,
  } = useCreateFunctions({
    index,
    totalRows,
    setProductRowAmount,
    uuidV4,
    form,
    onDelete,
    balanceOriginDestiny,
    paginationLot,
    destinySupplyPlaceId,
    lotsPagination,
    lotsRefetch,
    originSupplyPlaceId,
    selectedProductId,
    convertUnitOfMeasureAsync,
    unitsMeasurements,
  });

  return (
    <Row
      justify="space-between"
      style={{ marginBottom: '16px', display: 'flex', flexWrap: 'nowrap' }}
      gutter={[8, 0]}
    >
      <Col span={6}>
        <InputWithSearch
          name={`transfers.${index}.product`}
          label={index === 0 ? t('supply.transfers.fields.product') : ''}
          required
          onChange={(_, productSelected) => {
            form.setFieldValue(`transfers.${index}.lot`, undefined);
            form.setFieldValue(
              `transfers.${index}.unidadeMedidaId`,
              productSelected?.unidadeMedidaId
            );

            setUnitMeasurementPagination({
              ...unitMeasurementPagination,
              filter: [
                {
                  filterName: 'tipoClasseProduto',
                  value: productSelected?.classeProdutoId?.toString(),
                },
              ],
            });
            addRow();
          }}
          placeHolder={t('supply.transfers.placeHolders.product')}
          withoutMarginBottom={true}
          onScrollEnd={fetchNewProductPage}
          items={productsList?.data?.map((x: any) => ({
            id: x?.externalId,
            label: x?.descricao,
            subLabel: `${t('supply.transfers.subLabel')} ${
              x.sequenciaGroupTenant
            }`,
            allItem: x,
          }))}
          isLoading={isLoadingProduct}
          onSearch={(search) => {
            refetchProducts({
              pageIndex: 1,
              pageSize: 10,
              search: search,
            });
          }}
        />
      </Col>
      <Col span={5}>
        <InputWithSearch
          name={`transfers.${index}.lot`}
          label={index === 0 ? t('supply.transfers.fields.lot') : ''}
          required={true}
          withoutMarginBottom={true}
          placeHolder={t('supply.transfers.placeHolders.lot')}
          items={lotsList?.data?.map((x: any) => ({
            id: x.loteExternalId,
            label: x.loteNumero,
            subLabel: `NF: ${x.numeroNf}`,
          }))}
          onScrollEnd={fetchLotsNewPage}
          disabled={!form.values?.transfers[index]?.product}
          onChange={() => {
            form.setFieldValue(
              `transfers.${index}.originFunds`,
              balanceOriginDestiny?.saldoLoteOrigem
            );
            addRow();
          }}
          onInput={debounceLotsSearch}
          isLoading={lotLoading}
        />
      </Col>
      <Col span={4}>
        <WeightInput
          name={`transfers.${index}.originFunds`}
          label={
            index === 0 ? t('supply.transfers.fields.originFunds') : undefined
          }
          withoutMarginBottom={true}
          disabled={true}
          rightIcon={{
            titleString: selectedUnitOfMeasure?.unidadeAbreviacao,
          }}
        />
      </Col>
      <Col span={4}>
        <Tooltip
          title={t('supply.transfers.toolTips.currentBalanceNegative')}
          showMe={
            form.values.transfers[index]?.qntTransfers >
            form.values.transfers[index]?.originFunds
          }
        >
          <WeightInput
            name={`transfers.${index}.qntTransfers`}
            label={
              index === 0
                ? t('supply.transfers.fields.qntTransfers')
                : undefined
            }
            required={true}
            withoutMarginBottom={true}
            dropdownRight={{
              name: `transfers.${index}.unidadeMedidaId`,
              defaultValue: unitsMeasurements?.data?.[0]?.id,
              onChange: onUnitOfMeasureChange,
              options:
                unitsMeasurements?.map(
                  (x: IListUnitMeasurementByClassData) => ({
                    id: x.id,
                    content: x.unidadeAbreviacao,
                  })
                ) || [],
            }}
            error={
              form.values.transfers[index]?.qntTransfers >
              form.values.transfers[index]?.originFunds
            }
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <WeightInput
          name={`transfers.${index}.destinyFunds`}
          label={
            index === 0 ? t('supply.transfers.fields.destinyFunds') : undefined
          }
          withoutMarginBottom={true}
          disabled={true}
          rightIcon={{
            titleString: selectedUnitOfMeasure?.unidadeAbreviacao || '',
          }}
        />
      </Col>
      <Col
        style={{
          width: '50px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          type="secondary"
          onClick={deleteRow}
          disabled={index === totalRows - 1}
          style={{ marginTop: `${index === 0 && '20px'}` }}
        >
          <Icon
            icon="trash-line"
            size="L"
            style={{
              cursor: index === totalRows - 1 ? 'not-allowed' : 'default',
              color:
                index === totalRows - 1
                  ? 'var(--color-status-disabled-base)'
                  : '',
            }}
          />
        </Button>
      </Col>
    </Row>
  );
};

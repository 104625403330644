import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { ICreateLotRequest } from '@/Data/Interfaces/request/Lot/ILotResquest';
import DateUtils from '@/Utils/DateUtils';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { history } from '@/Store';

export const useLotFunctions = ({
  setClassTypeState,
  preferences,
  createLot,
}: IUseFunctions) => {
  const app = App.useApp();
  const unityIdDefault = 7;
  const productClassRawMaterial = 1;

  const onClassTypeSelected = useCallback(
    (classTypeId: number | undefined) => {
      setClassTypeState(classTypeId);
    },
    [setClassTypeState]
  );

  const onSubmit = async (values: ICreateLotRequest) => {
    const body: any = { ...values };
    delete body.classe;
    delete body.razaoSocial;
    delete body.Cnpj;

    if (!preferences) return;

    if (body.dataFabricacao)
      body.dataFabricacao = DateUtils.convertDateToApi(
        body.dataFabricacao,
        preferences.padraoData
      );
    if (body.dataValidade)
      body.dataValidade = DateUtils.convertDateToApi(
        body.dataValidade,
        preferences.padraoData
      );

    if (body?.classeId !== productClassRawMaterial) {
      body.loteInformacaoTecnica = null;
    }
    if (
      !body.loteInformacaoTecnica.loteUnidadeAlternativa2
        .quantidadeUnidadeAlternativa
    ) {
      body.loteInformacaoTecnica.loteUnidadeAlternativa2 = undefined;
    }
    if (
      !body.loteInformacaoTecnica.loteUnidadeAlternativa1
        .quantidadeUnidadeAlternativa
    ) {
      body.loteInformacaoTecnica.loteUnidadeAlternativa1 = undefined;
    }

    await createLot.mutateAsync(
      {
        ...body,
        unidadeId: unityIdDefault,
        saldoInicial: body.saldoInicial.localEstoqueExternalId
          ? body.saldoInicial
          : undefined,
      },
      {
        onError: handleModalError(app),
      }
    );
  };

  const onClick = useCallback(() => history.push('/inventory/lot'), []);

  return {
    onClassTypeSelected,
    onSubmit,
    onClick,
  };
};

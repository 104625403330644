import { cnpj, cpf } from 'cpf-cnpj-validator';
import { FormikContextType } from 'formik';
import { TFunction } from 'react-i18next';

export const ValidateCpf = (
  x: string,
  form: FormikContextType<unknown>,
  t: TFunction<'translation', undefined>,
  isBlur: boolean = false
) => {
  const cpfNotMask = x.replace(/[^\d]/g, '');

  if (cpfNotMask.length === 0) {
    form.setErrors({
      ...form.errors,
      cpf: undefined,
    });
  } else if (cpfNotMask.length === 11) {
    if (!cpf.isValid(cpfNotMask)) {
      form.setErrors({
        ...form.errors,
        cpf: t(
          'adminBootis.createCompany.responsibleUserCard.errors.invalidCpf'
        ),
      });
    } else {
      form.setErrors({
        ...form.errors,
        cpf: undefined,
      });
    }
  } else {
    if (isBlur) {
      form.setErrors({
        ...form.errors,
        cpf: t(
          'adminBootis.createCompany.responsibleUserCard.errors.invalidCpf'
        ),
      });
    } else {
      form.setErrors({
        ...form.errors,
        cpf: undefined,
      });
    }
  }
};

export const ValidateCnpj = (
  x: string,
  form: FormikContextType<unknown>,
  t: TFunction<'translation', undefined>,
  isBlur: boolean = false
) => {
  const cnpjNotMask = x.replace(/[^\d]/g, '');

  if (cnpjNotMask.length === 0) {
    form.setErrors({
      ...form.errors,
      cnpj: undefined,
    });
  } else if (cnpjNotMask.length === 14) {
    if (!cnpj.isValid(cnpjNotMask)) {
      form.setErrors({
        ...form.errors,
        cnpj: t('adminBootis.createCompany.companyDataCard.errors.invalidCNPJ'),
      });
    } else {
      form.setErrors({
        ...form.errors,
        cnpj: undefined,
      });
    }
  } else {
    if (isBlur) {
      form.setErrors({
        ...form.errors,
        cnpj: t('adminBootis.createCompany.companyDataCard.errors.invalidCNPJ'),
      });
    } else {
      form.setErrors({
        ...form.errors,
        cnpj: undefined,
      });
    }
  }
};

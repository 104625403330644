import { useCallback } from 'react';
import { IUseFunctions } from './interface';

export const useAccessCardFunctions = ({ userGroupListResponse }: IUseFunctions) => {
  const onSearchGroups = useCallback(
    (search?: string) => {
      userGroupListResponse.refetch({
        pageIndex: 0,
        pageSize: 10,
        search: search,
        sorter: { column: 'nome', direction: 'DESC' },
      });
    },
    [userGroupListResponse]
  );

  return {
    onSearchGroups,
  };
};

import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Card } from '@/Components/Card';
import { useParams } from 'react-router';
import { ProductValues } from './Components/ProductValues';
import { UnitaryDiscount } from './Components/UnitaryDiscount';
import { Total } from './Components/Total';
import { FreightCost } from './Components/FreightCost';
import { AdditionalValue } from './Components/AdditionalValue';
import { FullDiscount } from './Components/FullDiscount';
import { FinalValue } from './Components/FinalValue';
import { useTotalCardCallAPI } from './Hooks/useTotalCardCallAPI';
import { useTotalCardMemorizations } from './Hooks/useTotalCardMemorizations';

import styles from './Components/CardTotal/cartTotal.module.scss';

export const TotalPurchase: FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const { externalId } = useParams<{ externalId: string }>();
  
  const {
    orderPurchaseData
  } = useTotalCardCallAPI({
    externalId
  });

  const {
    orderPurchase,
    totalProductValue,
    totalEnding,
    totalProductValueWithoutDiscount,
    totalUnitaryDiscountValue,
    totalValueOrderPurchase
  } = useTotalCardMemorizations({
    orderPurchaseData
  });

  return (
    <>
      <Card title={t('common.productsUppercase')} withoutCollapse>
        <Row justify="start" gutter={[16, 0]}>
          <Col span={24}>
            <ProductValues
              totalProductValue={totalProductValue}
              orderPurchase={orderPurchase}
              totalProductValueWithoutDiscount={totalProductValueWithoutDiscount}
            />
          </Col>
          <Col span={24}>
            <UnitaryDiscount
              totalProductValue={totalProductValue}
              orderPurchase={orderPurchase}
              totalUnitaryDiscountValue={totalUnitaryDiscountValue}
            />
          </Col>
          <Col span={24}>
            <Total
              totalProductValue={totalProductValue}
              orderPurchase={orderPurchase}
              totalEnding={totalEnding}
            />
          </Col>
        </Row>
      </Card>
      <Card title={t('common.order')} withoutCollapse>
        <Row justify="start" style={{ marginTop: '16px' }} gutter={[16, 0]}>
          <Col span={24}>
            <FreightCost orderPurchase={orderPurchase} />
          </Col>
        </Row>
        <Row justify="start" style={{ marginTop: '16px' }} gutter={[16, 0]}>
          <Col span={24}>
            <AdditionalValue orderPurchase={orderPurchase} />
          </Col>
        </Row>
        <Row
          justify="start"
          style={{
            marginTop: '16px',
          }}
          gutter={[16, 0]}
        >
          <Col span={24}>
            <FullDiscount orderPurchase={orderPurchase} />
          </Col>
        </Row>
        <div className={`${styles['cardTotal-separator-line']}`}></div>
        <Row
          justify="start"
          style={{
            marginTop: '32px',
          }}
          gutter={[16, 0]}
        >
          <Col span={24}>
            <FinalValue
              orderPurchase={orderPurchase}
              totalValueOrderPurchase={totalValueOrderPurchase}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

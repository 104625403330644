import { InputWithSearch, Tooltip } from '@/Components';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { FETCH_SIMPLE_LIST_PRESCRIBERS } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { Col, Row } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import AddProductUtils from './AddProductUtils';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
  IFinishedProductListData,
  IProductResponse,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import DateUtils from '@/Utils/DateUtils';
import { IPreferences } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { InsufficientFunds } from './InsufficientFundsModal';
import { UseMutationResult } from '@tanstack/react-query';
import {
  convertCurrencyToInteger,
  currencyFormater,
  getCurrencySymbol,
} from '@/Utils/Currency';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';
import { CreateFastPrescriberModal } from './createFastPrescriberModal';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { ISimpleListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { ProductAPI } from '@/Data/API/Supply/Product';

interface IAddProductModalBody {
  productList: any;
  datePattern?: IPreferences;
  addProduct: UseMutationResult;
  editProduct: UseMutationResult;
  productSelected?: IFinishedProductListData;
  setProductSelected: (value: IFinishedProductListData) => void;
  insufficientFundsModalVisible: boolean;
  setInsufficientFundsModalVisible: (value: boolean) => void;
  editModal: boolean;
}

export const AddProductModalBody: FC<IAddProductModalBody> = ({
  productList,
  addProduct,
  editProduct,
  datePattern,
  productSelected,
  setProductSelected,
  insufficientFundsModalVisible,
  setInsufficientFundsModalVisible,
  editModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [createFastPrescriber, setCreateFastPrescriber] = useState(false);
  const [prescriberName, setPrescriberName] = useState('');

  const {
    data: prescriberList,
    fetchNewPage,
    refetch,
    isLoading,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_SIMPLE_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribersForDropdown,
    initialPagination: {
      sorter: { column: 'nomeCompleto', direction: 'DESC' },
      pageIndex: 0,
      pageSize: 50,
    },
  });

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]}>
        <Col flex="100%">
          <InputWithSearch
            name="produtoExternalId"
            label={t('saleOrder.editOrder.SaleOrder.addProductModal.product')}
            placeHolder={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.productPlaceholder'
            )}
            required
            items={productList.data?.data?.map(
              (x: IFinishedProductListData) => ({
                id: x.externalId,
                label: x.descricao,
                subLabel: `${t(
                  'saleOrder.editOrder.SaleOrder.addProductModal.code'
                )}: ${x.codigo} | ${
                  x.saldoEstoque > 0
                    ? `${x.saldoEstoque} un ${
                        x.saldoEstoque === 1
                          ? t(
                              'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaible'
                            )
                          : t(
                              'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaibles'
                            )
                      }`
                    : t(
                        'saleOrder.editOrder.SaleOrder.addProductModal.noBalanceAvailable'
                      )
                }`,
              })
            )}
            onChange={(x) =>
              setProductSelected(
                productList.data.find(
                  (value: IFinishedProductListData) => value.externalId === x
                )
              )
            }
            onScrollEnd={productList.fetchNewPage}
            isLoading={productList.isLoading}
            onSearch={(search) => {
              productList.refetch({
                pageIndex: 0,
                pageSize: 10,
                search: search,
                sorter: { column: 'descricao', direction: 'DESC' },
              });
            }}
          />
        </Col>
      </Row>
      <PriceRow t={t} productSelected={productSelected} />
      <Row gutter={[16, 0]}>
        <Col flex="70%">
          <InputWithSearch
            name="prescritorExternalId"
            label={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.prescriber'
            )}
            placeHolder={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.prescriberPlaceholder'
            )}
            items={prescriberList?.data?.data?.map(
              (x: ISimpleListPrescribersData) => ({
                id: x.externalId,
                label: x.nomeCompleto,
                subLabel: `${x.siglaRegistro}/${x.siglaRegistro} ${x.codigoRegistro}`,
              })
            )}
            onScrollEnd={fetchNewPage}
            isLoading={isLoading}
            onSearch={(search) => {
              refetch({
                pageIndex: 0,
                pageSize: 10,
                search: search,
                sorter: { column: 'nomeCompleto', direction: 'DESC' },
              });
            }}
            onChangeInputText={(x) => setPrescriberName(x)}
            emptyState={{
              onClick: () => {
                dispatch(NotificationActions.setCurrentFormIsDirty(false));
                setCreateFastPrescriber(true);
              },
              suffix: ` (${t('common.newPrescriber')})`,
            }}
            withoutMarginBottom
          />
        </Col>
        <Col flex="30%">
          <PrescriptionDate datePattern={datePattern} t={t} />
        </Col>
      </Row>
      <InsufficientFunds
        visible={insufficientFundsModalVisible}
        BalanceStock={productSelected?.saldoEstoque}
        productName={productSelected?.descricao}
        addProduct={addProduct}
        editProduct={editProduct}
        editModal={editModal}
        datePattern={datePattern}
        changeVisible={() => setInsufficientFundsModalVisible(false)}
      />
      <CreateFastPrescriberModal
        visible={createFastPrescriber}
        changeVisible={() => setCreateFastPrescriber(false)}
        nameClient={prescriberName}
        t={t}
      />
    </div>
  );
};

interface IPrescriptionDate {
  datePattern?: IPreferences;
  t: TFunction;
}

const PrescriptionDate: FC<IPrescriptionDate> = ({ datePattern, t }) => {
  const [dateGreaterThanCurrentDay, setDateGreaterThanCurrentDay] =
    useState(false);

  const form: any = useFormikContext();

  useEffect(() => {
    const parsedDate = DateUtils.convertStringToDate(
      form.values.dataPrescricao
    );
    setDateGreaterThanCurrentDay(parsedDate > new Date());
  }, [form.values.dataPrescricao]);

  return (
    <Tooltip
      title={t(
        'saleOrder.editOrder.SaleOrder.addProductModal.dateGreaterThanCurrentDay'
      )}
      showMe={dateGreaterThanCurrentDay}
    >
      <TextInputWithMask
        name="dataPrescricao"
        placeHolder={
          datePattern
            ? DateUtils.getDatePlaceholder(
                datePattern.idioma,
                datePattern.padraoData
              )
            : ''
        }
        label={t(
          'saleOrder.editOrder.SaleOrder.addProductModal.prescriptionDate'
        )}
        mask="dataMask"
        leftIcon="calendar"
        withoutMarginBottom
        error={dateGreaterThanCurrentDay}
      />
    </Tooltip>
  );
};

interface IPriceRow {
  t: TFunction;
  productSelected?: IFinishedProductListData;
}

const PriceRow: FC<IPriceRow> = ({ t, productSelected }) => {
  const form: any = useFormikContext();

  const changeUnitValueAfterChangeProduct = useCallback(
    (unitValue: number) => {
      if (unitValue) {
        form.setFieldValue('valorUnitario', unitValue);
        !form.values.quantidade && form.setFieldValue('quantidade', 1);
        form.setFieldValue(
          'total',
          AddProductUtils.getTotalValue(form, { unitValue })
        );
      }
    },
    [form]
  );

  const product = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: productSelected?.externalId,
  });

  useEffect(() => {
    if (product.isSuccess && product.data !== null) {
      const x = product.data;

      changeUnitValueAfterChangeProduct(x?.valorVenda);
    }
  }, [product]);

  const changeTotalInput = useMemo(
    () =>
      _.debounce((form: any, total?: number) => {
        const currentTotal = total ?? 0;

        const unitValue = form.values.valorUnitario ?? 0;

        const amount = form.values.quantidade ?? 0;

        const discountType = form.values.tipoDesconto;

        const lastValueWithoutDiscount = unitValue * amount;
        if (!lastValueWithoutDiscount) return;

        if (lastValueWithoutDiscount <= currentTotal) {
          form.setFieldValue('total', lastValueWithoutDiscount);
          form.setFieldValue('desconto', undefined);
        } else {
          let discount = 0;
          const currentDiscount = lastValueWithoutDiscount - currentTotal;

          if (amount > 0) discount = currentDiscount / amount;
          else discount = currentDiscount;

          if (discountType === 2) {
            form.setFieldValue('desconto', discount);
          } else {
            form.setFieldValue('desconto', (discount * 100) / unitValue);
          }
        }
      }, 600),
    []
  );

  return (
    <Row gutter={[16, 0]}>
      <Col flex="25%">
        <NumberInput
          name="valorUnitario"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.value')}
          placeHolder={currencyFormater(0)}
          rightIcon={{ titleString: 'un' }}
          fixedDecimalScale
          decimalScale={2}
          withDecimalSeparator
          withThousandSeparator
          required
          disabled
          prefix={getCurrencySymbol()}
          value={product.data?.valorVenda.toString().replace('.', ',')}
          dataTestId={'unitValue'}
        />
      </Col>
      <Col flex="25%">
        <NumberInput
          name="quantidade"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.amout')}
          placeHolder="0"
          dataTestId={'amount'}
          rightIcon={{ titleString: 'un' }}
          decimalScale={0}
          required
          onChange={(x) =>
            form.setFieldValue(
              'total',
              AddProductUtils.getTotalValue(form, {
                amount: parseInt(x),
              })
            )
          }
          disabled={!form.values.produtoExternalId}
          subText={
            productSelected &&
            `${productSelected?.saldoEstoque} un ${
              productSelected?.saldoEstoque === 1
                ? t(
                    'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaible'
                  )
                : t(
                    'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaibles'
                  )
            }`
          }
          subTextTestId="amount-sub-text"
          withoutMarginBottom
        />
      </Col>
      <Col flex="25%">
        <DiscountInput
          name="desconto"
          label={t(
            'saleOrder.editOrder.SaleOrder.addProductModal.unitDiscount'
          )}
          placeHolder={currencyFormater(0)}
          dataTestId={'discount'}
          dropdownLeft={{
            name: 'tipoDesconto',
            onChange: (discountType) =>
              form.setFieldValue(
                'desconto',
                AddProductUtils.getDiscountValue(form, parseInt(discountType))
              ),
          }}
          onChange={(discount) =>
            form.setFieldValue(
              'total',
              AddProductUtils.getTotalValue(form, { discount })
            )
          }
          withoutPrefix
          withoutSuffix
        />
      </Col>
      <Col
        flex="25%"
        onBlur={() =>
          form.values.valorUnitario * form.values.quantidade < form.values.total
            ? form.values.valorUnitario * form.values.quantidade
            : form.values.total
        }
      >
        <NumberInput
          name="total"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.totalValue')}
          placeHolder={currencyFormater(0)}
          fixedDecimalScale
          decimalScale={2}
          withDecimalSeparator
          withThousandSeparator
          required
          onChange={(x) => changeTotalInput(form, convertCurrencyToInteger(x))}
          error={
            form.values.valorUnitario * form.values.quantidade <
            form.values.total
          }
          disabled={!form.values.produtoExternalId}
          prefix={getCurrencySymbol()}
        />
      </Col>
    </Row>
  );
};

import { Row } from 'antd';
import { TextArea } from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';

const FifthRow: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
      <TextArea
        name="informacoesComplementares"
        label={t('purchasing.invoice.create.complementaryInformation')}
        placeholder={t(
          'purchasing.invoice.create.placeHolder.complementaryInformation'
        )}
        maxLength={5000}
        rows={1}
        heightTextArea={100}
        withStatusIcon
      />
    </Row>
  );
};

export default FifthRow;

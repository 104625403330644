import React, { useEffect, useState } from 'react';
import { Card } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import FirstRow from './rows/FirstRow';
import SecondRow from './rows/SecondRow';
import ThirdRow from './rows/ThirdRow';
import FourthRow from './rows/FourthRow';
import FifthRow from './rows/FifthRow';
import FormBreakLine from '../../../../../../../Components/FormBreakLine';
import SixthRow from './rows/SixthRow';
import { useFormikContext } from 'formik';

interface IInfoSectionProps {
  initialValues: any;
  setImportedProducts?: any;
}

export const InfoSection: React.FC<IInfoSectionProps> = ({
  initialValues,
  setImportedProducts,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const [modalTablePurchaseOrderVisible, setModalTablePurchaseOrder] =
    useState<boolean>(false);
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [purchaseOrderSelected, setPurchaseOrderSelected] = useState<any[]>([]);

  useEffect(() => {
    if (purchaseOrderSelected.length > 0) {
      const list = purchaseOrderSelected.map((e) => e.externalId);
      form.values.pedidosExternalId = list;

      const importedProductsFromPurchase = purchaseOrderSelected
        .filter((e) => !e.isInitial)
        .map((e) => e.itens);

      const importedProducts = importedProductsFromPurchase.flat();

      setImportedProducts(importedProducts);
    }
  }, [purchaseOrderSelected, isInitial]);

  useEffect(() => {
    if (initialValues && initialValues.pedidosVinculados) {
      const initialPurchaseOrders = initialValues.pedidosVinculados.map(
        (e: any) => {
          return {
            ...e,
            key: e.sequenciaGroupTenant,
            itens: e.pedidoItensVinculados,
            externalId: e.pedidoCompraExternalId,
            isInitial: true,
          };
        }
      );
      setPurchaseOrderSelected(initialPurchaseOrders);
    }
  }, [initialValues]);

  return (
    <Card title={t('purchasing.invoice.create.info')} withoutCollapse>
      <FirstRow initialValues={initialValues} />
      <SecondRow initialValues={initialValues} />
      <ThirdRow
        initialValues={initialValues}
        modalTablePurchaseOrderVisible={modalTablePurchaseOrderVisible}
        setModalTablePurchaseOrder={setModalTablePurchaseOrder}
        purchaseOrderSelected={purchaseOrderSelected}
        setPurchaseOrderSelected={(purchaseOrderSelected: any[]) => {
          setPurchaseOrderSelected(purchaseOrderSelected);
          setIsInitial(false);
        }}
      />
      <FourthRow initialValues={initialValues} />
      <FifthRow />
      <FormBreakLine />
      <SixthRow
        initialValues={initialValues}
        purchaseOrders={purchaseOrderSelected}
        setPurchaseOrderSelected={setPurchaseOrderSelected}
        setModalTablePurchaseOrder={setModalTablePurchaseOrder}
      />
    </Card>
  );
};

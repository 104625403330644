import { useControllerQueryListApiHook } from "@/ControllerApiHook/Controller";
import { LotsAPI } from "@/Data/API/Supply/LotApi";
import { FETCH_LIST_LOTS_DROPDOWN } from "@/ControllerApiHook/UniqIds/Supply/LotKeys";

export const useTechnicalInfoDilutionsRowsCallAPI = () => {
  const {
    data: lotList,
    isLoading: isLotLoading,
    fetchNewPage: fetchNewLotPage,
    refetch: refetchLotNewPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOTS_DROPDOWN,
    entityApi: LotsAPI.getLotListDropDown,
    initialPagination: { pageIndex: 0, pageSize: 10 }
  });

  return {
    lotList,
    isLotLoading,
    fetchNewLotPage,
    refetchLotNewPage
  };
}; 
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import {
  LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
  LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
  THERE_IS_BIND_LABORATORY_TO_COMPANY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';

export const useEditCallAPI = ({ form, t }: IUseCallAPI) => {
  const {
    data: companyList,
    isLoading: isLoadingCompany,
    refetch: refetchSearchCompanyPage,
    fetchNewPage: fetchInputCompany,
  } = useControllerQueryListApiHook({
    uniqId: LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listCompanyForDropdownToLaboratory,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'nomeFantasia', direction: 'ASC' },
    },
  });

  const {
    data: supplyPlaceList,
    isLoading: isLoadingSupplyPlace,
    refetch: refetchSupplyPlace,
    fetchNewPage: fetchNewPageSupplyPlace,
  } = useControllerQueryListApiHook({
    uniqId: LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listSupplyPlaceForDropdownToLaboratory,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const { mutateAsync: fetchDescriptionLaboratory }: any =
    useControllerMutateApiHook({
      uniqId: THERE_IS_BIND_LABORATORY_TO_COMPANY,
      entityApi: LaboratoryAPI.laboratoryBindCompany,
      options: {
        onSuccess: () => {
          form?.setErrors({
            ...form?.errors,
            nome: undefined,
          });
        },
        onError: () => {
          form?.setErrors({
            ...form?.errors,
            nome: t('production.laboratory.create.validation'),
          });
        },
      },
    });

  return {
    companyList,
    isLoadingCompany,
    refetchSearchCompanyPage,
    fetchInputCompany,
    supplyPlaceList,
    isLoadingSupplyPlace,
    refetchSupplyPlace,
    fetchDescriptionLaboratory,
    fetchNewPageSupplyPlace,
  };
};

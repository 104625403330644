import React, { FC } from 'react';
import { Button, i18n, Icon, Text } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { CREATE_SALES_ORDER } from '../../../../../../../ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import { GET_SERVICE } from '../../../../../../../ControllerApiHook/UniqIds/Sales/ServiceKeys';

import styles from './WithoutSaleOrder.module.scss';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';

interface IWithoutSaleOrder {
  serviceExternalId: string;
}

export const WithoutSaleOrder: FC<IWithoutSaleOrder> = ({
  serviceExternalId,
}) => {
  const queryClient = useQueryClient();

  const createSaleOrder = useControllerMutateApiHook({
    uniqId: CREATE_SALES_ORDER,
    entityApi: SalesOrderAPI.createSaleOrder,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SERVICE]);
        messageSucessToast(i18n.t('callCenter.messages.create'));
      }
    }
  });

  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['div-content']}>
        <Icon icon="cart" size="XXLL" color="text-500" />
        <Text
          type="ui-tiny-semibold"
          children={t('saleOrder.editOrder.withoutSaleOrder.title')}
          color="text-50"
        />
        <Text
          type="ui-tiny-content"
          children={t('saleOrder.editOrder.withoutSaleOrder.description')}
          color="text-200"
        />
        <Button
          type="primary"
          leftIcon="plus"
          children={t('saleOrder.editOrder.withoutSaleOrder.buttonText')}
          onClick={async () =>
            await createSaleOrder.mutateAsync({
              atendimentoExternalId: serviceExternalId,
            })
          }
          loading={createSaleOrder.isLoading}
        />
      </div>
    </div>
  );
};

import { useParams } from 'react-router-dom';
import { EDIT_LOT, FETCH_GET_LOT } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { IGetLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';

export const useDetailsCardsCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();
  
  const { data: lots } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalId: externalId
  })

  const editLot: any = useControllerMutateApiHook({
    uniqId: EDIT_LOT,
    entityApi: LotsAPI.editLot
  });

  return {
    lots,
    editLot
  };
};

import { useCallback } from "react";
import { useFormikContext } from "formik";
import { IUseFunctions } from "./interfaces";

export const useRadioButtonMessageTypeFunctions = ({
  setSelectedOptionsMessageType,
  refetchMessageProductDropdown,
  setMessageSearch
}: IUseFunctions) => {
  const form = useFormikContext();
  const addNewMessageProduct: number = 2;

  const createMessageProductLinked = async (message: string) => {
    setSelectedOptionsMessageType(addNewMessageProduct);
    form.setFieldValue('mensagem', message);
  };

  const onSearchMessageExisting = useCallback((search: string | undefined) => {
    refetchMessageProductDropdown({
      pageIndex: 0,
      pageSize: 10,
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' }
    });
  }, [refetchMessageProductDropdown]);

  const onChangeInputTextMessageExisting = (value: string) => {
    setMessageSearch(value);
  };
  
  return {
    createMessageProductLinked,
    onSearchMessageExisting,
    onChangeInputTextMessageExisting
  };
};
import { useState } from 'react';

export const useEditStates = () => {
  const [
    pharmaceuticalFormExternalIdState,
    setPharmaceuticalFormExternalIdState,
  ] = useState<string>('');

  return {
    pharmaceuticalFormExternalIdState,
    setPharmaceuticalFormExternalIdState,
  };
};

import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import styles from '../../FooterButton/PackingClassification.module.scss';
import { TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';

export const RowDescription = () => {
  const form: FormikContextType<unknown> = useFormikContext();
  const { t } = useTranslation();

  const { mutateAsync } = useCreateCallAPI({ form, t });

  const { fetchPackingClassificationName } = useCreateFunctions({
    mutateAsync,
  });

  return (
    <Row gutter={[16, 0]} justify="space-between">
      <Col span={12}>
        <TextInput
          className={styles['text-input']}
          name="descricao"
          label={t('production.packingClassification.create.name')}
          placeHolder={t(
            'production.packingClassification.create.namePlaceholder'
          )}
          required
          onChange={(value) => fetchPackingClassificationName(value)}
        />
      </Col>
    </Row>
  );
};

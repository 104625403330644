import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { useFormikContext } from 'formik';
import { IUseCallAPI } from './interfaces';
import { useEffect } from 'react';
import { isNil } from 'lodash';

export const useQuantityCallAPI = ({
  currentProductExternalId,
  pharmaceuticalFormExternalId,
}: IUseCallAPI) => {
  const form: any = useFormikContext();

  const { data: unitsMeasurementsDefault } =
    useControllerQueryApiHook<IProductResponse>({
      uniqId: FETCH_GET_PRODUCT,
      entityApi: ProductAPI.getProduct,
      externalId: form.values.produtoExternalId,
    });

  const {
    data: listunitsMeasurementsPrescription,
    isLoading: isUnitOfMeasurePrescriptionLoading,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementPrescriptionDropDown,
    initialPagination: {
      pageIndex: 0,
      pageSize: 50,
    },
    enabled:
      !isNil(currentProductExternalId) && !isNil(pharmaceuticalFormExternalId),
  });

  useEffect(() => {
    refetch({
      pageIndex: 0,
      pageSize: 50,
      filter: [
        {
          filterName: 'produtoExternalId',
          value: currentProductExternalId,
        },
        {
          filterName: 'formaFarmaceuticaExternalId',
          value: pharmaceuticalFormExternalId,
        },
      ],
    });
  }, [currentProductExternalId, pharmaceuticalFormExternalId, refetch]);

  return {
    unitsMeasurementsDefault,
    listunitsMeasurementsPrescription,
    refetch,
    isUnitOfMeasurePrescriptionLoading,
  };
};

import React from 'react';
import { Form } from '@/Components';
import { CreateInitialValuesForUserGroups } from '@/Utils/CreateInitialValuesUtils';
import { CreateSchema } from './CreateForm.schema';
import { DetailsCard } from './Components/DetailsCard';
import { useCreateUserGroupsFunctions } from './Hooks/useCreateUserGroupsFunctions';
import { FooterButton } from './Components/FooterButton';
import { useCreateUserGroupsCallAPI } from './Hooks/useCreateUserGroupsCallAPI';

export const CreateUserGroup: React.FC = () => {
  const {
    createUserGroup
  } = useCreateUserGroupsCallAPI();

  const { 
    onSubmit
  } = useCreateUserGroupsFunctions({
    createUserGroup
  });

  return (
    <Form
      initialValues={CreateInitialValuesForUserGroups()}
      onSubmit={onSubmit}
      schema={CreateSchema}
    >
      {(props) => (
        <>
          <DetailsCard props={props} />
          <FooterButton props={props} />
        </>
      )}
    </Form>
  );
};

import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { expertiseCollums } from './expertise.columns';
import { Modal } from '@/Components/Modal';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';

export const List: FC = () => {
  const { t } = useTranslation();

  const {
    specialtiesName,
    setSpecialtiesName,
    createModalOpen,
    setCreateModalOpen,
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  } = useListStates();

  const {
    createSpecialties,
    createSpecialtiesLoading,
    changeSpecialtiesStatus,
    changeSpecialtiesStatusLoading,
    deleteSpecialties,
    especialtiesList,
  } = useListCallAPI();

  const {
    onEdit,
    onDelete,
    selectRowsOnTable,
    onCancelClick,
    onClose,
    onOkClick,
    onOkClickForm,
  } = useListFunctions({
    changeSpecialtiesStatus,
    deleteSpecialties,
    setSelectedRowsOnTable,
    setCreateModalOpen,
    createSpecialties,
    specialtiesName,
  });

  const { headerOptions, rowOptions, deleteModal, modalBody } =
    useListMemorizations({
      setCreateModalOpen,
      selectedRowsOnTable,
      setSpecialtiesName,
    });

  return (
    <>
      <PageLoading loading={especialtiesList.isLoading}>
        <Table
          columns={expertiseCollums(t)}
          headerOptions={headerOptions}
          rowsOptions={rowOptions}
          onEdit={onEdit}
          onDelete={onDelete}
          deleteModal={deleteModal}
          selectedRowsOnTable={selectRowsOnTable}
          editModalLoading={changeSpecialtiesStatusLoading}
          editOptions={editTableStatus()}
          loading={especialtiesList.isLoading}
          hasSelection
          fieldStatus="ativo"
          pagination={especialtiesList.data}
          onRefetch={especialtiesList.refetch}
        />
      </PageLoading>
      <Modal
        title={t('specialties.create.title')}
        body={modalBody}
        okButtonName={t('common.create')}
        visible={createModalOpen}
        withForm
        onOkClickForm={onOkClickForm}
        htmlType="submit"
        onCancelClick={onCancelClick}
        onClose={onClose}
        onOkClick={onOkClick}
        loading={createSpecialtiesLoading}
        disabledButtonFormNames={['descricao']}
      />
    </>
  );
};

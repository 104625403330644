import { useCallback } from "react";
import { IFChildren } from "@/Components/Form";
import { IListUserData } from "@/Data/Interfaces/response/User/IGetUserReponse";
import { IUseFunctions } from "./interfaces";

export const useDetailsCardFunctions = ({
  usersSelected,
  setUsersSelected,
  userList
}: IUseFunctions) => {
  const addOrRemoveUsers = (
    value: string,
    remove: boolean,
    props: IFChildren
  ) => {
    const index = usersSelected?.findIndex(
      (user: IListUserData) => user.externalId.toString() === value.toString()
    );
    const tmp = usersSelected?.slice();
    if (remove) {
      tmp.splice(index, 1);
      setUsersSelected([...tmp]);
      props.setFieldValue(
        'usuarios',
        tmp.map((x: IListUserData) => x.externalId)
      );
      return;
    }

    const user = userList?.data?.data.find((x: IListUserData) => x.externalId === value);

    user && tmp.push(user);

    setUsersSelected([...tmp]);
    props.setFieldValue(
      'usuarios',
      tmp.map((x: IListUserData) => x.externalId)
    );
  };

  const  onSearch = useCallback(
    (search: string | undefined) =>
      userList.refetch({
        pageIndex: 0,
        pageSize: 10,
        search: search,
        sorter: { column: 'nome', direction: 'DESC' },
      }), [userList.refetch]
  );

  return {
    onSearch,
    addOrRemoveUsers
  };
};
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Message } from '../../Components/Feedback/Message';
import { HeaderMenu } from '../../Components/HeaderMenu';
import { SideMenu } from '../../Components/SideMenu';
import { getMenuById } from '../../Components/SideMenu/SideMenu.items';
import StorageUtils from '../../Utils/StorageUtils';
import { PageLayout } from '../PageLayout';
import { GET_COMPANY_CONFIG_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { keepDataStorageLogin } from '@/Utils/ThemeUtils';
import { ChangeLanguage } from '@/Utils/ChangeLanguageUtils';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { GET_ME } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { Context, CurrentContextType } from '@/Contexts/context';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { IGetCompanyConfigResponse } from '@/Data/Interfaces/response/Company/ICompanyResponse';

interface IWithSideBar {
  children: ReactNode;
}

const WithSideBar: FC<IWithSideBar> = ({ children }) => {
  const params: { pathname: string } = useLocation();
  const { data: companyConfig } =
    useControllerQueryApiHook<IGetCompanyConfigResponse>({
      uniqId: GET_COMPANY_CONFIG_TYPE,
      entityApi: CompanyAPI.getCompanyConfig,
    });
  const { data: getMe } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_ME,
    entityApi: UserAPI.getMe,
  });

  const { currentContext } = React.useContext(Context) as CurrentContextType;

  const menu = getMenuById(currentContext);

  const current = params.pathname.split('/');

  const COLLAPSE_SIDEBAR = 'COLLAPSE-SIDEBAR';
  const [isCollapsed, setIsCollapsed] = useState(
    StorageUtils.GetItem(COLLAPSE_SIDEBAR) === 'true'
  );

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!getMe) return;
    companyConfig && keepDataStorageLogin(getMe, companyConfig?.tipoMoedaId);
    ChangeLanguage(getMe.preferencias.idioma);
  }, [getMe, companyConfig]);

  useEffect(() => {
    dispatch(NotificationActions.setCurrentFormIsDirty(false));
  }, [dispatch, location]);

  return (
    <div>
      <Message />
      <HeaderMenu
        logo="logo-bootys"
        logoCollapsed="simple-logo"
        isCollapsed={isCollapsed}
        getMe={getMe}
      />
      {current[1] === 'account-manager' ||
      current[1] === 'user-preference' ||
      current[1] === 'system-configurations' ? (
        <React.Suspense fallback={<span>Loading...</span>}>
          {children}
        </React.Suspense>
      ) : (
        <SideMenu
          items={menu?.items}
          onCollapsedChange={(collapsed) => setIsCollapsed(collapsed)}
          getMe={getMe}
        >
          <React.Suspense fallback={<span>Loading...</span>}>
            <PageLayout children={children} currentContext={currentContext} />
          </React.Suspense>
        </SideMenu>
      )}
    </div>
  );
};

export default WithSideBar;

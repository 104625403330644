import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { productCollums } from './product.columns';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';

export const List: FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    deleteProduct,
    editProductStatus,
    productList,
    isLoading,
    patchProductStatus,
    refetchProductList,
  } = useListCallAPI();

  const { headerOptions, rowOptions, deleteModal } = useListMemorizations({
    selectedRowsOnTable,
  });

  const { onSelectedRowsOnTable, onEdit, onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    patchProductStatus,
    deleteProduct,
  });

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          columns={productCollums(t)}
          headerOptions={headerOptions}
          selectedRowsOnTable={onSelectedRowsOnTable}
          rowsOptions={rowOptions}
          editOptions={editTableStatus()}
          loading={isLoading}
          hasSelection
          fieldStatus="ativo"
          pagination={productList}
          onEdit={onEdit}
          onRefetch={refetchProductList}
          editModalLoading={editProductStatus.isLoading}
          deleteModal={deleteModal}
          onDelete={onDelete}
        />
      </PageLoading>
    </>
  );
};

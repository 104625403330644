import { IListRecordTypeData } from "@/Data/Interfaces/response/Record/IRecordResponse";
import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useRegisterTypeMemorizations = ({ recordTypeList }: IUseMemorizations) => {
  const items = useMemo(() => {
    return recordTypeList?.map((x: IListRecordTypeData) => ({
      id: x.id,
      label: x.sigla,
    }));
  }, [recordTypeList]);

  return { items };
};
import React from 'react';
import { Row, Col } from 'antd';
import PurchaseOrderInput from '../../../../components/PurchaseOrderInput';
import { IPurchaseOrderOpenGetResponseData } from '../../../../../../../../../Data/Interfaces/response/Purchase/IPurchaseOrderResponse';

interface ISixthRowProps {
  purchaseOrderSelected: IPurchaseOrderOpenGetResponseData[];
  setPurchaseOrderSelected: React.Dispatch<
    React.SetStateAction<IPurchaseOrderOpenGetResponseData[]>
  >;
  setImportedProducts: React.Dispatch<React.SetStateAction<any[]>>;
  setModalTablePurchaseOrder: React.Dispatch<React.SetStateAction<boolean>>;
}

const SixthRow: React.FC<ISixthRowProps> = ({
  purchaseOrderSelected,
  setPurchaseOrderSelected,
  setImportedProducts,
  setModalTablePurchaseOrder,
}) => {
  return (
    <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
      <Col span={24}>
        <PurchaseOrderInput
          purchaseOrderList={purchaseOrderSelected}
          setPurchaseOrderSelected={setPurchaseOrderSelected}
          setImportedProducts={setImportedProducts}
          setModalTablePurchaseOrder={setModalTablePurchaseOrder}
        />
      </Col>
    </Row>
  );
};

export default SixthRow;

import { FC } from 'react';
import { Row } from 'antd';
import { Card } from '@/Components';
import { ButtonCard } from '@/Components/EntityInformation/ButtonCard';
import { InfoModal } from '../../../../Modal/InfoModal';
import { IInfoCard } from './interfaces';
import { useInfoCardStates } from './Hooks/useInfoCardStates';
import { useInfoCardMemorizations } from './Hooks/useInfoCardMemorizations';
import { useInfoCardFunctions } from './Hooks/useInfoCardFunctions';
import { Register } from './Components/Register';
import { Specialties } from './Components/Specialties';
import { Birthday } from './Components/Birthday';
import { Observation } from './Components/Observation';
import { ProductDescription } from './Components/ProductDescription';
import { FormulaDescription } from './Components/FormulaDescription';

export const InfoCard: FC<IInfoCard> = ({ prescriber }) => {
  const { editCardDisabled, setEditCardDisabled } = useInfoCardStates();

  const { titleCard, buttonCardPermission, preferences } =
    useInfoCardMemorizations({ prescriber });

  const { changeVisibleModal } = useInfoCardFunctions({ setEditCardDisabled });

  return prescriber ? (
    <Card title={titleCard} withoutCollapse>
      <Row gutter={[0, 16]}>
        <Register prescriber={prescriber} />
        <Specialties prescriber={prescriber} />
        <Birthday prescriber={prescriber} datePattern={preferences} />
        <Observation prescriber={prescriber} />
        <ProductDescription prescriber={prescriber} datePattern={preferences} />
        <FormulaDescription prescriber={prescriber} datePattern={preferences} />
        <ButtonCard
          disabled={editCardDisabled}
          onDisabledChange={setEditCardDisabled}
          permission={buttonCardPermission}
        />
      </Row>
      <InfoModal
        visible={!editCardDisabled}
        prescriber={prescriber}
        changeVisibleModal={changeVisibleModal}
        preferences={preferences}
      />
    </Card>
  ) : (
    <></>
  );
};

import { currencyFormater } from '@/Utils/Currency';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useUnitaryDiscountMemorizations = ({
  totalUnitaryDiscountValue,
  totalProductValue,
  orderPurchase
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const withStatusIconInLabel = totalUnitaryDiscountValue !==
  form?.values?.totalizadores?.totalProdutosDescontos;

  const withTooltip = {
    showMe:
      totalProductValue !==
      form?.values?.totalizadores?.totalProdutosDescontos,
    title: `${t('common.originalValue')} ${currencyFormater(
      orderPurchase?.totalizadores?.totalProdutosDescontos
    )}`,
    targetOffset: [75, -2],
  };

  return {
    withStatusIconInLabel,
    withTooltip
  };
};
import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';
import { useSupplierDilutionFactorFunctions } from './Hooks/useSupplierDilutionFactorFunctions';

export const SupplierDilutionFactor: FC = () => {
  const { t } = useTranslation();

  const { onChange } = useSupplierDilutionFactorFunctions();

  return (
    <Col span={6}>
      <TextInput
        name="loteInformacaoTecnica.fatorDiluicaoFornecedor"
        label={[
          {
            children: t('supply.lot.create.supplierDilutionFactor'),
          },
        ]}
        placeHolder={t('supply.lot.create.supplierDilutionFactor')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('supply.lot.create.toolTips.supplierDilutionFactor'),
        }}
        type="number"
        onChange={onChange}
        required
      />
    </Col>
  );
};

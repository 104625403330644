import { Col, Row } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tooltip } from '../../../../../../../Components';
import { getCardStatus } from '../../../../../../../Components/Card';
import { IFChildren } from '../../../../../../../Components/Form';
import { WeightInput } from '../../../../../../../Components/Inputs/WeightInput';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

export interface IBalanceAdjustments {
  form: IFChildren;
  productExternalId?: string;
  typeClassProductId?: number;
  lotExternalId?: string;
  storageLocationExternalId?: string;
}

export const BalanceAdjustment: FC<IBalanceAdjustments> = ({
  form,
  productExternalId,
  typeClassProductId,
}) => {
  const { t } = useTranslation();

  const {
    mutateAsync: convertUnitOfMeasureAsync,
    isUnitOfMeasureLoading,
    listunitsMeasurements,
    unitsMeasurementsDefault,
  } = useCreateCallAPI({
    typeClassProductId,
    form,
  });

  const { onUnitOfMeasureChange } = useCreateFunctions({
    form,
    convertUnitOfMeasureAsync,
  });

  const { unitOfMeasureDropdown } = useCreateMemorizations({
    listunitsMeasurements,
    isUnitOfMeasureLoading,
    onUnitOfMeasureChange,
  });

  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  useEffect(() => {
    if (unitMeasureIdDefault) {
      form.setFieldValue('unidadeMedidaId', unitMeasureIdDefault);
    }
    if (form.values.produtoExternalId === undefined) {
      form.setFieldValue('unidadeMedidaId', undefined);
    }
  }, [unitMeasureIdDefault]);

  return (
    <Card
      title={t('supply.balanceAdjustment.priceAdjust')}
      status={getCardStatus(['currentQuantity', 'sobrenome'], form)}
      withoutCollapse={true}
    >
      <Row gutter={[16, 20]}>
        <Col span={8}>
          <WeightInput
            name="prevQnt"
            disabled
            dropdownRight={unitOfMeasureDropdown}
            label={t('supply.balanceAdjustment.currentQuantity')}
            placeHolder={t('supply.balanceAdjustment.currentQuantity')}
          />
        </Col>
        <Col span={8}>
          <WeightInput
            name="amount"
            dropdownRight={unitOfMeasureDropdown}
            label={t('supply.balanceAdjustment.moviment')}
            placeHolder={t('supply.balanceAdjustment.moviment')}
            required
            disabled={!productExternalId || !typeClassProductId}
          />
        </Col>
        <Col span={8}>
          <Tooltip
            title={t('supply.balanceAdjustment.details.errors.newQnt')}
            showMe={form.values.newQnt < 0}
          >
            <WeightInput
              name="newQnt"
              disabled
              dropdownRight={unitOfMeasureDropdown}
              label={t('supply.balanceAdjustment.newQuantity')}
              placeHolder={t('supply.balanceAdjustment.newQuantity')}
              required
              error={form.values.newQnt < 0}
            />
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};

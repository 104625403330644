import { FC } from "react";
import { Col } from 'antd';
import { InputWithSearch } from '@/Components';
import { useTranslation } from "react-i18next";
import { IUfRegister } from "./interfaces";
import { useUfRegisterMemorizations } from "./Hooks/useUfRegisterMemorizations";
import { useUfRegisterFunctions } from "./Hooks/useUfRegisterFunctions";

export const UfRegister: FC<IUfRegister> = ({
  listState,
  fetchNewStatePage,
  listStateLoading,
  refetchRecordTypeList
}) => {
  const { t } = useTranslation();

  const { items } = useUfRegisterMemorizations({ listState });

  const { onSearch } = useUfRegisterFunctions({ refetchRecordTypeList });
  
  return (
    <Col flex="13%">
      <InputWithSearch
        name="ufRegistroExternalId"
        placeHolder="UF"
        label={t('prescribers.createPage.info.UF')}
        required
        items={items}
        onScrollEnd={fetchNewStatePage}
        isLoading={listStateLoading}
        onSearch={onSearch}
        withoutSearchIcon
      />
    </Col>
  );
};
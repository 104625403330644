import { Text } from '../../../../../../Components/Text';
import { Link } from '../../../../../../Components';
import { hasPermission } from '../../../../../../Components/PermissionWrapper';
import { CurrencyText } from '../../../../../../Components/CurrencyText';

export const inventoryCollumn = (t: any) => [
  {
    title: t('supply.inventoryList.tableTitle.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    width: '5%',
  },
  {
    title: t('supply.inventoryList.tableTitle.productName'),
    dataIndex: 'descricao',
    key: 'descricao',
    isHighlightable: true,
    sortColumn: 'descricao',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (x: any, row: any) => {
      return !hasPermission(3002) ? (
        <Text type="ui-tiny-bold" color="text-50">
          {`${row?.descricao}`}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/inventory/products/${row?.produtoExternalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {`${row?.descricao}`}
        </Link>
      );
    },
  },
  {
    title: t('supply.inventoryList.tableTitle.group'),
    dataIndex: 'grupoDescricao',
    key: 'grupoDescrica',
  },
  {
    title: t('supply.inventoryList.tableTitle.subGroup'),
    dataIndex: 'subGrupoDescricao',
    key: 'subGrupoDescricao',
  },
  {
    title: t('supply.inventoryList.tableTitle.quantity'),
    dataIndex: 'saldo',
    key: 'saldo',
    render: (x: any, row: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText
          value={x}
          suffix={` ${row?.unidadeAbreviacao}`}
          decimalScale={4}
        />
      </Text>
    ),
  },
];

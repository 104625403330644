import { Col, Row } from 'antd';
import { CurrencyInput } from '../../../../../../../../Components/Inputs/CurrencyInput';
import { useTranslation } from 'react-i18next';
import { DiscountInput } from '../../../../../../../../Components/Inputs/DiscountInput';
import { useFormikContext } from 'formik';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { FC } from 'react';
import { Tooltip } from '@/Components';
import { currencyFormater } from '@/Utils/Currency';
import { StatusSalesOrderEnum } from '../SideRightMenu/Components';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';

interface IBottomMenu {
  saleOrder: IGetSaleOrderResponse;
  patchSaleOrder: _.DebouncedFunc<
    (
      value: any,
      propName: string,
      fieldType: 'string' | 'number'
    ) => Promise<any>
  >;
  patchSaleOrderLoading: boolean;
  patchSaleOrderSuccess: boolean;
  patchVariables: {
    taxa: string;
    desconto: string;
    valorTotal: string;
    tipoDesconto: string;
  };
}

export const BottomMenu: FC<IBottomMenu> = ({
  saleOrder,
  patchSaleOrder,
  patchSaleOrderLoading,
  patchSaleOrderSuccess,
  patchVariables,
}) => {
  const form: any = useFormikContext();

  const { t } = useTranslation();

  const monetario = 2;

  const retiradaNoBalcao = 1;

  const statusSaleOrder = saleOrder?.statusPedido;

  return (
    <Row gutter={[16, 0]}>
      <Col flex="20%">
        <CurrencyInput
          name="totalProdutos"
          label={t('saleOrder.editOrder.bottomMenu.totalProducts')}
          withoutMarginBottom={true}
          placeHolder={t(
            'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
          )}
          disabled
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('saleOrder.editOrder.bottomMenu.warningProducts'),
          }}
        />
      </Col>
      <Col flex="20%">
        <Tooltip
          title={
            form?.values?.tipoDesconto === monetario
              ? `${saleOrder?.percentualDescontoTotal}%`
              : currencyFormater(saleOrder?.valorDescontoTotal)
          }
          showMe={getCurrentUserRoles().some(
            (x) => PermissionEnum.Vendas_PedidosVenda_EditarDetalhes === x
          )}
          targetOffset={[0, -5]}
        >
          <PermissionWrapper
            permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
            tooltip={t('noPermission.sale.order.editOrder')}
          >
            <DiscountInput
              name={
                form?.values?.tipoDesconto === monetario
                  ? 'desconto'
                  : 'percentualDescontoTotal'
              }
              label={t('saleOrder.editOrder.bottomMenu.totalDiscounts')}
              dropdownLeft={{
                dataTestId: 'tipoDesconto',
                name: 'tipoDesconto',
                onChange(value) {
                  patchSaleOrder(value, 'tipoDesconto', 'number');
                },
              }}
              withoutSuffix
              error={
                form?.values?.desconto <
                saleOrder?.totalProdutosDescontoMonetario
              }
              withLoadingLabel={
                (!!patchVariables?.desconto ||
                  !!patchVariables?.tipoDesconto) &&
                patchSaleOrderLoading
              }
              isSuccessIcon={
                (!!patchVariables?.desconto ||
                  !!patchVariables?.tipoDesconto) &&
                patchSaleOrderSuccess
              }
              withoutPrefix
              onChange={(value) => patchSaleOrder(value, 'desconto', 'number')}
              onBlur={() =>
                form?.values?.tipoDesconto === monetario
                  ? form?.values?.desconto <
                    saleOrder?.totalProdutosDescontoMonetario
                    ? form.setFieldValue(
                        'desconto',
                        saleOrder?.totalProdutosDescontoMonetario
                      )
                    : {}
                  : form?.values?.percentualDescontoTotal <
                    saleOrder?.totalProdutosDescontoPercentual
                  ? form.setFieldValue(
                      'percentualDescontoTotal',
                      saleOrder?.totalProdutosDescontoPercentual
                    )
                  : {}
              }
            />
          </PermissionWrapper>
        </Tooltip>
      </Col>
      <Col flex="20%">
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <CurrencyInput
            name="taxa"
            label={t('saleOrder.editOrder.bottomMenu.deliveryFee')}
            withoutMarginBottom={true}
            placeHolder={t(
              'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
            )}
            disabled={
              !form.values.clienteEnderecoExternalId ||
              form.values.tipoEntregaId === retiradaNoBalcao
            }
            withLoadingLabel={!!patchVariables?.taxa && patchSaleOrderLoading}
            isSuccessIcon={!!patchVariables?.taxa && patchSaleOrderSuccess}
            onChange={(value) => patchSaleOrder(value, 'taxa', 'number')}
          />
        </PermissionWrapper>
      </Col>
      <Col flex="20%">
        <CurrencyInput
          name="valorRecebido"
          label={t('saleOrder.editOrder.bottomMenu.amountReceived')}
          withoutMarginBottom={true}
          placeHolder={t(
            'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
          )}
          disabled
        />
      </Col>
      <Col flex="20%">
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <CurrencyInput
            name="valorTotal"
            label={t('saleOrder.editOrder.bottomMenu.totalValue')}
            withoutMarginBottom={true}
            placeHolder={t(
              'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
            )}
            withLoadingLabel={
              !!patchVariables?.valorTotal && patchSaleOrderLoading
            }
            isSuccessIcon={
              !!patchVariables?.valorTotal && patchSaleOrderSuccess
            }
            onChange={(value) => patchSaleOrder(value, 'valorTotal', 'number')}
            error={form?.values?.valorTotal > saleOrder.valorTotal}
            onBlur={() =>
              form?.values?.valorTotal > saleOrder.valorTotal
                ? form.setFieldValue('valorTotal', saleOrder?.valorTotal)
                : {}
            }
            disabled={statusSaleOrder !== StatusSalesOrderEnum.Orcado}
            withBoldContent
          />
        </PermissionWrapper>
      </Col>
    </Row>
  );
};

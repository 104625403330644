import React, { useEffect, useState } from 'react';
import { Form, Button, i18n } from '../../../../../Components';
import { InfoSection } from './Sections/InfoSection';
import { ValueSection } from './Sections/ValueSection';
import { ProductSection } from './Sections/ProductsSection';
import { useParams } from 'react-router';
import {
  FETCH_GET_INVOICE,
  FETCH_LIST_INVOICE,
  SAVE_PUT_INVOICE
} from '../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { InvoiceEditSchema } from './InvoiceEdit.schema';
import { formatInvoice } from './utils/functions';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../Store';
import DateUtils from '../../../../../Utils/DateUtils';
import { ICreateInvoiceRequest } from '../../../../../Data/Interfaces/request/Invoice/IInvoiceRequest';
import { useQueryClient } from '@tanstack/react-query';
import StorageUtils from '@/Utils/StorageUtils';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IInvoiceGetResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';

import styles from '../Create/styles.module.scss';

export const InvoiceEdit: React.FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalId: externalId
  });
  const [products, setProducts] = useState<any[]>([]);
  const [totalProducts, setTotalProducts] = React.useState<any>(0);
  const [initialValues, setInitialValues] = useState<any>({});
  const [importedProducts, setImportedProducts] = useState<any[]>([]);
  
  const editInvoice: any = useControllerMutateApiHook({
    uniqId: SAVE_PUT_INVOICE,
    entityApi: InvoiceAPI.savePutInvoice,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.editInvoice')
        )
    }
  });

  const queryClient = useQueryClient();

  const preferences = StorageUtils.getPreference();

  const onSubmit = async (values: any) => {
    const body: ICreateInvoiceRequest = {
      ...values,
      dataEmissao: DateUtils.convertDateToApi(
        values.dataEmissao,
        preferences?.padraoData
      ),
      dataEntrega: DateUtils.convertDateToApi(
        values.dataEntrega,
        preferences?.padraoData
      ),
      notaFiscalEntradaExternalId: values.externalId,
    };

    const result = await editInvoice.mutateAsync(body);

    if (result.success) {
      queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
      queryClient.invalidateQueries([FETCH_GET_INVOICE]);
      history.push(
        `/order/invoice/${body.notaFiscalEntradaExternalId}/details`
      );
    }

    try {
    } catch (e) {}
  };

  useEffect(() => {
    if (invoice) {
      setProducts(invoice.itens);
      setTotalProducts(invoice.valorProdutos);
      setInitialValues(formatInvoice(invoice));
    }
  }, [invoice]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      schema={InvoiceEditSchema}
    >
      {() => (
        <>
          <InfoSection
            initialValues={initialValues}
            setImportedProducts={setImportedProducts}
          />
          <ValueSection
            totalProducts={totalProducts}
            initialValues={initialValues}
          />
          <ProductSection
            products={products}
            importedProducts={importedProducts}
            setImportedProducts={setImportedProducts}
            setTotalProducts={(number: number) => setTotalProducts(number)}
          />

          <div className={styles['footer-button']}>
            <div style={{ paddingBottom: '20px' }}>
              <Button
                type="secondary"
                style={{ marginRight: 8 }}
                children={t('common.cancel')}
                onClick={() => history.push('/order/invoice')}
              />
              <Button
                type="primary"
                children={false ? t('common.saving') : t('common.save')}
                htmlType="submit"
              />
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

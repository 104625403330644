import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  EDIT_PACKING_CLASSIFICATION,
  FETCH_GET_PACKING_CLASSIFICATION,
  FETCH_LIST_PACKING_CLASSIFICATION,
} from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';
import { IGetPackingClassification } from '@/Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';
import { IUseCallAPI } from './interfaces';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '@/Store';
import { IEditPackingClassification } from '@/Data/Interfaces/request/PackingClassification/IPackingClassificationRequest';

export const useEditCallAPI = ({ externalId, form, t }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const { data: getPackingClassification, isLoading: loading } =
    useControllerQueryApiHook<IGetPackingClassification>({
      uniqId: FETCH_GET_PACKING_CLASSIFICATION,
      entityApi: PackingClassificationAPI.getCurrentClassificationPacking,
      externalId: externalId,
    });

  const { mutateAsync: editPackingClassification } =
    useControllerMutateApiHook<IEditPackingClassification>({
      uniqId: EDIT_PACKING_CLASSIFICATION,
      entityApi: PackingClassificationAPI.editPackingClassification,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_LIST_PACKING_CLASSIFICATION]);
          history.push('/production/packing-classification');
        },
      },
    });

  return {
    getPackingClassification,
    loading,
    editPackingClassification,
  };
};

import { useState } from 'react';

export const useCreateStates = () => {
  const [onProdutoExternalIdSelected, setOnProdutoExternalIdSelected] =
    useState<string>();

  const [onClassProductIdSelected, setOnClassProductIdSelected] =
    useState<number>();

  const [lotExternalIdSelected, setlotExternalIdSelected] = useState<string>();

  const [
    storageLocationExternalIdSelected,
    setStorageLocationExternalIdSelected,
  ] = useState<string>();

  return {
    onProdutoExternalIdSelected,
    setOnProdutoExternalIdSelected,
    onClassProductIdSelected,
    setOnClassProductIdSelected,
    lotExternalIdSelected,
    setlotExternalIdSelected,
    storageLocationExternalIdSelected,
    setStorageLocationExternalIdSelected,
  };
};

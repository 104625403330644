import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useSpecialtyMemorizations = ({ specialtiesData }: IUseMemorizations) => {
  const items = useMemo(() => {
    return specialtiesData?.data?.map((x: any) => ({
      id: x.externalId,
      label: x.descricao,
    }));
  }, [specialtiesData]);

  return { items };
};
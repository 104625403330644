import { useTranslation } from 'react-i18next';

import { Status } from '../../../../../../../Components';
import { Skeleton } from '../../../../../../Skeleton';

import { FETCH_GET_INVOICE } from '../../../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';

import {
  filterStateColor,
  filterStateText,
} from '../../../../../../../Pages/Sectors/Order/Invoice/List/utils/functions';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { IInvoiceGetResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';

export const DynamicEditInfosInvoiceBreadcrumb = ({ match }: any) => {

  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalId: match.params.externalId
  });
  const { t } = useTranslation();
  const invoiceStatus = filterStateText(invoice?.status);

  if (!invoice) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {t('breadCrumb.purchasing.invoice.create.title')}
      <div style={{ marginLeft: '12px' }}>
        <Status
          iconColor="status-disabled-base"
          type={filterStateColor(invoice?.status)}
          leftIcon={invoice.status === 1 ? 'pencil' : undefined}
        >
          {t(invoiceStatus)}
        </Status>
      </div>
    </div>
  );
};

const formatInvoiceNumber = (invoice: any): string => {
  if (!invoice) return '...';

  // Convert the number to a string and add leading zeros if necessary
  const formattedInvoiceNumber = String(invoice.numero).padStart(9, '0');
  const serie = String(invoice.serie).padStart(3, '0');

  return `${formattedInvoiceNumber} / ${serie}`;
};

export const DynamicInvoiceDetailBreadcrumb = ({ match }: any) => {
  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalId: match.params.externalId
  });
  const { t } = useTranslation();
  const invoiceStatus = filterStateText(invoice?.status);

  const formattedInvoiceNumber = formatInvoiceNumber(invoice);

  if (!invoice) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {t('breadCrumb.purchasing.invoice.invoice')} | {formattedInvoiceNumber}
      <div style={{ marginLeft: '12px' }}>
        <Status
          iconColor="status-disabled-base"
          type={filterStateColor(invoice?.status)}
          leftIcon={invoice.status === 1 ? 'pencil' : undefined}
        >
          {t(invoiceStatus)}
        </Status>
      </div>
    </div>
  );
};

export const DynamicInvoiceBreadcrumb = ({ match }: any) => {
  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalId: match.params.externalId
  });
  const formattedInvoiceNumber = formatInvoiceNumber(invoice);

  return <span style={{ display: 'flex' }}>{formattedInvoiceNumber}</span>;
};

export const DynamicInvoiceRegisterLotsBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();
  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalId: match.params.externalId
  });
  const formattedInvoiceNumber = formatInvoiceNumber(invoice);

  if (!invoice) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <span style={{ display: 'flex' }}>
      {t('breadCrumb.purchasing.invoice.registerLots.title')}
      {formattedInvoiceNumber}
    </span>
  );
};

import React from 'react';
import { i18n, Link, Status } from '../../../../../../../../Components';
import { currencyFormater } from '../../../../../../../../Utils/Currency';

export const productCollums = (t: any, currentCurrency?: number) => [
  {
    title: t('products.productList.tableTitle.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    sortColumn: 'sequenciaGroupTenant',
    width: '5%',
  },
  {
    title: t('products.productList.tableTitle.productName'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    width: '50%',
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: string, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/inventory/products/${row?.ExternalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('products.productList.tableTitle.costValue'),
    dataIndex: 'valorCusto',
    key: 'valorCusto',
    sortColumn: 'valorCusto',
    render: (x: any, row: any) => currencyFormater(x, row.unidadeMedida),
  },
  {
    title: t('products.productList.tableTitle.saleValue'),
    dataIndex: 'valorVenda',
    key: 'valorVenda',
    sortColumn: 'valorVenda',
    render: (x: any, row: any) => currencyFormater(x, row.unidadeMedida),
  },
  {
    title: t('products.productList.tableTitle.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    sortColumn: 'ativo',
    render: (active: string) => (
      <Status type={active ? 'success' : 'disabled'}>
        {i18n.t(
          `userGroups.list.statusTypes.${active ? 'active' : 'inactive'}`
        )}
      </Status>
    ),
  },
];

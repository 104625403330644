export const callCenterEN = {
  service: 'Tickets',
  listPage: {
    code: 'Code',
    clientName: 'Client Name',
    creationDate: 'Creation Date',
    lastUpdate: 'Last update',

    attendant: 'Attendant',

    newButton: 'New Service',

    searchPlaceholder:
      'Search for a customer, code, CNPJ/CPF, main contact, etc.',
  },

  createService: {
    newService: 'New Service',
    client: 'Client',
    clientPlaceholder: 'Search or register a customer',
    newClient: 'New Client',

    channel: 'Channel',
    attendant: 'Attendant',

    code: 'Code',

    createClientModal: {
      newClient: 'Register new customer',
      createCompleteData: 'Register complete data',

      physicalPerson: 'Physical person',
      legalPerson: 'Legal person',

      fantasyName: 'Fantasy Name',
      fullName: 'Full name',

      socialReason: 'Social Reason',

      cellphone: 'Telephone',
    },
  },

  status: {
    awaitingService: 'Awaiting Service',
    inService: 'In Service',
    budgetSent: 'Budget Sent',
    serviceCompleted: 'Service Completed',
    awaitingRevenue: 'Awaiting Revenue',
    finished: 'Finished',
  },

  messages: {
    create: 'Service created!',
  },
};

export const callCenterPT = {
  service: 'Atendimentos',
  listPage: {
    code: 'Código',
    clientName: 'Nome do Cliente',
    creationDate: 'Data Criação',
    lastUpdate: 'Última Atualização',

    attendant: 'Atendente',

    newButton: 'Novo Atendimento',

    searchPlaceholder:
      'Pesquise por um cliente, código, CNPJ/CPF, contato principal, etc.',
  },

  createService: {
    newService: 'Novo Atendimento',
    client: 'Cliente',
    clientPlaceholder: 'Pesquise ou cadastre um cliente',
    newClient: 'Novo Cliente',

    channel: 'Canal',
    attendant: 'Atendente',

    code: 'Código',

    createClientModal: {
      newClient: 'Cadastrar novo cliente',
      createCompleteData: 'Cadastrar dados completos',

      physicalPerson: 'Pessoa física',
      legalPerson: 'Pessoa jurídica',

      fantasyName: 'Nome Fantasia',
      fullName: 'Nome completo',

      socialReason: 'Razão Social',

      cellphone: 'Telefone',
    },
  },
  status: {
    awaitingService: 'Aguardando Atendimento',
    inService: 'Em Atendimento',
    budgetSent: 'Orçamento Enviado',
    serviceCompleted: 'Atendimento Concluído',
    awaitingRevenue: 'Aguardando Receita',
    finished: 'Finalizado',
  },

  messages: {
    create: 'Atendimento criado!',
  },
};

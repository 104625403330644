import { FC } from "react";
import { Card } from '@/Components';
import { AdderList } from '@/Components/AdderList';
import { useTranslation } from 'react-i18next';
import { IFChildren } from "@/Components/Form";
import { useDocumentCardMemorizations } from "./Hooks/useDocumentCardMemorizations";
import { useDocumentCardFunctions } from "./Hooks/useDocumentCardFunctions";

export const DocumentCard: FC<{ props: IFChildren }> = ({ props }) => {
  const { t } = useTranslation();

  const {
    column,
    modal
  } = useDocumentCardMemorizations();

  const { onChange } = useDocumentCardFunctions({ props });

  return (
    <Card title={t('document.documents')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        collum={column}
        modal={modal}
        type="documentType"
        onChange={onChange}
        buttonLabel={t('document.addDocument')}
        empytDataDescription={t('document.documentEmpyt')}
        actions
        entityExternalIdName="prescritorDocumentoExternalId"
        withoutFavorite
      />
    </Card>
  );
};
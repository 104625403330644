import { useQueryClient } from '@tanstack/react-query';
import {
  DELETE_LABORATORY,
  EDIT_STATUS_LABORATORY,
  FETCH_LIST_FOR_LABORATORY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const deleteLaboratoryForm = useControllerMutateApiHook({
    uniqId: DELETE_LABORATORY,
    entityApi: LaboratoryAPI.deleteLabortory,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_FOR_LABORATORY]),
    },
  });

  const {
    data: laboratoryList,
    isLoading: isLoadingLaboratoryList,
    refetch: refetchLaboratoryList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_LABORATORY,
    entityApi: LaboratoryAPI.getLaboratoryList,
    initialPagination: {
      pageIndex: 0,
      pageSize: 10,
      sorter: { column: 'laboratorio', direction: 'ASC' },
    },
  });

  const editLaboratoryFormStatus = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_LABORATORY,
    entityApi: LaboratoryAPI.laboratoryStatus,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_LABORATORY]);
      },
    },
  });

  return {
    laboratoryList,
    isLoadingLaboratoryList,
    refetchLaboratoryList,
    deleteLaboratoryForm,
    editLaboratoryFormStatus,
  };
};

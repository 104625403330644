import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../Components';
import {
  IPermissionWrapper,
  PermissionWrapper,
} from '../../../../Components/PermissionWrapper';

import styles from './FooterButton.module.scss';

interface IFooterButton {
  disabled?: boolean;
  editLabel?: string;
  onChange?: (value: boolean) => void;
  permission?: IPermissionWrapper;
  cancelButtonClick?: () => void;

  loading?: boolean;

  dataTestId?: string;
}

export const FooterButton: React.FC<IFooterButton> = ({
  disabled,
  editLabel,
  onChange,
  permission,
  cancelButtonClick,
  loading,
  dataTestId,
}) => {
  const { t } = useTranslation();

  const form = useFormikContext();

  return (
    <div className={styles['div-footer-button']}>
      {disabled ? (
        <PermissionWrapper
          permission={permission?.permission}
          tooltip={permission?.tooltip || 'noPermission.default'}
        >
          <Button
            type="secondary"
            children={editLabel || t('accountManager.accountDetailsPage.edit')}
            onClick={() => onChange && onChange(false)}
            dataTestId={dataTestId}
          />
        </PermissionWrapper>
      ) : (
        <div className={styles['buttons']}>
          <Button
            type="secondary"
            children={t('accountManager.accountDetailsPage.cancel')}
            onClick={() => {
              onChange && onChange(true);
              cancelButtonClick && cancelButtonClick();
              form.resetForm();
            }}
          />
          <Button
            className={styles['save-button']}
            type="primary"
            children={t('accountManager.accountDetailsPage.save')}
            htmlType="submit"
            loading={loading}
            dataTestId={dataTestId}
          />
        </div>
      )}
    </div>
  );
};

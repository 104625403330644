import { FC, useEffect } from 'react';
import { Row } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Card
} from '@/Components';
import { Modal } from '@/Components/Modal';
import { ModalBody } from '../../Edit/Modal/LotContralModal';
import { IProductCardBody } from './interfaces';
import { ProductCode } from './Components/ProductCode';
import { ProductName } from './Components/ProductName';
import { LabelDescription } from './Components/LabelDescription';
import { Class } from './Components/Class';
import { Group } from './Components/Group';
import { SubGroup } from './Components/SubGroup';
import { UnitStock } from './Components/UnitStock';
import { Provider } from './Components/Provider';
import { BatchControl } from './Components/BatchControl';
import { QualityControl } from './Components/QualityControl';
import { ContinueUse } from './Components/ContinueUse';
import { StockLabel } from './Components/StockLabel';
import { useProductCardStates } from './Hooks/useProductCardStates';
import { useProductCardFunctions } from './Hooks/useProductCardFunctions';
import { useProductCardCallAPI } from './Hooks/useProductCardCallAPI';

import styles from './ProductCard.module.scss';

export const ProductInfoCard: FC<IProductCardBody> = ({
  disabledForm,
  product
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  
  const {
    lotControlModal,
    setLotControlModal
  } = useProductCardStates();

  const {
    listSubGroups,
    fetchNewSubGroupPage,
    refetchNewPageSubGroupPege
  } = useProductCardCallAPI();

  const {
    handlerGroupDropdown,
    onCancelClick
  } = useProductCardFunctions({
    refetchNewPageSubGroupPege,
    setLotControlModal
  });

  useEffect(() => {
    form.values.grupoExternalId !== form.initialValues.grupoExternalId &&
      form?.setFieldValue('subGrupoExternalId', undefined);
  }, [form?.values?.grupoExternalId]);

  useEffect(() => {
    if (!disabledForm) {
      form.resetForm();
    }
  }, [disabledForm]);

  useEffect(() => {
    refetchNewPageSubGroupPege({
      pageIndex: 0,
      pageSize: 10,
      filter: [
        {
          filterName: 'grupoExternalId',
          value: product?.grupoExternalId,
        },
      ],
    });
  }, [product?.grupoExternalId]);

  return (
    <>
      <Card title={t('products.create.titleInformation')} withoutCollapse>
        <Row gutter={[16, 0]}>
          {product && (
            <ProductCode product={ product } />
          )}
          <ProductName
            product={product}
            disabledForm={disabledForm}
          />
          <LabelDescription
            product={product}
            disabledForm={disabledForm}
          />
        </Row>
        <Row gutter={[16, 0]}>
          <Class
            product={product}
            disabledForm={disabledForm}
          />
          <Group
            product={product}
            disabledForm={disabledForm}
            handlerGroupDropdown={handlerGroupDropdown} 
          />
          <SubGroup
            product={product}
            disabledForm={disabledForm}
            listSubGroups={listSubGroups}
            fetchNewSubGroupPage={fetchNewSubGroupPage}
          />
          <UnitStock
            product={product}
            disabledForm={disabledForm}
          />
        </Row>
        <Row>
          <Provider
            product={product}
            disabledForm={disabledForm}
          />
        </Row>
        <div className={styles['divider']} />
        <Row gutter={[16, 0]}>
          <BatchControl
            product={product}
            disabledForm={disabledForm}
            setLotControlModal={setLotControlModal} 
          />
          <QualityControl
            product={product}
            disabledForm={disabledForm}  
          />
          <ContinueUse
            product={product}
            disabledForm={disabledForm}  
          />
          <StockLabel
            product={product}
            disabledForm={disabledForm}  
          />
        </Row>
      </Card>
      <Modal
        visible={lotControlModal}
        onCancelClick={onCancelClick}
        cancelButtonName={t('common.close')}
        title={t('products.modal.products.title')}
        withoutOkButton
        body={<ModalBody />}
      />
    </>
  );
};

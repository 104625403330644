import { FC, useEffect } from 'react';
import {
  Tooltip
} from '@/Components';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IDosageInputs } from './interfaces';
import { useDosageInputsStates } from './Hooks/useDosageInputsStates';
import { useDosageInputsCallAPI } from './Hooks/useDosageInputsCallAPI';
import { 
  IIListUnitMeasurementAllData 
} from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { useDosageInputsFunctions } from './Hooks/useDosageInputsFunctions';
import { useDosageInputsMemorizations } from './Hooks/useDosageInputsMemorizations';

export const DosageInputs: FC<IDosageInputs> = ({
  disabledForm,
  associatedProductData
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    unitAbbreviation,
    setUnitAbbreviation,
    paginationUnitMeasurement
  } = useDosageInputsStates();

  const {
    unitMeasurementList
  } = useDosageInputsCallAPI({  
    paginationUnitMeasurement
  });

  const {
    onChangeMaximalDosage
  } = useDosageInputsFunctions();

  const {
    dropdownRightMinimalDosage,
    dropdownRightAssociatedQuantity
  } = useDosageInputsMemorizations({
    unitMeasurementList,
    setUnitAbbreviation
  });

  useEffect(() => {
    if (form.values.dosagemMinima && unitMeasurementList) {
      const unit = unitMeasurementList.data ? unitMeasurementList?.data?.find(
        (e: IIListUnitMeasurementAllData) => e.id === form.values.unidadeMedidaDosagem
      ) : undefined;
      setUnitAbbreviation(unit?.unidadeAbreviacao || '');
    }
  }, [form.values.dosagemMinima, unitMeasurementList]);

  return (
    <>
      <Col span={3}>
        <Tooltip
          title={`${t('products.associated.originalMinimalDosage')}${
            associatedProductData?.dosagemMinima
          }`}
          showMe={
            associatedProductData &&
            associatedProductData.dosagemMinima !== form?.values?.dosagemMinima
          }
          targetOffset={[80, -10]}
        >
          <WeightInput
            name="dosagemMinima"
            label={t('products.diluted.minimalDosage')}
            dropdownRight={dropdownRightMinimalDosage}
            withStatusIcon={!disabledForm}
            placeHolder="0,000"
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('products.associated.minimalDosageTooltip'),
            }}
            disabled={disabledForm}
          />
        </Tooltip>
      </Col>
      <Col span={3}>
        <Tooltip
          title={`${t('products.associated.originalMaximalDosage')}${
            associatedProductData?.dosagemMaxima
          }`}
          showMe={
            associatedProductData &&
            associatedProductData.dosagemMaxima !== form?.values?.dosagemMaxima
          }
          targetOffset={[80, -10]}
        >
          <WeightInput
            name="dosagemMaxima"
            label={t('products.diluted.maximalDosage')}
            placeHolder="0,000"
            rightIcon={{
              titleString: unitAbbreviation || 'mg',
            }}
            withStatusIcon={!disabledForm}
            disabled={disabledForm}
            onChange={onChangeMaximalDosage}
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('products.associated.maximalDosageTooltip'),
            }}
          />
        </Tooltip>
      </Col>
      <Col span={3}>
        <Tooltip
          title={`${t('products.associated.originalAssociatedQuantity')}${
            associatedProductData?.quantidadeAssociada
          }`}
          showMe={
            associatedProductData &&
            associatedProductData.quantidadeAssociada !==
              form?.values?.quantidadeAssociada
          }
          targetOffset={[80, -10]}
        >
          <WeightInput
            name="quantidadeAssociada"
            label={t('products.associated.associatedQuantity')}
            required={!disabledForm}
            withStatusIcon={!disabledForm}
            placeHolder="0,000"
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('products.associated.associatedQuantityTooltip'),
            }}
            disabled={disabledForm}
            dropdownRight={dropdownRightAssociatedQuantity}
          />
        </Tooltip>
      </Col>
    </>
  );
};
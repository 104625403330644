import React from 'react';
import { Modal } from '../../../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

interface IDeleteModalProps {
  visible?: boolean;
  deleteRow: () => void;
  changeVisibleModal: (value: boolean) => void;
  product?: string;
}

const DeleteProductModal: React.FC<IDeleteModalProps> = ({
  visible = false,
  deleteRow,
  changeVisibleModal,
  product,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t('purchasing.invoice.create.remove')}${
        product ? ` "${product}"` : ''
      }?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex
            style={{
              flexDirection: 'column',
              marginBottom: 15,
              width: '400px',
            }}
          >
            <ReactMarkdown>
              {t('modal.deleteModal.single.productQuestion', {
                produto: `${product ? `**${product}**` : ''}`,
              })}
            </ReactMarkdown>
          </Divflex>
        </div>
      }
      visible={visible}
      okButtonColor="status-danger-base"
      onOkClick={deleteRow}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};
export default DeleteProductModal;

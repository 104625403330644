import _ from 'lodash';
import { IEditPackingClassification } from '@/Data/Interfaces/request/PackingClassification/IPackingClassificationRequest';
import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';

export const useEditFunctions = ({
  editPackingClassification,
  getPackingClassification,
}: IUseFunctions) => {
  const onSubmit = useCallback(
    async (values: IEditPackingClassification) => {
      if (getPackingClassification) {
        return await editPackingClassification({
          externalId: getPackingClassification.externalId,
          descricao: values.descricao,
          ativo: values.ativo,
        });
      }
    },
    [getPackingClassification]
  );

  return { onSubmit };
};
